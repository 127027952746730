import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import './Login.css';
import { Circle } from 'react-spinners-css'
import qs from 'query-string'

//import actions
import { socialLogin, getProfile } from '../../services/auth/authActions';


class Social extends React.Component {
    constructor(props) {
        super(props);

        this.change = this.change.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (this.props.error !== prevProps.error && this.props.error != null) {
            // toastr.error('Failed', this.props.error);
        }
        if (prevProps.token !== this.props.token && this.props.token != null) {
            // toastr.success('Success', this.props.message);
            this.props.authActions.getProfile();
        }
        if (prevProps.profile != this.props.profile) {
            this.props.history.push(`/myhipscans/detail/${this.props.profile.code.id}`)
            window.location.reload()
        }
    }

    componentWillMount() {
        if (localStorage.getItem("token") != null) {
            this.props.authActions.getProfile();
        }

        let uuid = qs.parse(this.props.location.search).oauth
        this.props.authActions.socialLogin(uuid)
    }

    change = (e) => { this.setState({ [e.target.name]: e.target.value }); }

    render() {
        return (
            <div className='loading-container'>
                <Circle />
            </div>
        )
    }
}

export default connect(
    state => ({
        ...state.default.services.auth
    }),
    dispatch => ({
        authActions: bindActionCreators({ socialLogin, getProfile }, dispatch)
    })
)(Social);