/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { notification } from "antd";
import _ from "lodash";
import {
  FaDownload,
  FaPhotoVideo,
  FaRegPlayCircle,
  FaRegCheckCircle,
  FaCopy,
} from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { TiSocialYoutubeCircular, TiSocialVimeoCircular } from "react-icons/ti";
import Iframe from "react-iframe";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import logo from "../../../assets/images/logo.svg";
import themeOne from "../../../assets/images/theme-1.png";
import themeTwo from "../../../assets/images/theme-2.png";
import patternOne from "../../../assets/images/pattern-1.png";
import patternTwo from "../../../assets/images/pattern-2.png";
import patternThree from "../../../assets/images/pattern-3.png";
import patternFour from "../../../assets/images/pattern-4.png";
import patternFive from "../../../assets/images/pattern-5.png";
import patternSix from "../../../assets/images/pattern-6.png";
import "./DetailMyHipScan.css";
import LaunchAnEvent from "./LaunchAnEvent";
// import ModalWrapper from '../ModalWrapper';
import moment from "moment";

import Header2 from "../../../components/Header2/Header2";

import QRCodeImage from "../../../assets/images/qr-code.png";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { GiOpenFolder, GiCancel } from "react-icons/gi";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

//import actions
import {
  getHipscanDetail,
  updateHipscan,
  updateContact,
  downloadQrCode,
  previewCustomDesign,
  saveCustomDesign,
} from "../../../services/profile/profileActions";
import { getProfile } from "../../../services/auth/authActions";
import { MdEdit, MdSave, MdCancel } from "react-icons/md";
import ProfilePhone from "../../../components/ProfilePhone/ProfilePhone";
import ProductPhone from "../../../components/ProductPhone/ProductPhone";
import MenuPhone from "../../../components/MenuPhone/MenuPhone";
import ContactInfoModal from "../../../components/ContactInfoModal/ContactInfoModal";
import RestaurantMenuModal from "../../../components/RestaurantMenuModal/RestaurantMenuModal";
import StatisticsNew from "../../../components/StatisticsNew";
import { CirclePicker } from "react-color";
import PartnerScan from "../../../components/PartnerScan";

import ImgInfo from "../../../assets/icons/remind-icon.svg";

var OAuth = require("@zalando/oauth2-client-js");

// styling of input field
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#383838",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#383838",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#383838",
      },
    },
  },
})(TextField);

const colorList = [
  '#000000',
  '#E64C15',
  '#DBE3EE',
  '#E86C77',
  '#6A7788',
  '#77D77E',
  '#64CDE3',
  '#DBE67E',
  '#BA6BC6',
  '#fa3d4a'
];

class DetailMyHipScan extends React.Component {
  constructor(props) {
    super(props);

    let uuid = this.props?.myHipscans?.find(hash => hash.id == this.props.match.params.id)?.uuid;
    this.state = {
      content: 'Copy URL',
      qrcolor: "#000000",
      qr_image_url: "",
      isEditingLabel: false,
      mainHipscanLabel: "",
      backUpmainHipscanLabel: "",
      isEditing: "none",
      inputURL: "",
      previewURL: "",
      text: "",
      location: '',
      previewLocation: '',
      file: '',
      previewFile: '',
      wifiName: '',
      wifiPassword: '',
      social: '',
      previewSocial: '',
      selectedFile: null,
      imageUrl: "",
      profilePreview: {
        contact: {},
      },
      productPreview: {},
      menuPreview: {
        contact: {},
      },
      myHipscanDetail: {},
      show: false,
      menuShow: false,
      urlTyping: false,
      urlTypingTimeout: 0,
      isContactChanged: false,
      isDownload: false,
      letterCount: 0,
      linkIframeEditNotWork: false,
      linkIframeViewNotWork: false,
      pmw_token: null,
      pmw_userId: null,
      design_type: null,
      flyer_designs: [],
      flyer_design: null,
      customize_plan_upgrade: false,
      designLabel: "",
      isCustomDesign: false,
      customDesignTheme: "",
      customDesignPattern: "",
      customDesignColor: "",
      designFile: "",
      designUrl: "",
      previewCustomDesign: "",
      qrId: null,
      linkedInCode: "",
      color: "#4193B9",
      uuid: uuid
    };

    this.iframeRef = React.createRef();
    this.iframeMbRef = React.createRef();

    this.clickedIncludeVideo = this.clickedIncludeVideo.bind(this);
    this.setPreviewURL = this.setPreviewURL.bind(this);
    this.setPreviewLocation = this.setPreviewLocation.bind(this)
    this.setPreviewFile = this.setPreviewFile.bind(this)
    this.setPreviewSocial = this.setPreviewSocial.bind(this)
    this.qrColorHandle = this.qrColorHandle.bind(this);
    this.editHaciendaLocation = this.editHaciendaLocation.bind(this);
    this.cancelHaciendaLocation = this.cancelHaciendaLocation.bind(this);
    this.saveHaciendaLocation = this.saveHaciendaLocation.bind(this);
    this.inputHandle = this.inputHandle.bind(this);
    this.editUrl = this.editUrl.bind(this);
    this.inputURLHandle = this.inputURLHandle.bind(this);
    this.locationHandle = this.locationHandle.bind(this)
    this.fileHandle = this.fileHandle.bind(this)
    this.socialHandle = this.socialHandle.bind(this)
    this.wifiNameHandle = this.wifiNameHandle.bind(this)
    this.wifiPasswordHandle = this.wifiPasswordHandle.bind(this)
    this.textChange = this.textChange.bind(this);
    this.upload = this.upload.bind(this);
    this.cancelUpload = this.cancelUpload.bind(this);
    this.uploadProfile = this.uploadProfile.bind(this);
    this.uploadProduct = this.uploadProduct.bind(this);
    this.fileSelectHandle = this.fileSelectHandle.bind(this);
    this.videoURLChange = this.videoURLChange.bind(this);
    this.nameChangeHandle = this.nameChangeHandle.bind(this);
    this.titleChangeHandle = this.titleChangeHandle.bind(this);
    this.descriptionChangeHandle = this.descriptionChangeHandle.bind(this);
    this.profileFileSelectHandle = this.profileFileSelectHandle.bind(this);
    this.productNameChange = this.productNameChange.bind(this);
    this.priceChange = this.priceChange.bind(this);
    this.productVideoURLChange = this.productVideoURLChange.bind(this);
    this.productDescriptionChange = this.productDescriptionChange.bind(this);
    this.productFileSelectHandle = this.productFileSelectHandle.bind(this);
    this.modalShow = this.modalShow.bind(this);
    this.modalHide = this.modalHide.bind(this);
    this.saveContact = this.saveContact.bind(this);
    this.onChangeBFile = this.onChangeBFile.bind(this);
    this.isIncludedVideoHandle = this.isIncludedVideoHandle.bind(this);
    this.emailHandle = this.emailHandle.bind(this);
    this.emailSubjectHandle = this.emailSubjectHandle.bind(this);
    this.emailBodyHandle = this.emailBodyHandle.bind(this);
    this.companyHandle = this.companyHandle.bind(this);
    this.meetingLinkHandle = this.meetingLinkHandle.bind(this);

    this.numberHandle = this.numberHandle.bind(this);
    this.websiteHandle = this.websiteHandle.bind(this);
    this.facebookHandle = this.facebookHandle.bind(this);
    this.instagramHandle = this.instagramHandle.bind(this);
    this.twitterHandle = this.twitterHandle.bind(this);
    this.videoURL = this.videoURL.bind(this);
    this.restaurantNameHandle = this.restaurantNameHandle.bind(this);
    this.menuDescriptionHandle = this.menuDescriptionHandle.bind(this);
    this.menuFileSelectHandle = this.menuFileSelectHandle.bind(this);
    this.addressHandle = this.addressHandle.bind(this);
    this.menuModalShow = this.menuModalShow.bind(this);
    this.menuModalHide = this.menuModalHide.bind(this);
    this.eventModalHide = this.eventModalHide.bind(this);
    this.eventModalShow = this.eventModalShow.bind(this);
    this.updateLetterCount = this.updateLetterCount.bind(this);

    this.checkIframeLoaded = this.checkIframeLoaded.bind(this);
    this.afterLoading = this.afterLoading.bind(this);

    this.handleSaveFlyer = this.handleSaveFlyer.bind(this);
    this.handleCloseFlyer = this.handleCloseFlyer.bind(this);

    this.popupCenter = this.popupCenter.bind(this);
    this.oauthCode = this.oauthCode.bind(this);
    this.oauthClosed = this.oauthClosed.bind(this);
    this.getDesignInfo = this.getDesignInfo.bind(this);

    this.launch_postermywall = this.launch_postermywall.bind(this);
    this.selectFlyerDesign = this.selectFlyerDesign.bind(this);
    this.editFlyerDesign = this.editFlyerDesign.bind(this);
    this.openFlyerDesigner = this.openFlyerDesigner.bind(this);

    this.customizeQRCode = this.customizeQRCode.bind(this);
    this.onDesignLabelChange = this.onDesignLabelChange.bind(this);

    this.onCustomDesignThemeChange = this.onCustomDesignThemeChange.bind(this);
    this.onCustomDesignPatternChange =
      this.onCustomDesignPatternChange.bind(this);
    this.onCustomDesignColorChange = this.onCustomDesignColorChange.bind(this);
    this.designSelectHandle = this.designSelectHandle.bind(this);
    this.handleSaveCustomDesign = this.handleSaveCustomDesign.bind(this);
    this.handleCancelCustomDesign = this.handleCancelCustomDesign.bind(this);
    this.handleClickCopyText = this.handleClickCopyText.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.myHipscanDetail === undefined) return;

    if (prevProps.profile !== this.props.profile) {
      if (!this.props.profile.access) {
        this.props.history.push("/plans");
      }
    }

    if (prevState.myHipscanDetail !== this.state.myHipscanDetail) {
      await this.onLoadIframeView();
    }
    if (prevState.previewURL !== this.state.previewURL) {
      await this.onLoadIframeEdit();
    }
    if (prevState.previewLocation !== this.state.previewLocation) {
      await this.onLoadIframeEdit();
    }
    if (prevState.previewFile !== this.state.previewFile) {
      await this.onLoadIframeEdit();
    }
    if (prevState.previewSocial !== this.state.previewSocial) {
      this.onLoadIframeEdit()
    }

    if (this.props.error !== prevProps.error && this.props.error !== null) {
      // toastr.error('Failed', this.props.error);
    }
    if (prevProps.myHipscanDetail !== this.props.myHipscanDetail) {
      this.setState({ mainHipscanLabel: this.props.myHipscanDetail.label });
      let item = this.props.myHipscanDetail;
      if (item.type === "profile") {
        item.contact.isIncludedVideo =
          item.contact.video_url !== "";
      }
      if (item.type === "product") {
        item.isIncludedVideo =
          item.video && item.video !== "undefined";
      }

      this.setState({ myHipscanDetail: item });
      this.setState({
        qr_image_url: this.props.myHipscanDetail.code_image_url,
      });
    }

    if (prevProps.qrCode !== this.props.qrCode) {
      if (this.props.qrCode.code_image_url !== null) {
        if (this.state.isDownload) {
          window.open(this.props.qrCode.code_image_url, "_blank");
          this.setState({
            isDownload: false,
          });
        } else if (
          this.state.qr_image_url !== this.props.qrCode.code_image_url
        ) {
          this.setState({
            qr_image_url: this.props.qrCode.code_image_url,
          });
        }
      }
    }

    if (prevProps.updateHipscan !== this.props.updateHipscan) {
      this.setState({ mainHipscanLabel: this.props.updateHipscan.label });
      let item = this.props.updateHipscan;
      if (item.type === "profile") {
        item.contact.isIncludedVideo =
          item.contact.video_url !== "";
      }
      if (item.type === "product") {
        item.isIncludedVideo =
          item.video && item.video !== "undefined";
      }

      this.setState({ qr_image_url: this.props.updateHipscan.code_image_url });

      if (this.state.isEditingLabel) {
        this.setState({
          backUpmainHipscanLabel: this.props.updateHipscan.label,
        });
        this.setState({ isEditingLabel: !this.state.isEditingLabel });
      }
      this.setState({ myHipscanDetail: item });
      if (
        this.state.isEditing === "profile" ||
        this.state.isEditing === "menu"
      ) {
        if (this.state.isContactChanged) {
          this.saveContact();
        } else {
          this.setState({
            isEditing: "none",
            isContactChanged: false,
          });
          // toastr.success('Success', this.props.message);
          this.editUrl("none");
        }
      } else {
        this.setState({ isEditing: "none" });
        // toastr.success('Success', this.props.message);
        this.editUrl("none");
      }
    }

    if (prevProps.updatedContact !== this.props.updatedContact) {
      // this.state.isEditing = 'none';
      this.setState({ isEditing: "none" });
      // toastr.success('Success', this.props.message);
      this.editUrl("none");
    }

    if (
      prevProps.previewCustomDesign?.imageUrl !==
      this.props.previewCustomDesign?.imageUrl
    ) {
      this.setState({
        previewCustomDesign: this.props.previewCustomDesign.imageUrl,
      });
    }

    if (
      prevProps.saveCustomDesign?.qr_code_image_url !==
      this.props.saveCustomDesign?.qr_code_image_url
    ) {
      this.setState({
        qr_image_url: this.props.saveCustomDesign?.qr_code_image_url,
      });
    }

    if (!_.isEmpty(this.props.myHipscanDetail)) {
      if (!_.isEmpty(this.props.myHipscanDetail.contact)) {
        if (this.props.myHipscanDetail.contact.color !== this.state.color) {
          this.setState({ color: this.props.myHipscanDetail.contact.color });
        }
      }
    }
  }

  componentWillMount() {
    this.setState({ qrId: this.props.match.params.id });
    this.props.profileActions.getHipscanDetail(this.props.match.params.id);

    if (!this.props.profile) {
      this.props.authActions.getProfile();
    }
  }

  componentDidMount = async () => {
    await window.scrollTo(0, 0);
    await this.onLoadIframeView();
    await this.onLoadIframeEdit();

    this.props.profileActions.getHipscanDetail(this.props.match.params.id);

    if (this.props.profile && !this.props.profile.access) {
      this.props.history.push("/plans");
    }
  };

  onCustomDesignColorChange = (color) => {
    this.setState({ customDesignColor: color.hex }, () => {
      this.handlePreviewCustomDesign();
    });
  };

  onCustomDesignThemeChange = (theme) => {
    this.setState({ customDesignTheme: theme }, () => {
      this.handlePreviewCustomDesign();
    });
  };

  onCustomDesignPatternChange = (pattern) => {
    this.setState({ customDesignPattern: pattern }, () => {
      this.handlePreviewCustomDesign();
    });
  };

  handleClickCopyText() {
    let copied_link = `https:://app.hipscan.com/show/${this.state.uuid}`
    let button_content = this.state.content
    this.setState({ content: copied_link && button_content === 'Copy URL' ? 'Copied' : 'Copy URL' })

    navigator.clipboard.writeText(copied_link)
  }

  handlePreviewCustomDesign = async () => {
    let params = {
      type: this.state.isEditing,
    };
    params.image = this.state.designFile;
    params.theme = this.state.customDesignTheme;
    params.pattern = this.state.customDesignPattern;
    params.color = this.state.customDesignColor;
    params.label = this.state.designLabel;
    if (params?.image?.size > 200000)
      notification.error({
        message: "Preview Failed",
        description: `This image is too large to be used as a center image.  Please make sure the image is smaller than 200KB.`,
      });
    else {
      this.props.profileActions.previewCustomDesign(
        this.props.match.params.id,
        params
      );
    }
  };

  handleSaveCustomDesign = () => {
    this.update();
  };

  handleCancelCustomDesign = () => {
    this.editUrl("none");
  };

  customizeQRCode = () => {
    if (this.props.profile.access.plan_type === "free")
      this.setState({ customize_plan_upgrade: true });
    else {
      this.editUrl("design");
      this.setState({ customDesignColor: "#ffffff" });
    }
  };

  clickedIncludeVideo = () => {
    let productPreview = this.state.productPreview;
    productPreview["isIncludedVideo"] =
      !this.state.productPreview.isIncludedVideo;
    this.setState({
      productPreview: productPreview,
    });
  };

  setPreviewURL = () => {
    let prefix = "";
    if (!this.state.inputURL.includes("http")) {
      prefix = "https://";
    } else if (this.state.inputURL.length <= 7) {
      prefix = "https://";
    }
    if (this.state.inputURL.length > 0) {
      this.setState({ previewURL: prefix + this.state.inputURL });
    } else {
      this.setState({ previewURL: this.state.inputURL });
    }
  };

  setPreviewLocation = () => {
    let prefix = "";
    if (!this.state.location.includes("http")) {
      prefix = "https://";
    } else if (this.state.location.length <= 7) {
      prefix = "https://";
    }
    if (this.state.location.length > 0) {
      this.setState({ previewLocation: prefix + this.state.location });
    } else {
      this.setState({ previewLocation: this.state.location });
    }
  };

  setPreviewFile = () => {
    let prefix = "";
    if (!this.state.file.includes("http")) {
      prefix = "https://";
    } else if (this.state.file.length <= 7) {
      prefix = "https://";
    }
    if (this.state.file.length > 0) {
      this.setState({ previewFile: prefix + this.state.file });
    } else {
      this.setState({ previewFile: this.state.file });
    }
  };

  setPreviewSocial = () => {
    let prefix = this.state.social.startsWith('http') ? '' : 'https://'
    let previewSocial = prefix + this.state.social
    this.setState({ previewSocial })
  }

  qrColorHandle(color) {
    this.setState({ qrcolor: color });
    let params = {
      background_color: "#ffffff",
      foreground_color: color,
      format: "png",
    };
    this.props.profileActions.downloadQrCode(
      this.props.match.params.id,
      params
    );
  }

  downloadQR = (type) => {
    this.setState({
      isDownload: true,
    });
    let params = {
      // size: '512',
      // background_color: '#000000',
      // foreground_color: '#ffffff',
      background_color: "#ffffff",
      foreground_color: this.state.qrcolor,
      format: type,
    };
    this.props.profileActions.downloadQrCode(
      this.props.match.params.id,
      params
    );
  };

  editHaciendaLocation = () => {
    this.setState({ isEditingLabel: !this.state.isEditingLabel });
    this.setState({ backUpmainHipscanLabel: this.state.mainHipscanLabel });
  };
  cancelHaciendaLocation = () => {
    this.setState({ isEditingLabel: !this.state.isEditingLabel });
    this.setState({ mainHipscanLabel: this.state.backUpmainHipscanLabel });
  };
  saveHaciendaLocation = () => {
    let params = {
      type: this.state.myHipscanDetail.type,
      label: this.state.mainHipscanLabel,
    };
    if (this.state.myHipscanDetail.type === "flyer") {
      params["user_id"] = this.state.myHipscanDetail.user_id;
      params["preview_url"] = this.state.myHipscanDetail.preview_url;
      params["design_type"] = this.state.myHipscanDetail.design_type;
      params["design_id"] = this.state.myHipscanDetail.design_id;
    }
    this.props.profileActions.updateHipscan(this.props.match.params.id, params);
  };
  inputHandle(e) {
    this.setState({ mainHipscanLabel: e.target.value });
  }

  editUrl = async (type = "none") => {
    this.props.authActions.getProfile();
    await this.setState({ previewLoad: this.state.isEditing });
    this.setState({
      isEditing: type,
      letterCount: 0,
      oauth_popup: false,
    });
    if (type === "url") {
      if (this.state.myHipscanDetail.type === "url")
        this.setState({ inputURL: this.state.myHipscanDetail.url });
      await this.setState({ previewURL: this.state.myHipscanDetail.url });
      setTimeout(() => {
        this.onLoadIframeEdit();
      }, 300);
    } else if (type === "profile") {
      if (this.state.myHipscanDetail.type === "profile") {
        let profile = {
          name: this.state.myHipscanDetail.name,
          description: this.state.myHipscanDetail.description,
          title: this.state.myHipscanDetail.title,
          profile_image_url: this.state.myHipscanDetail.profile_image_url,
          contact: {
            background_image_url: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.background_image_url
              : "",
            email: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.email
              : "",
            company: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.company
              : "",
            email_subject: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.email_subject
              : "",
            email_body: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.email_body
              : "",
            phone: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.phone
              : "",
            website_url: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.website_url
              : "",
            facebook_url: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.facebook_url
              : "",
            instagram_url: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.instagram_url
              : "",
            twitter_url: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.twitter_url
              : "",
            video_url: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.video_url
              : "",
          },
        };

        profile.contact.isIncludedVideo =
          profile.contact.video_url !== "";
        this.setState({ profilePreview: profile });
      }
    } else if (type === 'location') {
      if (this.state.myHipscanDetail.type === 'location')
        this.setState({ location: this.state.myHipscanDetail.location });
      await this.setState({ previewLocation: this.state.myHipscanDetail.location });
      setTimeout(() => {
        this.onLoadIframeEdit();
      }, 300);
    } else if (type === 'file') {
      if (this.state.myHipscanDetail.type === 'file')
        this.setState({ file: this.state.myHipscanDetail.file });
      await this.setState({ previewFile: this.state.myHipscanDetail.file });
      setTimeout(() => {
        this.onLoadIframeEdit();
      }, 300);
    } else if (type === 'social') {
      if (this.state.myHipscanDetail.type === 'social')
        this.setState({ social: this.state.myHipscanDetail.social })
      await this.setState({ previewSocial: this.state.myHipscanDetail.social })
      setTimeout(() => {
        this.onLoadIframeEdit()
      }, 300)
    } else if (type === "text") {
      if (this.state.myHipscanDetail.type === "text")
        this.setState({ text: this.state.myHipscanDetail.text });
    } else if (type === "wifi") {
      if (this.state.myHipscanDetail.type === "wifi")
        this.setState({ wifiName: this.state.myHipscanDetail.wifi_name })
      this.setState({ wifiPassword: this.state.myHipscanDetail.wifi_password })
    } else if (type === "image") {
      if (this.state.myHipscanDetail.type === "image")
        this.setState({ imageUrl: this.state.myHipscanDetail.image_url });
    } else if (type === "video") {
      if (this.state.myHipscanDetail.type === "video")
        this.setState({ videoURL: this.state.myHipscanDetail.video });
    } else if (type === "product") {
      if (this.state.myHipscanDetail.type === "product") {
        let product = {
          name: this.state.myHipscanDetail.name,
          description: this.state.myHipscanDetail.description,
          product_image_url: this.state.myHipscanDetail.product_image_url,
          video:
            this.state.myHipscanDetail.video !== "undefined"
              ? this.state.myHipscanDetail.video
              : "",
          price: this.state.myHipscanDetail.price,
          isIncludedVideo:
            this.state.myHipscanDetail.video !== "" &&
              this.state.myHipscanDetail.video !== "undefined",
        };
        this.setState({ productPreview: product });
      }
    } else if (type === "menu") {
      if (this.state.myHipscanDetail.type === "menu") {
        let menu = {
          name: this.state.myHipscanDetail.name,
          description: this.state.myHipscanDetail.description,
          menu_logo_url: this.state.myHipscanDetail.menu_logo_url,
          theme_color: this.state.myHipscanDetail.theme_color,
          label: this.state.myHipscanDetail.label,
          contact: {
            cover_image_url: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.cover_image_url
              : "",
            email: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.email
              : "",
            email_subject: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.email_subject
              : "",
            email_body: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.email_body
              : "",
            company: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.company
              : "",
            phone: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.phone
              : "",
            background_image_url: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.background_image_url
              : "",
            website_url: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.website_url
              : "",
            facebook_url: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.facebook_url
              : "",
            instagram_url: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.instagram_url
              : "",
            twitter_url: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.twitter_url
              : "",
            address: this.state.myHipscanDetail.contact
              ? this.state.myHipscanDetail.contact.address
              : "",
          },
          categories: this.state.myHipscanDetail.categories,
        };
        menu.contact.isIncludedVideo =
          menu.contact.video_url !== "";
        this.setState({ menuPreview: menu });
      }
    } else if (type === "flyer") {
      if (this.state.myHipscanDetail.type === "flyer") {
        // previously flyer
        let design = await this.getDesignInfo(
          this.state.myHipscanDetail.design_id
        );
        this.setState({ flyer_design: design });
      } else {
        // new flyer type
        this.setState({ flyer_design: null, flyer_designs: [] });
      }
    } else if (type === "design") {
      if (this.state.customDesigned)
        this.setState({
          customDesignTheme: this.state.myHipscanDetail.design.theme,
          customDesignColor: this.state.myHipscanDetail.design.color,
          customDesignPattern: this.state.myHipscanDetail.desgin.pattern,
          designFile: this.state.myHipscanDetail.design.image,
        });
    } else if (type === "event") {
    }
  };

  parseURL(url) {
    const urlParams = new URLSearchParams(url);
    return urlParams.get("code");
  }

  popupCenter(uri, title, w, h) {
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth;
    const height = window.innerHeight;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
      uri,
      title,
      `
      menubar=no,location=no,resizable=no,scrollbars=no,status=no
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
    );
    return newWindow;
  }

  async oauthCode(code) {
    const response = await fetch(
      `https://hipscan-oauth.herokuapp.com/access_token?code=${code}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-url-encoded" },
      }
    );
    const response_json = await response.json();
    this.setState({
      pmw_token: response_json.access_token,
      pmw_userId: response_json.user_id,
    });
    this.getDesigns();
    this.openFlyerDesigner();
  }

  oauthClosed() { }

  async launch_postermywall() {
    if (this.state.pmw_token === null) await this.oauthHandle();
    else this.openFlyerDesigner();
  }

  async oauthHandle() {
    let redirect_uri = `https://app.hipscan.com/`;
    let pmw = new OAuth.Provider({
      id: "pmw",
      authorization_url: "https://api.postermywall.com/v1/oauth/authorize",
    });
    let request = new OAuth.Request({
      client_id: "87AdjjIifGsPsFYKAKzC2S8As5MgkrB8",
      redirect_uri: redirect_uri,
    });
    let uri = pmw.requestToken(request);
    let oauth_window = this.popupCenter(uri, "OAuth with Hipscan", 500, 500);
    let hrefTimer = window.setInterval(() => {
      if (oauth_window && !oauth_window.closed) {
        try {
          const currentUrl = oauth_window.location.href;
          const params = new URL(currentUrl).searchParams;
          const code = params.get("code");
          if (!code) {
            return;
          }
          this.oauthCode(code);
          oauth_window.close();
          window.clearInterval(hrefTimer);
        } catch (error) {
          // eslint-ignore-line
        }
      } else {
        this.oauthClosed();
        window.clearInterval(hrefTimer);
      }
    }, 700);
  }

  async selectFlyerDesign(e) {
    let selected_design_id = e.target.value;
    let selected_design = null;
    if (selected_design_id !== -1) {
      selected_design = await this.getDesignInfo(selected_design_id);
      this.setState({ flyer_design: selected_design });
    } else {
      this.setState({ flyer_design: null });
    }
  }

  editFlyerDesign(design_id) {
    window.PMW.plugin.editor.openDesign(
      design_id,
      "edit",
      (id) => {
        this.handleCloseFlyer(id);
      },
      "normal",
      "flyer-letter",
      ["download", "file"],
      (id) => {
        this.handleSaveFlyer(id);
      }
    );
  }

  openFlyerDesigner() {
    window.PMW.plugin.editor.open(
      "all",
      (id) => {
        this.handleCloseFlyer(id);
      },
      "normal",
      "flyer-letter",
      ["download", "file"],
      (id) => {
        this.handleSaveFlyer(id);
      }
    );
  }

  async getDesigns() {
    const response = await fetch(
      `https://hipscan-oauth.herokuapp.com/designs?user_id=${this.state.pmw_userId}&token=${this.state.pmw_token}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-url-encoded" },
      }
    );
    const response_json = await response.json();
    this.setState({ flyer_designs: response_json });
  }

  async getDesignInfo(design_id) {
    const response = await fetch(
      `https://hipscan-oauth.herokuapp.com/design_info?user_id=${this.state.pmw_userId}&design_id=${design_id}&token=${this.state.pmw_token}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-url-encoded" },
      }
    );
    const response_json = await response.json();
    return response_json;
  }

  async handleCloseFlyer(id) {
    if (id) {
      this.setState({ design_id: id });
      this.getDesigns();
      let newDesign = await this.getDesignInfo(id);
      this.setState({ flyer_design: newDesign });
    }
  }

  async handleSaveFlyer(id) { }

  async onDesignLabelChange(e) {
    this.setState({ designLabel: e.target.value });
  }

  async inputURLHandle(e) {
    const self = this;
    this.setState({
      inputURL: e.target.value,
    });

    if (self.state.urlTypingTimeout) {
      clearTimeout(self.state.urlTypingTimeout);
    }

    self.setState({
      inputURL: e.target.value,
      urlTyping: false,
      urlTypingTimeout: setTimeout(async function () {
        await self.setPreviewURL();
      }, 300),
    });
    // await self.onLoadIframeEdit();
  }

  async locationHandle(e) {
    const self = this;
    this.setState({
      location: e.target.value,
    });

    if (self.state.urlTypingTimeout) {
      clearTimeout(self.state.urlTypingTimeout);
    }

    self.setState({
      location: e.target.value,
      urlTyping: false,
      urlTypingTimeout: setTimeout(async function () {
        await self.setPreviewLocation();
      }, 300),
    });
    // await self.onLoadIframeEdit();
  }

  async fileHandle(e) {
    const self = this;
    this.setState({
      file: e.target.value,
    });

    if (self.state.urlTypingTimeout) {
      clearTimeout(self.state.urlTypingTimeout);
    }

    self.setState({
      file: e.target.value,
      urlTyping: false,
      urlTypingTimeout: setTimeout(async function () {
        await self.setPreviewFile();
      }, 300),
    });
    // await self.onLoadIframeEdit();
  }

  socialHandle = async (e) => {
    const social = e.target.value
    this.setState({ social })

    if (this.state.urlTypingTimeout) {
      clearTimeout(this.state.urlTypingTimeout);
    }

    const urlTypingTimeout = setTimeout(async () => {
      await this.setPreviewSocial()
    }, 300)

    this.setState({ urlTyping: false, urlTypingTimeout })
  }

  updateLetterCount(e) {
    this.setState({
      letterCount: e.target.value.length,
    });
  }

  wifiNameHandle(e) {
    this.setState({
      wifiName: e.target.value,
    })
  }

  wifiPasswordHandle(e) {
    this.setState({
      wifiPassword: e.target.value,
    })
  }

  textChange(e) {
    this.setState({
      text: e.target.value,
    });
    this.updateLetterCount(e);
  }

  videoURLChange(e) {
    this.setState({
      videoURL: e.target.value,
    });
  }

  upload() {
    document.getElementById("selectImage").click();
  }
  uploadProfile() {
    document.getElementById("selectProfileImage").click();
  }
  uploadProduct() {
    document.getElementById("selectProductImage").click();
  }
  uploadMenu() {
    document.getElementById("selectMenuImage").click();
  }
  fileSelectHandle(e) {
    this.setState({ selectedFile: e.target.files[0] });
    this.setState({ imageUrl: URL.createObjectURL(e.target.files[0]) });
  }
  designSelectHandle(e) {
    this.setState({ designFile: e.target.files[0] });
    this.setState({ designUrl: URL.createObjectURL(e.target.files[0]) }, () => {
      this.handlePreviewCustomDesign();
    });
  }

  profileFromLinkedin = () => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const redirectUri = "https://app.hipscan.com/linkedin";
    const popup = window.open(
      `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&scope=r_liteprofile&state=123456&redirect_uri=${redirectUri}`,
      "_blank",
      "width=600,height=600"
    );
    window.addEventListener(
      "message",
      async ({ origin, data: { state, code, error, ...rest } }) => {
        if (code) {
          this.setState({ linkedInCode: code });
          this.update()
        }
        popup.close();
      }
    );
  };

  cancelUpload() {
    let type = this.state.isEditing;
    switch (type) {
      case "profile":
        {
          this.profileFileInput.value = "";
          let profilePreview = this.state.profilePreview;
          profilePreview["profile_image_url"] = "";
          profilePreview["selectedFile"] = null;
          this.setState({
            profilePreview: profilePreview,
          });
        }
        break;
      case "product":
        {
          this.productFileInput.value = "";
          let productPreview = this.state.productPreview;
          productPreview["product_image_url"] = "";
          productPreview["selectedFile"] = null;
          this.setState({
            productPreview: productPreview,
          });
        }
        break;
      case "menu":
        {
          this.menuFileInput.value = "";
          let menuPreview = this.state.menuPreview;
          menuPreview["menu_logo_url"] = "";
          menuPreview["selectedFile"] = null;
          this.setState({
            menuPreview: menuPreview,
          });
        }
        break;
      case "image":
        {
          this.fileInput.value = "";
          this.setState({
            selectedFile: null,
            imageUrl: "",
          });
        }
        break;
      default:
        break;
    }
  }

  onChangeBFile = (ee) => {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["background_image_url"] = ee
        ? URL.createObjectURL(ee)
        : "";
      profilePreview.contact["selectedFile"] = ee;
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["cover_image_url"] = ee
        ? URL.createObjectURL(ee)
        : "";
      menuPreview.contact["selectedFile"] = ee;
      this.setState({
        menuPreview: menuPreview,
      });
    }
  };

  isIncludedVideoHandle = () => {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["isIncludedVideo"] =
        !profilePreview.contact["isIncludedVideo"];
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["isIncludedVideo"] =
        !menuPreview.contact["isIncludedVideo"];
      this.setState({
        menuPreview: menuPreview,
      });
    }
  };

  emailHandle(ee) {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["email"] = ee;
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["email"] = ee;
      this.setState({
        menuPreview: menuPreview,
      });
    }
  }
  emailSubjectHandle(ee) {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["email_subject"] = ee;
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["email_subject"] = ee;
      this.setState({
        menuPreview: menuPreview,
      });
    }
  }
  emailBodyHandle(ee) {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["email_body"] = ee;
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["email_body"] = ee;
      this.setState({
        menuPreview: menuPreview,
      });
    }
  }

  companyHandle(ee) {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["company"] = ee;
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["company"] = ee;
      this.setState({
        menuPreview: menuPreview,
      });
    }
  }

  meetingLinkHandle(ee) {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["meetingLink"] = ee;
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["meetingLink"] = ee;
      this.setState({
        menuPreview: menuPreview,
      });
    }
  }

  numberHandle(ee) {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["phone"] = ee;
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["phone"] = ee;
      this.setState({
        menuPreview: menuPreview,
      });
    }
  }

  websiteHandle(ee) {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["website_url"] = ee;
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["website_url"] = ee;
      this.setState({
        menuPreview: menuPreview,
      });
    }
  }

  facebookHandle(ee) {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["facebook_url"] = ee;
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["facebook_url"] = ee;
      this.setState({
        menuPreview: menuPreview,
      });
    }
  }

  instagramHandle(ee) {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["instagram_url"] = ee;
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["instagram_url"] = ee;
      this.setState({
        menuPreview: menuPreview,
      });
    }
  }

  twitterHandle(ee) {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["twitter_url"] = ee;
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["twitter_url"] = ee;
      this.setState({
        menuPreview: menuPreview,
      });
    }
  }

  videoURL(ee) {
    this.setState({ isContactChanged: true });
    if (this.state.isEditing === "profile") {
      let profilePreview = this.state.profilePreview;
      profilePreview.contact["video_url"] = ee;
      this.setState({
        profilePreview: profilePreview,
      });
    } else if (this.state.isEditing === "menu") {
      let menuPreview = this.state.menuPreview;
      menuPreview.contact["video_url"] = ee;
      this.setState({
        menuPreview: menuPreview,
      });
    }
  }

  addressHandle(ee) {
    let menuPreview = this.state.menuPreview;
    menuPreview.contact["address"] = ee;
    this.setState({
      menuPreview: menuPreview,
    });
  }

  update = () => {
    console.log("Link: ", this.state.linkedInCode);
    let params = {
      type: this.state.isEditing,
      label: this.state.mainHipscanLabel,
    };
    if (this.state.linkedInCode) params.linkedinCode = this.state.linkedInCode;
    if (params.type === "url") {
      params.url = this.state.previewURL;
    } else if (params.type === "profile") {
      params.name = this.state.profilePreview.name
        ? this.state.profilePreview.name
        : "";
      params.title = this.state.profilePreview.title
        ? this.state.profilePreview.title
        : "";
      params.description = this.state.profilePreview.description
        ? this.state.profilePreview.description
        : "";
      params.profile = this.state.profilePreview.selectedFile
        ? this.state.profilePreview.selectedFile
        : "";
    } else if (params.type === "text") {
      params.text = this.state.text;
    } else if (params.type === "location") {
      params.location = this.state.previewLocation
    } else if (params.type === 'social') {
      params.social = this.state.previewSocial
    } else if (params.type === "file") {
      params.file = this.state.previewFile
    } else if (params.type === "image") {
      params.image = this.state.selectedFile;
    } else if (params.type === "video") {
      params.video = this.state.videoURL;
    } else if (params.type === "product") {
      params.name = this.state.productPreview.name
        ? this.state.productPreview.name
        : "";
      params.title = this.state.productPreview.title
        ? this.state.productPreview.title
        : "";
      params.description = this.state.productPreview.description
        ? this.state.productPreview.description
        : "";
      params.product = this.state.productPreview.selectedFile
        ? this.state.productPreview.selectedFile
        : "";
      params.video =
        this.state.productPreview.video !== "undefined"
          ? this.state.productPreview.video
          : "";
      params.price = this.state.productPreview.price
        ? this.state.productPreview.price
        : "";
      if (!this.state.productPreview.isIncludedVideo) {
        params.video = "";
      }
    } else if (params.type === "wifi") {
      params.wifiName = this.state.wifiName
      params.wifiPassword = this.state.wifiPassword
    } else if (params.type === "menu") {
      params.name = this.state.menuPreview.name
        ? this.state.menuPreview.name
        : "";
      params.title = this.state.menuPreview.title
        ? this.state.menuPreview.title
        : "";
      params.description = this.state.menuPreview.description
        ? this.state.menuPreview.description
        : "";
      params.logo = this.state.menuPreview.selectedFile
        ? this.state.menuPreview.selectedFile
        : "";
      params.theme_color = this.state.menuPreview.theme_color
        ? this.state.menuPreview.theme_color
        : "";
      // params.label = this.state.menuPreview.label;
      params.description = this.state.menuPreview.description
        ? this.state.menuPreview.description
        : "";
    } else if (params.type === "flyer") {
      params.design_id = this.state.flyer_design.id;
      params.design_type = this.state.flyer_design.type;
      params.preview_url =
        this.state.flyer_design.type === "image"
          ? this.state.flyer_design.preview_url
          : this.state.flyer_design.preview_video_url;
      params.user_id = this.state.pmw_userId;
    } else if (params.type === "design") {
      params.image = this.state.designFile;
      params.theme = this.state.customDesignTheme;
      params.pattern = this.state.customDesignPattern;
      params.color = this.state.customDesignColor;
      params.label = this.state.designLabel;
      this.props.profileActions.saveCustomDesign(
        this.props.match.params.id,
        params
      );
      this.editUrl("none");
      return;
    }
    this.props.profileActions.updateHipscan(this.props.match.params.id, params);
    if (params.type !== "design") this.onLoadIframeView();

    this.setState({ linkedInCode: "" })
    let profilePreview = this.state.profilePreview;
    profilePreview["selectedFile"] = "";
    this.setState({
      profilePreview: profilePreview,
    });
  };
  nameChangeHandle(e) {
    let profilePreview = this.state.profilePreview;
    profilePreview["name"] = e.target.value;
    this.setState({
      profilePreview: profilePreview,
    });
  }
  titleChangeHandle(e) {
    let profilePreview = this.state.profilePreview;
    profilePreview["title"] = e.target.value;
    this.setState({
      profilePreview: profilePreview,
    });
  }
  descriptionChangeHandle(e) {
    let profilePreview = this.state.profilePreview;
    profilePreview["description"] = e.target.value;
    this.setState({
      profilePreview: profilePreview,
    });
    this.updateLetterCount(e);
  }
  profileFileSelectHandle(e) {
    let profilePreview = this.state.profilePreview;
    profilePreview["profile_image_url"] = URL.createObjectURL(
      e.target.files[0]
    );
    profilePreview["selectedFile"] = e.target.files[0];
    this.setState({
      profilePreview: profilePreview,
    });
  }
  productNameChange(e) {
    let productPreview = this.state.productPreview;
    productPreview["name"] = e.target.value;
    this.setState({
      productPreview: productPreview,
    });
  }
  priceChange(e) {
    let productPreview = this.state.productPreview;
    productPreview["price"] = e.target.value;
    this.setState({
      productPreview: productPreview,
    });
  }
  productVideoURLChange(e) {
    let productPreview = this.state.productPreview;
    productPreview["video"] = e.target.value;
    this.setState({
      productPreview: productPreview,
    });
  }
  productDescriptionChange(e) {
    let productPreview = this.state.productPreview;
    productPreview["description"] = e.target.value;
    this.setState({
      productPreview: productPreview,
    });
    this.updateLetterCount(e);
  }
  productFileSelectHandle(e) {
    let productPreview = this.state.productPreview;
    productPreview["product_image_url"] = URL.createObjectURL(
      e.target.files[0]
    );
    productPreview["selectedFile"] = e.target.files[0];
    this.setState({
      productPreview: productPreview,
    });
  }

  modalShow() {
    this.setState({ show: true });
  }
  modalHide() {
    this.setState({ show: false });
  }
  saveContact(color = this.state.color) {
    this.setState({ color });
    this.modalHide();
    if (this.state.isEditing === "profile") {
      let params = {
        email: this.state.profilePreview.contact.email,
        email_subject: this.state.profilePreview.contact.email_subject,
        email_body: this.state.profilePreview.contact.email_body,
        company: this.state.profilePreview.contact.company,
        color: color,
        meetingLink: this.state.profilePreview.contact.meetingLink,
        phone: this.state.profilePreview.contact.phone,
        background_image_url:
          this.state.profilePreview.contact.background_image_url,
        website_url: this.state.profilePreview.contact.website_url,
        facebook_url: this.state.profilePreview.contact.facebook_url,
        instagram_url: this.state.profilePreview.contact.instagram_url,
        twitter_url: this.state.profilePreview.contact.twitter_url,
        video_url: this.state.profilePreview.contact.video_url,
        background: this.state.profilePreview.contact.selectedFile,
      };
      if (!this.state.profilePreview.contact.isIncludedVideo) {
        params.video_url = "";
      }
      // console.log("params: ", params)
      this.props.profileActions.updateContact(
        this.props.match.params.id,
        params
      );
    } else if (this.state.isEditing === "menu") {
      let params = {
        email: this.state.menuPreview.contact.email,
        email_subject: this.state.profilePreview.contact.email_subject,
        email_body: this.state.profilePreview.contact.email_body,
        company: this.state.profilePreview.contact.company,
        color: color,
        meetingLink: this.state.menuPreview.contact.meetingLink,
        phone: this.state.menuPreview.contact.phone,
        background_image_url:
          this.state.menuPreview.contact.background_image_url,
        website_url: this.state.menuPreview.contact.website_url,
        facebook_url: this.state.menuPreview.contact.facebook_url,
        instagram_url: this.state.menuPreview.contact.instagram_url,
        twitter_url: this.state.menuPreview.contact.twitter_url,
        address: this.state.menuPreview.contact.address,
        cover: this.state.menuPreview.contact.selectedFile,
        video_url: this.state.menuPreview.contact.video_url
          ? this.state.menuPreview.contact.video_url
          : "",
      };
      this.props.profileActions.updateContact(
        this.props.match.params.id,
        params
      );
    }
  }

  restaurantNameHandle(e) {
    let menuPreview = this.state.menuPreview;
    menuPreview["name"] = e.target.value;
    this.setState({
      menuPreview: menuPreview,
    });
  }

  menuDescriptionHandle(e) {
    let menuPreview = this.state.menuPreview;
    menuPreview["description"] = e.target.value;
    this.setState({
      menuPreview: menuPreview,
    });
    this.updateLetterCount(e);
  }
  menuFileSelectHandle(e) {
    let menuPreview = this.state.menuPreview;
    menuPreview["menu_logo_url"] = URL.createObjectURL(e.target.files[0]);
    menuPreview["selectedFile"] = e.target.files[0];
    this.setState({
      menuPreview: menuPreview,
    });
  }

  menuModalShow() {
    this.setState({ menuShow: true });
  }
  menuModalHide() {
    this.setState({ menuShow: false });
  }

  eventModalShow() {
    this.setState({ eventShow: true });
  }
  eventModalHide() {
    this.setState({ eventShow: false });
  }

  // ===============
  onLoadedData = (e) => { };

  onLoadIframeEdit = (e) => {
    this.checkIframeLoaded("edit");
  };

  onLoadIframeView = (e) => {
    this.checkIframeLoaded("view");
  };

  handlePartnerCount = (e) => { };

  checkIframeLoaded(type) {
    // Get a handle to the iframe element
    var iframe = document.getElementById("iframe_" + type);
    try {
      var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      // Check if loading is complete
      if (iframeDoc.readyState === "complete") {
        //iframe.contentWindow.alert("Hello");
        iframe.contentWindow.onload = function () {
          // alert('I am loaded');
        };
        // The loading is complete, call the function we want executed once the iframe is loaded
        this.afterLoading(type);
        return;
      }

      // if (type === 'view') {
      // 	this.setState({ linkIframeViewNotWork: true });
      // } else {
      // 	this.setState({ linkIframeEditNotWork: true });
      // }
    } catch (error) {
      if (type === "view") {
        this.setState({ linkIframeViewNotWork: true });
      } else {
        this.setState({ linkIframeEditNotWork: true });
      }
    }

    // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
    // window.setTimeout(this.checkIframeLoaded(), 100);
  }

  afterLoading(type) {
    if (type === "view") {
      this.setState({ linkIframeViewNotWork: false });
    } else {
      this.setState({ linkIframeEditNotWork: false });
    }
  }

  render() {
    const width = window.innerWidth;
    const { profile } = this.props;
    const { linkIframeEditNotWork, linkIframeViewNotWork } = this.state;

    const currentTime = moment();
    const timeStore = moment(profile && profile.created_at);
    const checkDiffHours = currentTime.diff(timeStore, "h");

    return (
      <div className="detail-my-hipscan">
        {width > 767 ? (
          <div className="hipscan d-none d-md-flex">
            <div className="action-part">
              <img src={QRCodeImage} className="action-image" alt="qrimage" />
              <Header2 {...this.props} />

              <div className="msg-upgrade">
                <Alert variant="warning">
                  <img src={ImgInfo} alt="" />{" "}
                  {(profile &&
                    profile.access &&
                    profile.access.plan_type === "premium") ||
                    checkDiffHours < 24
                    ? "Welcome to Hipscan!  Choose one of the below destination points for your Hipscan now."
                    : "Enjoy more powerful features on Hipscan."}
                  {checkDiffHours > 23 &&
                    profile &&
                    profile.access &&
                    profile.access.plan_type !== "premium" && (
                      <Link to="/plans"> UPGRADE PLAN NOW!</Link>
                    )}
                </Alert>
              </div>

              {this.state.isEditing === "none" ? (
                <div className="action">
                  <div className="qr-code">
                    {this.state.isEditingLabel === false ? (
                      <div className="label text-center">
                        <p>{this.state.mainHipscanLabel}</p>
                        <div className="edit">
                          <MdEdit
                            className="icon"
                            size="1.4rem"
                            onClick={this.editHaciendaLocation}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="label">
                        <input
                          type="text"
                          value={this.state.mainHipscanLabel}
                          onChange={this.inputHandle}
                        ></input>
                        <div className="save-cancel">
                          <MdSave
                            className="icon"
                            size="1.4rem"
                            onClick={this.saveHaciendaLocation}
                          />
                          <MdCancel
                            className="icon"
                            size="1.4rem"
                            onClick={this.cancelHaciendaLocation}
                          />
                        </div>
                      </div>
                    )}

                    <div className="qrcodeimage">
                      <img
                        src={
                          this.state.qr_image_url !== ""
                            ? this.state.qr_image_url
                            : // ? window.location.origin + `/${this.state.qr_image_url}`
                            this.state.myHipscanDetail &&
                              this.state.myHipscanDetail.code_image_url
                              ? this.state.myHipscanDetail.code_image_url
                              : ""
                        }
                        id="qr"
                        fgcolor={this.state.qrcolor}
                      />
                    </div>
                    <div className='download-btn'>
                      <button className='copy-btn' onClick={this.handleClickCopyText}><FaCopy />{this.state.content}</button>
                    </div>
                    {profile && !profile.code.customDesigned ? (
                      <div className="color4">
                        <div
                          className={
                            this.state.qrcolor === "#000000" ? "selected" : ""
                          }
                        >
                          <div
                            className="black"
                            onClick={() => this.qrColorHandle("#000000")}
                          ></div>
                        </div>
                        <div
                          className={
                            this.state.qrcolor === "#00b300" ? "selected" : ""
                          }
                        >
                          <div
                            className="green"
                            onClick={() => this.qrColorHandle("#00b300")}
                          ></div>
                        </div>
                        <div
                          className={
                            this.state.qrcolor === "#820606" ? "selected" : ""
                          }
                        >
                          <div
                            className="red"
                            onClick={() => this.qrColorHandle("#820606")}
                          ></div>
                        </div>
                        <div
                          className={
                            this.state.qrcolor === "#030369" ? "selected" : ""
                          }
                        >
                          <div
                            className="blue"
                            onClick={() => this.qrColorHandle("#030369")}
                          ></div>
                        </div>
                      </div>
                    ) : null}
                    <div
                      className="download-btn"
                      style={{
                        marginTop: profile?.code.customDesigned
                          ? "30px"
                          : "0px",
                      }}
                    >
                      <Button
                        className="btn"
                        onClick={() => this.downloadQR("png")}
                        download
                      >
                        <FaDownload /> PNG
                      </Button>
                      <Button
                        className="btn"
                        onClick={() => this.downloadQR("jpg")}
                        download
                      >
                        <FaDownload /> JPG
                      </Button>
                      <Button
                        className="btn"
                        onClick={() => this.downloadQR("eps")}
                        download
                      >
                        <FaDownload /> EPS
                      </Button>
                    </div>
                    <div className="custom-design">
                      <Button
                        className="action-btn"
                        onClick={() => this.customizeQRCode()}
                      >
                        Customize QR Code Design
                      </Button>
                    </div>
                    {this.state.customize_plan_upgrade && (
                      <div className="custom-design-upgrade">
                        <p className="upgrade-link">
                          You can customize your QR code by upgrading to the Pro
                          or Premium plans.
                          <br />
                          <Link to="/plans" className="gotoUpgrade">
                            <span> Upgrade Now </span>
                          </Link>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="info-input">
                    <h1>Awesome! So, what will your Hipscan do?</h1>
                    <p>
                      (Don't worry -- you can change this any time your want)
                    </p>
                    <div className="action-btns">
                      <Button
                        className={
                          this.state.myHipscanDetail &&
                            this.state.myHipscanDetail.type === "url"
                            ? "action-btn active"
                            : "action-btn"
                        }
                        onClick={() => this.editUrl("url")}
                      >
                        Launch Custom URL
                      </Button>
                      <Button
                        className={
                          this.state.myHipscanDetail &&
                            this.state.myHipscanDetail.type === 'video'
                            ? "action-btn active"
                            : "action-btn"
                        }
                        onClick={() => this.editUrl('video')}
                      >
                        Launch a Video
                      </Button>
                      <Button
                        className={
                          this.state.myHipscanDetail?.type === 'location'
                            ? "action-btn active"
                            : "action-btn"
                        }
                        onClick={() => this.editUrl('location')}
                      >
                        Launch a Location
                      </Button>
                      <Button
                        className={
                          this.state.myHipscanDetail?.type === 'file'
                            ? "action-btn active"
                            : "action-btn"
                        }
                        onClick={() => this.editUrl('file')}
                      >
                        Launch a File
                      </Button>
                      <Button
                        className={
                          this.state.myHipscanDetail?.type === 'wifi'
                            ? "action-btn active"
                            : "action-btn"
                        }
                        onClick={() => this.editUrl('wifi')}
                      >
                        Launch Wifi Network
                      </Button>
                      <Button
                        className={
                          this.state.myHipscanDetail?.type === 'social'
                            ? 'action-btn active'
                            : 'action-btn'
                        }
                        onClick={() => this.editUrl('social')}
                      >
                        Launch a Social
                      </Button>
                      <Button
                        className={
                          this.state.myHipscanDetail &&
                            this.state.myHipscanDetail.type === 'profile'
                            ? "action-btn active"
                            : "action-btn"
                        }
                        onClick={() => this.editUrl('profile')}
                      >
                        Launch Hipscan Business Card
                      </Button>

                      {profile &&
                        profile.access &&
                        profile.access.plan_type !== "free" && (
                          <>
                            <Button
                              className={
                                this.state.myHipscanDetail &&
                                  this.state.myHipscanDetail.type === "menu"
                                  ? "action-btn active"
                                  : "action-btn"
                              }
                              onClick={() => this.editUrl("menu")}
                            >
                              Launch a Menu
                            </Button>
                            <Button
                              className={
                                this.state.myHipscanDetail &&
                                  this.state.myHipscanDetail.type === "product"
                                  ? "action-btn active"
                                  : "action-btn"
                              }
                              onClick={() => this.editUrl("product")}
                            >
                              Launch a Product Page
                            </Button>
                            <Button
                              className={
                                this.state.myHipscanDetail &&
                                  this.state.myHipscanDetail.type === "flyer"
                                  ? "action-btn active"
                                  : "action-btn"
                              }
                              onClick={() => this.editUrl("flyer")}
                            >
                              Launch a Flyer
                            </Button>
                            {!this.state?.myHipscanDetail?.is_partner && (
                              <Button
                                className={
                                  this.state.myHipscanDetail &&
                                    this.state.myHipscanDetail.type === "event"
                                    ? "action-btn active"
                                    : "action-btn"
                                }
                                onClick={() => this.editUrl("event")}
                              >
                                Launch an event
                              </Button>
                            )}
                          </>
                        )}
                      <Button
                        className={
                          this.state.myHipscanDetail &&
                            this.state.myHipscanDetail.type === 'text'
                            ? 'action-btn active'
                            : 'action-btn'
                        }
                        onClick={() => this.editUrl('text')}
                      >
                        Display Custom Text
                      </Button>
                      <Button
                        className={
                          this.state.myHipscanDetail &&
                            this.state.myHipscanDetail.type === 'image'
                            ? 'action-btn active'
                            : 'action-btn'
                        }
                        onClick={() => this.editUrl('image')}
                      >
                        Display a Custom Image
                      </Button>
                    </div>
                  </div>
                </div>
              ) : this.state.isEditing === "url" ? (
                <div className="action-edit-url">
                  <Link
                    to="#"
                    className="goto"
                    onClick={() => this.editUrl("none")}
                  >
                    <IoIosArrowBack size="1.3rem" />
                    Back
                  </Link>
                  <h1>Launch Custom URL</h1>
                  <input
                    type="text"
                    placeholder="Input your target URL here"
                    value={this.state.inputURL}
                    onChange={this.inputURLHandle}
                  ></input>
                  <div className="save-continue-btn">
                    <Button onClick={() => this.update()}>
                      SAVE AND CONTINUE
                    </Button>
                  </div>
                </div>
              ) : this.state.isEditing === 'location' ? (
                <div className='action-edit-url'>
                  <Link
                    to='#'
                    className='goto'
                    onClick={() => this.editUrl('none')}
                  >
                    <IoIosArrowBack size='1.3rem' />
                    Back
                  </Link>
                  <h1>Launch a Location</h1>
                  <input
                    type='text'
                    placeholder='Input your target Location here'
                    value={this.state.location}
                    onChange={this.locationHandle}
                  ></input>
                  <div className='save-continue-btn'>
                    <Button onClick={() => this.update()}>
                      SAVE AND CONTINUE
                    </Button>
                  </div>
                </div>
              ) : this.state.isEditing === 'file' ? (
                <div className='action-edit-url'>
                  <Link
                    to='#'
                    className='goto'
                    onClick={() => this.editUrl('none')}
                  >
                    <IoIosArrowBack size='1.3rem' />
                    Back
                  </Link>
                  <h1>Launch a file</h1>
                  <input
                    type='text'
                    placeholder='Input your target File here'
                    value={this.state.file}
                    onChange={this.fileHandle}
                  ></input>
                  <div className='save-continue-btn'>
                    <Button onClick={() => this.update()}>
                      SAVE AND CONTINUE
                    </Button>
                  </div>
                </div>
              ) : this.state.isEditing === 'social' ? (
                <div className='action-edit-url'>
                  <Link
                    to='#'
                    className='goto'
                    onClick={() => this.editUrl('none')}
                  >
                    <IoIosArrowBack size='1.3rem' />
                    Back
                  </Link>
                  <h1>Launch a Social</h1>
                  <input
                    type='text'
                    placeholder='Input Twitter, Snapchat, Instagram, Facebook URL here'
                    value={this.state.social}
                    onChange={this.socialHandle}
                  ></input>
                  <div className='save-continue-btn'>
                    <Button onClick={() => this.update()}>
                      SAVE AND CONTINUE
                    </Button>
                  </div>
                </div>
              ) : this.state.isEditing === 'wifi' ? (
                <div className='action-edit-url'>
                  <Link
                    to='#'
                    className='goto'
                    onClick={() => this.editUrl('none')}
                  >
                    <IoIosArrowBack size='1.3rem' />
                    Back
                  </Link>
                  <h1>Launch Wifi Network</h1>
                  <div>
                    <input
                      type='text'
                      placeholder='Enter Wifi name'
                      value={this.state.wifiName}
                      onChange={this.wifiNameHandle}
                    />
                  </div>
                  <div>
                    <input
                      type='text'
                      placeholder='Enter Wifi password'
                      value={this.state.wifiPassword}
                      onChange={this.wifiPasswordHandle}
                    />
                  </div>
                  <div className='save-continue-btn'>
                    <Button onClick={() => this.update()}>
                      SAVE AND CONTINUE
                    </Button>
                  </div>
                </div>
              ) : this.state.isEditing === "text" ? (
                <div className="action-edit-text">
                  <Link
                    to="#"
                    className="goto"
                    onClick={() => this.editUrl("none")}
                  >
                    <IoIosArrowBack size="1.3rem" />
                    Back
                  </Link>
                  <h1>Display Custom Text</h1>
                  <div className="display-custom-text">
                    <textarea
                      id="#"
                      placeholder="Go ahead, pour your heart out."
                      value={this.state.text}
                      onChange={this.textChange}
                      maxLength="600"
                    />
                    <p className="letter-count">
                      {this.state.letterCount} / 600
                    </p>
                  </div>
                  <div className="save-continue-btn">
                    <Button onClick={() => this.update()}>
                      SAVE AND CONTINUE
                    </Button>
                  </div>
                </div>
              ) : this.state.isEditing === "image" ? (
                <div className="action-edit-image">
                  <Link
                    to="#"
                    className="goto"
                    onClick={() => this.editUrl("none")}
                  >
                    <IoIosArrowBack size="1.3rem" />
                    Back
                  </Link>
                  <h1>Display Custom Image</h1>
                  <div className="display-custom-image">
                    <input
                      id="selectImage"
                      type="file"
                      hidden
                      placeholder="Custom Image Type"
                      onChange={this.fileSelectHandle}
                      ref={(ref) => (this.fileInput = ref)}
                    ></input>
                    <span>
                      {this.state.selectedFile &&
                        this.state.selectedFile.name !== null
                        ? this.state.selectedFile.name
                        : "Upload An Image"}
                    </span>
                    <div className="icon left" onClick={this.upload}>
                      <GiOpenFolder size="2.5rem" />
                    </div>
                    <div className="icon" onClick={this.cancelUpload}>
                      <GiCancel size="2.5rem" />
                    </div>
                  </div>
                  <div className="save-continue-btn">
                    <Button onClick={() => this.update()}>
                      SAVE AND CONTINUE
                    </Button>
                  </div>
                </div>
              ) : this.state.isEditing === "video" ? (
                <div className="action-edit-video">
                  <Link
                    to="#"
                    className="goto"
                    onClick={() => this.editUrl("none")}
                  >
                    <IoIosArrowBack size="1.3rem" />
                    Back
                  </Link>
                  <h1>Launch a Video</h1>
                  <div className="display-video">
                    <input
                      type="text"
                      placeholder="Input the Youtube or Vimeo URL here"
                      value={this.state.videoURL}
                      onChange={this.videoURLChange}
                    ></input>
                    <div className="icon1">
                      <TiSocialYoutubeCircular size="2.5rem" />
                    </div>
                    <div className="icon2">
                      <TiSocialVimeoCircular size="2.5rem" />
                    </div>
                  </div>
                  <div className="save-continue-btn">
                    <Button onClick={() => this.update()}>
                      SAVE AND CONTINUE
                    </Button>
                  </div>
                </div>
              ) : this.state.isEditing === "event" ? (
                <LaunchAnEvent editUrl={this.editUrl} />
              ) : this.state.isEditing === "profile" ? (
                <div className="action-edit-profile">
                  <Link
                    to="#"
                    className="goto"
                    onClick={() => this.editUrl("none")}
                  >
                    <IoIosArrowBack size="1.3rem" />
                    Back
                  </Link>
                  <h1>Launch Hipscan Business Card</h1>
                  <p>
                    Hipscan Profiles are digital business cards that provide
                    your most essential contact details to anyone who scans your
                    Hipscan. So go ahead, create your Hipscan profile!
                  </p>
                  <p>Customize Theme</p>
                  <div className="d-flex flex-row" style={{ marginBottom: 20 }}>
                    {colorList.map((color, index) => (
                      <div
                        onClick={() => this.saveContact(color)}
                        key={index.toString()}
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: 30,
                          backgroundColor: color,
                          marginRight: 10,
                          cursor: "pointer",
                        }}
                      ></div>
                    ))}
                  </div>
                  <div className="input-field">
                    <div className="file-upload">
                      <input
                        id="selectProfileImage"
                        type="file"
                        hidden
                        placeholder="Custom Image Type"
                        onChange={this.profileFileSelectHandle}
                        ref={(ref) => (this.profileFileInput = ref)}
                      ></input>
                      <span>
                        {this.state.profilePreview.selectedFile &&
                          this.state.profilePreview.selectedFile.name !== null
                          ? this.state.profilePreview.selectedFile.name
                          : "Upload Photo"}
                      </span>
                      <div className="icon left" onClick={this.uploadProfile}>
                        <GiOpenFolder size="2.5rem" />
                      </div>
                      <div className="icon" onClick={this.cancelUpload}>
                        <GiCancel size="2.5rem" />
                      </div>
                    </div>
                    <Button
                      style={{
                        backgroundColor: "#ED8C1D",
                        border: "1px solid #000000",
                        marginRight: "auto",
                        marginLeft: 10,
                        fontWeight: "bold",
                      }}
                      onClick={this.profileFromLinkedin}
                    >
                      Upload from LinkedIn
                    </Button>
                    {/* <div className="icon" >

                      </div> */}

                    <div
                      className="common-input-mui"
                      style={{ paddingLeft: "22px" }}
                    >
                      {
                        <>
                          <CssTextField
                            label="Display Name"
                            variant="outlined"
                            onChange={this.nameChangeHandle}
                            value={this.state.profilePreview.name}
                            size="medium"
                            fullWidth
                            type="text"
                          />
                        </>
                      }
                    </div>
                  </div>
                  <div
                    className="common-input-mui"
                    style={{ marginTop: "1rem" }}
                  >
                    <CssTextField
                      label="Title"
                      variant="outlined"
                      onChange={this.titleChangeHandle}
                      value={this.state.profilePreview.title}
                      size="medium"
                      fullWidth
                      type="text"
                    />
                  </div>
                  <div className="textarea-input">
                    <CssTextField
                      label="Additional Notes"
                      variant="outlined"
                      onChange={this.descriptionChangeHandle}
                      size="medium"
                      value={this.state.profilePreview.description}
                      multiline={true}
                      fullWidth
                      type="text"
                      minRows={3}
                      maxRows={4}
                      inputProps={{
                        maxLength: this.state.profilePreview.contact
                          .isIncludedVideo
                          ? 300
                          : 600,
                      }}
                    />
                    <p className="letter-count">
                      {this.state.letterCount} /{" "}
                      {this.state.profilePreview.contact.isIncludedVideo
                        ? 300
                        : 600}
                    </p>
                  </div>
                  <div className="contact-info-btn">
                    <Button onClick={this.modalShow}>CONTACT INFO</Button>
                    <ContactInfoModal
                      show={this.state.show}
                      modalHide={this.modalHide}
                      saveContact={this.saveContact}
                      onChangeEmail={this.emailHandle}
                      onChangeEmailSubject={this.emailSubjectHandle}
                      onChangeEmailBody={this.emailBodyHandle}
                      onChangeCompany={this.companyHandle}
                      onChangeMeetingLink={this.meetingLinkHandle}
                      onChangeNumber={this.numberHandle}
                      onChangeWebsiteURL={this.websiteHandle}
                      onChangeFacebookURL={this.facebookHandle}
                      onChangeInstagramURL={this.instagramHandle}
                      onChangeTwitterURL={this.twitterHandle}
                      onChangeVideoURL={this.videoURL}
                      onChangeBFile={this.onChangeBFile}
                      isIncludedVideoHandle={this.isIncludedVideoHandle}
                      bFile={
                        !_.isEmpty(this.state.myHipscanDetail.contact)
                          ? this.state.myHipscanDetail.contact
                            .background_image_url
                            ? this.state.myHipscanDetail.contact
                              .background_image_url
                            : ""
                          : ""
                      }
                      email={
                        !_.isEmpty(this.state.myHipscanDetail.contact)
                          ? this.state.myHipscanDetail.contact.email
                            ? this.state.myHipscanDetail.contact.email
                            : ""
                          : ""
                      }
                      email_subject={
                        !_.isEmpty(this.state.myHipscanDetail.contact)
                          ? this.state.myHipscanDetail.contact.email_subject
                            ? this.state.myHipscanDetail.contact.email_subject
                            : ""
                          : ""
                      }
                      email_body={
                        !_.isEmpty(this.state.myHipscanDetail.contact)
                          ? this.state.myHipscanDetail.contact.email_body
                            ? this.state.myHipscanDetail.contact.email_body
                            : ""
                          : ""
                      }
                      company={
                        !_.isEmpty(this.state.myHipscanDetail.contact)
                          ? this.state.myHipscanDetail.contact.company
                            ? this.state.myHipscanDetail.contact.company
                            : ""
                          : ""
                      }
                      phone={
                        !_.isEmpty(this.state.myHipscanDetail.contact)
                          ? this.state.myHipscanDetail.contact.phone
                            ? this.state.myHipscanDetail.contact.phone
                            : ""
                          : ""
                      }
                      website_url={
                        !_.isEmpty(this.state.myHipscanDetail.contact)
                          ? this.state.myHipscanDetail.contact.website_url
                            ? this.state.myHipscanDetail.contact.website_url
                            : ""
                          : ""
                      }
                      facebook_url={
                        !_.isEmpty(this.state.myHipscanDetail.contact)
                          ? this.state.myHipscanDetail.contact.facebook_url
                            ? this.state.myHipscanDetail.contact.facebook_url
                            : ""
                          : ""
                      }
                      instagram_url={
                        !_.isEmpty(this.state.myHipscanDetail.contact)
                          ? this.state.myHipscanDetail.contact.instagram_url
                            ? this.state.myHipscanDetail.contact.instagram_url
                            : ""
                          : ""
                      }
                      meeting_link={
                        this.state.myHipscanDetail.contact?.meeting_link || ''
                      }
                      twitter_url={
                        !_.isEmpty(this.state.myHipscanDetail.contact)
                          ? this.state.myHipscanDetail.contact.twitter_url
                            ? this.state.myHipscanDetail.contact.twitter_url
                            : ""
                          : ""
                      }
                      video_url={
                        !_.isEmpty(this.state.myHipscanDetail.contact)
                          ? this.state.profilePreview.contact.video_url
                            ? this.state.profilePreview.contact.video_url
                            : ""
                          : ""
                      }
                      isIncludedVideo={
                        !_.isEmpty(this.state.myHipscanDetail.contact)
                          ? this.state.profilePreview.contact.isIncludedVideo
                            ? this.state.profilePreview.contact.isIncludedVideo
                            : ""
                          : ""
                      }
                    />
                  </div>
                  <div className="save-continue-btn">
                    <Button onClick={() => this.update()}>
                      SAVE AND CONTINUE
                    </Button>
                  </div>
                </div>
              ) : this.state.isEditing === "product" ? (
                <div className="action-edit-product">
                  <Link
                    to="#"
                    className="goto"
                    onClick={() => this.editUrl("none")}
                  >
                    <IoIosArrowBack size="1.3rem" />
                    Back
                  </Link>
                  <h1>Launch a Product</h1>
                  <div className="product-img-upload">
                    <input
                      id="selectProductImage"
                      type="file"
                      hidden
                      placeholder="Upload Product Picture"
                      onChange={this.productFileSelectHandle}
                      ref={(ref) => (this.productFileInput = ref)}
                    ></input>
                    <span>
                      {this.state.productPreview.selectedFile &&
                        this.state.productPreview.selectedFile.name !== null
                        ? this.state.productPreview.selectedFile.name
                        : "Upload Product Picture"}
                    </span>
                    <div className="icon left" onClick={this.uploadProduct}>
                      <GiOpenFolder size="2.5rem" />
                    </div>
                    <div className="icon" onClick={this.cancelUpload}>
                      <GiCancel size="2.5rem" />
                    </div>
                  </div>
                  <div className="product">
                    <div className="product-name">
                      <input
                        id=""
                        placeholder="Product name"
                        value={this.state.productPreview.name}
                        onChange={this.productNameChange}
                      />
                    </div>
                    <div className="product-price">
                      <input
                        id=""
                        placeholder="$"
                        value={this.state.productPreview.price}
                        onChange={this.priceChange}
                      />
                    </div>
                  </div>
                  <div className="select">
                    <div className="name">
                      <input
                        type="checkbox"
                        name="test"
                        checked={this.state.productPreview.isIncludedVideo}
                        onChange={this.clickedIncludeVideo}
                      />
                      <span className="checkmark"></span>
                      <p>Include Video</p>
                    </div>
                  </div>
                  {/* <div className="display-video"> */}
                  {this.state.productPreview.isIncludedVideo ? (
                    <div className="display-video">
                      <input
                        type="text"
                        placeholder="Custom Video Type"
                        value={this.state.productPreview.video}
                        onChange={this.productVideoURLChange}
                      ></input>
                      <div className="icon1">
                        <TiSocialYoutubeCircular size="2.5rem" />
                      </div>
                      <div className="icon2">
                        <TiSocialVimeoCircular size="2.5rem" />
                      </div>
                    </div>
                  ) : null}
                  {/* </div> */}
                  <div className="product-description">
                    <textarea
                      placeholder="Tell us how great this product or service is!"
                      maxLength={
                        this.state.productPreview.isIncludedVideo ? 300 : 600
                      }
                      value={this.state.productPreview.description}
                      onChange={this.productDescriptionChange}
                    />
                    <p className="letter-count">
                      {this.state.letterCount} /{" "}
                      {this.state.profilePreview.contact.isIncludedVideo
                        ? 300
                        : 600}
                    </p>
                  </div>
                  <div className="save-continue-btn">
                    <Button onClick={() => this.update()}>
                      SAVE AND CONTINUE
                    </Button>
                  </div>
                </div>
              ) : this.state.isEditing === "menu" ? (
                <div className="action-edit-menu">
                  <Link
                    to="#"
                    className="goto"
                    onClick={() => this.editUrl("none")}
                  >
                    <IoIosArrowBack size="1.3rem" />
                    Back
                  </Link>
                  <h1>Launch a Menu</h1>
                  <div className="input-field">
                    <div className="common-input">
                      <input
                        type="text"
                        placeholder="Restaurant Name"
                        value={this.state.menuPreview.name}
                        onChange={this.restaurantNameHandle}
                      ></input>
                    </div>
                    <div className="file-upload">
                      <input
                        id="selectMenuImage"
                        type="file"
                        hidden
                        placeholder="Custom Image Type"
                        onChange={this.menuFileSelectHandle}
                        ref={(ref) => (this.menuFileInput = ref)}
                      ></input>
                      <span>
                        {this.state.menuPreview.selectedFile &&
                          this.state.menuPreview.selectedFile.name !== null
                          ? this.state.menuPreview.selectedFile.name
                          : "Upload Logo"}
                      </span>
                      <div className="icon left" onClick={this.uploadMenu}>
                        <GiOpenFolder size="2.5rem" />
                      </div>
                      <div className="icon" onClick={this.cancelUpload}>
                        <GiCancel size="2.5rem" />
                      </div>
                    </div>
                  </div>
                  <div className="textarea-input">
                    <textarea
                      id="#"
                      placeholder="Go ahead, tell us why your restaurant is so great!"
                      maxLength="600"
                      value={this.state.menuPreview.description}
                      onChange={this.menuDescriptionHandle}
                    />
                    <p className="letter-count">
                      {this.state.letterCount} / 600
                    </p>
                  </div>
                  <div className="btns">
                    <div className="contact-info-btn">
                      <Button onClick={this.modalShow}>CONTACT INFO</Button>
                      <ContactInfoModal
                        show={this.state.show}
                        modalHide={this.modalHide}
                        isAddress={true}
                        saveContact={this.saveContact}
                        onChangeEmail={this.emailHandle}
                        onChangeEmailSubject={this.emailSubjectHandle}
                        onChangeEmailBody={this.emailBodyHandle}
                        onChangeCompany={this.companyHandle}
                        onChangeMeetingLink={this.meetingLinkHandle}
                        onChangeNumber={this.numberHandle}
                        onChangeWebsiteURL={this.websiteHandle}
                        onChangeFacebookURL={this.facebookHandle}
                        onChangeInstagramURL={this.instagramHandle}
                        onChangeTwitterURL={this.twitterHandle}
                        onChangeAddress={this.addressHandle}
                        onChangeVideoURL={this.videoURL}
                        onChangeBFile={this.onChangeBFile}
                        isIncludedVideoHandle={this.isIncludedVideoHandle}
                        bFile={
                          this.state.menuPreview.contact
                            ? this.state.menuPreview.contact.cover_image_url
                            : ""
                        }
                        email={
                          this.state.menuPreview.contact
                            ? this.state.menuPreview.contact.email
                            : ""
                        }
                        email_subject={
                          this.state.menuPreview.contact
                            ? this.state.menuPreview.contact.email_subject
                            : ""
                        }
                        email_body={
                          this.state.menuPreview.contact
                            ? this.state.menuPreview.contact.email_body
                            : ""
                        }
                        meeting_link={
                          this.props.myHipscanDetail.contact?.email_subject || ''
                        }
                        company={
                          this.state.menuPreview.contact
                            ? this.state.menuPreview.contact.company
                            : ""
                        }
                        phone={
                          this.state.menuPreview.contact
                            ? this.state.menuPreview.contact.phone
                            : ""
                        }
                        website_url={
                          this.state.menuPreview.contact
                            ? this.state.menuPreview.contact.website_url
                            : ""
                        }
                        facebook_url={
                          this.state.menuPreview.contact
                            ? this.state.menuPreview.contact.facebook_url
                            : ""
                        }
                        instagram_url={
                          this.state.menuPreview.contact
                            ? this.state.menuPreview.contact.instagram_url
                            : ""
                        }
                        twitter_url={
                          this.state.menuPreview.contact
                            ? this.state.menuPreview.contact.twitter_url
                            : ""
                        }
                        video_url={
                          this.state.menuPreview.contact
                            ? this.state.menuPreview.contact.video_url
                            : ""
                        }
                        isIncludedVideo={
                          this.state.menuPreview.contact.isIncludedVideo
                        }
                        address={
                          this.state.menuPreview.contact
                            ? this.state.menuPreview.contact.address
                            : ""
                        }
                      />
                    </div>
                    <div className="goto-menu-btn">
                      <Button onClick={this.menuModalShow}>GO TO MENU</Button>
                      <RestaurantMenuModal
                        show={this.state.menuShow}
                        modalHide={this.menuModalHide}
                        hipId={this.props.match.params.id}
                      />
                    </div>
                  </div>
                  <div className="save-continue-btn">
                    <Button onClick={() => this.update()}>
                      SAVE AND CONTINUE
                    </Button>
                  </div>
                </div>
              ) : this.state.isEditing === "flyer" ? (
                <div className="action-edit-flyer">
                  <Link
                    to="#"
                    className="goto"
                    onClick={() => {
                      this.editUrl("none");
                      this.setState({ pmw_token: null });
                    }}
                  >
                    <IoIosArrowBack size="1.3rem" />
                    Back
                  </Link>
                  <h1>Launch a Flyer</h1>
                  <p>
                    We have partnered with PosterMyWall to allow you to choose
                    from over 225,000 flyer and poster templates that you can
                    point your Hipscan to. Once you press the 'Launch
                    PosterMyWall' button below, you will be asked to login to
                    your PosterMyWall account. If you do not have a PosterMyWall
                    account, you can go through the quick setup process to
                    create a free account. Once you do that, you will be able to
                    choose the template that you want your Hipscan to point to.
                    You can always come back and tweak this template or choose
                    another destination.
                  </p>
                  <div className="launch-postermywall">
                    <Button onClick={() => this.launch_postermywall()}>
                      LAUNCH POSTERMYWALL
                    </Button>
                  </div>
                  <div>
                    <Form.Group>
                      <Form.Control
                        className="flyer-designs"
                        as="select"
                        size="lg"
                        onChange={this.selectFlyerDesign}
                      >
                        <option value={-1}>Select the Design to Display</option>
                        {this.state.flyer_designs &&
                          this.state.flyer_designs.length > 0 &&
                          this.state.flyer_designs.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div
                    className={
                      this.state.flyer_design
                        ? "launch-postermywall"
                        : "launch-postermywall-disabled"
                    }
                  >
                    <Button
                      disabled={!this.state.flyer_design}
                      onClick={() =>
                        this.editFlyerDesign(this.state.flyer_design.id)
                      }
                    >
                      EDIT DESIGN
                    </Button>
                  </div>
                  <div className="save-continue-btn">
                    <Button
                      disabled={!this.state.flyer_design}
                      onClick={() => this.update()}
                    >
                      SAVE AND CONTINUE
                    </Button>
                  </div>
                </div>
              ) : this.state.isEditing === "design" ? (
                <div className="action-edit-design">
                  <Link
                    to="#"
                    className="goto"
                    onClick={() => this.editUrl("none")}
                  >
                    <IoIosArrowBack size="1.3rem" />
                    Back
                  </Link>
                  <h1>Customize Design</h1>
                  <div className="custom-design-page">
                    <div className="design-options">
                      <div className="display-custom-design">
                        <input
                          id="selectImage"
                          type="file"
                          hidden
                          placeholder="Custom Image Type"
                          onChange={this.designSelectHandle}
                          ref={(ref) => (this.fileInput = ref)}
                        ></input>
                        <span>
                          {this.state.designFile &&
                            this.state.designFile.name !== null
                            ? this.state.designFile.name
                            : "Center Image (Optional)"}
                        </span>
                        <div className="icon" onClick={this.upload}>
                          <GiOpenFolder size="2.5rem" />
                        </div>
                      </div>
                      <div className="design-theme">
                        <div style={{ marginBottom: "30px" }}>
                          <span>Theme</span>
                          <img
                            src={themeOne}
                            className={`icon-class ${this.state.customDesignTheme === "theme-one" &&
                              "active"
                              }`}
                            alt="theme one"
                            onClick={() =>
                              this.onCustomDesignThemeChange("theme-one")
                            }
                          />
                          <img
                            src={themeTwo}
                            className={`icon-class ${this.state.customDesignTheme === "theme-two" &&
                              "active"
                              }`}
                            alt="theme two"
                            onClick={() =>
                              this.onCustomDesignThemeChange("theme-two")
                            }
                          />
                        </div>
                        <div style={{ marginBottom: "30px" }}>
                          <CirclePicker
                            width="400px"
                            color={this.state.customDesignColor}
                            colors={[
                              "#000000",
                              "#F00000",
                              "#D9E3F0",
                              "#F47373",
                              "#697689",
                              "#37D67A",
                              "#2CCCE4",
                              "#dce775",
                              "#ba68c8",
                            ]}
                            onChangeComplete={this.onCustomDesignColorChange}
                          />
                        </div>
                      </div>
                      <div className="design-pattern">
                        <div>
                          <span>Pattern</span>
                          <img
                            src={patternOne}
                            className={`icon-class ${this.state.customDesignPattern === "pattern-one" &&
                              "active"
                              }`}
                            alt="pattern one"
                            onClick={() =>
                              this.onCustomDesignPatternChange("pattern-one")
                            }
                          />
                          <img
                            src={patternTwo}
                            className={`icon-class ${this.state.customDesignPattern === "pattern-two" &&
                              "active"
                              }`}
                            alt="pattern two"
                            onClick={() =>
                              this.onCustomDesignPatternChange("pattern-two")
                            }
                          />
                          <img
                            src={patternThree}
                            className={`icon-class ${this.state.customDesignPattern ===
                              "pattern-three" && "active"
                              }`}
                            alt="pattern three"
                            onClick={() =>
                              this.onCustomDesignPatternChange("pattern-three")
                            }
                          />
                          <img
                            src={patternFour}
                            className={`icon-class ${this.state.customDesignPattern ===
                              "pattern-four" && "active"
                              }`}
                            alt="pattern four"
                            onClick={() =>
                              this.onCustomDesignPatternChange("pattern-four")
                            }
                          />
                          <img
                            src={patternFive}
                            className={`icon-class ${this.state.customDesignPattern ===
                              "pattern-five" && "active"
                              }`}
                            alt="pattern five"
                            onClick={() =>
                              this.onCustomDesignPatternChange("pattern-five")
                            }
                          />
                          <img
                            src={patternSix}
                            className={`icon-class ${this.state.customDesignPattern === "pattern-six" &&
                              "active"
                              }`}
                            alt="pattern six"
                            onClick={() =>
                              this.onCustomDesignPatternChange("pattern-six")
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="design-label">
                        <input
                          type="text"
                          placeholder="Text Border (Optional)"
                          value={this.state.designLabel}
                          onChange={this.onDesignLabelChange}
                          maxLength="36"
                        />
                        <p>Max 36 letters (Remaining: {36-this.state.designLabel?.length})</p>
                      </div> */}
                      <div className="design-actions">
                        <div className="save-continue-btn">
                          <Button onClick={() => this.handleSaveCustomDesign()}>
                            SAVE
                          </Button>
                        </div>
                        <div className="cancel-btn">
                          <Button
                            onClick={() => this.handleCancelCustomDesign()}
                          >
                            CANCEL
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="design-preview">
                      <p>Design Preview</p>
                      <div className="qr-design-preview">
                        {this.state.previewCustomDesign ? (
                          <img
                            src={this.state.previewCustomDesign}
                            id="qr"
                            fgcolor="#000000"
                          />
                        ) : (
                          <img
                            src={profile.code.code_image_url}
                            id="qr"
                            fgcolor="#000000"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="preview-part">
              <div className="title">
                <p>SCREEN PREVIEW</p>
              </div>
              <div className="phone-frame">
                {this.state.isEditing === "none" ? (
                  <div
                    className={
                      this.state.myHipscanDetail.type === "url"
                        ? "phone-screen with-out-gray"
                        : "phone-screen"
                    }
                  >
                    {this.state.myHipscanDetail ? (
                      this.state?.myHipscanDetail?.is_partner ? (
                        <PartnerScan
                          name={this.state.myHipscanDetail.event_title}
                          logo={
                            this.state.myHipscanDetail?.event_logo
                              ? this.state.myHipscanDetail.event_logo
                              : ""
                          }
                          description={
                            this.state.myHipscanDetail?.event_description
                              ? this.state.myHipscanDetail.event_description
                              : ""
                          }
                          qrId={this.state.qrId}
                          bFile={
                            this.state.myHipscanDetail?.contact
                              ? this.state.myHipscanDetail.contact
                                .background_image_url
                              : ""
                          }
                        />
                      ) : this.state.myHipscanDetail.type === "url" ? (
                        this.state.myHipscanDetail.url &&
                          this.state.myHipscanDetail.url !== "undefined" &&
                          this.state.myHipscanDetail.url !== "" ? (
                          <>
                            {linkIframeViewNotWork === true && (
                              <div>
                                <FaRegCheckCircle
                                  className="prev-icon"
                                  size="2.5rem"
                                />
                                <h5 style={{ padding: "15px" }}>
                                  Woohoo! Although this URL does not support
                                  preview, this URL has been mapped to your
                                  Hipscan QR. Go ahead and give it a scan.
                                </h5>
                              </div>
                            )}

                            <Iframe
                              // onLoad={this.onLoadIframeView}
                              url={this.state.myHipscanDetail.url}
                              width={linkIframeViewNotWork ? "1px" : "100%"}
                              height={linkIframeViewNotWork ? "1px" : "100%"}
                              id="iframe_view"
                              display="initial"
                              position="relative"
                            />
                          </>
                        ) : (
                          <div>
                            <FaPhotoVideo className="prev-icon" size="2.5rem" />
                            <h5>
                              Go ahead and input a URL in the custom URL field
                              on the left.
                            </h5>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "location" ? (
                        this.state.myHipscanDetail.location &&
                          this.state.myHipscanDetail.location !== "undefined" &&
                          this.state.myHipscanDetail.location !== "" ? (
                          <>
                            {linkIframeViewNotWork === true && (
                              <div>
                                <FaRegCheckCircle
                                  className='prev-icon'
                                  size='2.5rem'
                                />
                                <h5 style={{ padding: '15px' }}>
                                  Woohoo! Although this Location does not support
                                  preview, this Location has been mapped to your
                                  Hipscan QR. Go ahead and give it a scan.
                                </h5>
                              </div>
                            )}

                            <Iframe
                              // onLoad={this.onLoadIframeView}
                              url={this.state.myHipscanDetail.location}
                              width={linkIframeViewNotWork ? '1px' : '100%'}
                              height={linkIframeViewNotWork ? '1px' : '100%'}
                              id='iframe_view'
                              display='initial'
                              position='relative'
                            />
                          </>
                        ) : (
                          <div>
                            <FaPhotoVideo className='prev-icon' size='2.5rem' />
                            <h5>
                              Go ahead and input a Location in the custom Location field
                              on the left.
                            </h5>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "file" ? (
                        this.state.myHipscanDetail.file &&
                          this.state.myHipscanDetail.file !== "undefined" &&
                          this.state.myHipscanDetail.file !== "" ? (
                          <>
                            {linkIframeViewNotWork === true && (
                              <div>
                                <FaRegCheckCircle
                                  className='prev-icon'
                                  size='2.5rem'
                                />
                                <h5 style={{ padding: '15px' }}>
                                  Woohoo! Although this File does not support
                                  preview, this File has been mapped to your
                                  Hipscan QR. Go ahead and give it a scan.
                                </h5>
                              </div>
                            )}

                            <Iframe
                              // onLoad={this.onLoadIframeView}
                              url={this.state.myHipscanDetail.file}
                              width={linkIframeViewNotWork ? '1px' : '100%'}
                              height={linkIframeViewNotWork ? '1px' : '100%'}
                              id='iframe_view'
                              display='initial'
                              position='relative'
                            />
                          </>
                        ) : (
                          <div>
                            <FaPhotoVideo className='prev-icon' size='2.5rem' />
                            <h5>
                              Go ahead and input a File in the custom File field
                              on the left.
                            </h5>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === 'social' ? (
                        this.state.myHipscanDetail.social &&
                          this.state.myHipscanDetail.social !== 'undefined' &&
                          this.state.myHipscanDetail.social !== '' ? (
                          <>
                            {linkIframeViewNotWork === true && (
                              <div>
                                <FaRegCheckCircle
                                  className='prev-icon'
                                  size='2.5rem'
                                />
                                <h5 style={{ padding: '15px' }}>
                                  Woohoo! Although this Social does not support
                                  preview, this Social has been mapped to your
                                  Hipscan QR. Go ahead and give it a scan.
                                </h5>
                              </div>
                            )}

                            <Iframe
                              url={this.state.myHipscanDetail.social}
                              width={linkIframeViewNotWork ? '1px' : '100%'}
                              height={linkIframeViewNotWork ? '1px' : '100%'}
                              id='iframe_view'
                              display='initial'
                              position='relative'
                            />
                          </>
                        ) : (
                          <div>
                            <FaPhotoVideo className='prev-icon' size='2.5rem' />
                            <h5>
                              Go ahead and input a Social in the custom Social field
                              on the left.
                            </h5>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "wifi" ? (
                        this.state.myHipscanDetail.wifi_name &&
                          this.state.myHipscanDetail.wifi_name !== "undefined" &&
                          this.state.myHipscanDetail.wifi_name !== "" &&
                          this.state.myHipscanDetail.wifi_password &&
                          this.state.myHipscanDetail.wifi_password !== "undefined" &&
                          this.state.myHipscanDetail.wifi_password !== "" ? (
                          <>
                            {linkIframeViewNotWork === true && (
                              <div>
                                <FaRegCheckCircle
                                  className='prev-icon'
                                  size='2.5rem'
                                />
                                <h5 style={{ padding: '15px' }}>
                                  Woohoo! These credentials has been mapped to your
                                  Hipscan QR. Go ahead and give it a scan.
                                </h5>
                              </div>
                            )}
                          </>
                        ) : (
                          <div>
                            <FaPhotoVideo className='prev-icon' size='2.5rem' />
                            <h5>
                              Go ahead and input a Wifi's credentials in the custom field
                              on the left.
                            </h5>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "text" ? (
                        this.state.myHipscanDetail.text !== "" ? (
                          <div className="preview-text-container">
                            {/* <IoIosInformationCircle className="prev-icon" size="2.5rem" /> */}
                            <div className="preview-text">
                              {this.state.myHipscanDetail.text}
                            </div>
                            <div className="phone-footer">
                              <p>POWERED BY</p>
                              <img src={logo} alt="logo" />
                            </div>
                          </div>
                        ) : (
                          <div className="preview-text-container">
                            {/* <IoIosInformationCircle className="prev-icon" size="2.5rem" /> */}
                            <div className="preview-text">
                              <FaPhotoVideo
                                className="prev-icon"
                                size="2.5rem"
                              />
                              <h5>
                                Go ahead and input your message into the text
                                box on the left.
                              </h5>
                            </div>
                            <div className="phone-footer">
                              <p>POWERED BY</p>
                              <img src={logo} alt="logo" />
                            </div>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "image" ? (
                        this.state.myHipscanDetail.image_url !== "" ? (
                          <div className="preview-image-container">
                            <div className="preview-image-content">
                              <img
                                className="image-preview"
                                src={this.state.myHipscanDetail.image_url}
                                alt="upload image"
                              />
                            </div>
                            <div className="phone-footer">
                              <p>POWERED BY</p>
                              <img src={logo} alt="logo" />
                            </div>
                          </div>
                        ) : (
                          <div className="preview-image-container">
                            <div className="preview-image-content">
                              <FaPhotoVideo
                                className="prev-icon"
                                size="2.5rem"
                              />
                              <h5>
                                Go ahead, upload your favorite image here!
                              </h5>
                            </div>
                            <div className="phone-footer">
                              <p>POWERED BY</p>
                              <img src={logo} alt="logo" />
                            </div>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "video" ? (
                        this.state.myHipscanDetail.video !== "" ? (
                          <div className="preview-video-container">
                            <div className="preview-video-content">
                              <ReactPlayer
                                url={this.state.myHipscanDetail.video}
                                playing
                                controls
                                width="100%"
                                height="100%"
                                onPause
                              />
                            </div>
                            <div className="phone-footer">
                              <p>POWERED BY</p>
                              <img src={logo} alt="logo" />
                            </div>
                          </div>
                        ) : (
                          <div className="preview-video-container">
                            <div className="preview-video-content">
                              <FaRegPlayCircle
                                className="prev-icon"
                                size="2.5rem"
                              />
                              <h5>
                                Go ahead, upload your favorite video here!
                              </h5>
                            </div>
                            <div className="phone-footer">
                              <p>POWERED BY</p>
                              <img src={logo} alt="logo" />
                            </div>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "flyer" ? (
                        this.state.myHipscanDetail.preview_url === "" ? (
                          <div>
                            <FaPhotoVideo className="prev-icon" size="2.5rem" />
                            <h5>Loading the preview...</h5>
                          </div>
                        ) : this.state.myHipscanDetail.design_type ===
                          "image" ? (
                          <div className="image-flyer-preview">
                            <img
                              style={{ width: "100%" }}
                              src={this.state.myHipscanDetail.preview_url}
                            ></img>
                          </div>
                        ) : (
                          <Iframe
                            url={this.state.myHipscanDetail.preview_url}
                            width={"100%"}
                            height={"100%"}
                            id="iframe_view"
                            display="initial"
                            position="relative"
                          />
                        )
                      ) : this.state.myHipscanDetail.type === "profile" ? (
                        <ProfilePhone
                          color={this.state.color}
                          name={this.state.myHipscanDetail.name}
                          title={this.state.myHipscanDetail.title}
                          meeting_link={
                            !_.isEmpty(this.props.myHipscanDetail.contact)
                              ? this.props.myHipscanDetail.contact.meeting_link
                                ? this.props.myHipscanDetail.contact
                                  .meeting_link
                                : ""
                              : ""
                          }
                          email_subject={
                            this.props.myHipscanDetail.contact?.email_subject || ''
                          }
                          email_body={
                            this.props.myHipscanDetail.contact?.email_body || ''
                          }
                          description={this.state.myHipscanDetail.description}
                          file={this.state.myHipscanDetail.profile_image_url}
                          bFile={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact
                                .background_image_url
                              : ""
                          }
                          c_email={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.email
                              : ""
                          }
                          c_number={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.phone
                              : ""
                          }
                          c_website={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.website_url
                              : ""
                          }
                          c_facebook={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.facebook_url
                              : ""
                          }
                          c_instagram={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.instagram_url
                              : ""
                          }
                          c_twitter={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.twitter_url
                              : ""
                          }
                          c_videoURL={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.video_url
                              : ""
                          }
                          isIncludedVideo={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact
                                .isIncludedVideo
                              : ""
                          }
                        />
                      ) : this.state.myHipscanDetail.type === "product" ? (
                        <ProductPhone
                          productName={this.state.myHipscanDetail.name}
                          productPrice={this.state.myHipscanDetail.price}
                          videoURL={this.state.myHipscanDetail.video}
                          description={this.state.myHipscanDetail.description}
                          fileUpload={
                            this.state.myHipscanDetail.product_image_url
                          }
                          isIncludedVideo={
                            this.state.myHipscanDetail.isIncludedVideo
                          }
                        />
                      ) : this.state.myHipscanDetail.type === "menu" ? (
                        <MenuPhone
                          detailInfo={this.state.myHipscanDetail}
                          restaurantName={this.state.myHipscanDetail.name}
                          description={this.state.myHipscanDetail.description}
                          uploadFile={this.state.myHipscanDetail.menu_logo_url}
                          themeColor={this.state.myHipscanDetail.theme_color}
                          bFile={
                            this.state.myHipscanDetail.contact.cover_image_url
                          }
                          c_email={this.state.myHipscanDetail.contact.email}
                          c_number={this.state.myHipscanDetail.contact.phone}
                          c_website={
                            this.state.myHipscanDetail.contact.website_url
                          }
                          c_facebook={
                            this.state.myHipscanDetail.contact.facebook_url
                          }
                          c_instagram={
                            this.state.myHipscanDetail.contact.instagram_url
                          }
                          c_twitter={
                            this.state.myHipscanDetail.contact.twitter_url
                          }
                          c_address={this.state.myHipscanDetail.contact.address}
                        />
                      ) : null
                    ) : null}
                  </div>
                ) : this.state.isEditing === "url" ? (
                  <div className="phone-screen with-out-gray">
                    {this.state.previewURL &&
                      this.state.previewURL !== "undefined" &&
                      this.state.previewURL !== "" ? (
                      <>
                        {linkIframeEditNotWork === true && (
                          <div>
                            <FaRegCheckCircle
                              className="prev-icon"
                              size="2.5rem"
                            />
                            <h5 style={{ padding: "15px" }}>
                              Woohoo! Although this URL does not support
                              preview, your URL will be mapped to your Hipscan
                              upon pressing the "Save" button.
                            </h5>
                          </div>
                        )}
                        <Iframe
                          url={this.state.previewURL}
                          width={linkIframeEditNotWork ? "1px" : "100%"}
                          height={linkIframeEditNotWork ? "1px" : "100%"}
                          id="iframe_edit"
                          display="initial"
                          position="relative"
                        />
                      </>
                    ) : (
                      <div>
                        <FaPhotoVideo className="prev-icon" size="2.5rem" />
                        <h5>
                          Go ahead and input a URL in the custom URL field on
                          the left.
                        </h5>
                      </div>
                    )}
                  </div>
                ) : this.state.isEditing === "location" ? (
                  <div className="phone-screen with-out-gray">
                    {this.state.previewLocation &&
                      this.state.previewLocation !== "undefined" &&
                      this.state.previewLocation !== "" ? (
                      <>
                        {linkIframeEditNotWork === true && (
                          <div>
                            <FaRegCheckCircle
                              className="prev-icon"
                              size="2.5rem"
                            />
                            <h5 style={{ padding: "15px" }}>
                              Woohoo! Although this Location does not support
                              preview, your Location will be mapped to your Hipscan
                              upon pressing the "Save" button.
                            </h5>
                          </div>
                        )}
                        <Iframe
                          url={this.state.location}
                          width={linkIframeEditNotWork ? "1px" : "100%"}
                          height={linkIframeEditNotWork ? "1px" : "100%"}
                          id="iframe_edit"
                          display="initial"
                          position="relative"
                        />
                      </>
                    ) : (
                      <div>
                        <FaPhotoVideo className="prev-icon" size="2.5rem" />
                        <h5>
                          Go ahead and input a Location in the custom Location field on
                          the left.
                        </h5>
                      </div>
                    )}
                  </div>
                ) : this.state.isEditing === "file" ? (
                  <div className="phone-screen with-out-gray">
                    {this.state.previewFile &&
                      this.state.previewFile !== "undefined" &&
                      this.state.previewFile !== "" ? (
                      <>
                        {linkIframeEditNotWork === true && (
                          <div>
                            <FaRegCheckCircle
                              className="prev-icon"
                              size="2.5rem"
                            />
                            <h5 style={{ padding: "15px" }}>
                              Woohoo! Although this File does not support
                              preview, your File will be mapped to your Hipscan
                              upon pressing the "Save" button.
                            </h5>
                          </div>
                        )}
                        <Iframe
                          url={this.state.file}
                          width={linkIframeEditNotWork ? "1px" : "100%"}
                          height={linkIframeEditNotWork ? "1px" : "100%"}
                          id="iframe_edit"
                          display="initial"
                          position="relative"
                        />
                      </>
                    ) : (
                      <div>
                        <FaPhotoVideo className="prev-icon" size="2.5rem" />
                        <h5>
                          Go ahead and input a File in the custom File field on
                          the left.
                        </h5>
                      </div>
                    )}
                  </div>
                ) : this.state.isEditing === 'social' ? (
                  <div className='phone-screen with-out-gray'>
                    {this.state.previewSocial &&
                      this.state.previewSocial !== 'undefined' &&
                      this.state.previewSocial !== '' ? (
                      <>
                        {linkIframeEditNotWork === true && (
                          <div>
                            <FaRegCheckCircle
                              className='prev-icon'
                              size='2.5rem'
                            />
                            <h5 style={{ padding: '15px' }}>
                              Woohoo! Although this Social does not support
                              preview, your Social will be mapped to your Hipscan
                              upon pressing the "Save" button.
                            </h5>
                          </div>
                        )}
                        <Iframe
                          url={this.state.social}
                          width={linkIframeEditNotWork ? '1px' : '100%'}
                          height={linkIframeEditNotWork ? '1px' : '100%'}
                          id='iframe_edit'
                          display='initial'
                          position='relative'
                        />
                      </>
                    ) : (
                      <div>
                        <FaPhotoVideo className='prev-icon' size='2.5rem' />
                        <h5>
                          Go ahead and input Social in the custom Social field on
                          the left.
                        </h5>
                      </div>
                    )}
                  </div>
                ) : this.state.isEditing === "wifi" ? (
                  <div className="phone-screen with-out-gray">
                    {this.state.myHipscanDetail.wifi_name &&
                      this.state.myHipscanDetail.wifi_name !== "undefined" &&
                      this.state.myHipscanDetail.wifi_name !== "" &&
                      this.state.myHipscanDetail.wifi_password &&
                      this.state.myHipscanDetail.wifi_password !== "undefined" &&
                      this.state.myHipscanDetail.wifi_password !== "" ? (
                      <>
                        {linkIframeEditNotWork === true && (
                          <div>
                            <FaRegCheckCircle
                              className="prev-icon"
                              size="2.5rem"
                            />
                            <h5 style={{ padding: '15px' }}>
                              Woohoo! These credentials has been mapped to your
                              Hipscan QR. Go ahead and give it a scan.
                            </h5>
                          </div>
                        )}
                        <Iframe
                          url={this.state.file}
                          width={linkIframeEditNotWork ? "1px" : "100%"}
                          height={linkIframeEditNotWork ? "1px" : "100%"}
                          id="iframe_edit"
                          display="initial"
                          position="relative"
                        />
                      </>
                    ) : (
                      <div>
                        <FaPhotoVideo className="prev-icon" size="2.5rem" />
                        <h5>
                          Go ahead and input a Wifi's credentials in the custom field
                          on the left.
                        </h5>
                      </div>
                    )}
                  </div>
                  //
                ) : this.state.isEditing === "text" ? (
                  <div className="phone-screen">
                    {this.state.text !== "" ? (
                      <div className="preview-text-container">
                        {/* <IoIosInformationCircle className="prev-icon" size="2.5rem" /> */}
                        <div className="preview-text">{this.state.text}</div>
                        <div className="phone-footer">
                          <p>POWERED BY</p>
                          <img src={logo} alt="logo" />
                        </div>
                      </div>
                    ) : (
                      <div className="preview-text-container">
                        {/* <IoIosInformationCircle className="prev-icon" size="2.5rem" /> */}
                        <div className="preview-text">
                          <FaPhotoVideo className="prev-icon" size="2.5rem" />
                          <h5>
                            Go ahead and input your message into the text box on
                            the left.
                          </h5>
                        </div>
                        <div className="phone-footer">
                          <p>POWERED BY</p>
                          <img src={logo} alt="logo" />
                        </div>
                      </div>
                    )}
                  </div>
                ) : this.state.isEditing === "image" ? (
                  <div className="phone-screen">
                    {this.state.imageUrl !== "" ? (
                      <div className="preview-image-container">
                        <div className="preview-image-content">
                          <img
                            className="image-preview"
                            src={this.state.imageUrl}
                            alt="upload image"
                          />
                        </div>
                        <div className="phone-footer">
                          <p>POWERED BY</p>
                          <img src={logo} alt="logo" />
                        </div>
                      </div>
                    ) : (
                      <div className="preview-image-container">
                        <div className="preview-image-content">
                          <FaPhotoVideo className="prev-icon" size="2.5rem" />
                          <h5>Go ahead, upload your favorite image here!</h5>
                        </div>
                        <div className="phone-footer">
                          <p>POWERED BY</p>
                          <img src={logo} alt="logo" />
                        </div>
                      </div>
                    )}
                  </div>
                ) : this.state.isEditing === "video" ? (
                  <div className="phone-screen">
                    {this.state.videoURL && this.state.videoURL !== "" ? (
                      <div className="preview-video-container">
                        <div className="preview-video-content">
                          <ReactPlayer
                            url={this.state.videoURL}
                            playing
                            controls
                            width="100%"
                            height="100%"
                            onPause
                          />
                        </div>
                        <div className="phone-footer">
                          <p>POWERED BY</p>
                          <img src={logo} alt="logo" />
                        </div>
                      </div>
                    ) : (
                      <div className="preview-video-container">
                        <div className="preview-video-content">
                          <FaRegPlayCircle
                            className="prev-icon"
                            size="2.5rem"
                          />
                          <h5>Go ahead, upload your favorite video here!</h5>
                        </div>
                        <div className="phone-footer">
                          <p>POWERED BY</p>
                          <img src={logo} alt="logo" />
                        </div>
                      </div>
                    )}
                  </div>
                ) : this.state.isEditing === "profile" ? (
                  <div
                    className="phone-screen"
                    style={{
                      zIndex:
                        this.state.show || this.state.menuShow ? 1 : 999999999,
                    }}
                  >
                    <ProfilePhone
                      name={this.state.profilePreview.name}
                      color={this.state.color}
                      description={this.state.profilePreview.description}
                      meeting_link={
                        !_.isEmpty(this.props.myHipscanDetail.contact)
                          ? this.props.myHipscanDetail.contact.meeting_link
                            ? this.props.myHipscanDetail.contact.meeting_link
                            : ""
                          : ""
                      }
                      email_subject={
                        !_.isEmpty(this.props.myHipscanDetail.contact)
                          ? this.props.myHipscanDetail.contact.email_subject
                            ? this.props.myHipscanDetail.contact.email_subject
                            : ""
                          : ""
                      }
                      email_body={
                        !_.isEmpty(this.props.myHipscanDetail.contact)
                          ? this.props.myHipscanDetail.contact.email_body
                            ? this.props.myHipscanDetail.contact.email_body
                            : ""
                          : ""
                      }
                      title={this.state.profilePreview.title}
                      file={this.state.profilePreview.profile_image_url}
                      bFile={
                        this.state.profilePreview.contact.background_image_url
                      }
                      c_email={this.state.profilePreview.contact.email}
                      c_number={this.state.profilePreview.contact.phone}
                      c_website={this.state.profilePreview.contact.website_url}
                      c_facebook={
                        this.state.profilePreview.contact.facebook_url
                      }
                      c_instagram={
                        this.state.profilePreview.contact.instagram_url
                      }
                      c_twitter={this.state.profilePreview.contact.twitter_url}
                      c_videoURL={this.state.profilePreview.contact.video_url}
                      isIncludedVideo={
                        this.state.profilePreview.contact.isIncludedVideo
                      }
                    />
                  </div>
                ) : this.state.isEditing === "product" ? (
                  <div
                    className="phone-screen"
                    style={{
                      zIndex:
                        this.state.show || this.state.menuShow ? 1 : 999999999,
                    }}
                  >
                    <ProductPhone
                      productName={this.state.productPreview.name}
                      productPrice={this.state.productPreview.price}
                      videoURL={this.state.productPreview.video}
                      description={this.state.productPreview.description}
                      fileUpload={this.state.productPreview.product_image_url}
                      isIncludedVideo={
                        this.state.productPreview.isIncludedVideo
                      }
                    />
                  </div>
                ) : this.state.isEditing === "menu" ? (
                  <div
                    className="phone-screen"
                    style={{
                      zIndex:
                        this.state.show || this.state.menuShow ? 1 : 999999999,
                    }}
                  >
                    <MenuPhone
                      detailInfo={this.state.menuPreview}
                      restaurantName={this.state.menuPreview.name}
                      description={this.state.menuPreview.description}
                      uploadFile={this.state.menuPreview.menu_logo_url}
                      themeColor={this.state.menuPreview.theme_color}
                      bFile={this.state.menuPreview.contact.cover_image_url}
                      c_email={this.state.menuPreview.contact.email}
                      c_number={this.state.menuPreview.contact.phone}
                      c_website={this.state.menuPreview.contact.website_url}
                      c_facebook={this.state.menuPreview.contact.facebook_url}
                      c_instagram={this.state.menuPreview.contact.instagram_url}
                      c_twitter={this.state.menuPreview.contact.twitter_url}
                      c_address={this.state.menuPreview.contact.address}
                    />
                  </div>
                ) : this.state.isEditing === "flyer" ? (
                  <div className="phone-screen with-out-gray">
                    {this.state.flyer_design ? (
                      this.state.flyer_design.type === "image" ? (
                        <div className="image-flyer-preview">
                          <img
                            style={{ width: "100%" }}
                            src={this.state.flyer_design.preview_url}
                          ></img>
                        </div>
                      ) : (
                        <Iframe
                          url={this.state.flyer_design.preview_video_url}
                          width={"100%"}
                          height={"100%"}
                          id="iframe_view"
                          display="initial"
                          position="relative"
                        />
                      )
                    ) : this.state.myHipscanDetail.type === "flyer" ? (
                      this.state.myHipscanDetail.design_type === "image" ? (
                        <div className="image-flyer-preview">
                          <img
                            style={{ width: "100%" }}
                            src={this.state.myHipscanDetail.preview_url}
                          ></img>
                        </div>
                      ) : (
                        <Iframe
                          url={this.state.myHipscanDetail.preview_url}
                          width={"100%"}
                          height={"100%"}
                          id="iframe_view"
                          display="initial"
                          position="relative"
                        />
                      )
                    ) : (
                      <div>
                        <FaPhotoVideo className="prev-icon" size="2.5rem" />
                        <h5>
                          Go ahead and pick a destination and input where you
                          want it to point.
                        </h5>
                      </div>
                    )}
                  </div>
                ) : this.state.isEditing === "design" ? (
                  <div
                    className={
                      this.state.myHipscanDetail.type === "url"
                        ? "phone-screen with-out-gray"
                        : "phone-screen"
                    }
                  >
                    {this.state.myHipscanDetail ? (
                      this.state.myHipscanDetail.type === "url" ? (
                        this.state.myHipscanDetail.url &&
                          this.state.myHipscanDetail.url !== "undefined" &&
                          this.state.myHipscanDetail.url !== "" ? (
                          <>
                            {linkIframeViewNotWork === true && (
                              <div>
                                <FaRegCheckCircle
                                  className="prev-icon"
                                  size="2.5rem"
                                />
                                <h5 style={{ padding: "15px" }}>
                                  Woohoo! Although this URL does not support
                                  preview, this URL has been mapped to your
                                  Hipscan QR. Go ahead and give it a scan.
                                </h5>
                              </div>
                            )}

                            <Iframe
                              // onLoad={this.onLoadIframeView}
                              url={this.state.myHipscanDetail.url}
                              width={linkIframeViewNotWork ? "1px" : "100%"}
                              height={linkIframeViewNotWork ? "1px" : "100%"}
                              id="iframe_view"
                              display="initial"
                              position="relative"
                            />
                          </>
                        ) : (
                          <div>
                            <FaPhotoVideo className="prev-icon" size="2.5rem" />
                            <h5>
                              Go ahead and input a URL in the custom URL field
                              on the left.
                            </h5>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "text" ? (
                        this.state.myHipscanDetail.text !== "" ? (
                          <div className="preview-text-container">
                            {/* <IoIosInformationCircle className="prev-icon" size="2.5rem" /> */}
                            <div className="preview-text">
                              {this.state.myHipscanDetail.text}
                            </div>
                            <div className="phone-footer">
                              <p>POWERED BY</p>
                              <img src={logo} alt="logo" />
                            </div>
                          </div>
                        ) : (
                          <div className="preview-text-container">
                            {/* <IoIosInformationCircle className="prev-icon" size="2.5rem" /> */}
                            <div className="preview-text">
                              <FaPhotoVideo
                                className="prev-icon"
                                size="2.5rem"
                              />
                              <h5>
                                Go ahead and input your message into the text
                                box on the left.
                              </h5>
                            </div>
                            <div className="phone-footer">
                              <p>POWERED BY</p>
                              <img src={logo} alt="logo" />
                            </div>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "location" ? (
                        this.state.myHipscanDetail.location &&
                          this.state.myHipscanDetail.location !== "undefined" &&
                          this.state.myHipscanDetail.location !== "" ? (
                          <>
                            {linkIframeViewNotWork === true && (
                              <div>
                                <FaRegCheckCircle
                                  className="prev-icon"
                                  size="2.5rem"
                                />
                                <h5 style={{ padding: "15px" }}>
                                  Woohoo! Although this Location does not support
                                  preview, this Location has been mapped to your
                                  Hipscan QR. Go ahead and give it a scan.
                                </h5>
                              </div>
                            )}

                            <Iframe
                              // onLoad={this.onLoadIframeView}
                              url={this.state.myHipscanDetail.location}
                              width={linkIframeViewNotWork ? "1px" : "100%"}
                              height={linkIframeViewNotWork ? "1px" : "100%"}
                              id="iframe_view"
                              display="initial"
                              position="relative"
                            />
                          </>
                        ) : (
                          <div>
                            <FaPhotoVideo className="prev-icon" size="2.5rem" />
                            <h5>
                              Go ahead and input a Location in the custom Location field
                              on the left.
                            </h5>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "file" ? (
                        this.state.myHipscanDetail.file &&
                          this.state.myHipscanDetail.file !== "undefined" &&
                          this.state.myHipscanDetail.file !== "" ? (
                          <>
                            {linkIframeViewNotWork === true && (
                              <div>
                                <FaRegCheckCircle
                                  className="prev-icon"
                                  size="2.5rem"
                                />
                                <h5 style={{ padding: "15px" }}>
                                  Woohoo! Although this File does not support
                                  preview, this File has been mapped to your
                                  Hipscan QR. Go ahead and give it a scan.
                                </h5>
                              </div>
                            )}

                            <Iframe
                              // onLoad={this.onLoadIframeView}
                              url={this.state.myHipscanDetail.file}
                              width={linkIframeViewNotWork ? "1px" : "100%"}
                              height={linkIframeViewNotWork ? "1px" : "100%"}
                              id="iframe_view"
                              display="initial"
                              position="relative"
                            />
                          </>
                        ) : (
                          <div>
                            <FaPhotoVideo className="prev-icon" size="2.5rem" />
                            <h5>
                              Go ahead and input a File in the custom File field
                              on the left.
                            </h5>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === 'social' ? (
                        this.state.myHipscanDetail.social &&
                          this.state.myHipscanDetail.social !== 'undefined' &&
                          this.state.myHipscanDetail.social !== '' ? (
                          <>
                            {linkIframeViewNotWork === true && (
                              <div>
                                <FaRegCheckCircle
                                  className='prev-icon'
                                  size='2.5rem'
                                />
                                <h5 style={{ padding: '15px' }}>
                                  Woohoo! Although this Social does not support
                                  preview, this Social has been mapped to your
                                  Hipscan QR. Go ahead and give it a scan.
                                </h5>
                              </div>
                            )}

                            <Iframe
                              url={this.state.myHipscanDetail.social}
                              width={linkIframeViewNotWork ? '1px' : '100%'}
                              height={linkIframeViewNotWork ? '1px' : '100%'}
                              id='iframe_view'
                              display='initial'
                              position='relative'
                            />
                          </>
                        ) : (
                          <div>
                            <FaPhotoVideo className='prev-icon' size='2.5rem' />
                            <h5>
                              Go ahead and input a Social in the custom Social field
                              on the left.
                            </h5>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "wifi" ? (
                        this.state.myHipscanDetail.wifi_name &&
                          this.state.myHipscanDetail.wifi_name !== "undefined" &&
                          this.state.myHipscanDetail.wifi_name !== "" &&
                          this.state.myHipscanDetail.wifi_password &&
                          this.state.myHipscanDetail.wifi_password !== "undefined" &&
                          this.state.myHipscanDetail.wifi_password !== "" ? (
                          <>
                            {linkIframeViewNotWork === true && (
                              <div>
                                <FaRegCheckCircle
                                  className='prev-icon'
                                  size='2.5rem'
                                />
                                <h5 style={{ padding: '15px' }}>
                                  Woohoo! These credentials has been mapped to your
                                  Hipscan QR. Go ahead and give it a scan.
                                </h5>
                              </div>
                            )}
                          </>
                        ) : (
                          <div>
                            <FaPhotoVideo className='prev-icon' size='2.5rem' />
                            <h5>
                              Go ahead and input a Wifi's credentials in the custom field
                              on the left.
                            </h5>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "image" ? (
                        this.state.myHipscanDetail.image_url !== "" ? (
                          <div className="preview-image-container">
                            <div className="preview-image-content">
                              <img
                                className="image-preview"
                                src={this.state.myHipscanDetail.image_url}
                                alt="upload image"
                              />
                            </div>
                            <div className="phone-footer">
                              <p>POWERED BY</p>
                              <img src={logo} alt="logo" />
                            </div>
                          </div>
                        ) : (
                          <div className="preview-image-container">
                            <div className="preview-image-content">
                              <FaPhotoVideo
                                className="prev-icon"
                                size="2.5rem"
                              />
                              <h5>
                                Go ahead, upload your favorite image here!
                              </h5>
                            </div>
                            <div className="phone-footer">
                              <p>POWERED BY</p>
                              <img src={logo} alt="logo" />
                            </div>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "video" ? (
                        this.state.myHipscanDetail.video !== "" ? (
                          <div className="preview-video-container">
                            <div className="preview-video-content">
                              <ReactPlayer
                                url={this.state.myHipscanDetail.video}
                                playing
                                controls
                                width="100%"
                                height="100%"
                                onPause
                              />
                            </div>
                            <div className="phone-footer">
                              <p>POWERED BY</p>
                              <img src={logo} alt="logo" />
                            </div>
                          </div>
                        ) : (
                          <div className="preview-video-container">
                            <div className="preview-video-content">
                              <FaRegPlayCircle
                                className="prev-icon"
                                size="2.5rem"
                              />
                              <h5>
                                Go ahead, upload your favorite video here!
                              </h5>
                            </div>
                            <div className="phone-footer">
                              <p>POWERED BY</p>
                              <img src={logo} alt="logo" />
                            </div>
                          </div>
                        )
                      ) : this.state.myHipscanDetail.type === "flyer" ? (
                        this.state.myHipscanDetail.preview_url === "" ? (
                          <div>
                            <FaPhotoVideo className="prev-icon" size="2.5rem" />
                            <h5>Loading the preview...</h5>
                          </div>
                        ) : this.state.myHipscanDetail.design_type ===
                          "image" ? (
                          <div className="image-flyer-preview">
                            <img
                              style={{ width: "100%" }}
                              src={this.state.myHipscanDetail.preview_url}
                            ></img>
                          </div>
                        ) : (
                          <Iframe
                            url={this.state.myHipscanDetail.preview_url}
                            width={"100%"}
                            height={"100%"}
                            id="iframe_view"
                            display="initial"
                            position="relative"
                          />
                        )
                      ) : this.state.myHipscanDetail.type === "profile" ? (
                        <ProfilePhone
                          name={this.state.myHipscanDetail.name}
                          color={this.state.color}
                          description={this.state.myHipscanDetail.description}
                          meeting_link={
                            !_.isEmpty(this.props.myHipscanDetail.contact)
                              ? this.props.myHipscanDetail.contact.meeting_link
                                ? this.props.myHipscanDetail.contact
                                  .meeting_link
                                : ""
                              : ""
                          }
                          email_subject={
                            !_.isEmpty(this.props.myHipscanDetail.contact)
                              ? this.props.myHipscanDetail.contact.email_subject
                                ? this.props.myHipscanDetail.contact
                                  .email_subject
                                : ""
                              : ""
                          }
                          email_body={
                            this.props.myHipscanDetail.contact?.email_body || ''
                          }
                          title={this.state.profilePreview.title}
                          file={this.state.myHipscanDetail.profile_image_url}
                          bFile={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact
                                .background_image_url
                              : ""
                          }
                          c_email={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.email
                              : ""
                          }
                          c_number={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.phone
                              : ""
                          }
                          c_website={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.website_url
                              : ""
                          }
                          c_facebook={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.facebook_url
                              : ""
                          }
                          c_instagram={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.instagram_url
                              : ""
                          }
                          c_twitter={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.twitter_url
                              : ""
                          }
                          c_videoURL={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact.video_url
                              : ""
                          }
                          isIncludedVideo={
                            this.state.myHipscanDetail.contact
                              ? this.state.myHipscanDetail.contact
                                .isIncludedVideo
                              : ""
                          }
                        />
                      ) : this.state.myHipscanDetail.type === "product" ? (
                        <ProductPhone
                          productName={this.state.myHipscanDetail.name}
                          productPrice={this.state.myHipscanDetail.price}
                          videoURL={this.state.myHipscanDetail.video}
                          description={this.state.myHipscanDetail.description}
                          fileUpload={
                            this.state.myHipscanDetail.product_image_url
                          }
                          isIncludedVideo={
                            this.state.myHipscanDetail.isIncludedVideo
                          }
                        />
                      ) : this.state.myHipscanDetail.type === "menu" ? (
                        <MenuPhone
                          detailInfo={this.state.myHipscanDetail}
                          restaurantName={this.state.myHipscanDetail.name}
                          description={this.state.myHipscanDetail.description}
                          uploadFile={this.state.myHipscanDetail.menu_logo_url}
                          themeColor={this.state.myHipscanDetail.theme_color}
                          bFile={
                            this.state.myHipscanDetail.contact.cover_image_url
                          }
                          c_email={this.state.myHipscanDetail.contact.email}
                          c_number={this.state.myHipscanDetail.contact.phone}
                          c_website={
                            this.state.myHipscanDetail.contact.website_url
                          }
                          c_facebook={
                            this.state.myHipscanDetail.contact.facebook_url
                          }
                          c_instagram={
                            this.state.myHipscanDetail.contact.instagram_url
                          }
                          c_twitter={
                            this.state.myHipscanDetail.contact.twitter_url
                          }
                          c_address={this.state.myHipscanDetail.contact.address}
                        />
                      ) : null
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className="mob-hipscan d-block d-md-none">
            <Header2
              {...this.props}
              isEditing={this.state.isEditing}
              goBack={this.editUrl}
            />

            <div className="msg-upgrade">
              <Alert variant="warning">
                {/* <BsInfoCircle /> */}
                <img src={ImgInfo} alt="" /> Enjoy more powerful features on
                Hipscan.
                <Link to="/plans"> UPGRADE PLAN NOW!</Link>
              </Alert>
            </div>

            {this.state.isEditing === "none" ? (
              <div className="action">
                <div className="row qr-img-section">
                  <div className="col-6">
                    {profile && !profile.code.customDesigned ? (
                      <div className="color4">
                        <div
                          className={
                            this.state.qrcolor === "#000000" ? "selected" : ""
                          }
                        >
                          <div
                            className="color-btn black"
                            onClick={() => this.qrColorHandle("#000000")}
                          ></div>
                        </div>
                        <div
                          className={
                            this.state.qrcolor === "#00b300" ? "selected" : ""
                          }
                        >
                          <div
                            className="color-btn green"
                            onClick={() => this.qrColorHandle("#00b300")}
                          ></div>
                        </div>
                        <div
                          className={
                            this.state.qrcolor === "#820606" ? "selected" : ""
                          }
                        >
                          <div
                            className="color-btn red"
                            onClick={() => this.qrColorHandle("#820606")}
                          ></div>
                        </div>
                        <div
                          className={
                            this.state.qrcolor === "#030369" ? "selected" : ""
                          }
                        >
                          <div
                            className="color-btn blue"
                            onClick={() => this.qrColorHandle("#030369")}
                          ></div>
                        </div>
                      </div>
                    ) : null}
                    <div className="download-btn">
                      <Button
                        className="btn"
                        onClick={() => this.downloadQR("png")}
                        download
                      >
                        <FaDownload /> PNG
                      </Button>
                      <Button
                        className="btn"
                        onClick={() => this.downloadQR("jpg")}
                        download
                      >
                        <FaDownload /> JPG
                      </Button>
                      <Button
                        className="btn"
                        onClick={() => this.downloadQR("eps")}
                        download
                      >
                        <FaDownload /> EPS
                      </Button>
                    </div>
                    <div className="custom-design">
                      <Button
                        className="action-btn"
                        onClick={() => this.customizeQRCode()}
                      >
                        Customize QR Code Design
                      </Button>
                    </div>
                    {this.state.customize_plan_upgrade && (
                      <div className="custom-design-upgrade">
                        <p className="upgrade-link">
                          You can customize your QR code by upgrading to the Pro
                          or Premium plans.
                          <br />
                          <Link to="/plans" className="gotoUpgrade">
                            <span> Upgrade Now </span>
                          </Link>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <img
                      src={
                        this.state.qr_image_url !== ""
                          ? this.state.qr_image_url
                          : this.state.myHipscanDetail &&
                            this.state.myHipscanDetail.code_image_url
                            ? this.state.myHipscanDetail.code_image_url
                            : ""
                      }
                      className="action-image"
                      fgColor={this.state.qrcolor}
                    />
                  </div>
                </div>
                <div className="info-input">
                  <h1>Awesome! So, what will your Hipscan do?</h1>
                  <p>(Don't worry -- you can change this any time your want)</p>
                  <div className="action-btns">
                    <Button
                      className={
                        this.state.myHipscanDetail &&
                          this.state.myHipscanDetail.type === "url"
                          ? "action-btn active"
                          : "action-btn"
                      }
                      onClick={() => this.editUrl("url")}
                    >
                      Launch Custom URL
                    </Button>
                    <Button
                      className={
                        this.state.myHipscanDetail &&
                          this.state.myHipscanDetail.type === 'video'
                          ? 'action-btn active'
                          : 'action-btn'
                      }
                      onClick={() => this.editUrl('video')}
                    >
                      Launch a Video
                    </Button>
                    <Button
                      className={
                        this.state.myHipscanDetail &&
                          this.state.myHipscanDetail.type === 'location'
                          ? 'action-btn active'
                          : 'action-btn'
                      }
                      onClick={() => this.editUrl('location')}
                    >
                      Launch a Location
                    </Button>
                    <Button
                      className={
                        this.state.myHipscanDetail &&
                          this.state.myHipscanDetail.type === 'file'
                          ? 'action-btn active'
                          : 'action-btn'
                      }
                      onClick={() => this.editUrl('file')}
                    >
                      Launch a File
                    </Button>
                    <Button
                      className={
                        this.state.myHipscanDetail &&
                          this.state.myHipscanDetail.type === 'wifi'
                          ? 'action-btn active'
                          : 'action-btn'
                      }
                      onClick={() => this.editUrl('wifi')}
                    >
                      Launch Wifi Network
                    </Button>
                    <Button
                      className={
                        this.state.myHipscanDetail &&
                          this.state.myHipscanDetail.type === 'social'
                          ? 'action-btn active'
                          : 'action-btn'
                      }
                      onClick={() => this.editUrl('social')}
                    >
                      Launch a Social
                    </Button>
                    <Button
                      className={
                        this.state.myHipscanDetail &&
                          this.state.myHipscanDetail.type === "profile"
                          ? "action-btn active"
                          : "action-btn"
                      }
                      onClick={() => this.editUrl("profile")}
                    >
                      Launch your Hipscan Profile
                    </Button>
                    <Button
                      className={
                        this.state.myHipscanDetail &&
                          this.state.myHipscanDetail.type === "text"
                          ? "action-btn active"
                          : "action-btn"
                      }
                      onClick={() => this.editUrl("text")}
                    >
                      Display Custom Text
                    </Button>
                    <Button
                      className={
                        this.state.myHipscanDetail &&
                          this.state.myHipscanDetail.type === "image"
                          ? "action-btn active"
                          : "action-btn"
                      }
                      onClick={() => this.editUrl("image")}
                    >
                      Display a Custom Image
                    </Button>

                    {profile &&
                      profile.access &&
                      profile.access.plan_type !== "free" && (
                        <>
                          <Button
                            className={
                              this.state.myHipscanDetail &&
                                this.state.myHipscanDetail.type === "menu"
                                ? "action-btn active"
                                : "action-btn"
                            }
                            onClick={() => this.editUrl("menu")}
                          >
                            Launch a Menu
                          </Button>
                          <Button
                            className={
                              this.state.myHipscanDetail &&
                                this.state.myHipscanDetail.type === "product"
                                ? "action-btn active"
                                : "action-btn"
                            }
                            onClick={() => this.editUrl("product")}
                          >
                            Launch a Product Page
                          </Button>
                          <Button
                            className={
                              this.state.myHipscanDetail &&
                                this.state.myHipscanDetail.type === "flyer"
                                ? "action-btn active"
                                : "action-btn"
                            }
                            onClick={() => this.editUrl("flyer")}
                          >
                            Launch a Flyer
                          </Button>
                          <Button
                            className={
                              this.state.myHipscanDetail &&
                                this.state.myHipscanDetail.type === "event"
                                ? "action-btn active"
                                : "action-btn"
                            }
                            onClick={() => this.editUrl("event")}
                          >
                            Launch a Event
                          </Button>
                        </>
                      )}
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === "url" ? (
              <div className="action-edit-url">
                <h1>Launch Custom URL</h1>
                <input
                  type="text"
                  placeholder="Input your target URL here"
                  value={this.state.inputURL}
                  onChange={this.inputURLHandle}
                ></input>
                <div className="save-continue-btn">
                  <Button onClick={() => this.update()}>
                    SAVE AND CONTINUE
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === "profile" ? (
              <div className="action-edit-profile">
                <h1>Launch Hipscan Profile2222222</h1>
                <p>
                  Hipscan Profiles are digital business cards that provide your
                  most essential contact details to anyone who scans your
                  Hipscan. So go ahead, create your Hipscan profile!
                </p>
                <div className="input-field">
                  <div className="file-upload">
                    <input
                      id="selectProfileImage"
                      type="file"
                      hidden
                      placeholder="Custom Image Type"
                      onChange={this.profileFileSelectHandle}
                      ref={(ref) => (this.profileFileInput = ref)}
                    ></input>
                    <span>
                      {this.state.profilePreview.selectedFile &&
                        this.state.profilePreview.selectedFile.name !== null
                        ? this.state.profilePreview.selectedFile.name
                        : "Upload Photo"}
                    </span>
                    <div className="icon left" onClick={this.uploadProfile}>
                      <GiOpenFolder size="1rem" />
                    </div>
                    <div className="icon" onClick={this.cancelUpload}>
                      <GiCancel size="1rem" />
                    </div>
                  </div>
                  <div className="common-input">
                    <input
                      type="text"
                      placeholder="Display Name"
                      value={this.state.profilePreview.name}
                      onChange={this.nameChangeHandle}
                    ></input>
                  </div>
                </div>
                <div className="textarea-input">
                  <textarea
                    id="#"
                    maxLength={
                      this.state.profilePreview.contact.isIncludedVideo
                        ? 300
                        : 600
                    }
                    placeholder="This is where you want to talk about your accomplishments or tell your users about your story. Make it good!"
                    value={this.state.profilePreview.description}
                    onChange={this.descriptionChangeHandle}
                  />
                  <p className="letter-count">
                    {this.state.letterCount} /{" "}
                    {this.state.profilePreview.contact.isIncludedVideo
                      ? 300
                      : 600}
                  </p>
                </div>
                <div className="contact-info-btn">
                  <Button onClick={this.modalShow}>CONTACT INFO</Button>
                  <ContactInfoModal
                    show={this.state.show}
                    modalHide={this.modalHide}
                    saveContact={this.saveContact}
                    onChangeEmail={this.emailHandle}
                    onChangeEmailSubject={this.emailSubjectHandle}
                    onChangeEmailBody={this.emailBodyHandle}
                    onChangeCompany={this.companyHandle}
                    onChangeMeetingLink={this.meetingLinkHandle}
                    onChangeNumber={this.numberHandle}
                    onChangeWebsiteURL={this.websiteHandle}
                    onChangeFacebookURL={this.facebookHandle}
                    onChangeInstagramURL={this.instagramHandle}
                    onChangeTwitterURL={this.twitterHandle}
                    onChangeVideoURL={this.videoURL}
                    onChangeBFile={this.onChangeBFile}
                    isIncludedVideoHandle={this.isIncludedVideoHandle}
                    bFile={this.state.profilePreview.contact.cover_image_url}
                    email={this.state.profilePreview.contact.email}
                    email_subject={this.state.profilePreview.contact.email_subject}
                    email_body={this.state.profilePreview.contact.email_body}
                    company={this.state.profilePreview.contact.company}
                    phone={this.state.profilePreview.contact.phone}
                    website_url={this.state.profilePreview.contact.website_url}
                    facebook_url={this.state.profilePreview.contact.facebook_url}
                    instagram_url={this.state.profilePreview.contact.instagram_url}
                    twitter_url={this.state.profilePreview.contact.twitter_url}
                    video_url={this.state.profilePreview.contact.video_url}
                    isIncludedVideo={this.state.profilePreview.contact.isIncludedVideo}
                  />
                </div>
                <div className="save-continue-btn">
                  <Button onClick={() => this.update()}>
                    SAVE AND CONTINUE
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === "text" ? (
              <div className="action-edit-text">
                <h1>Display Custom Text</h1>
                <div className="display-custom-text">
                  <textarea
                    id="#"
                    placeholder="Go ahead, pour your heart out."
                    value={this.state.text}
                    onChange={this.textChange}
                    maxLength="600"
                  />
                  <p className="letter-count">{this.state.letterCount} / 600</p>
                </div>
                <div className="save-continue-btn">
                  <Button onClick={() => this.update()}>
                    SAVE AND CONTINUE
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === "image" ? (
              <div className="action-edit-image">
                <h1>Display Custom Image</h1>
                <div className="display-custom-image">
                  <input
                    id="selectImage"
                    type="file"
                    hidden
                    placeholder="Custom Image Type"
                    onChange={this.fileSelectHandle}
                    ref={(ref) => (this.fileInput = ref)}
                  ></input>
                  <span>
                    {this.state.selectedFile &&
                      this.state.selectedFile.name !== null
                      ? this.state.selectedFile.name
                      : "Upload An Image"}
                  </span>
                  <div className="icon left" onClick={this.upload}>
                    <GiOpenFolder size="1rem" />
                  </div>
                  <div className="icon" onClick={this.cancelUpload}>
                    <GiCancel size="1rem" />
                  </div>
                </div>
                <div className="save-continue-btn">
                  <Button onClick={() => this.update()}>
                    SAVE AND CONTINUE
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === "video" ? (
              <div className="action-edit-video">
                <h1>Launch a Video</h1>
                <div className="display-video">
                  <input
                    type="text"
                    placeholder="Input the Youtube or Vimeo URL here"
                    value={this.state.videoURL}
                    onChange={this.videoURLChange}
                  ></input>
                  <div className="icon1">
                    <TiSocialYoutubeCircular size="1.5rem" />
                  </div>
                  <div className="icon2">
                    <TiSocialVimeoCircular size="1.5rem" />
                  </div>
                </div>
                <div className="save-continue-btn">
                  <Button onClick={() => this.update()}>
                    SAVE AND CONTINUE
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === "location" ? (
              <div className="action-edit-video">
                <h1>Launch a Location</h1>
                <div className="display-video">
                  <input
                    type="text"
                    placeholder="Input your target Location here"
                    value={this.state.location}
                    onChange={this.locationHandle}
                  ></input>
                </div>
                <div className="save-continue-btn">
                  <Button onClick={() => this.update()}>
                    SAVE AND CONTINUE
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === "file" ? (
              <div className="action-edit-video">
                <h1>Launch a File</h1>
                <div className="display-video">
                  <input
                    type="text"
                    placeholder="Input your target File here"
                    value={this.state.file}
                    onChange={this.fileHandle}
                  ></input>
                </div>
                <div className="save-continue-btn">
                  <Button onClick={() => this.update()}>
                    SAVE AND CONTINUE
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === 'social' ? (
              <div className='action-edit-video'>
                <h1>Launch a Social</h1>
                <div className='display-video'>
                  <input
                    type='text'
                    placeholder='Input Twitter, Snapchat, Instagram, Facebook URL here'
                    value={this.state.social}
                    onChange={this.socialHandle}
                  ></input>
                </div>
                <div className='save-continue-btn'>
                  <Button onClick={() => this.update()}>
                    SAVE AND CONTINUE
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === "wifi" ? (
              <div className="action-edit-video">
                <h1>Launch Wifi Network</h1>
                <div className="display-video">
                  <div>
                    <input
                      type='text'
                      placeholder='Enter Wifi name'
                      value={this.state.wifiName}
                      onChange={this.wifiNameHandle}
                    />
                  </div>
                  <div>
                    <input
                      type='text'
                      placeholder='Enter Wifi password'
                      value={this.state.wifiPassword}
                      onChange={this.wifiPasswordHandle}
                    />
                  </div>
                </div>
                <div className="save-continue-btn">
                  <Button onClick={() => this.update()}>
                    SAVE AND CONTINUE
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === "menu" ? (
              <div className="action-edit-menu">
                <h1>Launch a Menu</h1>
                <div className="input-field">
                  <div className="common-input">
                    <input
                      type="text"
                      placeholder="Restaurant Name"
                      value={this.state.menuPreview.name}
                      onChange={this.restaurantNameHandle}
                    ></input>
                  </div>
                  <div className="file-upload">
                    <input
                      id="selectMenuImage"
                      type="file"
                      hidden
                      placeholder="Custom Image Type"
                      onChange={this.menuFileSelectHandle}
                      ref={(ref) => (this.menuFileInput = ref)}
                    ></input>
                    <span>
                      {this.state.menuPreview.selectedFile &&
                        this.state.menuPreview.selectedFile.name !== null
                        ? this.state.menuPreview.selectedFile.name
                        : "Upload Logo"}
                    </span>
                    <div className="icon left" onClick={this.uploadMenu}>
                      <GiOpenFolder size="1rem" />
                    </div>
                    <div className="icon" onClick={this.cancelUpload}>
                      <GiCancel size="1rem" />
                    </div>
                  </div>
                </div>
                <div className="textarea-input">
                  <textarea
                    id="#"
                    placeholder="Go ahead, tell us why your restaurant is so great!"
                    maxLength="600"
                    value={this.state.menuPreview.description}
                    onChange={this.menuDescriptionHandle}
                  />
                  <p className="letter-count">{this.state.letterCount} / 600</p>
                </div>
                <div className="btns">
                  <div className="contact-info-btn">
                    <Button onClick={this.modalShow}>CONTACT INFO</Button>
                    <ContactInfoModal
                      show={this.state.show}
                      modalHide={this.modalHide}
                      isAddress={true}
                      saveContact={this.saveContact}
                      onChangeEmail={this.emailHandle}
                      onChangeEmailSubject={this.emailSubjectHandle}
                      onChangeEmailBody={this.emailBodyHandle}
                      onChangeCompany={this.companyHandle}
                      onChangeMeetingLink={this.meetingLinkHandle}
                      onChangeNumber={this.numberHandle}
                      onChangeWebsiteURL={this.websiteHandle}
                      onChangeFacebookURL={this.facebookHandle}
                      onChangeInstagramURL={this.instagramHandle}
                      onChangeTwitterURL={this.twitterHandle}
                      onChangeAddress={this.addressHandle}
                      onChangeVideoURL={this.videoURL}
                      onChangeBFile={this.onChangeBFile}
                      isIncludedVideoHandle={this.isIncludedVideoHandle}
                      bFile={
                        this.state.menuPreview.contact
                          ? this.state.menuPreview.contact.cover_image_url
                          : ""
                      }
                      email={
                        this.state.menuPreview.contact
                          ? this.state.menuPreview.contact.email
                          : ""
                      }
                      email_subject={
                        this.state.menuPreview.contact
                          ? this.state.menuPreview.contact.email_subject
                          : ""
                      }
                      email_body={
                        this.state.menuPreview.contact
                          ? this.state.menuPreview.contact.email_body
                          : ""
                      }
                      company={
                        this.state.menuPreview.contact
                          ? this.state.menuPreview.contact.company
                          : ""
                      }
                      phone={
                        this.state.menuPreview.contact
                          ? this.state.menuPreview.contact.phone
                          : ""
                      }
                      website_url={
                        this.state.menuPreview.contact
                          ? this.state.menuPreview.contact.website_url
                          : ""
                      }
                      facebook_url={
                        this.state.menuPreview.contact
                          ? this.state.menuPreview.contact.facebook_url
                          : ""
                      }
                      instagram_url={
                        this.state.menuPreview.contact
                          ? this.state.menuPreview.contact.instagram_url
                          : ""
                      }
                      twitter_url={
                        this.state.menuPreview.contact
                          ? this.state.menuPreview.contact.twitter_url
                          : ""
                      }
                      video_url={
                        this.state.menuPreview.contact
                          ? this.state.menuPreview.contact.video_url
                          : ""
                      }
                      isIncludedVideo={
                        this.state.menuPreview.contact.isIncludedVideo
                      }
                      address={
                        this.state.menuPreview.contact
                          ? this.state.menuPreview.contact.address
                          : ""
                      }
                    />
                  </div>
                  <div className="goto-menu-btn">
                    <Button onClick={this.menuModalShow}>GO TO MENU</Button>
                    <RestaurantMenuModal
                      show={this.state.menuShow}
                      modalHide={this.menuModalHide}
                      hipId={this.props.match.params.id}
                    />
                  </div>
                </div>
                <div className="save-continue-btn">
                  <Button onClick={() => this.update()}>SAVE AND EXIT</Button>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === "product" ? (
              <div className="action-edit-product">
                <h1>Launch a Product</h1>
                <div className="product-img-upload">
                  <input
                    id="selectProductImage"
                    type="file"
                    hidden
                    placeholder="Upload Product Picture"
                    onChange={this.productFileSelectHandle}
                    ref={(ref) => (this.productFileInput = ref)}
                  ></input>
                  <span>
                    {this.state.productPreview.selectedFile &&
                      this.state.productPreview.selectedFile.name !== null
                      ? this.state.productPreview.selectedFile.name
                      : "Upload Product Picture"}
                  </span>
                  <div className="icon left" onClick={this.uploadProduct}>
                    <GiOpenFolder size="1rem" />
                  </div>
                  <div className="icon" onClick={this.cancelUpload}>
                    <GiCancel size="1rem" />
                  </div>
                </div>
                <div className="product">
                  <div className="product-name">
                    <input
                      id=""
                      placeholder="Product name"
                      value={this.state.productPreview.name}
                      onChange={this.productNameChange}
                    />
                  </div>
                  <div className="product-price">
                    <input
                      id=""
                      placeholder="$"
                      value={this.state.productPreview.price}
                      onChange={this.priceChange}
                    />
                  </div>
                </div>
                <div className="select">
                  <div className="name">
                    <input
                      type="checkbox"
                      name="test"
                      checked={this.state.productPreview.isIncludedVideo}
                      onChange={this.clickedIncludeVideo}
                    />
                    <span className="checkmark"></span>
                    <p>Include Video</p>
                  </div>
                </div>
                {this.state.productPreview.isIncludedVideo ? (
                  <div className="display-video">
                    <input
                      type="text"
                      placeholder="Custom Video Type"
                      value={this.state.productPreview.video}
                      onChange={this.productVideoURLChange}
                    ></input>
                    <div className="icon1">
                      <TiSocialYoutubeCircular size="1.5rem" />
                    </div>
                    <div className="icon2">
                      <TiSocialVimeoCircular size="1.5rem" />
                    </div>
                  </div>
                ) : null}
                {/* </div> */}
                <div className="product-description">
                  <textarea
                    placeholder="Tell us how great this product or service is!"
                    maxLength={
                      this.state.productPreview.isIncludedVideo ? 300 : 600
                    }
                    value={this.state.productPreview.description}
                    onChange={this.productDescriptionChange}
                  />
                  <p className="letter-count">
                    {this.state.letterCount} /{" "}
                    {this.state.profilePreview.contact.isIncludedVideo
                      ? 300
                      : 600}
                  </p>
                </div>
                <div className="save-continue-btn">
                  <Button onClick={() => this.update()}>
                    SAVE AND CONTINUE
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === "flyer" ? (
              <div className="action-edit-flyer">
                <h1>Launch a Flyer</h1>
                <p>
                  We have partnered with PosterMyWall to allow you to choose
                  from over 225,000 flyer and poster templates that you can
                  point your Hipscan to. Once you press the 'Launch
                  PosterMyWall' button below, you will be asked to login to your
                  PosterMyWall account. If you do not have a PosterMyWall
                  account, you can go through the quick setup process to create
                  a free account. Once you do that, you will be able to choose
                  the template that you want your Hipscan to point to. You can
                  always come back and tweak this template or choose another
                  destination.
                </p>
                <div className="launch-postermywall">
                  <Button onClick={() => this.launch_postermywall()}>
                    LAUNCH POSTERMYWALL
                  </Button>
                </div>
                <div>
                  <Form.Group>
                    <Form.Control
                      className="flyer-designs"
                      as="select"
                      size="lg"
                      onChange={this.selectFlyerDesign}
                    >
                      <option value={-1}>Select the Design to Display</option>
                      {this.state.flyer_designs &&
                        this.state.flyer_designs.length > 0 &&
                        this.state.flyer_designs.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div
                  className={
                    this.state.flyer_design
                      ? "launch-postermywall"
                      : "launch-postermywall-disabled"
                  }
                >
                  <Button
                    disabled={!this.state.flyer_design}
                    onClick={() =>
                      this.editFlyerDesign(this.state.flyer_design.id)
                    }
                  >
                    EDIT DESIGN
                  </Button>
                </div>
                <div className="save-continue-btn">
                  <Button
                    disabled={!this.state.flyer_design}
                    onClick={() => this.update()}
                  >
                    SAVE AND CONTINUE
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.isEditing === "design" ? (
              <div className="action-edit-design">
                <Link
                  to="#"
                  className="goto"
                  onClick={() => this.editUrl("none")}
                >
                  <IoIosArrowBack size="1.3rem" />
                  Back
                </Link>
                <h1>Customize Design</h1>
                <div className="custom-design-page">
                  <div className="design-preview">
                    <p>Design Preview</p>
                    <div className="qr-design-preview">
                      {this.state.previewCustomDesign ? (
                        <img
                          src={this.state.previewCustomDesign}
                          id="qr"
                          fgcolor="#000000"
                        />
                      ) : (
                        <img
                          src={profile.code.code_image_url}
                          id="qr"
                          fgcolor="#000000"
                        />
                      )}
                    </div>
                  </div>
                  <div className="design-options">
                    <div className="display-custom-design">
                      <input
                        id="selectImage"
                        type="file"
                        hidden
                        placeholder="Custom Image Type"
                        onChange={this.designSelectHandle}
                        ref={(ref) => (this.fileInput = ref)}
                      ></input>
                      <span>
                        {this.state.selectedFile &&
                          this.state.selectedFile.name !== null
                          ? this.state.selectedFile.name
                          : "Center Image (Optional)"}
                      </span>
                      <div className="icon" onClick={this.upload}>
                        <GiOpenFolder size="2.5rem" />
                      </div>
                    </div>
                    <div className="design-theme">
                      <span>Theme</span>
                      <img
                        src={themeOne}
                        className={`icon-class ${this.state.customDesignTheme === "theme-one" &&
                          "active"
                          }`}
                        alt="theme one"
                        onClick={() =>
                          this.onCustomDesignThemeChange("theme-one")
                        }
                      />
                      <img
                        src={themeTwo}
                        className={`icon-class ${this.state.customDesignTheme === "theme-two" &&
                          "active"
                          }`}
                        alt="theme two"
                        onClick={() =>
                          this.onCustomDesignThemeChange("theme-two")
                        }
                      />
                    </div>
                    <div className="design-color">
                      <CirclePicker
                        width="400px"
                        color={this.state.customDesignColor}
                        colors={[
                          "#000000",
                          "#F00000",
                          "#D9E3F0",
                          "#F47373",
                          "#697689",
                          "#37D67A",
                          "#2CCCE4",
                          "#dce775",
                          "#ba68c8",
                        ]}
                        onChangeComplete={this.onCustomDesignColorChange}
                      />
                    </div>
                    <div className="design-pattern">
                      <span>Pattern</span>
                      <div>
                        <img
                          src={patternOne}
                          className={`icon-class ${this.state.customDesignPattern === "pattern-one" &&
                            "active"
                            }`}
                          alt="pattern one"
                          onClick={() =>
                            this.onCustomDesignPatternChange("pattern-one")
                          }
                        />
                        <img
                          src={patternTwo}
                          className={`icon-class ${this.state.customDesignPattern === "pattern-two" &&
                            "active"
                            }`}
                          alt="pattern two"
                          onClick={() =>
                            this.onCustomDesignPatternChange("pattern-two")
                          }
                        />
                        <img
                          src={patternThree}
                          className={`icon-class ${this.state.customDesignPattern === "pattern-three" &&
                            "active"
                            }`}
                          alt="pattern three"
                          onClick={() =>
                            this.onCustomDesignPatternChange("pattern-three")
                          }
                        />
                        <img
                          src={patternFour}
                          className={`icon-class ${this.state.customDesignPattern === "pattern-four" &&
                            "active"
                            }`}
                          alt="pattern four"
                          onClick={() =>
                            this.onCustomDesignPatternChange("pattern-four")
                          }
                        />
                        <img
                          src={patternFive}
                          className={`icon-class ${this.state.customDesignPattern === "pattern-five" &&
                            "active"
                            }`}
                          alt="pattern five"
                          onClick={() =>
                            this.onCustomDesignPatternChange("pattern-five")
                          }
                        />
                        <img
                          src={patternSix}
                          className={`icon-class ${this.state.customDesignPattern === "pattern-six" &&
                            "active"
                            }`}
                          alt="pattern six"
                          onClick={() =>
                            this.onCustomDesignPatternChange("pattern-six")
                          }
                        />
                      </div>
                    </div>
                    <div className="design-actions">
                      <div className="save-continue-btn">
                        <Button onClick={() => this.handleSaveCustomDesign()}>
                          SAVE
                        </Button>
                      </div>
                      <div className="cancel-btn">
                        <Button onClick={() => this.handleCancelCustomDesign()}>
                          CANCEL
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
        <div className="statistics">
          <StatisticsNew id={this.props.match.params.id} />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.default.services.profile,
    ...state.default.services.auth,
  }),
  (dispatch) => ({
    profileActions: bindActionCreators(
      {
        getHipscanDetail,
        updateHipscan,
        updateContact,
        downloadQrCode,
        previewCustomDesign,
        saveCustomDesign,
      },
      dispatch
    ),
    authActions: bindActionCreators({ getProfile }, dispatch),
  })
)(DetailMyHipScan);
