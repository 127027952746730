import React from "react";
import "./Plan.css";
import Axios from "axios";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Button from "react-bootstrap/Button";
import { FaStar, FaChevronDown } from "react-icons/fa";
import TMobileImage from "../../assets/images/customers/t-mobile.svg";
import baskinImage from "../../assets/images/customers/baskin.png";
import hondaImage from "../../assets/images/customers/honda.png";
import menchieImage from "../../assets/images/customers/menchie.png";
import mikesImage from "../../assets/images/customers/mikes.png";
import RequireEmailModal from "../../components/RequireEmailModal/RequireEmailModal";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import {
  getHipscansLimits,
  getHipscansPlanFreeBasic,
  getHipscansPlanProMonthly,
  getHipscansPlanProYearly,
  getHipscansPlanPremiumMonthly,
  getHipscansPlanPremiumYearly,
  getHipscansPlanTrial,
} from "../../services/pricing/pricingActions";
import { getProfile } from "../../services/auth/authActions";
import { notification } from "antd";

const faqData = [
  {
    question: "Can I use Hipscan for free?",
    answer: `Of course! Signup for the free plan and use our basic tools for free, or signup for a pro or premium plan and your first 30 days are on us.`,
  },
  {
    question: "Can I use Hipscan for personal use?",
    answer: `Absolutely! You can point your Hipscan to any destination you want! Try out our Hipscan Profile page that acts like a virtual business card that you can use to connect with others in a fast and easy way.`,
  },
  {
    question: "When is my plan ready to use?",
    answer: `Immediately after you have selected your plan, entered your details and completed the signup process. It’s that easy!`,
  },
  {
    question: "How long is my subscription term?",
    answer: `There is no term! Cancel anytime you want.`,
  },
  {
    question: "What happens to my Hipscan QR codes if I cancel?",
    answer: `If you decide to cancel your subscription, all of your codes will remain active for one more year. If you want to get access to your statistics or would like to create more QR codes, simply renew your subscription.`,
  },
  {
    question: "Can I switch to a different plan later?",
    answer: `Yes, you can upgrade your account at any time. It’s possible to downgrade your account at the end of your billing cycle as well.`,
  },
];

class Plans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faqData,
      isShowAnswer: false,
      currentPlan: "",
      faqIndex: 0,
      confirmEmailShow: false
    };
    const token = JSON.parse(localStorage.getItem("token"));
    token && this.props.authActions.getProfile();
  }

  componentDidUpdate(prevProps) {
    const { currentPlan } = this.state;
    this.props.pricing[currentPlan] &&
      window.location.replace(this.props.pricing[currentPlan].url);
    if(this.props.auth.profile !== prevProps.auth.profile) {
      // some check goes here if I need to show the form to fill the email
      if(!this.state.confirmEmailShow && this.props.auth.profile.empty_email)
      {
        this.setState({ confirmEmailShow: true });
      }
    }
  }

  getHipscansPlan = (currentPlan, alias) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const registerToken = JSON.parse(localStorage.getItem("registerToken"));
    if (token || registerToken) {
      this.setState({
        currentPlan: alias,
      });
      this.props.pricingAction[currentPlan](
        registerToken ? registerToken : token
      );
    } else {
      this.props.history.push("/signup");
    }
  };
  
  onShowAnswer = (index) => {
    this.setState({
      faqIndex: index,
    });
  };

  onRedirectHome = () => {
    const {
      auth: { profile },
    } = this.props;

    this.props.history.push(
      `/myhipscans${
        profile && profile.code ? `/detail/${profile.code.id}` : ""
      }`
    );
    // window.location.reload();
  };

  checkShowTrialPlan = () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const registerToken = JSON.parse(localStorage.getItem("registerToken"));

    const {
      auth: { profile },
    } = this.props;

    if (registerToken) {
      return true;
    } else if (profile) {
      if (profile.access) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  onSubmitPlanTrial = async (isTrial, name) => {
    const registerToken = JSON.parse(localStorage.getItem("registerToken"));

    const token = JSON.parse(localStorage.getItem("token"));

    if (!token && !registerToken) {
      this.props.history.push("/signup");
    } else {
      if (isTrial) {
        let _url = `https://api.hipscan.com/trial/${name}`;

        await Axios.get(_url, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
            charset: "UTF-8",
            Authorization: `Bearer ${
              registerToken ? registerToken.token : token ? token.token : null
            }`,
          },
        })
          .then((res) => {
            if (res.status === 202) {
              // notification.success({
              //   message: "Success",
              //   description: `Sign up for a ${name} trial successfully.`,
              // });

              if (registerToken) {
                localStorage.setItem("token", JSON.stringify(registerToken));
                localStorage.removeItem("registerToken");
              }
              this.props.history.push("/signup");
            }
          })
          .catch((err) => {
            // notification.error({
            //   message: "Error",
            //   description: `Sign up for a ${name} trial  failed!`,
            // });
          });
      } else {
        let _url = `https://api.hipscan.com/payment/free/basic`;

        await Axios.get(_url, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
            charset: "UTF-8",
            Authorization: `Bearer ${
              registerToken ? registerToken.token : token ? token.token : null
            }`,
          },
        })
          .then((res) => {
            if (res.status === 200) {
              // notification.success({
              //   message: "Success",
              //   description: "Sign up for a free basic successfully.",
              // });

              if (registerToken) {
                localStorage.setItem("token", JSON.stringify(registerToken));
                localStorage.removeItem("registerToken");
                // this.props.history.push("/login");
              }

              if (res.data) {
                window.location.href = res.data.url;
              }
            }
          })
          .catch((err) => {
            // notification.error({
            //   message: "Error",
            //   description: "Sign up for a free basic  failed!",
            // });
          });
      }
    }
  };

  confirmEmailModalShow = () => {
    this.setState({ confirmEmailShow: true });
  };
  confirmEmailModalHide = () => {
    this.setState({ confirmEmailShow: false });
    this.props.authActions.getProfile();
  };

  renderButtonPlan = (name, type) => {
    const {
      auth: { profile },
    } = this.props;

    const token = JSON.parse(localStorage.getItem("token"));
    const registerToken = JSON.parse(localStorage.getItem("registerToken"));
    if (name === "free") {
      if (registerToken) {
        return (
          <Button
            className="pricing-btn"
            onClick={() => this.onSubmitPlanTrial(false, "free")}
          >
            GET STARTED
            <br /> <span>(IT’S FREE)</span>
          </Button>
        );
      } else if (token && profile && profile.access) {
        if (profile.access.trial) {
          return (
            <Button
              className="pricing-btn"
              onClick={() => this.onSubmitPlanTrial(false, "free")}
            >
              GET STARTED
              <br /> <span>(IT’S FREE)</span>
            </Button>
          );
        } else {
          if (profile.access.plan_type === "free") {
            return (
              <Button
                className="pricing-btn disable current-plan current-plan-free"
                onClick={() => this.onRedirectHome()}
              >
                YOUR CURRENT PLAN
                <br />
                <span>Return to account</span>
              </Button>
            );
          } else {
            return (
              <Button
                className="pricing-btn"
                onClick={() =>
                  this.getHipscansPlan(
                    "getHipscansPlanFreeBasic",
                    "plan_free_basic"
                  )
                }
              >
                DOWNGRADE TO BASIC
                <br /> <span>(ARE YOU SURE?)</span>
              </Button>
            );
          }
        }
      } else {
        return (
          <Button
            className="pricing-btn"
            onClick={() => this.onSubmitPlanTrial(false, "free")}
          >
            GET STARTED
            <br /> <span>(IT’S FREE)</span>
          </Button>
        );
      }
    } else if (name === "pro") {
      if (
        token &&
        profile &&
        profile.access &&
        profile.access.trial === false
      ) {
        if (profile.access.plan_type === "pro") {
          const plan_period = profile.access.plan_period;
          if (type === "monthly") {
            if (plan_period === "monthly") {
              return (
                <Button
                  className="pricing-btn disable current-plan"
                  onClick={() => this.onRedirectHome()}
                >
                  YOUR CURRENT PLAN
                  <br />
                  <span>Return to account</span>
                </Button>
              );
            } else {
              return (
                <Button
                  className={`pricing-btn month`}
                  onClick={() =>
                    this.getHipscansPlan(
                      "getHipscansPlanProMonthly",
                      "plan_pro_monthly"
                    )
                  }
                >
                  SUBSCRIBE MONTHLY
                  <br />
                  <span>($9 MONTHLY)</span>
                </Button>
              );
            }
          } else {
            if (plan_period === "annually") {
              return (
                <Button
                  className="pricing-btn disable current-plan"
                  onClick={() => this.onRedirectHome()}
                >
                  YOUR CURRENT PLAN
                  <br />
                  <span>Return to account</span>
                </Button>
              );
            } else {
              return (
                <Button
                  className={`pricing-btn annual`}
                  onClick={() =>
                    this.getHipscansPlan(
                      "getHipscansPlanProYearly",
                      "plan_pro_yearly"
                    )
                  }
                >
                  SUBSCRIBE ANNUALLY
                  <br /> <span>($89 ANNUALLY)</span>
                </Button>
              );
            }
          }
        } else if (profile.access.plan_type === "premium") {
          if (type === "monthly") {
            return (
              <Button
                className={`pricing-btn month`}
                onClick={() =>
                  this.getHipscansPlan(
                    "getHipscansPlanProMonthly",
                    "plan_pro_monthly"
                  )
                }
              >
                DOWNGRADE TO PRO
                <br />
                <span>($9 MONTHLY)</span>
              </Button>
            );
          } else {
            return (
              <Button
                className={`pricing-btn annual`}
                onClick={() =>
                  this.getHipscansPlan(
                    "getHipscansPlanProYearly",
                    "plan_pro_yearly"
                  )
                }
              >
                DOWNGRADE TO PRO
                <br /> <span>($89 ANNUALLY)</span>
              </Button>
            );
          }
        } else {
          if (type === "monthly") {
            return (
              <Button
                className={`pricing-btn month`}
                onClick={() =>
                  this.getHipscansPlan(
                    "getHipscansPlanProMonthly",
                    "plan_pro_monthly"
                  )
                }
              >
                SUBSCRIBE MONTHLY
                <br />
                <span>($9 MONTHLY)</span>
              </Button>
            );
          } else {
            return (
              <Button
                className={`pricing-btn annual`}
                onClick={() =>
                  this.getHipscansPlan(
                    "getHipscansPlanProYearly",
                    "plan_pro_yearly"
                  )
                }
              >
                SUBSCRIBE ANNUALLY
                <br /> <span>($89 ANNUALLY)</span>
              </Button>
            );
          }
        }
      } else {
        if (type === "monthly") {
          return (
            <Button
              className={`pricing-btn month`}
              onClick={() =>
                this.getHipscansPlan(
                  "getHipscansPlanProMonthly",
                  "plan_pro_monthly"
                )
              }
            >
              SUBSCRIBE MONTHLY
              <br />
              <span>($9 MONTHLY)</span>
            </Button>
          );
        } else {
          return (
            <Button
              className={`pricing-btn annual`}
              onClick={() =>
                this.getHipscansPlan(
                  "getHipscansPlanProYearly",
                  "plan_pro_yearly"
                )
              }
            >
              SUBSCRIBE ANNUALLY
              <br /> <span>($89 ANNUALLY)</span>
            </Button>
          );
        }
      }
    } else if (name === "premium") {
      if (profile && profile.access) {
        if (
          profile.access.plan_type === "premium" &&
          profile.access.trial === false
        ) {
          const plan_period = profile.access.plan_period;
          if (type === "monthly") {
            if (plan_period === "monthly") {
              return (
                <Button
                  className="pricing-btn disable current-plan"
                  onClick={() => this.onRedirectHome()}
                >
                  YOUR CURRENT PLAN
                  <br />
                  <span>Return to account</span>
                </Button>
              );
            } else {
              return (
                <Button
                  className={`pricing-btn month`}
                  onClick={() =>
                    this.getHipscansPlan(
                      "getHipscansPlanPremiumMonthly",
                      "plan_premium_monthly"
                    )
                  }
                >
                  SUBSCRIBE MONTHLY
                  <br />
                  <span>($19 MONTHLY)</span>
                </Button>
              );
            }
          } else {
            if (plan_period === "annually") {
              return (
                <Button
                  className="pricing-btn disable current-plan"
                  onClick={() => this.onRedirectHome()}
                >
                  YOUR CURRENT PLAN
                  <br />
                  <span>Return to account</span>
                </Button>
              );
            } else {
              return (
                <Button
                  className={`pricing-btn annual`}
                  onClick={() =>
                    this.getHipscansPlan(
                      "getHipscansPlanPremiumYearly",
                      "plan_premium_yearly"
                    )
                  }
                >
                  SUBSCRIBE ANNUALLY
                  <br /> <span>($199 ANNUALLY)</span>
                </Button>
              );
            }
          }
        } else {
          if (type === "monthly") {
            return (
              <Button
                className={`pricing-btn month`}
                onClick={() =>
                  this.getHipscansPlan(
                    "getHipscansPlanPremiumMonthly",
                    "plan_premium_monthly"
                  )
                }
              >
                SUBSCRIBE MONTHLY
                <br />
                <span>($19 MONTHLY)</span>
              </Button>
            );
          } else {
            return (
              <Button
                className={`pricing-btn annual`}
                onClick={() =>
                  this.getHipscansPlan(
                    "getHipscansPlanPremiumYearly",
                    "plan_premium_yearly"
                  )
                }
              >
                SUBSCRIBE ANNUALLY
                <br /> <span>($199 ANNUALLY)</span>
              </Button>
            );
          }
        }
      } else {
        if (type === "monthly") {
          return (
            <Button
              className={`pricing-btn month`}
              onClick={() =>
                this.getHipscansPlan(
                  "getHipscansPlanPremiumMonthly",
                  "plan_premium_monthly"
                )
              }
            >
              SUBSCRIBE MONTHLY
              <br />
              <span>($19 MONTHLY)</span>
            </Button>
          );
        } else {
          return (
            <Button
              className={`pricing-btn annual`}
              onClick={() =>
                this.getHipscansPlan(
                  "getHipscansPlanPremiumYearly",
                  "plan_premium_yearly"
                )
              }
            >
              SUBSCRIBE ANNUALLY
              <br /> <span>($199 ANNUALLY)</span>
            </Button>
          );
        }
      }
    }
  };

  handleContactClicked = () => {
    window.location.href="https://hipscan.com/contact-us/";
  }

  render() {
    const { faqData, faqIndex } = this.state;
    const token = JSON.parse(localStorage.getItem("token"));
    const registerToken = JSON.parse(localStorage.getItem("registerToken"));
    const {
      auth: { profile },
    } = this.props;

    return (
      <div className="plans-page">
        <RequireEmailModal
          show={this.state.confirmEmailShow}
          modalHide={this.confirmEmailModalHide}
          hipId={this.props.match.params.id}
        />
        <div className="top-section">
          <Header {...this.props} />
        </div>
        <div className="plans-content">
          {!profile && (
            <div className="plans-title-container ">
              <h1 className="title">
                {registerToken && registerToken.token
                  ? "Woohoo, you did it!  "
                  : "Get the #1 QR Marketing Platform"}
              </h1>
              <h2>Next, please pick your plan below.</h2>
              <p className="description">
                {registerToken && registerToken.token
                  ? "Hipscan is the most robust and cohesive QR marketing solution, built to scale."
                  : "Discover the most robust and cohesive QR marketing solution, built to scale."}
              </p>
              <p className="description">
                Upgrade as you grow or cancel anytime.
              </p>
            </div>
          )}
          {profile && (
            <div className="plans-title-container ">
              <h2>Next, please pick your plan below.</h2>
            </div>
          )}

          {profile && profile.access && profile.access.trial && (
            <h3 className="trialPlan">
              You are currently on the{" "}
              {profile.access.plan_type === "pro"
                ? "Pro"
                : profile.access.plan_type === "premium"
                ? "Premium"
                : "Free"}{" "}
              plan on a 30-day trial.
            </h3>
          )}

          <div className="plans-pricing container">
            <div className="pricing-section">
              <h1>Free Basic Hipscan </h1>
              <p className="pricing-section-content">
                Essential tools for getting started <br />
                <br />
                <br />
              </p>

              {false && this.checkShowTrialPlan() && (
                <Button
                  className="pricing-btn trial"
                  onClick={() => this.onSubmitPlanTrial(false, "free")}
                >
                  GET STARTED
                </Button>
              )}

              <div className="pricing-description">
                <p className="pricing-section-title">Dynamic QR Code</p>
                <p className="pricing-section-title">10,000 Scans</p>
                <p className="pricing-section-title">Real-Time Analytics</p>
                <p className="pricing-section-title">Destination Control</p>
                <p className="pricing-section-title">Management Tools</p>
                <p className="pricing-section-title">Codes Last Forever</p>
                <p className="pricing-section-title">Ultra-Fast Scanning</p>
                <p className="pricing-section-title">Privacy Compliant</p>
              </div>
              <div className="bottom-btn">
                {this.renderButtonPlan("free", null)}
              </div>
            </div>

            <div className="pricing-section center">
              <div className="most-popular">
                <p>MOST POPULAR</p>
              </div>
              <h1>
                Hipscan <br /> Pro
              </h1>
              <p className="pricing-section-content">
                Powerful tools that allow you to create <br /> touchless,
                contactless menus and <br /> product pages in seconds
              </p>
              <p className="pricing-section-addition">Everything in Basic Hipscan and</p>
              <p className="pricing-section-title">Unlimited Scans</p>
              {false && this.checkShowTrialPlan() && (
                <Button
                  className="pricing-btn trial"
                  onClick={() => this.onSubmitPlanTrial(true, "pro")}
                >
                  GET STARTED - FREE TRIAL
                </Button>
              )}
              <div className="pricing-description">
                <div className="dynamic">
                  <p className="dynamic-title pro">
                    <FaStar /> Hipscan Menu Pro
                  </p>
                  <p className="dynamic-content">
                    Create a touchless menu to direct your customers to. Change or update your menu and have changes reflect in real-time
                  </p>
                </div>
                <div className="dynamic">
                  <p className="dynamic-title pro">
                    <FaStar /> Hipscan Product Pro
                  </p>
                  <p className="dynamic-content">
                    Create an interactive product page for your in-location items to direct your customers to. Change or update your product pages and have changes reflect in real-time
                  </p>
                </div>
                <div className="dynamic">
                  <p className="dynamic-title pro">
                    <FaStar /> Hipscan Flyers - Over 225,000!
                  </p>
                  <p className="dynamic-content">
                  Have access to over 225,000 templates that you can customize and point your Hipscan to. This allows you to quickly market to your customers and have them take action instantly
                  </p>
                </div>
              </div>
              <div className="bottom-btn">
                {this.renderButtonPlan("pro", "monthly")}
                {this.renderButtonPlan("pro", "annually")}
              </div>
            </div>

            <div className="pricing-section">
              <h1>
                Hipscan <br /> Premium
              </h1>
              <p className="pricing-section-content">
                Advanced tools for business at <br /> scale. Create up to 25
                dynamic <br />
                Hipscan QR codes
              </p>
              {false && this.checkShowTrialPlan() && (
                <Button
                  className="pricing-btn trial"
                  onClick={() => this.onSubmitPlanTrial(true, "premium")}
                >
                  GET STARTED - FREE TRIAL
                </Button>
              )}
              <div className="pricing-description">
              <p className="pricing-section-addition">Everything in Hipscan Pro and</p>
                <p className="pricing-section-title">
                  Create up to <span className="pricing-section-content">25 Hipscan QR Codes</span>, <br/> Each With It’s Own Destination
                </p>
                <p className="pricing-section-title">Enterprise Grade Security</p>
                <p className="pricing-section-title">Advanced Reporting</p>
                <p className="pricing-section-title">Custom API Access</p>
              </div>
              <div className="bottom-btn">
                  {this.renderButtonPlan("premium", "monthly")}
                  {this.renderButtonPlan("premium", "annually")}
              </div>
            </div>
          </div>

          {!profile && (
            <>
              <div className="plans-enterprise">
                <div className="bg-color">
                  <div className="container">
                    <h1>Hipscan Enterprise</h1>
                    <p>
                      A customer solution for organizations that want more
                      Hipscan QR Codes, white labeling, <br /> access to our API
                      or other add-on features.
                    </p>
                    <Button className="contact-btn" onClick={() => this.handleContactClicked()}>CONTACT US</Button>
                  </div>
                </div>
              </div>
              <div className="plans-customers container">
                <h1>Join our 5,000+ happy customers</h1>
                <div className="customer-imgs">
                  <div className="image-container">
                    <img
                      src={baskinImage}
                      className="customer-image"
                      alt="baskinImage"
                    />
                  </div>
                  <div className="image-container">
                    <img
                      src={menchieImage}
                      className="customer-image"
                      alt="menchieImage"
                    />
                  </div>
                  <div className="image-container">
                    <img
                      src={TMobileImage}
                      className="customer-image"
                      alt="TMobileImage"
                    />
                  </div>
                  <div className="image-container">
                    <img
                      src={hondaImage}
                      className="customer-image"
                      alt="hondaImage"
                    />
                  </div>
                  <div className="image-container">
                    <img
                      src={mikesImage}
                      className="customer-image"
                      alt="mikesImage"
                    />
                  </div>
                </div>
              </div>
              <div className="faqs">
                <div className="container">
                  <h1>FAQ’s</h1>
                  {faqData.length
                    ? faqData.map((obj, index) => {
                        return (
                          <div key={index} className="question-answer">
                            <h4 className="question">
                              {obj.question}
                              {faqIndex !== index && (
                                <FaChevronDown
                                  onClick={() => this.onShowAnswer(index)}
                                />
                              )}
                            </h4>
                            {faqIndex === index && (
                              <p className="answer">{obj.answer}</p>
                            )}
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </>
          )}
        </div>
        {!profile && <Footer />}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      ...state.default.services,
    };
  },
  (dispatch) => ({
    pricingAction: bindActionCreators(
      {
        // plans
        getHipscansLimits,
        getHipscansPlanFreeBasic,
        getHipscansPlanProMonthly,
        getHipscansPlanProYearly,
        getHipscansPlanPremiumMonthly,
        getHipscansPlanPremiumYearly,
        getHipscansPlanTrial,
      },
      dispatch
    ),
    authActions: bindActionCreators(
      {
        // users
        getProfile,
      },
      dispatch
    ),
  })
)(Plans);
