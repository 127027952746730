import React from "react";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import { logout } from "../../../../services/auth/authActions";
import { bindActionCreators } from "redux";

import { Container, Nav, Navbar } from "react-bootstrap";

import Logo from "../../../../assets/images/logo2.png";

class HeaderAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.logoutHandleClick = this.logoutHandleClick.bind(this);
  }

  logoutHandleClick = () => {
    this.props.authActions.logout();
    localStorage.clear();
    this.props.history.push("/login");
    window.location.reload();
  };

  render() {
    return (
      <div className="header-admin">
        <Container fluid>
          <Navbar collapseOnSelect expand="md" bg="light" variant="light">
            <Navbar.Brand href="/admin" id="logo">
              <img src={Logo} alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto"></Nav>
              <Nav>
                <Nav.Link>Administrator</Nav.Link>
                <Nav.Link onClick={this.logoutHandleClick}>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    );
  }
}

export default connect(
  state => ({
    ...state.default.services.auth
  }),
  dispatch => ({
    authActions: bindActionCreators({ logout }, dispatch)
  })
)(withRouter(HeaderAdmin));
