import React from "react";
import { withRouter } from "react-router-dom";
import "antd/dist/antd.css";
import { Result as ResultMsg, Button } from "antd";
import Header from "../../components/Header/Header";
import "./index.scss";
import { getProfile } from "../../services/auth/authActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class PlansNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount = async () => {
    let registerToken = JSON.parse(localStorage.getItem("registerToken"));
    if (registerToken) {
      localStorage.setItem("token", JSON.stringify(registerToken));
      localStorage.removeItem("registerToken");
    }
  };

  componentDidMount = () => {
    this.props.authActions.getProfile();
  };

  renderMessage = () => {
    return (
      <ResultMsg
        status="success"
        title="Successfully Purchased Cloud Server ECS!"
        subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
        extra={[
          <Button type="primary" key="console">
            Go Console
          </Button>,
          <Button key="buy">Buy Again</Button>,
        ]}
      />
    );
  };

  goToMainDetail = () => {
    if (this.props.profile) {
      this.props.history.push(
        `/myhipscans/detail/${this.props.profile.code.id}`
      );
      window.location.reload();
    } else {
      this.props.history.push("/login");
    }
  };

  render() {
    const {
      match: {
        params: { role, status, type },
      },
    } = this.props;

    // fire an intercom event of changed plan
    if (status === "succeeded") {
      let plan_name = "Basic";
      if (role === "pro") {
        plan_name = "Pro";
      } else if (role === "premium") {
        plan_name = "Premium";
      } else {
        plan_name = "Basic";
      }
      window.intercomSettings = {
        app_id: "wddgm6tg",
        PlanType: plan_name,
      };
      window.Intercom("reattach_activator");
      window.Intercom("update", window.intercomSettings);
      window.Intercom("trackEvent", "plan-changed", { PlanType: plan_name });
      window.Intercom("update", window.intercomSettings);
    }

    let title = "";
    if (status === "succeeded") {
      title = `Successfully Purchased Plan (${
        role === "pro" ? "Pro" : role === "premium" ? "Premium" : "Free"
      }${
        type === "monthly" ? " Monthly" : role === "annually" ? " Annually" : ""
      })!`;
    } else {
      title =
        "There was an error in creating your account.  Please try again and the problem persists, contact us.";
    }

    return (
      <div>
        <Header />
        {status === "succeeded" ? (
          <div className="success-wrap">
            <div className="notify-success">
              <h1>Congratulations!</h1>
              <p>You have successfully purchased the</p>
              <h2>
                Hipscan{" "}
                {role === "pro"
                  ? "Pro"
                  : role === "premium"
                  ? "Premium"
                  : "Free"}{" "}
                Plan
              </h2>
              <p>Proceed to your user homepage to setup your Hipscan</p>
              <Button
                type="primary"
                className="btn-proceed"
                onClick={() => this.goToMainDetail()}
              >
                Proceed to user homepage
              </Button>
            </div>
          </div>
        ) : (
          <ResultMsg
            status="error"
            // title={`${status === 'succeeded' ? 'Successfully' : 'Error'} Purchased Plan (${
            // 	role === 'pro' ? 'Pro' : role === 'premium' ? 'Premium' : 'Free'
            // }${type === 'monthly' ? ' Monthly' : role === 'annually' ? ' Annually' : ''})!`}
            title={title}
            subTitle=""
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => this.goToMainDetail()}
              >
                Back
              </Button>,
            ]}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.default.services.auth,
  }),
  (dispatch) => ({
    authActions: bindActionCreators({ getProfile }, dispatch),
  })
)(withRouter(PlansNotifications));

// export default withRouter(PlansNotifications);
