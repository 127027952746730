import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';

import JS_SHA256 from 'js-sha256';

export const storeCurrentToken = token => {
	localStorage.setItem('token', JSON.stringify(token));
};

export const storeRegisterToken = registerToken => {
	localStorage.setItem('registerToken', JSON.stringify(registerToken));
};

export const getStoredToken = () => JSON.parse(localStorage.getItem('token'));

export const removeCurrentToken = () => {
	localStorage.removeItem('token');
};

export const removeRegisterToken = () => {
	localStorage.removeItem('registerToken');
};

export const removeCurrentUser = () => {
	localStorage.removeItem('username');
};

export const storeUserName = user_name => {
	localStorage.setItem('username', user_name);
};

export const storeMainId = mainId => {
	localStorage.setItem('mainid', mainId);
};

export const storeUserInfo = userInfo => {
	localStorage.setItem('userInfo', userInfo);
};

export const isLogin = () => {
	const token = JSON.parse(localStorage.getItem('token'));
	if (token && token.token) {
		let userInfo = JSON.parse(localStorage.getItem('userInfo'));

		if (userInfo && userInfo.is_admin === false) {
			let js_sha256_hash = JS_SHA256.hmac('lU9UL1eCM2oCgDIGVcqhnHjQhTOw48cKjq8_D3Jg', userInfo.email);

			let plan_name = 'Basic';
			if (userInfo && userInfo.access) {
				if (userInfo.access.plan_type === 'pro') {
					plan_name = 'Pro';
				} else if (userInfo.access.plan_type === 'premium') {
					plan_name = 'Premium';
				} else {
					plan_name = 'Basic';
				}
			}

			window.intercomSettings = {
				app_id: 'wddgm6tg',
				name: `${userInfo && userInfo.firstname ? userInfo.firstname : ''} ${
					userInfo && userInfo.lastname ? userInfo.lastname : ''
				}`,
				email: `${userInfo && userInfo.email ? userInfo.email : ''}`,
				PlanType: plan_name,
			};
		}

		// custom script
		(function () {
			var w = window;
			var ic = w.Intercom;
			if (typeof ic === 'function') {
				ic('reattach_activator');
				ic('update', w.intercomSettings);
			} else {
				var d = document;
				var i = function () {
					i.c(arguments);
				};
				i.q = [];
				i.c = function (args) {
					i.q.push(args);
				};
				w.Intercom = i;
				var l = function () {
					var s = d.createElement('script');
					s.type = 'text/javascript';
					s.async = true;
					s.src = 'https://widget.intercom.io/widget/wddgm6tg';
					var x = d.getElementsByTagName('script')[0];
					x.parentNode.insertBefore(s, x);
				};
				if (w.attachEvent) {
					w.attachEvent('onload', l);
				} else {
					w.addEventListener('load', l, false);
				}
			}
		})();

		return true;
	}
	
	return false;
};
