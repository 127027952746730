/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/images/logo.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "../../services/auth/authActions";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import bell from "../../assets/icons/bell-icon.svg";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="drop-icon">&#x25bc;</span>
  </a>
));
class Header extends React.Component {
  constructor(props) {
    super(props);

    this.signHandleClick = this.signHandleClick.bind();
    this.loginHandleClick = this.loginHandleClick.bind();
    this.setOpen = this.setOpen.bind(this);

    this.state = {
      totalNotify: 0,
      isOpen: false,
    };
  }

  componentDidMount = () => {
    let _self = this;

    window.Intercom("onUnreadCountChange", function (unreadCount) {
      _self.setState({ totalNotify: unreadCount });
      // }
    });
  };

  onOpenMessage = async () => {
    const { isOpen } = this.state;
    if (isOpen) {
      await window.Intercom("hide");
    } else {
      await window.Intercom("showNewMessage");
    }
    await this.setOpen();
  };

  setOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  signHandleClick = () => {
    this.props.history.push("/signup");
  };
  loginHandleClick = () => {
    this.props.history.push("/login");
  };

  myHipscans = () => {
    this.props.history.push("/myhipscans");
  };

  logoutHandleClick = () => {
    this.props.authActions.logout();
    localStorage.clear();
    this.props.history.push("/login");
    window.location.reload();
  };

  render() {
    const token = JSON.parse(localStorage.getItem("token"));
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const { totalNotify } = this.state;
    const width = window.innerWidth;

    return (
      <div className="headerBar">
        <div className="container ">
          {/* <div className="header d-none d-md-block"> */}
          {/* <div className="header-wrapper"> */}
          <Navbar collapseOnSelect expand="md">
            <Navbar.Brand href="https://hipscan.com" id="logo">
              <img src={logo} alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            {width < 768 ? (
              <Navbar.Collapse
                // className="navMobile"
                id="responsive-navbar-nav"
              >
                <Nav className="mr-auto"></Nav>
                {token && userInfo ? (
                  <Nav>
                    {userInfo && userInfo.is_admin ? (
                      <Nav.Link href="/admin">Dashboard</Nav.Link>
                    ) : (
                      <Nav.Link href="/profile">Account</Nav.Link>
                    )}

                    {userInfo && !userInfo.is_admin && (
                      <Nav.Link href="/plans">Upgrade</Nav.Link>
                    )}

                    <Nav.Link href="#" onClick={this.logoutHandleClick}>
                      {" "}
                      Logout
                    </Nav.Link>
                  </Nav>
                ) : (
                  <Nav>
                    <Nav.Link href={`${process.env.REACT_APP_DOMAIN}about-us`}>
                      About us
                    </Nav.Link>
                    <Nav.Link href="/plans">Pricing</Nav.Link>
                    <Nav.Link href="/login">Login</Nav.Link>
                    <Nav.Link href="/signup">Sign up</Nav.Link>
                  </Nav>
                )}
              </Navbar.Collapse>
            ) : (
              <Navbar.Collapse
                // className="navDesktop"
                id="responsive-navbar-nav"
              >
                <Nav className="mr-auto"></Nav>

                {token && userInfo ? (
                  <Nav>
                    <Nav.Link>
                      <Dropdown>
                        <Dropdown.Toggle
                          as={CustomToggle}
                          variant="success"
                          id="dropdown-basic"
                        >
                          {userInfo.firstname} {userInfo.lastname}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {userInfo && userInfo.is_admin ? (
                            <Dropdown.Item>
                              <Link to="/admin">Dashboard</Link>
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item>
                              <Link to="/profile">Account</Link>
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            onClick={this.logoutHandleClick}
                            href="#"
                          >
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav.Link>
                    <Nav.Link>
                      <div
                        className="notification"
                        onClick={this.onOpenMessage}
                      >
                        <img src={bell} alt="bell" className="bell-icon" />
                        {totalNotify > 0 && (
                          <span className="notification-num">
                            {totalNotify}
                          </span>
                        )}
                      </div>
                    </Nav.Link>
                  </Nav>
                ) : (
                  <Nav>
                    <Nav.Link href={`${process.env.REACT_APP_DOMAIN}about-us`}>
                      ABOUT US
                    </Nav.Link>
                    <Nav.Link className="login-button btn-plans" href="/plans">
                      PRICING
                    </Nav.Link>
                    <Nav.Link
                      className="login-button"
                      onClick={this.loginHandleClick}
                    >
                      LOGIN
                    </Nav.Link>
                    <Nav.Link
                      onClick={this.signHandleClick}
                      className="signup-button"
                    >
                      SIGN UP
                    </Nav.Link>
                  </Nav>
                )}
              </Navbar.Collapse>
            )}
          </Navbar>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.default.services.auth,
  }),
  (dispatch) => ({
    authActions: bindActionCreators({ logout }, dispatch),
  })
)(Header);
