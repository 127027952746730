import React from 'react';
import './ContactInfoModal.css';
import { Modal, Button } from 'react-bootstrap';
import { GiOpenFolder, GiCancel } from 'react-icons/gi';
import { FaDribbble, FaFacebookF, FaTwitter, FaInstagram, FaRegPlayCircle, FaCalendar } from 'react-icons/fa'
import { TiSocialYoutubeCircular, TiSocialVimeoCircular, TiCalendar } from 'react-icons/ti'

class ContactInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            c_email: "",
            c_email_subject: "",
            c_email_body: "",
            c_company: "",
            c_meetingLink: "",
            c_number: "",
            c_website: "",
            c_facebook: "",
            c_instagram: "",
            c_twitter: "",
            c_videoURL: "",
            c_address: "",
            isIncludedVideo: false,
        }
        this.isIncludedVideoHandle = this.isIncludedVideoHandle.bind(this);
        this.upload = this.upload.bind(this);
        this.cancelUpload = this.cancelUpload.bind(this);
        this.fileSelectHandle = this.fileSelectHandle.bind(this);

        this.emailHandle = this.emailHandle.bind(this);
        this.emailSubjectHandle = this.emailSubjectHandle.bind(this);
        this.emailBodyHandle = this.emailBodyHandle.bind(this);
        this.companyHandle = this.companyHandle.bind(this);
        this.meetingLinkHandle = this.meetingLinkHandle.bind(this);
        this.numberHandle = this.numberHandle.bind(this);
        this.websiteURLHandle = this.websiteURLHandle.bind(this);
        this.facebookURLHandle = this.facebookURLHandle.bind(this);
        this.instagramURLHandle = this.instagramURLHandle.bind(this);
        this.twitterURLHandle = this.twitterURLHandle.bind(this);
        this.videoURLHandle = this.videoURLHandle.bind(this);
        this.addressHandle = this.addressHandle.bind(this);

    }

    componentWillMount() {
        this.setState({ c_email: this.props.email });
        this.setState({ c_email_subject: this.props.email_subject });
        this.setState({ c_email_body: this.props.email_body });
        this.setState({ c_company: this.props.company });
        this.setState({ c_number: this.props.phone });
        this.setState({ c_website: this.props.website_url });
        this.setState({ c_facebook: this.props.facebook_url });
        this.setState({ c_instagram: this.props.instagram_url });
        this.setState({ c_twitter: this.props.twitter_url });
        this.setState({ c_videoURL: this.props.video_url });
        this.setState({ c_address: this.props.address });
        this.setState({ c_meetingLink: this.props.meeting_link });
        this.setState({ isIncludedVideo: (this.props.video_url && this.props.video_url !== 'undefined') ? true : false });
    }

    upload() { document.getElementById("contact_info_selectImage").click() }
    fileSelectHandle(e) {
        this.setState({ selectedFile: e.target.files[0] })
        this.props.onChangeBFile(e.target.files[0])
    }

    cancelUpload() {
        this.fileInput.value = ''
        this.setState({ selectedFile: null })
        this.props.onChangeBFile(null)
    }

    isIncludedVideoHandle(e) {
        this.setState({ isIncludedVideo: !this.state.isIncludedVideo })
        this.props.isIncludedVideoHandle(e.target.value)

        if (e.target.id === 'url-checkbox' && e.target.checked === false) {
            this.setState({ c_meetingLink: '' })
            this.props.onChangeMeetingLink('')
        }
    };

    emailHandle(e) {
        this.setState({ c_email: e.target.value })
        this.props.onChangeEmail(e.target.value)
    };

    emailSubjectHandle(e) {
        this.setState({ c_email_subject: e.target.value })
        this.props.onChangeEmailSubject(e.target.value)
    };

    emailBodyHandle(e) {
        this.setState({ c_email_body: e.target.value })
        this.props.onChangeEmailBody(e.target.value)
    };

    companyHandle(e) {
        this.setState({ c_company: e.target.value })
        this.props.onChangeCompany(e.target.value)
    };

    meetingLinkHandle(e) {
        this.setState({ c_meetingLink: e.target.value })
        this.props.onChangeMeetingLink(e.target.value)
    };

    numberHandle(e) {
        this.setState({ c_number: e.target.value })
        this.props.onChangeNumber(e.target.value)
    };
    websiteURLHandle(e) {
        this.setState({ c_website: e.target.value })
        this.props.onChangeWebsiteURL(e.target.value)
    };
    facebookURLHandle(e) {
        this.setState({ c_facebook: e.target.value })
        this.props.onChangeFacebookURL(e.target.value)
    };
    instagramURLHandle(e) {
        this.setState({ c_instagram: e.target.value })
        this.props.onChangeInstagramURL(e.target.value)
    };
    twitterURLHandle(e) {
        this.setState({ c_twitter: e.target.value })
        this.props.onChangeTwitterURL(e.target.value)
    };
    videoURLHandle(e) {
        this.setState({ c_videoURL: e.target.value })
        this.props.onChangeVideoURL(e.target.value)
    };
    addressHandle(e) {
        this.setState({ c_address: e.target.value })
        this.props.onChangeAddress(e.target.value)
    };

    render() {
        const width = window.innerWidth;
        return (
            <div>
                {width > 767 ? (
                    <Modal show={this.props.show} onHide={() => this.props.modalHide()} className="d-none d-md-block" dialogClassName="modal-container" aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Contact Infomation
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-field">
                            <div className="image-upload">
                                <input id="contact_info_selectImage" type="file" hidden placeholder="Upload Background Picture" onChange={this.fileSelectHandle} ref={ref => this.fileInput = ref}></input>
                                <span>{this.state.selectedFile && this.state.selectedFile.name !== null ? this.state.selectedFile.name : "Upload Background Picture"}</span>
                                <div className="icon left" onClick={this.upload}><GiOpenFolder size="2.5rem" /></div>
                                <div className="icon" onClick={this.cancelUpload}><GiCancel size="2.5rem" /></div>
                            </div>
                            {
                                this.props.isAddress && <div className="address">
                                    <input type="text" placeholder="Address" value={this.state.c_address} onChange={this.addressHandle} />
                                </div>
                            }
                            <div className="address">
                                <input type="text" placeholder="Company" value={this.state.c_company} onChange={this.companyHandle} />
                            </div>

                            <div className="address">
                                <input placeholder="Phone Number" value={this.state.c_number} onChange={this.numberHandle} />
                            </div>
                            <div className="website-facebook">
                                <div className="website">
                                    <input placeholder="Website" value={this.state.c_website} onChange={this.websiteURLHandle} />
                                    <FaDribbble className="icon" size="1.9rem" />
                                </div>
                                <div className="facebook">
                                    <input placeholder="Facebook" value={this.state.c_facebook} onChange={this.facebookURLHandle} />
                                    <FaFacebookF className="icon" size="1.9rem" />
                                </div>
                            </div>
                            <div className="instagram-twitter">
                                <div className="instagram">
                                    <input placeholder='Instagram' value={this.state.c_instagram} onChange={this.instagramURLHandle} />
                                    <FaInstagram className="icon" value={this.state.c_instagram} size="1.9rem" />
                                </div>
                                <div className="twitter">
                                    <input placeholder="Twitter" value={this.state.c_twitter} onChange={this.twitterURLHandle} />
                                    <FaTwitter className="icon" size="1.9rem" />
                                </div>
                            </div>

                            {/* <div className="email-phone">
                                <div className="email">
                                    <input placeholder="Meeting Link" value={this.state.c_meetingLink} onChange={this.meetingLinkHandle} />
                                </div>
                            </div> */}
                            <div style={{width: "100%", height: 1, backgroundColor: "#afafaf", marginTop: 20}} ></div>
                            <h5 style={{marginTop: 20}} >Email Details</h5>
                            <div className="email-phone">
                                <div className="email">
                                    <input placeholder="Email Address" value={this.state.c_email} onChange={this.emailHandle} />
                                </div>
                                <div className="phone">
                                    <input placeholder="Subject Line" value={this.state.c_email_subject} onChange={this.emailSubjectHandle} />
                                </div>
                            </div>
                            <div className="address">
                                <input placeholder="Email Body" value={this.state.c_email_body} onChange={this.emailBodyHandle} />
                            </div>
                            
                            { 
                                !this.props.isAddress && <div className='select'>
                                    <div className='name'>
                                        <input type='checkbox' name='test' id='url-checkbox' checked={this.state.c_meetingLink} onChange={this.isIncludedVideoHandle} />
                                        <span className='checkmark'></span>
                                        <p>Add a meeting link</p>
                                    </div>
                                </div>
                            }

                            {
                                this.state.isIncludedVideo || this.state.c_meetingLink != '' ?
                                    !this.props.isAddress && <div className='instagram-twitter' style={{width: '210%'}}>
                                    <div className='instagram'>
                                        <input placeholder='Meeting Link' id='meet-link-box' value={this.state.c_meetingLink} onChange={this.meetingLinkHandle} />
                                        <TiCalendar className='icon' size='1.9rem' />
                                    </div>
                                </div> : null
                            }

                            {/* {
                                this.state.isIncludedVideo ?
                                    !this.props.isAddress && <div className="video">
                                        <input type="text" placeholder="Custom Video Type" value={this.state.c_videoURL} onChange={this.videoURLHandle}></input>
                                        <div className="icon1"><TiSocialYoutubeCircular size="2.5rem" /></div>
                                        <div className="icon2"><TiSocialVimeoCircular size="2.5rem" /></div>
                                    </div> : null
                            } */}
                            <div className="save-btn">
                                <Button onClick={() => this.props.saveContact()}>Save</Button>
                            </div>
                        </div>
                        <div className="image-preview">
                            <div className="image">
                                {this.state.selectedFile && this.state.selectedFile !== "" ?
                                    <img className="selectedImage" src={URL.createObjectURL(this.state.selectedFile)} />
                                    :
                                    (
                                        this.props.bFile && this.props.bFile !== '' && this.props.bFile !== 'undefined' ?
                                            <img className="selectedImage" src={this.props.bFile} />
                                            :
                                            <p>Background Image Preview</p>
                                    )
                                }

                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                ) : (
                    <Modal show={this.props.show} onHide={() => this.props.modalHide()} className="d-block d-md-none" dialogClassName="mob-modal-container" aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Contact Infomation
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-field">
                            <div className="image-upload">
                                <input id="contact_info_selectImage" type="file" hidden placeholder="Upload Background Picture" onChange={this.fileSelectHandle} ref={ref => this.fileInput = ref}></input>
                                <span>{this.state.selectedFile && this.state.selectedFile.name !== null ? this.state.selectedFile.name : "Upload Background Picture"}</span>
                                <div className="icon left" onClick={this.upload}><GiOpenFolder size="1rem" /></div>
                                <div className="icon" onClick={this.cancelUpload}><GiCancel size="1rem" /></div>
                            </div>
                            {
                                this.props.isAddress && <div className="address">
                                    <input type="text" placeholder="Address" value={this.state.c_address} onChange={this.addressHandle} />
                                </div>
                            }
                            <div className="email-phone">
                                <div className="email">
                                    <input placeholder="Email Address" value={this.state.c_email} onChange={this.emailHandle} />
                                </div>
                                <div className="phone">
                                    <input placeholder="Phone Number" value={this.state.c_number} onChange={this.numberHandle} />
                                </div>
                            </div>
                            <div className="website-facebook">
                                <div className="website">
                                    <input placeholder="Website" value={this.state.c_website} onChange={this.websiteURLHandle} />
                                    <FaDribbble className="icon" size="1.5rem" />
                                </div>
                                <div className="facebook">
                                    <input placeholder="Facebook" value={this.state.c_facebook} onChange={this.facebookURLHandle} />
                                    <FaFacebookF className="icon" size="1.5rem" />
                                </div>
                            </div>
                            <div className="instagram-twitter">
                                <div className="instagram">
                                    <input placeholder="Instagram @username" value={this.state.c_instagram} onChange={this.instagramURLHandle} />
                                    <FaInstagram className="icon" value={this.state.c_instagram} size="1.5rem" />
                                </div>
                                <div className="twitter">
                                    <input placeholder="Twitter" value={this.state.c_twitter} onChange={this.twitterURLHandle} />
                                    <FaTwitter className="icon" size="1.5rem" />
                                </div>
                            </div>
                            {
                                !this.props.isAddress && <div className="select">
                                    <div className="name">
                                        <input type="checkbox" name="test" checked={this.state.isIncludedVideo} onChange={this.isIncludedVideoHandle} />
                                        <span className="checkmark"></span>
                                        <p>Include Video</p>
                                    </div>
                                </div>}
                            {
                                this.state.isIncludedVideo ?
                                    !this.props.isAddress && <div className="video">
                                        <input type="text" placeholder="Custom Video Type" value={this.state.c_videoURL} onChange={this.videoURLHandle}></input>
                                        <div className="icon1"><TiSocialYoutubeCircular size="1.5rem" /></div>
                                        <div className="icon2"><TiSocialVimeoCircular size="1.5rem" /></div>
                                    </div> : null
                            }
                            <div className="save-btn">
                                <Button onClick={() => this.props.saveContact()}>Return</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                )}
            </div>
        )
    }
}

export default ContactInfoModal;
