import { put, takeEvery, call, all, takeLatest } from "redux-saga/effects";

//import actions
import {
  getHipscansLimitsSucceed,
  getHipscansLimitsFailed,
  getHipscansPlanFreeBasicSucceed,
  getHipscansPlanFreeBasicFailed,
  getHipscansPlanProMonthlySucceed,
  getHipscansPlanProMonthlyFailed,
  getHipscansPlanProYearlySucceed,
  getHipscansPlanProYearlyFailed,
  getHipscansPlanPremiumMonthlySucceed,
  getHipscansPlanPremiumMonthlyFailed,
  getHipscansPlanPremiumYearlySucceed,
  getHipscansPlanPremiumYearlyFailed,
  getHipscansPlanTrialSucceed,
  getHipscansPlanTrialFailed,
} from "./pricingActions";

//import api
import * as pricingApi from "./pricingApi";

export function* pricingSubscriber() {
  yield all([takeEvery("GET_HIPSCANS_LIMITS", getHipscansLimits)]);

  yield all([
    takeEvery("GET_HIPSCANS_PLAN_FREE_BASIC", getHipscansPlanFreeBasic),
  ]);
  yield all([
    takeEvery("GET_HIPSCANS_PLAN_PRO_MONTHLY", getHipscansPlanProMonthly),
  ]);
  yield all([
    takeEvery("GET_HIPSCANS_PLAN_PRO_YEARLY", getHipscansPlanProYearly),
  ]);
  yield all([
    takeEvery(
      "GET_HIPSCANS_PLAN_PREMIUM_MONTHLY",
      getHipscansPlanPremiumMonthly
    ),
  ]);
  yield all([
    takeEvery("GET_HIPSCANS_PLAN_PREMIUM_YEARLY", getHipscansPlanPremiumYearly),
  ]);
  yield all([takeEvery("GET_HIPSCANS_PLAN_TRIAL", getHipscansPlanTrial)]);
}

export function* getHipscansLimits() {
  try {
    const limits = yield call(pricingApi.getHipscansLimits);
    yield put(getHipscansLimitsSucceed(limits));
  } catch (error) {
    yield put(getHipscansLimitsFailed(error));
  }
}

export function* getHipscansPlanFreeBasic() {
  try {
    const plan_free_basic = yield call(pricingApi.getHipscansPlanFreeBasic);
    yield put(getHipscansPlanFreeBasicSucceed(plan_free_basic));
  } catch (error) {
    yield put(getHipscansPlanFreeBasicFailed(error));
  }
}

export function* getHipscansPlanProMonthly() {
  try {
    const plan_pro_monthly = yield call(pricingApi.getHipscansPlanProMonthly);
    yield put(getHipscansPlanProMonthlySucceed(plan_pro_monthly));
  } catch (error) {
    yield put(getHipscansPlanProMonthlyFailed(error));
  }
}

export function* getHipscansPlanProYearly() {
  try {
    const plan_pro_yearly = yield call(pricingApi.getHipscansPlanProYearly);
    yield put(getHipscansPlanProYearlySucceed(plan_pro_yearly));
  } catch (error) {
    yield put(getHipscansPlanProYearlyFailed(error));
  }
}

export function* getHipscansPlanPremiumMonthly() {
  try {
    const plan_premium_monthly = yield call(
      pricingApi.getHipscansPlanPremiumMonthly
    );
    yield put(getHipscansPlanPremiumMonthlySucceed(plan_premium_monthly));
  } catch (error) {
    yield put(getHipscansPlanPremiumMonthlyFailed(error));
  }
}

export function* getHipscansPlanPremiumYearly() {
  try {
    const plan_premium_yearly = yield call(
      pricingApi.getHipscansPlanPremiumYearly
    );
    yield put(getHipscansPlanPremiumYearlySucceed(plan_premium_yearly));
  } catch (error) {
    yield put(getHipscansPlanPremiumYearlyFailed(error));
  }
}

export function* getHipscansPlanTrial({ payload: { isTrial, plan } }) {
  try {
    const plan_trial = yield call(
      pricingApi.getHipscansPlanTrial,
      isTrial,
      plan
    );

    yield put(getHipscansPlanTrialSucceed(plan_trial));
  } catch (error) {
    yield put(getHipscansPlanTrialFailed(error));
  }
}
