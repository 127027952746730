import { handleActions } from "redux-actions";

import {
  getHipscansLimitsFailed,
  getHipscansLimitsSucceed,
  getHipscansPlanFreeBasicSucceed,
  getHipscansPlanFreeBasicFailed,
  getHipscansPlanProMonthlyFailed,
  getHipscansPlanProMonthlySucceed,
  getHipscansPlanProYearlyFailed,
  getHipscansPlanProYearlySucceed,
  getHipscansPlanPremiumMonthlyFailed,
  getHipscansPlanPremiumMonthlySucceed,
  getHipscansPlanPremiumYearlyFailed,
  getHipscansPlanPremiumYearlySucceed,
  getHipscansPlanTrialSucceed,
  getHipscansPlanTrialFailed,
} from "./pricingActions";
import { getHashes } from "crypto";
import {
  getHipscansLimits,
  getHipscansPlanFreeBasic,
  getHipscansPlanProMonthly,
  getHipscansPlanProYearly,
  getHipscansPlanPremiumMonthly,
  getHipscansPlanPremiumYearly,
  getHipscansPlanTrial,
} from "./pricingApi";

const defaultState = {
  error: null,
  loading: false,
  message: "",
  success: false,
  plan_pro_monthly: null,
  plan_pro_yearly: null,
  plan_premium_monthly: null,
  plan_premium_yearly: null,

  plan_trial: null,
};

const reducer = handleActions(
  {
    [getHipscansLimits](state) {
      return {
        ...state,
        error: null,
        loading: true,
      };
    },
    [getHipscansLimitsFailed](state, { payload: { error } }) {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
    [getHipscansLimitsSucceed](state, { payload: { limits } }) {
      return {
        ...state,
        loading: false,
        limits,
      };
    },

    [getHipscansPlanFreeBasic](state) {
      return {
        ...state,
        error: null,
        loading: true,
      };
    },
    [getHipscansPlanFreeBasicFailed](state, { playload: { error } }) {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
    [getHipscansPlanFreeBasicSucceed](state, { payload: { plan_free_basic } }) {
      return {
        ...state,
        loading: false,
        plan_free_basic,
      };
    },

    [getHipscansPlanProMonthly](state) {
      return {
        ...state,
        error: null,
        loading: true,
      };
    },
    [getHipscansPlanProMonthlyFailed](state, { payload: { error } }) {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
    [getHipscansPlanProMonthlySucceed](
      state,
      { payload: { plan_pro_monthly } }
    ) {
      return {
        ...state,
        loading: false,
        plan_pro_monthly,
      };
    },

    [getHipscansPlanProYearly](state) {
      return {
        ...state,
        error: null,
        loading: true,
      };
    },
    [getHipscansPlanProYearlyFailed](state, { payload: { error } }) {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
    [getHipscansPlanProYearlySucceed](state, { payload: { plan_pro_yearly } }) {
      return {
        ...state,
        loading: false,
        plan_pro_yearly,
      };
    },

    [getHipscansPlanPremiumMonthly](state) {
      return {
        ...state,
        error: null,
        loading: true,
      };
    },
    [getHipscansPlanPremiumMonthlyFailed](state, { payload: { error } }) {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
    [getHipscansPlanPremiumMonthlySucceed](
      state,
      { payload: { plan_premium_monthly } }
    ) {
      return {
        ...state,
        loading: false,
        plan_premium_monthly,
      };
    },

    [getHipscansPlanPremiumYearly](state) {
      return {
        ...state,
        error: null,
        loading: true,
      };
    },
    [getHipscansPlanPremiumYearlyFailed](state, { payload: { error } }) {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
    [getHipscansPlanPremiumYearlySucceed](
      state,
      { payload: { plan_premium_yearly } }
    ) {
      return {
        ...state,
        loading: false,
        plan_premium_yearly,
      };
    },

    [getHipscansPlanTrial](state) {
      return {
        ...state,
        error: null,
        loading: true,
      };
    },

    [getHipscansPlanTrialFailed](state, { payload: { error } }) {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },

    [getHipscansPlanTrialSucceed](state, { payload: { plan_trial } }) {
      return {
        ...state,
        loading: false,
        plan_trial,
      };
    },
  },
  defaultState
);

export default reducer;
