import React from "react";
import "./index.scss";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// actions
import {
  getHipscansAdminStats,
  getHipscansAdminUsers,
  postHipscansAdminAutoLogin
} from "../../services/admin/adminActions";
import { getProfile } from "../../services/auth/authActions";
// components
import HeaderAdmin from "../../components/AdminPanel/Layouts/Header";
import StatisticsAdmin from "../../components/AdminPanel/Statistics";
import UsersManager from "../../components/AdminPanel/Users";
import { message } from "antd";
import {
  storeMainId,
  storeUserInfo,
  storeUserName
} from "../../services/auth/services";

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onAutoLoginUser = this.onAutoLoginUser.bind(this);
  }

  componentDidMount = async () => {
    await this.props.authActions.getProfile();
    (await this.props.auth) &&
      this.props.auth.profile &&
      this.props.auth.profile.is_admin === false &&
      this.props.history.push("/");
    // window.location.reload();

    this.props.adminActions.getHipscansAdminStats();
    this.props.adminActions.getHipscansAdminUsers();
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth.profile) {
        if (this.props.auth.profile.is_admin === false) {
          this.props.history.push("/");
          // window.location.reload();
        }
      }
    }
  };

  onAutoLoginUser = async user_id => {
    let admin_token = JSON.parse(localStorage.getItem("token"));
    await localStorage.setItem("admin_token", localStorage.getItem("token"));

    let results = await axios.post(
      `https://api.hipscan.com/admin/autologin`,
      { user_id },
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Accept: "application/json",
          charset: "UTF-8",
          Authorization: `Bearer ${admin_token.token}`
        }
      }
    );

    if (results.status === 200) {
      await localStorage.setItem("token", JSON.stringify(results.data));
      await this.onGetProfile();
    } else {
      message.error("Login failed! Please try again!");
    }
  };

  onGetProfile = async () => {
    let data_token = JSON.parse(localStorage.getItem("token"));
    let results = await axios.get(`https://api.hipscan.com/profile`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
        charset: "UTF-8",
        Authorization: `Bearer ${data_token.token}`
      }
    });

    if (results.status === 200) {
      let _profile = results.data;
      let code = _profile.code;
      if (code) {
        await storeUserName(_profile.username);
        await storeUserInfo(JSON.stringify(_profile));
        await storeMainId(_profile.code.id);
        await localStorage.setItem("viewedBy", "admin");
        await this.props.history.push(`/myhipscans/detail/${code.id}`);
      }
    }
  };

  render() {
    const { usersManager, statsAdmin } = this.props.admin;

    return (
      <div className="admin-panel">
        <HeaderAdmin />
        <StatisticsAdmin stats={statsAdmin} />
        <UsersManager
          users={usersManager}
          onAutoLoginUser={this.onAutoLoginUser}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    ...state.default.services
  }),
  dispatch => ({
    adminActions: bindActionCreators(
      {
        getHipscansAdminStats,
        getHipscansAdminUsers,
        postHipscansAdminAutoLogin
      },
      dispatch
    ),
    authActions: bindActionCreators({ getProfile }, dispatch)
  })
)(withRouter(AdminPanel));
