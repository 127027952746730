import { put, takeEvery, call, all, takeLatest } from 'redux-saga/effects';

//import actions
import {
    getHipscansAdminStatsSucceed,
    getHipscansAdminStatsFailed,

    getHipscansAdminUsersSucceed,
    getHipscansAdminUsersFailed,

    postHipscansAdminAutoLoginSucceed,
    postHipscansAdminAutoLoginFailed,

    getHipscansPlanProMonthlySucceed,
    getHipscansPlanProMonthlyFailed,

    getHipscansPlanProYearlySucceed,
    getHipscansPlanProYearlyFailed,

    getHipscansPlanPremiumMonthlySucceed,
    getHipscansPlanPremiumMonthlyFailed,

    getHipscansPlanPremiumYearlySucceed,
    getHipscansPlanPremiumYearlyFailed,

} from './adminActions';

//import api
import * as adminApi from './adminApi';

export function* adminSubscriber() {
    yield all([takeEvery('GET_HIPSCANS_ADMIN_STATS', getHipscansAdminStats)]);

    yield all([takeEvery('GET_HIPSCANS_ADMIN_USERS', getHipscansAdminUsers)]);

    yield all([takeEvery('POST_HIPSCANS_ADMIN_AUTO_LOGIN', postHipscansAdminAutoLogin)]);

    yield all([takeEvery('GET_HIPSCANS_PLAN_PRO_MONTHLY', getHipscansPlanProMonthly)]);
    yield all([takeEvery('GET_HIPSCANS_PLAN_PRO_YEARLY', getHipscansPlanProYearly)]);
    yield all([takeEvery('GET_HIPSCANS_PLAN_PREMIUM_MONTHLY', getHipscansPlanPremiumMonthly)]);
    yield all([takeEvery('GET_HIPSCANS_PLAN_PREMIUM_YEARLY', getHipscansPlanPremiumYearly)]);

}

export function* getHipscansAdminStats() {
    try {
        const myHipscans = yield call(adminApi.getHipscansAdminStats);
        yield put(getHipscansAdminStatsSucceed(myHipscans));
    } catch (error) {
        yield put(getHipscansAdminStatsFailed(error));
    }
}

export function* getHipscansAdminUsers() {
    try {
        const usersManager = yield call(adminApi.getHipscansAdminUsers);
        yield put(getHipscansAdminUsersSucceed(usersManager));
    } catch (error) {
        yield put(getHipscansAdminUsersFailed(error));
    }
}

export function* postHipscansAdminAutoLogin({ payload: { user_id } }) {
    try {
        const token = yield call(adminApi.postHipscansAdminAutoLogin, user_id);
        yield put(postHipscansAdminAutoLoginSucceed(token));
    } catch (error) {
        yield put(postHipscansAdminAutoLoginFailed(error));
    }
}

export function* getHipscansPlanProMonthly() {
    try {
        const plan_pro_monthly = yield call(adminApi.getHipscansPlanProMonthly);
        yield put(getHipscansPlanProMonthlySucceed(plan_pro_monthly));
    } catch (error) {
        yield put(getHipscansPlanProMonthlyFailed(error));
    }
}

export function* getHipscansPlanProYearly() {
    try {
        const plan_pro_yearly = yield call(adminApi.getHipscansPlanProYearly);
        yield put(getHipscansPlanProYearlySucceed(plan_pro_yearly));
    } catch (error) {
        yield put(getHipscansPlanProYearlyFailed(error));
    }
}

export function* getHipscansPlanPremiumMonthly() {
    try {
        const plan_premium_monthly = yield call(adminApi.getHipscansPlanPremiumMonthly);
        yield put(getHipscansPlanPremiumMonthlySucceed(plan_premium_monthly));
    } catch (error) {
        yield put(getHipscansPlanPremiumMonthlyFailed(error));
    }
}

export function* getHipscansPlanPremiumYearly() {
    try {
        const plan_premium_yearly = yield call(adminApi.getHipscansPlanPremiumYearly);
        yield put(getHipscansPlanPremiumYearlySucceed(plan_premium_yearly));
    } catch (error) {
        yield put(getHipscansPlanPremiumYearlyFailed(error));
    }
}
