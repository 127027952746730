import React from "react";
import { Container } from "react-bootstrap";
import "./index.scss";
import moment from "moment";

import Img1 from "../../../assets/icons/stats/cal.svg";
import Img2 from "../../../assets/icons/stats/ic2.svg";
import Img3 from "../../../assets/icons/stats/dola.svg";
import Chart from "react-google-charts";

class StatisticsAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { stats } = this.props;

    return (
      <div className="statistics-admin">
        <Container fluid>
          <div className="statistics-content">
            <div className="stats-box box1">
              <div className="image">
                <img src={Img1} alt="" />
              </div>
              {/* <h5>12 MAY 2020</h5> */}
              <h5>{moment().format("DD MMM YYYY")}</h5>

              <h3>
                {stats && stats.sign_up && stats.sign_up.today
                  ? stats.sign_up.today
                  : 0}
              </h3>
              <h4>SIGNUPS TODAY</h4>
            </div>

            <div className="stats-box box1">
              <div className="image">
                <img src={Img1} alt="" />
              </div>
              {/* <h5>11 MAY - 17 MAY</h5> */}
              <h5>
                {moment().startOf("week").format("DD MMM")} -{" "}
                {moment().endOf("week").format("DD MMM")}
              </h5>

              <h3>
                {stats && stats.sign_up && stats.sign_up.week
                  ? stats.sign_up.week
                  : 0}
              </h3>
              <h4>SIGNUPS THIS WEEK</h4>
            </div>

            <div className="stats-box box1">
              <div className="image">
                <img src={Img1} alt="" />
              </div>
              {/* <h5>MAY 2020</h5> */}
              <h5>{moment().format("MMM YYYY")}</h5>

              <h3>
                {stats && stats.sign_up && stats.sign_up.month
                  ? stats.sign_up.month
                  : 0}
              </h3>
              <h4>SIGNUPS THIS MONTH</h4>
            </div>

            <div className="stats-box box2">
              <div className="total-item">
                <img src={Img2} alt="" />
                <div className="detail">
                  <p>TOTAL CUSTOMERS</p>
                  <h3>
                    {stats && stats.total_customers ? stats.total_customers : 0}
                  </h3>
                </div>
              </div>
              <div className="total-item">
                <img src={Img3} alt="" />
                <div className="detail">
                  <p>TOTAL REVENUE</p>
                  <h3>
                    ${stats && stats.total_revenue ? stats.total_revenue : 0}
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="statistics-content">
            <div className="stats-box box3">
              <Chart
                className="chart-view"
                width={"220px"}
                height={"220px"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["Task", "Hours per Day"],
                  [
                    `PRO (${
                      stats && stats.group_pro ? stats.group_pro.percent : 0
                    }%)`,
                    stats && stats.group_pro ? stats.group_pro.percent : 0
                  ],
                  [
                    `PREMIUM (${
                      stats && stats.group_premium
                        ? stats.group_premium.percent
                        : 0
                    }%)`,
                    stats && stats.group_premium
                      ? stats.group_premium.percent
                      : 0
                  ]
                ]}
                options={{
                  // title: "REVENUE BREAKDOWN",
                  title: "",
                  pieHole: 0.8,
                  pieStartAngle: 0,
                  slices: {
                    0: { color: "#f25a19" },
                    1: { color: "#b7b6b5" }
                  },
                  legend: "none",
                  pieSliceText: "none"
                }}
                rootProps={{ "data-testid": "3" }}
              />
              <div className="detail-chart">
                <div className="slice-item">
                  <p>
                    PRO (
                    {stats && stats.group_pro ? stats.group_pro.percent : 0}%)
                  </p>
                  <h3>
                    ${stats && stats.group_pro ? stats.group_pro.amount : 0}
                  </h3>
                </div>
                <div className="slice-item">
                  <p>
                    PREMIUM (
                    {stats && stats.group_premium
                      ? stats.group_premium.percent
                      : 0}
                    %)
                  </p>
                  <h3 className="color-dark">
                    $
                    {stats && stats.group_premium
                      ? stats.group_premium.amount
                      : 0}
                  </h3>
                </div>
              </div>
            </div>

            <div className="stats-box box4">
              <div className="detail-chart">
                <div className="slice-item">
                  <p>
                    BASIC (
                    {stats && stats.plan && stats.plan.basic
                      ? stats.plan.basic.percent
                      : 0}
                    %)
                  </p>
                  <h3 className="color-dark">
                    {stats && stats.plan && stats.plan.basic
                      ? stats.plan.basic.customers
                      : 0}
                  </h3>
                </div>
              </div>
              <Chart
                className="chart-view"
                width={"220px"}
                height={"220px"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["Task", "Hours per Day"],
                  [
                    `PRO (${
                      stats && stats.plan && stats.plan.pro
                        ? stats.plan.pro.percent
                        : 0
                    }%)`,
                    stats && stats.plan && stats.plan.pro
                      ? stats.plan.pro.percent
                      : 0
                  ],
                  [
                    `PREMIUM (${
                      stats && stats.plan && stats.plan.premium
                        ? stats.plan.premium.percent
                        : 0
                    }%)`,
                    stats && stats.plan && stats.plan.premium
                      ? stats.plan.premium.percent
                      : 0
                  ],
                  [
                    `BASIC (${
                      stats && stats.plan && stats.plan.basic
                        ? stats.plan.basic.percent
                        : 0
                    }%)`,
                    stats && stats.plan && stats.plan.basic
                      ? stats.plan.basic.percent
                      : 0
                  ]
                ]}
                options={{
                  title: "",
                  // title: "PLAN  SUBSCRIPTIONS",
                  pieHole: 0.8,
                  pieStartAngle: 0,
                  slices: {
                    0: { color: "#f58113" },
                    1: { color: "#f25a19" },
                    2: { color: "#b7b6b5" }
                  },
                  legend: "none",
                  pieSliceText: "none"
                }}
                rootProps={{ "data-testid": "3" }}
              />
              <div className="detail-chart">
                <div className="slice-item">
                  <p>
                    PRO (
                    {stats && stats.plan && stats.plan.pro
                      ? stats.plan.pro.percent
                      : 0}
                    %)
                  </p>
                  <h3 className="color-org">
                    {stats && stats.plan && stats.plan.pro
                      ? stats.plan.pro.customers
                      : 0}
                  </h3>
                </div>
                <div className="slice-item">
                  <p>
                    PREMIUM (
                    {stats && stats.plan && stats.plan.premium
                      ? stats.plan.premium.percent
                      : 0}
                    %)
                  </p>
                  <h3>
                    {stats && stats.plan && stats.plan.premium
                      ? stats.plan.premium.customers
                      : 0}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default StatisticsAdmin;
