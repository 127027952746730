import { wrapRequest, xapi } from '../utils';

const getHipscansLimits = wrapRequest(async () =>
    xapi().get('/admin/limits')
)

const getHipscansPlanFreeBasic = wrapRequest(async () =>
    xapi().get("/payment/free/basic")
)

const getHipscansPlanProMonthly = wrapRequest(async () =>
    xapi().get('/payment/pro/monthly')
)

const getHipscansPlanProYearly = wrapRequest(async () =>
    xapi().get('/payment/pro/annually')
)

const getHipscansPlanPremiumMonthly = wrapRequest(async () =>
    xapi().get('/payment/premium/monthly')
)

const getHipscansPlanPremiumYearly = wrapRequest(async () =>
    xapi().get('/payment/premium/annually')
)

const getHipscansPlanTrial = wrapRequest(async (isTrial, plan) => {
    let _url = `/payment/free/basic`;
    if(isTrial){
        _url = `/trial/${plan}`
    }

    xapi().get(_url, {})
})

export {
    getHipscansLimits,

    getHipscansPlanFreeBasic,
    getHipscansPlanProMonthly,
    getHipscansPlanProYearly,
    getHipscansPlanPremiumMonthly,
    getHipscansPlanPremiumYearly,

    getHipscansPlanTrial,
}