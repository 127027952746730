import React from 'react';
import { FaDribbble, FaFacebookF, FaTwitter, FaInstagram, FaRegPlayCircle, FaPhotoVideo } from 'react-icons/fa'

import './PhoneScreen.css';

import Handshake from '../../assets/images/handshake.jpg';
import Profile from '../../assets/images/profile.jpg';
import logo from '../../assets/images/logo.svg';

import { getHipscanByUuid } from '../../services/profile/profileActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactPlayer from 'react-player'
import Product from '../../assets/images/product.jpg';
import Iframe from 'react-iframe';
import MenuBg from '../../assets/images/menu-phone-bg.jpg';
import MenuPhoto from '../../assets/images/menu-photo.png';
import { Button } from 'react-bootstrap';
import { IoIosArrowBack, IoIosInformationCircle, IoMdArrowDropdown } from 'react-icons/io';
import ProfilePhone from '../../components/ProfilePhone/ProfilePhone';

class PhoneScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "Display Name",
            description: "Description",
            viewing: 'home',
            selectedProduct: {}
        }

        this.changeviewing = this.changeviewing.bind(this)
        this.selectProduct = this.selectProduct.bind(this)
    }

    componentWillMount() {
        this.props.profileActions.getHipscanByUuid(this.props.match.params.uuid);
    }

    changeviewing(viewing) {
        this.setState({
            viewing: viewing
        })
    }

    selectProduct(p) {
        this.setState({
            viewing: 'product',
            selectedProduct: p
        })
    }

    renderProduct(p, j) {
        return <div className="product-item" onClick={() => this.selectProduct(p)}>
            <div className="product-image">
                {
                    p.image_url ? <img src={p.image_url} /> : null
                }
            </div>
            <div className="product-info">
                <p className="product-name">{p.name}</p>
                <p className="product-desc">{p.description}</p>
                <p className="product-price">$ {p.price}</p>
            </div>
        </div>
    }

    renderCategory(c, i) {
        return <div className="category-item">
            <div className="category-title">
                <span>{c.name}</span>
            </div>
            <div className="product-list">
                {
                    c.products ? c.products.map((p, j) => this.renderProduct(p, j)) : null
                }
            </div>
            <div className="category-separator"></div>
        </div>
    }

    renderModifier(m) {
        return <div className="mod">
            <label className="container">{m.name}<input type="checkbox" /><span className="checkmark"></span></label>
            <label>$ {m.price}</label>
        </div>
    }

    render() {
        console.log("this.props.myHipscanDetail Phone screen: ", this.props.myHipscanDetail)
        return (
            this.props.myHipscanDetail ?
                this.props.myHipscanDetail.type == 'url' ?
                    (<div className="url-screen-container">
                        <div className="url">
                            <div className="url-screen">
                                {this.props.myHipscanDetail.url !== "" ?
                                    <Iframe url={this.props.myHipscanDetail.url}
                                        width="100%"
                                        height="100%"
                                        id="myId"
                                        display="initial"
                                        position="relative" />
                                    :
                                    <div>
                                        <FaPhotoVideo className="prev-icon" size="2.5rem" />
                                        <h5>Enter URL for Preview</h5>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>) :
            this.props.myHipscanDetail.type == 'location' ?
            (<div className="url-screen-container">
            <div className="url">
                <div className="url-screen">
                    {this.props.myHipscanDetail.location !== "" ?
                        <Iframe url={this.props.myHipscanDetail.location}
                            width="100%"
                            height="100%"
                            id="myId"
                            display="initial"
                            position="relative" />
                        :
                        <div>
                            <FaPhotoVideo className="prev-icon" size="2.5rem" />
                            <h5>Enter Location for Preview</h5>
                        </div>
                    }
                </div>
                <div className="phone-footer">
                    <p>POWERED BY</p>
                    <img src={logo} alt="logo" />
                </div>
            </div>
             </div>) : 
                    this.props.myHipscanDetail.type == 'text' ?
                        (<div className="text-screen-container">
                            <div className="text">
                                <div className="text-screen">
                                    {this.props.myHipscanDetail.text !== "" ?
                                        <div class="text-content">
                                            {/* <IoIosInformationCircle className="prev-icon" size="2.5rem" /> */}
                                            <div className="preview-text">
                                                {this.props.myHipscanDetail.text}
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {/* <IoIosInformationCircle className="prev-icon" size="2.5rem" /> */}
                                            <h5>Custom Text for Preview</h5>
                                        </div>
                                    }
                                </div>
                                <div className="phone-footer">
                                    <p>POWERED BY</p>
                                    <img src={logo} alt="logo" />
                                </div>
                            </div>
                        </div>) :
                        this.props.myHipscanDetail.type == 'file' ?
                        (<div className="url-screen-container">
                            <div className="url">
                                <div className="url-screen">
                                    {this.props.myHipscanDetail.file !== "" ?
                                        <Iframe url={this.props.myHipscanDetail.file}
                                            width="100%"
                                            height="100%"
                                            id="myId"
                                            display="initial"
                                            position="relative" />
                                        :
                                        <div>
                                            <FaPhotoVideo className="prev-icon" size="2.5rem" />
                                            <h5>Enter File URL for Preview</h5>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>) :
                        this.props.myHipscanDetail.type == 'wifi' ?
                        (<div className="url-screen-container">
                            <div className="url">
                                <div className="url-screen">
                                    {this.props.myHipscanDetail.wifi_name !== "" && 
                                     this.props.myHipscanDetail.wifi_password !=="" ?
                                    <>
                                        {this.props.myHipscanDetail.wifi_name}{this.props.myHipscanDetail.wifi_password}
                                        <div className="phone-footer">
                                        <p>POWERED BY</p>
                                            <img src={logo} alt="logo" />
                                        </div>
                                    </>
                                        :
                                    <>
                                        <div>
                                            <FaPhotoVideo className="prev-icon" size="2.5rem" />
                                            <h5>Enter Wifi Credentials</h5>
                                        </div>
                                        <div className="phone-footer">
                                        <p>POWERED BY</p>
                                            <img src={logo} alt="logo" />
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>) :
                        this.props.myHipscanDetail.type == 'image' ?
                            (<div className="image-screen-container">
                                <div className="image">
                                    <div className="image-screen">
                                        {this.props.myHipscanDetail.image_url !== "" ?
                                            <img className="image-preview" src={this.props.myHipscanDetail.image_url} alt="upload image" />
                                            :
                                            <div>
                                                <FaPhotoVideo className="prev-icon" size="2.5rem" />
                                            </div>
                                        }
                                    </div>
                                    <div className="phone-footer">
                                        <p>POWERED BY</p>
                                        <img src={logo} alt="logo" />
                                    </div>
                                </div>
                            </div>) :
                            this.props.myHipscanDetail.type == 'video' ?
                                (<div className="video-screen-container">
                                    <div className="video">
                                        {this.props.myHipscanDetail.video !== "" && this.props.myHipscanDetail.video != 'undefined' ?
                                            <div className="video-screen"><ReactPlayer url={this.props.myHipscanDetail.video} playing width="100%" height="100%" /></div>
                                            :
                                            // <div className="play">
                                            //     <FaRegPlayCircle className="icon" size="1.2rem" />
                                            // </div>
                                            null
                                        }
                                        <div className="phone-footer">
                                            <p>POWERED BY</p>
                                            <img src={logo} alt="logo" />
                                        </div>
                                    </div>
                                </div>) :
                                this.props.myHipscanDetail.type == 'profile' ?
                                    (
                                        <div className="phone-screen">
                                            <ProfilePhone
                                                name={this.props.myHipscanDetail.name}
                                                description={this.props.myHipscanDetail.description}
                                                meeting_link={this.props.myHipscanDetail.contact.meeting_link}
                                                email_subject={this.props.myHipscanDetail.contact.email_subject}
                                                email_body={this.props.myHipscanDetail.contact.email_body}
                                                color={this.props.myHipscanDetail.contact.color}
                                                title={this.props.myHipscanDetail.title}
                                                file={this.props.myHipscanDetail.profile_image_url !== "" ? this.props.myHipscanDetail.profile_image_url : Profile}
                                                bFile={
                                                    this.props.myHipscanDetail.contact.background_image_url !== "" ? this.props.myHipscanDetail.contact.background_image_url : Handshake
                                                }

                                                c_email={this.props.myHipscanDetail.contact.email}
                                                c_number={this.props.myHipscanDetail.contact.phone}
                                                c_website={this.props.myHipscanDetail.contact.website_url}
                                                c_facebook={
                                                    this.props.myHipscanDetail.contact.facebook_url
                                                }
                                                c_instagram={
                                                    this.props.myHipscanDetail.contact.instagram_url
                                                }
                                                c_twitter={this.props.myHipscanDetail.contact.twitter_url}
                                                c_videoURL={this.props.myHipscanDetail.contact.video_url}
                                            />
                                            </div>
                                    ) :
                                    this.props.myHipscanDetail.type == 'product' ?
                                        (<div className="product-phone-screen">
                                            <div className="product-phone">
                                                <div className="product-photo">
                                                    {
                                                        this.props.myHipscanDetail.product_image_url !== "" ?
                                                            <img src={this.props.myHipscanDetail.product_image_url} /> :
                                                            <img src={Product} alt="product" />
                                                    }
                                                </div>
                                                <div className="product-name">
                                                    <p>{this.props.myHipscanDetail.name}</p>
                                                </div>
                                                <div className="price">
                                                    <h1>{this.props.myHipscanDetail.price}</h1>
                                                </div>
                                                <div className="description">
                                                    <p>{this.props.myHipscanDetail.description}</p>
                                                </div>
                                                {this.props.myHipscanDetail.video !== "" && this.props.myHipscanDetail.video != 'undefined' ?
                                                    // <Iframe url={this.props.myHipscanDetail.video}
                                                    //     width="100%"
                                                    //     height="100%"
                                                    //     id="myId"
                                                    //     display="initial"
                                                    //     position="relative" />
                                                    <div className="video-screen"><ReactPlayer url={this.props.myHipscanDetail.video} playing width="100%" height="100%" /></div>
                                                    :
                                                    // <FaRegPlayCircle className="play" size="2rem" />
                                                    null
                                                }
                                                <div className="phone-footer">
                                                    <p>POWERED BY</p>
                                                    <img src={logo} alt="logo" />
                                                </div>
                                            </div>
                                        </div>) :
                                        this.props.myHipscanDetail.type == 'menu' ?
                                            (
                                                <div className="home-phone">
                                                    <div className="menu">
                                                        {
                                                            this.props.myHipscanDetail.contact && this.props.myHipscanDetail.contact.cover_image_url !== "" ?
                                                                <img src={this.props.myHipscanDetail.contact.cover_image_url} className="background" alt="background" /> :
                                                                <img src={MenuBg} className="background" alt="background" />
                                                        }

                                                        <div style={this.state.viewing == 'home' ? { display: 'contents' } : { display: 'none' }}>
                                                            <div className="menu-photo">
                                                                {
                                                                    this.props.myHipscanDetail.menu_logo_url !== "" ?
                                                                        <img src={this.props.myHipscanDetail.menu_logo_url} alt="menuphoto" /> :
                                                                        <img src={MenuPhoto} alt="menuphoto" />
                                                                }
                                                            </div>
                                                            <div className="display-name">
                                                                <p>{this.props.myHipscanDetail.name}</p>
                                                            </div>
                                                            <div className="contact-info">
                                                                <p>{this.props.myHipscanDetail.contact && this.props.myHipscanDetail.contact.phone ? this.props.myHipscanDetail.contact.phone : ''}</p>
                                                                <h5>{this.props.myHipscanDetail.contact && this.props.myHipscanDetail.contact.email ? this.props.myHipscanDetail.contact.email : ''}</h5>
                                                            </div>
                                                            <div className="description">
                                                                <p>{this.props.myHipscanDetail.description}</p>
                                                            </div>
                                                            <p>{this.props.myHipscanDetail.contact && this.props.myHipscanDetail.contact.website_url ? this.props.myHipscanDetail.contact.website_url : ''}</p>
                                                            <div className="social-icons">
                                                                {
                                                                    (this.props.myHipscanDetail.contact && this.props.myHipscanDetail.contact.website_url && this.props.myHipscanDetail.contact.website_url != 'undefined') ?
                                                                        <a href={this.props.myHipscanDetail.contact.website_url}><FaDribbble className="social" size="1.8rem" /></a> : null
                                                                }
                                                                {
                                                                    (this.props.myHipscanDetail.contact && this.props.myHipscanDetail.contact.facebook_url && this.props.myHipscanDetail.contact.facebook_url != 'undefined') ?
                                                                        <a href={this.props.myHipscanDetail.contact.facebook_url}><FaFacebookF className="social" size="1.8rem" /></a> : null
                                                                }
                                                                {
                                                                    (this.props.myHipscanDetail.contact && this.props.myHipscanDetail.contact.twitter_url && this.props.myHipscanDetail.contact.twitter_url != 'undefined') ?
                                                                        <a href={this.props.myHipscanDetail.contact.twitter_url}><FaTwitter className="social" size="1.8rem" /></a> : null
                                                                }
                                                                {
                                                                    (this.props.myHipscanDetail.contact && this.props.myHipscanDetail.contact.instagram_url && this.props.myHipscanDetail.contact.instagram_url != 'undefined') ?
                                                                        <a href={this.props.myHipscanDetail.contact.instagram_url}><FaInstagram className="social" size="1.8rem" /></a> : null
                                                                }
                                                            </div>

                                                            <div className="view-menu-btn">
                                                                <Button onClick={() => this.changeviewing('menu')}>VIEW MENU</Button>
                                                            </div>
                                                            <div className="phone-footer">
                                                                <p>POWERED BY</p>
                                                                <img src={logo} alt="logo" />
                                                            </div>
                                                        </div>
                                                        <div style={this.state.viewing == 'menu' ? { display: 'contents' } : { display: 'none' }}>
                                                            <div className="history-container">
                                                                <span className="go-back" onClick={() => this.changeviewing('home')}>&#8592;</span>
                                                            </div>
                                                            <div className="logo-container">
                                                                <div className="menu-photo">
                                                                    {
                                                                        this.props.myHipscanDetail.menu_logo_url !== "" ?
                                                                            <img src={this.props.myHipscanDetail.menu_logo_url} alt="menuphoto" /> :
                                                                            <img src={MenuPhoto} alt="menuphoto" />
                                                                    }
                                                                </div>
                                                                <div className="menu-info">
                                                                    <p className="menu-title">{this.props.myHipscanDetail.name}</p>
                                                                    <p>{this.props.myHipscanDetail.contact && this.props.myHipscanDetail.contact.phone ? this.props.myHipscanDetail.contact.phone : ''}</p>
                                                                </div>
                                                            </div>
                                                            <div className="categories-container">
                                                                {
                                                                    this.props.myHipscanDetail.categories ?
                                                                        this.props.myHipscanDetail.categories.map((c, i) => this.renderCategory(c, i))
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={this.state.viewing == 'product' ? { display: 'contents' } : { display: 'none' }}>
                                                            <div className="history-container">
                                                                <span className="go-back" onClick={() => this.changeviewing('menu')}>&#8592;</span>
                                                            </div>
                                                            <div className="product-details">
                                                                <div className="detail-container">
                                                                    <div className="header">
                                                                        <p>{this.state.selectedProduct.name}</p>
                                                                        <p>{this.state.selectedProduct.description}</p>
                                                                    </div>
                                                                    <div className="mod-list">
                                                                        <div className="mod">
                                                                            <span>Additional</span>
                                                                        </div>
                                                                        {
                                                                            this.state.selectedProduct.modifiers ?
                                                                                this.state.selectedProduct.modifiers.map((m) => this.renderModifier(m))
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="add-to-cart">
                                                                    <div className="cart-button">
                                                                        <span>ADD TO CART</span>
                                                                        <span>$ {this.state.selectedProduct.price}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>) : this.props.myHipscanDetail.type == 'flyer' ?
                                                            ( this.props.myHipscanDetail.design_type == 'video' ? (
                                                                <div className="url-screen-container">
                                                                    <div className="url">
                                                                        <div className="url-screen">
                                                                            {this.props.myHipscanDetail.preview_url !== "" ?
                                                                                <Iframe url={this.props.myHipscanDetail.preview_url}
                                                                                    width="100%"
                                                                                    height="100%"
                                                                                    id="myId"
                                                                                    display="initial"
                                                                                    position="relative" />
                                                                                :
                                                                                <div>
                                                                                    <FaPhotoVideo className="prev-icon" size="2.5rem" />
                                                                                    <h5>Enter URL for Preview</h5>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="image-screen-container">
                                                                    <div className="image">
                                                                        <div className="image-screen">
                                                                            {this.props.myHipscanDetail.preview_url !== "" ?
                                                                                <img className="image-preview" src={this.props.myHipscanDetail.preview_url} alt="upload image" />
                                                                                :
                                                                                <div>
                                                                                    <FaPhotoVideo className="prev-icon" size="2.5rem" />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="phone-footer">
                                                                            <p>POWERED BY</p>
                                                                            <img src={logo} alt="logo" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                            ) :
                                            null : null
        )
    }
}

export default connect(
    state => ({
        ...state.default.services.profile
    }),
    dispatch => ({
        profileActions: bindActionCreators({ getHipscanByUuid }, dispatch)
    })
)(PhoneScreen);
