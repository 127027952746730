import React from "react";
import "./Footer.css";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import logo from "../../assets/images/footer_logo.jpg";

class Footer extends React.Component {
  render() {
    return (
      <div className="footerbar">
        <div className="footer">
          <div className="header-wrapper">
            <div className="logo-footer">
              <a className="social" href={process.env.REACT_APP_DOMAIN}>
                <img src={logo} alt="" />
              </a>
            </div>
            <div className="icons-and-links">
              <div className="social-icon">
                <a
                  className="social"
                  href="https://www.facebook.com/hipscanqr/"
                  target="_blank"
                >
                  <FaFacebookF />
                </a>
                <a
                  className="social"
                  href="https://www.facebook.com/hipscanqr/"
                  target="_blank"
                >
                  <FaTwitter />
                </a>
                <a
                  className="social"
                  href="https://www.instagram.com/hipscan/"
                  target="_blank"
                >
                  <FaInstagram />
                </a>
              </div>
              <div className="links">
                <a href={process.env.REACT_APP_DOMAIN}>Home</a>
                <span className="dot">.</span>
                <a href={`${process.env.REACT_APP_DOMAIN}about-us`}>About</a>
                <span className="dot">.</span>
                <a href={`${process.env.REACT_APP_DOMAIN}contact-us`}>
                  Contact Us
                </a>
                <span className="dot">.</span>
                <a href={`${process.env.REACT_APP_DOMAIN_SECONDARY}plans`}>
                  Pricing
                </a>
              </div>
            </div>
            <div className="terms">
              <div className="terms-and-condition">
                <a
                  href={`${process.env.REACT_APP_DOMAIN}terms-and-conditions`}
                  className="tos"
                >
                  Terms and Conditions
                </a>
                <span>|</span>
                <a
                  href={`${process.env.REACT_APP_DOMAIN}privacy-polily`}
                  className="tos"
                >
                  Privacy Policy
                </a>
              </div>
              <div className="copyright">
                © Hipscan 2020. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
