import React,{ useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import { addPartnerStamp, getPartnerStamps } from "../../services/profile/profileActions";
import Profile from "../../assets/images/profile.jpg";
import EmptyStamp from '../../assets/images/empty-stamp.png';
import FilledStamp from '../../assets/images/filled-badge.png';
import BackgroundStamp from '../../assets/images/badgeBackground.png';
import "./PartnerScan.css";
import { useEffect } from "react";


const PartnerScan = (props) => {
  const [state, setState] = useState({
    name: "",
    email: "",
    showStampScreen: false
  });

  useEffect(() => {
    props.profileActions.getPartnerStamps(props.qrId)
  }, [props.stamp])

  useEffect(() => {
    if(props.isStamped) {
      setState({ ...state, showStampScreen: true })
    } else {
      setState({ ...state, showStampScreen: false })
    }
  }, [props.isStamped])

  const inputChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    props.profileActions.addPartnerStamp(state.name, state.email, props.qrId)
  };

  return <>
    <div className="profile-preview">
      {!state.showStampScreen && <div className="profile">
        <div className="profile-photo">
          <img 
            src={window.location.origin + `/${props.logo}`} 
            alt="profile" 
            onError={e => {
              e.target.src = Profile; 
            }}
            />
        </div>
        <div className="display-name">
          <p>{props.name}</p>
        </div>
        <div className="description">
          <p className="event-description">{props.description !== ''? props.description: 'Helo this is event dummy description.'}</p>
          <p className="m-0">Thank you for participating.</p>
          <p>Please provide the following information.</p>
        </div>
        <div className="contact-info mb-2">
          <input
            type="text"
            name="name"
            placeholder="Enter Name"
            value={state.name}
            onChange={inputChangeHandler}
            className=" user-input mb-3"
          ></input>
          <input
            type="text"
            name="email"
            placeholder="Enter Email Address"
            value={state.email}
            onChange={inputChangeHandler}
            className="user-input mb-4"
          ></input>
        </div>

        <div className="submit">
          <Button onClick={submitHandler}>SUBMIT</Button>
        </div>
      </div>}
      {state.showStampScreen && <div className="stamps">
        <div className="stamp-heading">
          <h4>{props.name}</h4>
        </div>
        <div className="stamp-cards">
          {props.partnerStamps.stamped.map((e, index) => <img key={index.toString()} src={`/${e.PartnerLogo}`} alt="logo" width={'18%'} />
          )}
          {props.partnerStamps.unstamped.map((e, index) => <img key={index.toString()} src={EmptyStamp} alt="logo" width={'18%'} />)}
        </div>

        <div className="stamp-count">
          <h4>YOU HAVE</h4>
          <h1>{props.partnerStamps.unstamped.length}</h1>
        </div>
        
        <div className="footer">
          <p>more partners to visit to complete this digital stamp card.</p>
        </div>
      </div>}
    </div>
  </>
};

const mapStateToProps = (state) => {
  return {
    stamp: state.default.services.profile.stamp,
    isStamped: state.default.services.profile.isStamped,
    partnerStamps: state.default.services.profile.partnerStamps,
    ...state.default.services.profile,
  }
}

const mapDispatchToProps = (dispatch) => ({
  profileActions: bindActionCreators(
    { addPartnerStamp, getPartnerStamps },
    dispatch
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(PartnerScan);
