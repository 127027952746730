/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { FaBars, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Header2.css";
import logo from "../../assets/images/logo.svg";
import bell from "../../assets/icons/bell-icon.svg";
import { logout } from "../../services/auth/authActions";
import { bindActionCreators } from "redux";
import { Modal, Dropdown, ButtonGroup, DropdownButton } from "react-bootstrap";
import Axios from "axios";
import {
  storeMainId,
  storeUserInfo,
  storeUserName
} from "../../services/auth/services";

import { Popover, List, Avatar } from "antd";

import "antd/dist/antd.css";

import axios from "axios";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="drop-icon">&#x25bc;</span>
  </a>
));
class Header2 extends React.Component {
  constructor(props) {
    super(props);

    this.logoutHandleClick = this.logoutHandleClick.bind(this);
    this.myHipscans = this.myHipscans.bind(this);
    this.goToMainDetail = this.goToMainDetail.bind(this);
    this.getUserName = this.getUserName.bind(this);
    this.modalShow = this.modalShow.bind(this);
    this.modalHide = this.modalHide.bind(this);
    this.setOpen = this.setOpen.bind(this);

    this.state = {
      modal_show: false,
      viewedBy: null,
      totalNotify: 0,
      isOpen: false
    };
  }

  componentDidMount = () => {
    let viewedBy = localStorage.getItem("viewedBy");
    if (viewedBy && viewedBy.trim() !== "") {
      this.setState({ viewedBy });
    }

    let _self = this;

    window.Intercom("onUnreadCountChange", function (unreadCount) {
      _self.setState({ totalNotify: unreadCount });
      // }
    });
  };

  modalShow = () => {
    this.setState({
      modal_show: true
    });
  };

  modalHide = () => {
    this.setState({
      modal_show: false
    });
  };

  logoutHandleClick = () => {
    this.props.authActions.logout();
    localStorage.clear();
    this.props.history.push("/login");
    window.location.reload();
  };

  myHipscans = () => {
    this.props.history.push("/myhipscans");
  };

  goToMainDetail = mainId => {
    this.props.history.push(`/myhipscans/detail/${mainId}`);
    window.location.reload();
  };

  getUserName = () => {
    let userInfo = localStorage.getItem("userInfo");

    if (!userInfo) return "";
    userInfo = JSON.parse(userInfo);
    return userInfo.firstname + " " + userInfo.lastname;
  };

  handleBackAdmin = async () => {
    await localStorage.removeItem("viewedBy");
    await localStorage.setItem("token", localStorage.getItem("admin_token"));

    let data_token = JSON.parse(localStorage.getItem("admin_token"));
    let results = await Axios.get(`https://api.hipscan.com/profile`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
        charset: "UTF-8",
        Authorization: `Bearer ${data_token.token}`
      }
    });

    if (results.status === 200) {
      let _profile = results.data;
      let code = _profile.code;
      if (code) {
        await storeUserName(_profile.username);
        await storeUserInfo(JSON.stringify(_profile));
        await storeMainId(_profile.code.id);
        await this.props.history.push(`/admin`);
        await window.location.reload();
      }
    }
  };

  onOpenMessage = async () => {
    const { isOpen } = this.state;
    if (isOpen) {
      await window.Intercom("hide");
    } else {
      await window.Intercom("showNewMessage");
    }
    await this.setOpen();
  };

  setOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    let userName = "",
      mainId = null;
    if (localStorage.getItem("username") != null) {
      // userName = localStorage.getItem("username");
      userName = this.getUserName();
    }
    if (localStorage.getItem("mainid") != null) {
      mainId = localStorage.getItem("mainid");
    }

    const { viewedBy, totalNotify } = this.state;

    return (
      <div>
        <div className="header2bar d-none d-md-block">
          <div className="header">
            <div className="logo">
              <Link
                to="#"
                className="gotoHomepage"
                onClick={() => this.goToMainDetail(mainId)}
              >
                <img src={logo} alt="logo" />
              </Link>
            </div>
            {viewedBy && (
              <div
                className="backAdmin"
                style={{ fontWeight: "bold", marginRight: "30px" }}
              >
                <a onClick={this.handleBackAdmin}>Back Admin</a>
              </div>
            )}

            <div
              className="user-name"
            >
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  variant="success"
                  id="dropdown-basic"
                >
                  {userName}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/profile">Account</Dropdown.Item>
                  <Dropdown.Item onClick={this.logoutHandleClick} href="#">
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <p onClick={this.myHipscans}>My Hipscans</p>

            <a className="notification" onClick={this.onOpenMessage}>
              <img src={bell} alt="bell" className="bell-icon" />
              {totalNotify > 0 && (
                <span className="notification-num">{totalNotify}</span>
              )}
            </a>
          </div>
          <div className="underline"></div>
        </div>
        <div className="mob-header2bar d-block d-md-none">
          <div className="header">
            <Link
              to="#"
              className="goto"
              onClick={() => this.props.goBack("none")}
            >
              {!this.props.isEditing ||
              this.props.isEditing === "none" ? null : (
                <FaArrowLeft size="1rem" />
              )}
            </Link>
            <div className="logo">
              <Link
                to="#"
                className="gotoHomepage"
                onClick={() => this.goToMainDetail(mainId)}
              >
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <Link to="#" className="goto" onClick={this.modalShow}>
              <FaBars size="1rem" />
            </Link>
          </div>
        </div>
        <Modal
          show={this.state.modal_show}
          onHide={() => this.modalHide()}
          dialogClassName="mob-menu-modal-container"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body>
            <div className="menu-list">
              {viewedBy && (
                <div
                  className="backAdmin"
                  style={{ fontWeight: "bold", marginRight: "30px" }}
                >
                  <a onClick={this.handleBackAdmin}>Back Admin</a>
                </div>
              )}
              <div className="menu-item-container">
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    variant="success"
                    id="dropdown-basic"
                  >
                    {userName}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/profile">Account</Dropdown.Item>
                    <Dropdown.Item onClick={this.logoutHandleClick} href="#">
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="menu-item-container">
                <Link to="#" onClick={this.myHipscans}>
                  My Hipscans
                </Link>
              </div>

              <div className="menu-item-container">
                <a className="notification" onClick={this.onOpenMessage}>
                  <img src={bell} alt="bell" className="bell-icon" />
                  {totalNotify > 0 && (
                    <span className="notification-num">{totalNotify}</span>
                  )}
                </a>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(
  state => ({
    ...state.default.services.auth
  }),
  dispatch => ({
    authActions: bindActionCreators({ logout }, dispatch)
  })
)(Header2);
