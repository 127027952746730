import React from 'react';
import { FaDribbble, FaFacebookF, FaTwitter, FaInstagram, FaRegPlayCircle } from 'react-icons/fa'

import './MenuPhone.css';

import MenuBg from '../../assets/images/menu-phone-bg.jpg';
import MenuPhoto from '../../assets/images/menu-photo.png';
import logo from '../../assets/images/logo.svg';
import { Button } from 'react-bootstrap';

class MenuPhone extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            viewing: 'home',
            selectedProduct: {}
        }

        this.changeviewing = this.changeviewing.bind(this)
        this.selectProduct = this.selectProduct.bind(this)
    }

    changeviewing(viewing) {
        this.setState({
            viewing: viewing
        })
    }

    selectProduct(p) {
        this.setState({
            viewing: 'product',
            selectedProduct: p
        })
    }

    renderProduct(p, j) {
        return <div className="product-item" onClick={() => this.selectProduct(p)}>
            <div className="product-image">
                {
                    p.image_url ? <img src={p.image_url} /> : null
                }
            </div>
            <div className="product-info">
                <p className="product-name">{p.name}</p>
                <p className="product-desc">{p.description}</p>
                <p className="product-price">$ {p.price}</p>
            </div>
        </div>
    }

    renderCategory(c, i) {
        return <div className="category-item">
            <div className="category-title">
                <span>{c.name}</span>
            </div>
            <div className="product-list">
                {
                    c.products ? c.products.map((p, j) => this.renderProduct(p, j)) : null
                }
            </div>
            <div className="category-separator"></div>
        </div>
    }

    renderModifier(m) {
        return <div className="mod">
            <label className="container">{m.name}<input type="checkbox" /><span className="checkmark"></span></label>
            <label>$ {m.price}</label>
        </div>
    }

    render() {
        return (
            <div className="menu-phone">
                <div className="menu">
                    {
                        this.props.bFile && this.props.bFile !== "" ?
                            // <img src={ URL.createObjectURL(this.props.bFile)} className="background" alt="background"/> : <img src={MenuBg} className="background" alt="background"/>
                            <img src={this.props.bFile} className="background" alt="background" /> : <img src={MenuBg} className="background" alt="background" />
                    }
                    <div style={this.state.viewing == 'home' ? { display: 'contents' } : { display: 'none' }}>
                        <div className="menu-photo">
                            {this.props.uploadFile && this.props.uploadFile !== "" ?
                                // <img src={ URL.createObjectURL(this.props.uploadFile)} style={{borderColor:this.props.themeColor}} /> 
                                <img src={this.props.uploadFile} style={{ borderColor: this.props.themeColor }} />
                                :
                                <img src={MenuPhoto} alt="menuphoto" style={{ borderColor: this.props.themeColor }} />
                            }
                        </div>
                        <div className="display-name">
                            <p>{this.props.restaurantName}</p>
                        </div>
                        <div className="contact-info">
                            <p style={{ color: 'white' }}>{this.props.c_number}</p>
                            <p style={{ color: 'white' }}>{this.props.c_email}</p>
                        </div>
                        <div className="description">
                            <p>{this.props.description}</p>
                        </div>
                        <p style={{ color: this.props.themeColor }}>{this.props.c_address}</p>
                        <div className="social-icons">
                            {
                                (this.props.c_website && this.props.c_website !== 'undefined') ?
                                    <a href={this.props.c_website}><FaDribbble className="website" size="1.4rem" style={{ backgroundColor: this.props.c_website !== "" ? this.props.themeColor : '#efefef' }} /></a> : null
                            }
                            {
                                (this.props.c_facebook && this.props.c_facebook !== 'undefined') ?
                                    <a href={this.props.c_facebook}><FaFacebookF className="facebook" size="1.4rem" style={{ backgroundColor: this.props.c_facebook !== "" ? this.props.themeColor : '#efefef' }} /></a> : null
                            }
                            {
                                (this.props.c_twitter && this.props.c_twitter !== 'undefined') ?
                                    <a href={this.props.c_facebook}><FaTwitter className="twitter" size="1.4rem" style={{ backgroundColor: this.props.c_twitter !== "" ? this.props.themeColor : '#efefef' }} /></a> : null
                            }
                            {
                                (this.props.c_instagram && this.props.c_instagram !== 'undefined') ?
                                    <a href={this.props.c_facebook}><FaInstagram className="instagram" size="1.4rem" style={{ backgroundColor: this.props.c_instagram !== "" ? this.props.themeColor : '#efefef' }} /></a> : null
                            }
                        </div>

                        <div className="view-menu-btn">
                            <Button style={{ backgroundColor: this.props.themeColor }} onClick={() => this.changeviewing('menu')}>VIEW MENU</Button>
                        </div>
                        <div className="phone-footer">
                            <p>POWERED BY</p>
                            <img src={logo} alt="logo" />
                        </div>
                    </div>
                    <div style={this.state.viewing == 'menu' ? { display: 'contents' } : { display: 'none' }}>
                        <div className="history-container">
                            <span className="go-back" onClick={() => this.changeviewing('home')}>&#8592;</span>
                        </div>
                        <div className="logo-container">
                            <div className="menu-photo">
                                {
                                    this.props.detailInfo.menu_logo_url !== "" ?
                                        <img src={this.props.detailInfo.menu_logo_url} alt="menuphoto" /> :
                                        <img src={MenuPhoto} alt="menuphoto" />
                                }
                            </div>
                            <div className="menu-info">
                                <p className="menu-title">{this.props.detailInfo.name}</p>
                                <p>{this.props.detailInfo.contact && this.props.detailInfo.contact.phone ? this.props.detailInfo.contact.phone : ''}</p>
                            </div>
                        </div>
                        <div className="categories-container">
                            {
                                this.props.detailInfo.categories ?
                                    this.props.detailInfo.categories.map((c, i) => this.renderCategory(c, i))
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div style={this.state.viewing == 'product' ? { display: 'contents' } : { display: 'none' }}>
                        <div className="history-container">
                            <span className="go-back" onClick={() => this.changeviewing('menu')}>&#8592;</span>
                        </div>
                        <div className="product-details">
                            <div className="detail-container">
                                <div className="header">
                                    <p>{this.state.selectedProduct.name}</p>
                                    <p>{this.state.selectedProduct.description}</p>
                                </div>
                                <div className="mod-list">
                                    <div className="mod">
                                        <span>Additional</span>
                                    </div>
                                    {
                                        this.state.selectedProduct.modifiers ?
                                            this.state.selectedProduct.modifiers.map((m) => this.renderModifier(m))
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="add-to-cart">
                                <div className="cart-button">
                                    <span>ADD TO CART</span>
                                    <span>$ {this.state.selectedProduct.price}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MenuPhone;