import { createActions } from 'redux-actions';
const {
    getHipscansAdminStats,
    getHipscansAdminStatsSucceed,
    getHipscansAdminStatsFailed,

    getHipscansAdminUsers,
    getHipscansAdminUsersSucceed,
    getHipscansAdminUsersFailed,

    postHipscansAdminAutoLogin,
    postHipscansAdminAutoLoginSucceed,
    postHipscansAdminAutoLoginFailed,

    getHipscansPlanProMonthly,
    getHipscansPlanProMonthlySucceed,
    getHipscansPlanProMonthlyFailed,

    getHipscansPlanProYearly,
    getHipscansPlanProYearlySucceed,
    getHipscansPlanProYearlyFailed,

    getHipscansPlanPremiumMonthly,
    getHipscansPlanPremiumMonthlySucceed,
    getHipscansPlanPremiumMonthlyFailed,

    getHipscansPlanPremiumYearly,
    getHipscansPlanPremiumYearlySucceed,
    getHipscansPlanPremiumYearlyFailed,

} = createActions({
    GET_HIPSCANS_ADMIN_STATS: () => ({}),
    GET_HIPSCANS_ADMIN_STATS_SUCCEED: statsAdmin => ({ statsAdmin }),
    GET_HIPSCANS_ADMIN_STATS_FAILED: error => ({ error }),

    GET_HIPSCANS_ADMIN_USERS: () => ({}),
    GET_HIPSCANS_ADMIN_USERS_SUCCEED: usersManager => ({ usersManager }),
    GET_HIPSCANS_ADMIN_USERS_FAILED: error => ({ error }),

    POST_HIPSCANS_ADMIN_AUTO_LOGIN: (user_id) => ({ user_id }),
    POST_HIPSCANS_ADMIN_AUTO_LOGIN_SUCCEED: userLogin => ({ userLogin }),
    POST_HIPSCANS_ADMIN_AUTO_LOGIN_FAILED: error => ({ error }),

    GET_HIPSCANS_PLAN_PRO_MONTHLY: () => ({}),
    GET_HIPSCANS_PLAN_PRO_MONTHLY_SUCCEED: plan_pro_monthly => ({ plan_pro_monthly }),
    GET_HIPSCANS_PLAN_PRO_MONTHLY_FAILED: error => ({ error }),

    GET_HIPSCANS_PLAN_PRO_YEARLY: () => ({}),
    GET_HIPSCANS_PLAN_PRO_YEARLY_SUCCEED: plan_pro_yearly => ({ plan_pro_yearly }),
    GET_HIPSCANS_PLAN_PRO_YEARLY_FAILED: error => ({ error }),

    GET_HIPSCANS_PLAN_PREMIUM_MONTHLY: () => ({}),
    GET_HIPSCANS_PLAN_PREMIUM_MONTHLY_SUCCEED: plan_premium_monthly => ({ plan_premium_monthly }),
    GET_HIPSCANS_PLAN_PREMIUM_MONTHLY_FAILED: error => ({ error }),

    GET_HIPSCANS_PLAN_PREMIUM_YEARLY: () => ({}),
    GET_HIPSCANS_PLAN_PREMIUM_YEARLY_SUCCEED: plan_premium_yearly => ({ plan_premium_yearly }),
    GET_HIPSCANS_PLAN_PREMIUM_YEARLY_FAILED: error => ({ error }),

});

export {
    getHipscansAdminStats,
    getHipscansAdminStatsSucceed,
    getHipscansAdminStatsFailed,

    getHipscansAdminUsers,
    getHipscansAdminUsersSucceed,
    getHipscansAdminUsersFailed,

    postHipscansAdminAutoLogin,
    postHipscansAdminAutoLoginSucceed,
    postHipscansAdminAutoLoginFailed,

    getHipscansPlanProMonthly,
    getHipscansPlanProMonthlySucceed,
    getHipscansPlanProMonthlyFailed,

    getHipscansPlanProYearly,
    getHipscansPlanProYearlySucceed,
    getHipscansPlanProYearlyFailed,

    getHipscansPlanPremiumMonthly,
    getHipscansPlanPremiumMonthlySucceed,
    getHipscansPlanPremiumMonthlyFailed,

    getHipscansPlanPremiumYearly,
    getHipscansPlanPremiumYearlySucceed,
    getHipscansPlanPremiumYearlyFailed,
};