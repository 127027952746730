import { createActions } from 'redux-actions';
const {
    getHipscans,
    getHipscansSucceed,
    getHipscansFailed,

    getPartnerHipscans,
    getPartnerHipscansSucceed,
    getPartnerHipscansFailed,

    getHipscanDetail,
    getHipscanDetailSucceed,
    getHipscanDetailFailed,

    addHipscan,
    addHipscanSucceed,
    addHipscanFailed,

    updateHipscan,
    updateHipscanSucceed,
    updateHipscanFailed,

    saveCustomDesign,
    saveCustomDesignSucceed,
    saveCustomDesignFailed,

    previewCustomDesign,
    previewCustomDesignSucceed,
    previewCustomDesignFailed,

    updateContact,
    updateContactSucceed,
    updateContactFailed,

    getHipscanByUuid,
    getHipscanByUuidSucceed,
    getHipscanByUuidFailed,

    downloadQrCode,
    downloadQrCodeSucceed,
    downloadQrCodeFailed,

    getHipscanStats,
    getHipscanStatsSucceed,
    getHipscanStatsFailed,

    deleteHipscan,
    deleteHipscanSucceed,
    deleteHipscanFailed,

    getHipscanCategories,
    getHipscanCategoriesSucceed,
    getHipscanCategoriesFailed,

    saveHipscanCategory,
    saveHipscanCategorySucceed,
    saveHipscanCategoryFailed,

    updateHipscanCategory,
    updateHipscanCategorySucceed,
    updateHipscanCategoryFailed,

    deleteHipscanCategory,
    deleteHipscanCategorySucceed,
    deleteHipscanCategoryFailed,

    getCategoryProduct,
    getCategoryProductSucceed,
    getCategoryProductFailed,

    saveCategoryProduct,
    saveCategoryProductSucceed,
    saveCategoryProductFailed,

    updateCategoryProduct,
    updateCategoryProductSucceed,
    updateCategoryProductFailed,

    deleteCategoryProduct,
    deleteCategoryProductSucceed,
    deleteCategoryProductFailed,

    saveProductModifiers,
    saveProductModifiersSucceed,
    saveProductModifiersFailed,

    getHipscanTotalStats,
    getHipscanTotalStatsSucceed,
    getHipscanTotalStatsFailed,

    getHipscanFilter,
    getHipscanFilterSucceed,
    getHipscanFilterFailed,

    addPartnerHipscan,
    addPartnerHipscanSucceed,
    addPartnerHipscanFailed,

    addPartnerStamp,
    addPartnerStampSucceed,
    addPartnerStampFailed,
    
    addFollowStamp,
    addFollowStampSucceed,
    addFollowStampFailed,

    getPartnerStamps,
    getPartnerStampsSucceed,
    getPartnerStampsFailed

} = createActions({
    GET_HIPSCANS: () => ({}),
    GET_HIPSCANS_SUCCEED: myHipscans => ({ myHipscans }),
    GET_HIPSCANS_FAILED: error =>({ error }),

    GET_PARTNER_HIPSCANS: () => ({}),
    GET_PARTNER_HIPSCANS_SUCCEED: partnerHipscans => ({ partnerHipscans }),
    GET_PARTNER_HIPSCANS_FAILED: error =>({ error }),

    GET_HIPSCAN_DETAIL: (id) => ({id}),
    GET_HIPSCAN_DETAIL_SUCCEED: myHipscanDetail => ({ myHipscanDetail }),
    GET_HIPSCAN_DETAIL_FAILED: error =>({ error }),

    ADD_HIPSCAN: (label) => ({ label }),
    ADD_HIPSCAN_SUCCEED: (addedHipscan) => ({addedHipscan}),
    ADD_HIPSCAN_FAILED: error => ({ error }),

    UPDATE_HIPSCAN: (id, params) => ({id, params}),
    UPDATE_HIPSCAN_SUCCEED: (updateHipscan) => ({updateHipscan}),
    UPDATE_HIPSCAN_FAILED: error => ({error}),

    SAVE_CUSTOM_DESIGN: (id, params) => ({id, params}),
    SAVE_CUSTOM_DESIGN_SUCCEED: (saveCustomDesign) => ({saveCustomDesign}),
    SAVE_CUSTOM_DESIGN_FAILED: error => ({error}),

    PREVIEW_CUSTOM_DESIGN: (id, params) => ({id, params}),
    PREVIEW_CUSTOM_DESIGN_SUCCEED: (previewCustomDesign) => ({previewCustomDesign}),
    PREVIEW_CUSTOM_DESIGN_FAILED: error => ({error}),

    UPDATE_CONTACT: (id, params) => ({id, params}),
    UPDATE_CONTACT_SUCCEED: (updatedContact) => ({updatedContact}),
    UPDATE_CONTACT_FAILED: error => ({error}),

    GET_HIPSCAN_BY_UUID: (id) => ({id}),
    GET_HIPSCAN_BY_UUID_SUCCEED: myHipscanDetail => ({ myHipscanDetail }),
    GET_HIPSCAN_BY_UUID_FAILED: error =>({ error }),

    DOWNLOAD_QR_CODE: (id, params) => ({id, params}),
    DOWNLOAD_QR_CODE_SUCCEED: qrCode => ({qrCode}),
    DOWNLOAD_QR_CODE_FAILED: error => ({error}),

    GET_HIPSCAN_STATS: (id) => ({id}),
    GET_HIPSCAN_STATS_SUCCEED: hipscanStats => ({ hipscanStats }),
    GET_HIPSCAN_STATS_FAILED: error => ({ error }),

    DELETE_HIPSCAN: (id) => ({id}),
    DELETE_HIPSCAN_SUCCEED: (deletedId) => ({deletedId}),
    DELETE_HIPSCAN_FAILED: error => ({error}),

    GET_HIPSCAN_CATEGORIES: (id) => ({id}),
    GET_HIPSCAN_CATEGORIES_SUCCEED: categories => ({ categories }),
    GET_HIPSCAN_CATEGORIES_FAILED: (error) => ({ error }),

    SAVE_HIPSCAN_CATEGORY: (id, name) => ({id, name}),
    SAVE_HIPSCAN_CATEGORY_SUCCEED: (category) => ({ category }),
    SAVE_HIPSCAN_CATEGORY_FAILED: (error) => ({ error }),

    UPDATE_HIPSCAN_CATEGORY: (hipId, id, name) => ({hipId, id, name}),
    UPDATE_HIPSCAN_CATEGORY_SUCCEED: (category) => ({ category }),
    UPDATE_HIPSCAN_CATEGORY_FAILED: (error) => ({ error }),

    DELETE_HIPSCAN_CATEGORY: (hipId, id) => ({hipId, id}),
    DELETE_HIPSCAN_CATEGORY_SUCCEED: (id) => ({ id }),
    DELETE_HIPSCAN_CATEGORY_FAILED: (error) => ({ error }),

    GET_CATEGORY_PRODUCT: (hipId, catId) => ({hipId, catId}),
    GET_CATEGORY_PRODUCT_SUCCEED: (products) => ({ products }),
    GET_CATEGORY_PRODUCT_FAILED: (error) => ({ error }),

    SAVE_CATEGORY_PRODUCT: (hipId, catId, name) => ({hipId, catId, name}),
    SAVE_CATEGORY_PRODUCT_SUCCEED: (product) => ({ product }),
    SAVE_CATEGORY_PRODUCT_FAILED: (error) => ({ error }),

    UPDATE_CATEGORY_PRODUCT: (hipId, catId, id, params) => ({hipId, catId, id, params}),
    UPDATE_CATEGORY_PRODUCT_SUCCEED: (product) => ({ product }),
    UPDATE_CATEGORY_PRODUCT_FAILED: (error) => ({ error }),

    DELETE_CATEGORY_PRODUCT: (hipId, catId, id) => ({hipId, catId, id}),
    DELETE_CATEGORY_PRODUCT_SUCCEED: (id) => ({ id }),
    DELETE_CATEGORY_PRODUCT_FAILED: (error) => ({ error }),

    SAVE_PRODUCT_MODIFIERS: (hipId, catId, prodId, params) => ({hipId, catId, prodId, params}),
    SAVE_PRODUCT_MODIFIERS_SUCCEED: (prodId, modifiers) => ({ prodId, modifiers }),
    SAVE_PRODUCT_MODIFIERS_FAILED: (error) => ({ error }),

    GET_HIPSCAN_TOTAL_STATS: () => ({}),
    GET_HIPSCAN_TOTAL_STATS_SUCCEED: hipscanTotalStats => ({ hipscanTotalStats }),
    GET_HIPSCAN_TOTAL_STATS_FAILED: error => ({ error }),

    GET_HIPSCAN_FILTER: (id, from, to, group) => ({ id, from, to, group }),
    GET_HIPSCAN_FILTER_SUCCEED: hipscanFilter => ({ hipscanFilter }),
    GET_HIPSCAN_FILTER_FAILED: error => ({ error }),

    ADD_PARTNER_HIPSCAN: (userId, info) => ({userId, info}),
    ADD_PARTNER_HIPSCAN_SUCCEED: myHipscanDetail => ({ myHipscanDetail }),
    ADD_PARTNER_HIPSCAN_FAILED: error => ({ error }),

    ADD_PARTNER_STAMP: (name, email, qr_id) => ({ name, email, qr_id }),
    ADD_PARTNER_STAMP_SUCCEED: (res) => ({ res }),
    ADD_PARTNER_STAMP_FAILED: error => ({ error }),
    
    ADD_FOLLOW_STAMP: (firstName, lastName, email, phoneNumber, uuid) => ({ firstName, lastName, email, phoneNumber, uuid }),
    ADD_FOLLOW_STAMP_SUCCEED: (res) => ({ res }),
    ADD_FOLLOW_STAMP_FAILED: error => ({ error }),

    GET_PARTNER_STAMPS: (id) => ({ id }),
    GET_PARTNER_STAMPS_SUCCEED: stamps => ({ stamps }),
    GET_PARTNER_STAMPS_FAILED: error => ({ error }),
});

export {
    getHipscans,
    getHipscansSucceed,
    getHipscansFailed,

    getPartnerHipscans,
    getPartnerHipscansSucceed,
    getPartnerHipscansFailed,

    getHipscanDetail,
    getHipscanDetailSucceed,
    getHipscanDetailFailed,

    addHipscan,
    addHipscanSucceed,
    addHipscanFailed,

    updateHipscan,
    updateHipscanSucceed,
    updateHipscanFailed,

    saveCustomDesign,
    saveCustomDesignSucceed,
    saveCustomDesignFailed,

    previewCustomDesign,
    previewCustomDesignSucceed,
    previewCustomDesignFailed,

    updateContact,
    updateContactSucceed,
    updateContactFailed,

    getHipscanByUuid,
    getHipscanByUuidSucceed,
    getHipscanByUuidFailed,

    downloadQrCode,
    downloadQrCodeSucceed,
    downloadQrCodeFailed,

    getHipscanStats,
    getHipscanStatsSucceed,
    getHipscanStatsFailed,

    deleteHipscan,
    deleteHipscanSucceed,
    deleteHipscanFailed,

    getHipscanCategories,
    getHipscanCategoriesSucceed,
    getHipscanCategoriesFailed,

    saveHipscanCategory,
    saveHipscanCategorySucceed,
    saveHipscanCategoryFailed,

    updateHipscanCategory,
    updateHipscanCategorySucceed,
    updateHipscanCategoryFailed,

    deleteHipscanCategory,
    deleteHipscanCategorySucceed,
    deleteHipscanCategoryFailed,

    getCategoryProduct,
    getCategoryProductSucceed,
    getCategoryProductFailed,

    saveCategoryProduct,
    saveCategoryProductSucceed,
    saveCategoryProductFailed,

    updateCategoryProduct,
    updateCategoryProductSucceed,
    updateCategoryProductFailed,

    deleteCategoryProduct,
    deleteCategoryProductSucceed,
    deleteCategoryProductFailed,

    saveProductModifiers,
    saveProductModifiersSucceed,
    saveProductModifiersFailed,

    getHipscanTotalStats,
    getHipscanTotalStatsSucceed,
    getHipscanTotalStatsFailed,

    getHipscanFilter,
    getHipscanFilterSucceed,
    getHipscanFilterFailed,

    addPartnerHipscan,
    addPartnerHipscanSucceed,
    addPartnerHipscanFailed,

    addPartnerStamp,
    addPartnerStampSucceed,
    addPartnerStampFailed,
    
    addFollowStamp,
    addFollowStampSucceed,
    addFollowStampFailed,

    getPartnerStamps,
    getPartnerStampsSucceed,
    getPartnerStampsFailed
};
