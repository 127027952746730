import React from 'react';
import './EditProductModal.css';
import { Modal, Button } from 'react-bootstrap';
import { GiOpenFolder } from 'react-icons/gi';
import { FaDribbble, FaFacebookF, FaTwitter, FaInstagram, FaRegPlayCircle } from 'react-icons/fa'
import { TiSocialYoutubeCircular, TiSocialVimeoCircular } from 'react-icons/ti'
import { IoIosArrowBack } from 'react-icons/io';
import { v1 as uuidv1 } from 'uuid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    updateCategoryProduct,
    saveProductModifiers
} from '../../../services/profile/profileActions';
import { MdEdit, MdSave, MdCancel } from 'react-icons/md';

class EditProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            addMod: [],
            product: {}
        }
        this.upload = this.upload.bind(this);
        this.fileSelectHandle = this.fileSelectHandle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleModInputChange = this.handleModInputChange.bind(this);
        this.addModifier = this.addModifier.bind(this);
        this.deleteModifier = this.deleteModifier.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.product != this.props.product) {
            this.setState({ product: this.props.product });
            this.setState({ addMod: this.props.product.modifiers });
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.product !== state.product) {
            return {
                product: props.product,
                addMod: props.product.modifiers
            };
        }

        return null;
    }
    upload() { document.getElementById("edit_product_selectImage").click() }
    fileSelectHandle(e) { this.setState({ selectedFile: e.target.files[0] }) }

    initModal() {
        this.setState({
            selectedFile: null
        })
    }
    addModifier() {
        var { addMod } = this.state;
        var id = uuidv1();
        addMod.push({ id });
        this.setState({ addMod: addMod });
    }
    deleteModifier(e, item) {
        var { addMod } = this.state;
        let index = addMod.indexOf(item);
        addMod.splice(index, 1);
        this.setState({ addMod: addMod });
    }
    handleChange(e, type) {
        let { product } = this.state;
        product[type] = e.target.value;
        this.setState({
            product: product
        });
    }
    handleModInputChange(e, item, type) {
        let mods = this.state.addMod;

        let index = mods.indexOf(item);
        mods[index][type] = e.target.value;

        this.setState({
            addMod: mods
        })
    }
    saveProduct() {
        let params = {
            name: this.state.product.name,
            price: this.state.product.price,
            description: this.state.product.description
        };
        if (this.state.selectedFile) {
            params.image = this.state.selectedFile;
        }
        this.props.menuActions.updateCategoryProduct(this.state.product.qr_id, this.state.product.category_id, this.state.product.id, params);
        this.props.menuActions.saveProductModifiers(this.state.product.qr_id, this.state.product.category_id, this.state.product.id, this.state.addMod);

        this.props.modalHide();
    }
    render() {
        return (
            <div>
                <Modal onEnter={this.initModal.bind(this)} show={this.props.show} onHide={() => this.props.modalHide()} className="d-none d-md-block" dialogClassName="menu-modal-container" aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Edit Product
                </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="edit-product">
                            <div className="input-field">
                                <div className="image-upload">
                                    <input id="edit_product_selectImage" type="file" hidden placeholder="Upload Product Picture" onChange={this.fileSelectHandle}></input>
                                    <span>{this.state.selectedFile && this.state.selectedFile.name !== null ? this.state.selectedFile.name : "Upload Product Picture"}</span>
                                    <div className="icon" onClick={this.upload}><GiOpenFolder size="2.5rem" /></div>
                                </div>
                                <div className="name-price">
                                    <div className="name">
                                        <input type="text" placeholder="Fluffy Pancakes" value={this.state.product.name} onChange={(e) => { this.handleChange(e, 'name') }} />
                                    </div>
                                    <div className="price">
                                        <input type="number" placeholder="$" value={this.state.product.price} onChange={(e) => { this.handleChange(e, 'price') }} />
                                    </div>
                                </div>
                                <div className="description">
                                    <textarea type="text" placeholder="description of product" value={this.state.product.description} onChange={(e) => { this.handleChange(e, 'description') }} />
                                </div>
                            </div>
                            <div className="image-preview">
                                <div className="image">
                                    {this.state.selectedFile !== null ?
                                        <img className="_image" src={URL.createObjectURL(this.state.selectedFile)} />
                                        :
                                        <p>Product image preview</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modifiers">
                            <div className="input-field">
                                <p>Modifiers</p>
                                {/* <div className="first">
                                <div className="name">
                                    <input type="text" placeholder="Extra Maple Syrup" />
                                </div>
                                <div className="price">
                                    <input type="number" placeholder="$"/>
                                </div>
                            </div>
                            <div className="first">
                                <div className="name">
                                    <input type="text" placeholder="Mixed Berries Topping" />
                                </div>
                                <div className="price">
                                    <input type="number" placeholder="$"/>
                                </div>
                            </div>
                            <div className="first">
                                <div className="name">
                                    <input type="text" placeholder="Vanilla Ice Cream" />
                                </div>
                                <div className="price">
                                    <input type="number" placeholder="$"/>
                                </div>
                            </div> */}
                                {
                                    this.state.addMod && this.state.addMod.map((item, index) => (
                                        <div className="first" key={index}>
                                            <div className="name">
                                                <input type="text" placeholder="Enter you want" value={item.name} onChange={(e) => { this.handleModInputChange(e, item, 'name') }} />
                                            </div>
                                            <div className="price">
                                                <input type="number" placeholder="$" value={item.price} onChange={(e) => { this.handleModInputChange(e, item, 'price') }} />
                                            </div>
                                            {index != 0 && <MdCancel className="cancel" size="1.4rem" onClick={(e) => this.deleteModifier(e, item)} />}
                                        </div>
                                    ))
                                }
                                <div className="add-btn">
                                    <Button onClick={this.addModifier}>+Add Modifier</Button>
                                </div>
                            </div>
                        </div>
                        <div className="save-btn">
                            <p className="goto" onClick={this.props.modalHide}><IoIosArrowBack size="1.3rem" />Return to Menu Without Saving</p>
                            <Button onClick={this.saveProduct.bind(this)}>SAVE</Button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal onEnter={this.initModal.bind(this)} show={this.props.show} onHide={() => this.props.modalHide()} className="d-block d-md-none" dialogClassName="mob-menu-modal-container" aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Edit Product
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="edit-product">
                            <div className="input-field">
                                <div className="image-upload">
                                    <input id="edit_product_selectImage" type="file" hidden placeholder="Upload Product Picture" onChange={this.fileSelectHandle}></input>
                                    <span>{this.state.selectedFile && this.state.selectedFile.name !== null ? this.state.selectedFile.name : "Upload Product Picture"}</span>
                                    <div className="icon" onClick={this.upload}><GiOpenFolder size="1rem" /></div>
                                </div>
                                <div className="name-price">
                                    <div className="name">
                                        <input type="text" placeholder="Fluffy Pancakes" value={this.state.product.name} onChange={(e) => { this.handleChange(e, 'name') }} />
                                    </div>
                                    <div className="price">
                                        <input type="number" placeholder="$" value={this.state.product.price} onChange={(e) => { this.handleChange(e, 'price') }} />
                                    </div>
                                </div>
                                <div className="description">
                                    <textarea type="text" placeholder="description of product" value={this.state.product.description} onChange={(e) => { this.handleChange(e, 'description') }} />
                                </div>
                            </div>
                        </div>
                        <div className="modifiers">
                            <div className="input-field">
                                <p>Modifiers</p>
                                {
                                    this.state.addMod && this.state.addMod.map((item, index) => (
                                        <div className="first" key={index}>
                                            <div className="name">
                                                <input type="text" placeholder="Enter you want" value={item.name} onChange={(e) => { this.handleModInputChange(e, item, 'name') }} />
                                            </div>
                                            <div className="price">
                                                <input type="number" placeholder="$" value={item.price} onChange={(e) => { this.handleModInputChange(e, item, 'price') }} />
                                            </div>
                                            {index != 0 && <MdCancel className="cancel" size="1.4rem" onClick={(e) => this.deleteModifier(e, item)} />}
                                        </div>
                                    ))
                                }
                                <div className="add-btn">
                                    <Button onClick={this.addModifier}>+Add Modifier</Button>
                                </div>
                            </div>
                        </div>
                        <div className="save-btn">
                            <Button onClick={this.props.modalHide}>Return to Menu Without Saving</Button>
                            <Button onClick={this.saveProduct.bind(this)}>SAVE</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default connect(
    null,
    dispatch => ({
        menuActions: bindActionCreators({
            updateCategoryProduct,
            saveProductModifiers
        }, dispatch)
    })
)(EditProductModal);