import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import React, { useState } from 'react';

import { addPartnerHipscan } from "../../../services/profile/profileActions";
import PartnerEventHipscan from "../../../components/Modals/PartnerEventHipscan";
import PartnerEventHipscanCount from "../../../components/Modals/PartnerEventHipscanCount";
import PartnerEventInfo from "../../../components/Modals/PartnerEventInfo";
import PartnerHipscan from "../../partnerHipscan";
import './LaunchAnEvent.css';


const LaunchAnEvent = (props) => {
  const [step, setStep] = useState(1)
  const [modalStatus, setModalStatus] = useState(true);
  const [eventInfo, setEventInfo] = useState({})
  const [hipscanCount, setHipscanCount] = useState(0)

  const handleSubmission = (hipScanObj) => {
    setModalStatus(false)
    setStep(null);
    props.profileActions.addPartnerHipscan(props.profile.id, { event: eventInfo, hipscan: hipScanObj })
  }

  const changeModalStatusHandler = flag => {
    setModalStatus(flag);
  }

  const setEventInfoHandler = eventInfo => {
    setEventInfo(eventInfo);
    setStep(2);
  }
  
  const setCountHandler = (count) => {
    setHipscanCount(count);
    setStep(3)
  }

  return (
    <div className="action-edit-menu">
      <Link
        to="#"
        className="goto"
        onClick={() => props.editUrl("none")}
      >
        <IoIosArrowBack size="1.3rem" /> Back
      </Link>
      <h1>Launch an Event</h1>

      <PartnerHipscan />

      {step == 1 && <PartnerEventInfo 
        modalStatus={modalStatus} 
        changeModalStatusHandler={changeModalStatusHandler} 
        setEventInfoHandler={setEventInfoHandler}
      />}

      {step == 2 && <PartnerEventHipscanCount 
        modalStatus={modalStatus} 
        changeModalStatusHandler={changeModalStatusHandler} 
        handleValueSet={setCountHandler}
      />}

      {step == 3 && <PartnerEventHipscan  
        modalStatus={modalStatus} 
        changeModalStatusHandler={changeModalStatusHandler} 
        count={hipscanCount}
        handleSubmission={handleSubmission}
      />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state.default.services.profile,
    ...state.default.services.auth,
  }
}

const mapDispatchToProps = (dispatch) => ({
  profileActions: bindActionCreators(
    { addPartnerHipscan },
    dispatch
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(LaunchAnEvent);
