import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { GiCancel, GiOpenFolder } from "react-icons/gi";

const PartnerEventHipscan = props => {
  const [hipScanObj, sethipScanObj] = useState([])
  const [logoIndex, setLogoIndex] = useState(null)

  useEffect(() => {
    let obj = [];
    for (let i = 0; i < props.count; i++) {
      obj.push({name: '', logo: null})
    }
    sethipScanObj(obj);
  }, [])

  const handleNameChange = (e, i) => {
    var tempObj = hipScanObj.slice();
    tempObj[i].name = e.target.value;
    sethipScanObj(tempObj);
  }

  const uploadLogo = (i) => {
    setLogoIndex(i)
    document.getElementById('selectPartnerLogo').click();
  }

  const handleLogoSelect = (e) => {
    let tempPartnerObj = hipScanObj.slice();
    tempPartnerObj[logoIndex].logo = e.target.files[0];
    sethipScanObj(tempPartnerObj);
  }

  const cancelUpload = (i) => {
    var newValues = hipScanObj.slice();
    newValues[i].logo = null;
    sethipScanObj(newValues);
  }

  return <Modal
    show={props.modalStatus}
    onHide={() => props.changeModalStatusHandler(false)}
    dialogClassName='launch-event-modal'
    className='launch-event-hipscan-modal d-none d-md-block'
    aria-labelledby='example-custom-modal-styling-title'
  >
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body className='launch-event-hipscan-modal-body'>
        <h1>Setup Each Hipscan</h1>
        <p>Input the partner name and upload their logo</p>

        {hipScanObj.map((item, index) => <div key={index.toString()} className='d-flex'>
          <div className='hipscan-input'>
            <h5 className='partner-hipscan-index'>HipScan {index+1}</h5>

            <div className='input-field1 d-flex'>
              <div className='common-input'>
                <input
                  type='string'
                  placeholder='Name of Hipscan'
                  value={item.name}
                  onChange={e => handleNameChange(e, index)}
                ></input>
              </div>

              <div className='file-upload'>
                <input
                  id='selectPartnerLogo'
                  type='file'
                  hidden
                  placeholder='Custom Image Type'
                  onChange={(e) => handleLogoSelect(e)}
                ></input>
                <span>
                  {item.logo?.name? item.logo.name:'Upload Logo'}
                </span>
                <div className='icon left' onClick={() => uploadLogo(index)}>
                  <GiOpenFolder size='2.5rem' />
                </div>
                <div className='icon' onClick={() => cancelUpload(index)}>
                  <GiCancel size='2.5rem' />
                </div>
              </div>
            </div>
          </div>
        </div>)}
        <div className='save-continue-btn'>
          <Button onClick={() => props.handleSubmission(hipScanObj)}>SAVE AND EXIT</Button>
        </div>
    </Modal.Body>
  </Modal>
};

export default PartnerEventHipscan;