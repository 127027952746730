import React from "react";
import { connect } from "react-redux";
import "./ChangePasswordModal.css";
import { Modal, Button } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { changePassword, getProfile } from "../../services/auth/authActions";
import { message } from "antd";

class ChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password_new: "",
      password_new_confirm: "",
      errorMessage: ""
    };
  }

  onChangeValue = (field, value) => {
    let statePW = this.state;
    statePW[field] = value;
    this.setState({ statePW, errorMessage: "" });
  };

  componentWillReceiveProps(prevProps) {
    if (prevProps.error) {
      this.setState({ errorMessage: prevProps.message });
    }
    if (
      prevProps.updatedPW !== this.props.updatedPW &&
      prevProps.updatedPW === true
    ) {
      message.success("Updated password!");
      this.props.authActions.getProfile();
      this.props.modalHide();
    }
  }

  onSubmit = () => {
    const datas = {
      password_new: this.state.password_new,
      password_new_confirm: this.state.password_new_confirm
    };
    this.props.authActions.changePassword(datas);
  };

  render() {
    const width = window.innerWidth;
    const { password_new, password_new_confirm, errorMessage } = this.state;
    return (
      <div>
        {width > 767 ? (
          <Modal
            show={this.props.show}
            onHide={() => this.props.modalHide()}
            dialogClassName="change-password-modal"
            className="d-none d-md-block"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Change Password
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="body-container">
                {errorMessage && errorMessage.length > 0 && (
                  <div
                    className="alert alert-danger"
                    style={{ width: "80%" }}
                    role="alert"
                  >
                    {errorMessage}
                  </div>
                )}
                <div className="input-field">
                  <div className="common-input">
                    <input
                      type="password"
                      placeholder="New Password"
                      value={password_new}
                      onChange={e =>
                        this.onChangeValue("password_new", e.target.value)
                      }
                    ></input>
                  </div>
                  <div className="common-input">
                    <input
                      type="password"
                      placeholder="Confirm New Password"
                      onChange={e =>
                        this.onChangeValue(
                          "password_new_confirm",
                          e.target.value
                        )
                      }
                      value={password_new_confirm}
                    ></input>
                  </div>
                </div>
                <div className="change-bottom">
                  <p onClick={() => this.props.modalHide()}>
                    {"< Return Without Saving"}{" "}
                  </p>
                  <Button className="change-btn" onClick={this.onSubmit}>
                    CHANGE PASSWORD
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <Modal
            show={this.props.show}
            onHide={() => this.props.modalHide()}
            dialogClassName="mob-change-password-modal"
            className="d-block d-md-none"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Change Password
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="body-container">
                {errorMessage && errorMessage.length > 0 && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                <div className="input-field">
                  <div className="common-input">
                    <input
                      type="password"
                      placeholder="New Password"
                      value={password_new}
                      onChange={e =>
                        this.onChangeValue("password_new", e.target.value)
                      }
                    ></input>
                  </div>
                  <div className="common-input">
                    <input
                      type="password"
                      placeholder="Confirm New Password"
                      onChange={e =>
                        this.onChangeValue(
                          "password_new_confirm",
                          e.target.value
                        )
                      }
                      value={password_new_confirm}
                    ></input>
                  </div>
                </div>
                <div className="change-bottom">
                  <Button className="change-btn" onClick={this.onSubmit}>
                    CHANGE PASSWORD
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    ...state.default.services.auth
  }),
  dispatch => ({
    authActions: bindActionCreators({ changePassword, getProfile }, dispatch)
  })
)(ChangePasswordModal);
