import React from 'react';
import {
	BrowserRouter as Router,
	Switch
} from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

//Import Scenes
import Home from './Home/Home';
import Signup from './Signup/Signup';
import Login from './Login/Login';
import Social from './Login/Social'
import MyHipscans from './MyHipscans/MyHipscans';
import DetailMyHipScan from './MyHipscans/DetailMyHipScan/DetailMyHipScan';
import EditHaciendaLocation from './MyHipscans/EditHaciendaLocation/EditHaciendaLocation';
import Dashboard from './Dashboard/Dashboard';
import LaunchUrl from './LaunchUrl/LaunchUrl';
import LaunchProfile from './LaunchProfile/LaunchProfile';
import DisplayText from './DisplayText/DisplayText';
import DisplayImage from './DisplayImage/DisplayImage';
import LaunchVideo from './LaunchVideo/LaunchVideo';
import LaunchMenu from './LaunchMenu/LaunchMenu';
import LaunchEvent from './LaunchEvent/LaunchEvent';
import LaunchProduct from './LaunchProduct/LaunchProduct';
import Plans from './Plans/Plan'
import TOS from './TOS/TOS';
import Profile from './Profile/Profile';

//Import Phone screen
import HomePhone from '../phone/HomePhone/HomePhone';
import ProfilePhoneScreen from '../phone/ProfilePhoneScreen/ProfilePhoneScreen';
import ProductPhoneScreen from '../phone/ProductPhoneScreen/ProductPhoneScreen';
import MenuCategoryPhoneScreen from '../phone/MenuCategoryPhoneScreen/MenuCategoryPhoneScreen';
import DetailedCategoryPhoneScreen from '../phone/DetailedCategoryPhoneScreen/DetailedCategoryPhonescreen';
import DetailedFoodPhoneScreen from '../phone/DetailedFoodPhoneScreen/DetailedFoodPhoneScreen';
import PhoneScreen from '../phone/PhoneScreen/PhoneScreen';
import FollowUpPhoneScreen from './FollowUp';

import ModalConductor from '../modals/ModalConductor';

import PrivateRoute from '../components/Routes/PrivateRoute'
import PublicRoute from '../components/Routes/PublicRoute'
import AdminPanel from './Admin';
import QRNotFound from './QRNotFound';

import PlansNotifications from './PlansNotifications'
import Hipscan from './Hipscan/Hipscan';

class App extends React.Component {
	render() {
		return (

			<main>
				<Router>
					<Switch>
						<PrivateRoute component={MyHipscans} path="/myhipscans" exact />
            <PrivateRoute component={Hipscan} path="/hipscan" />
						<PrivateRoute component={EditHaciendaLocation} path="/myhipscans/edit-hacienda-location" />
						<PrivateRoute component={DetailMyHipScan} path="/myhipscans/detail/:id" />
						<PrivateRoute component={Dashboard} path="/dashboard" />
						<PrivateRoute component={LaunchUrl} path="/launch-url" />
						<PrivateRoute component={LaunchProfile} path="/launch-profile" />
						<PrivateRoute component={DisplayText} path="/display-text" />
						<PrivateRoute component={DisplayImage} path="/display-image" />
						<PrivateRoute component={LaunchVideo} path="/launch-video" />
						<PrivateRoute component={LaunchMenu} path="/launch-menu" />
            <PrivateRoute component={LaunchEvent} path="/launch-event" />
						<PrivateRoute component={LaunchProduct} path="/launch-product" />
						<PrivateRoute component={HomePhone} path="/home-phone" />
						<PrivateRoute component={ProfilePhoneScreen} path="/profile-phone" />
						<PrivateRoute component={ProductPhoneScreen} path="/product-phone" />
						<PrivateRoute component={MenuCategoryPhoneScreen} path="/category-phone" />
						<PrivateRoute component={DetailedCategoryPhoneScreen} path="/detailed-category-phone" />
						<PrivateRoute component={DetailedFoodPhoneScreen} path="/detailed-food-phone" />

						<PrivateRoute component={Profile} path="/profile" />

						<PublicRoute component={PlansNotifications} path="/plans/:role/:type/:status" />
						<PublicRoute component={Plans} path="/plans" />

						<PublicRoute component={Home} path="/" exact />
						<PublicRoute component={Social} path='/accept' />
						<PublicRoute component={Signup} path="/signup" />
						<PublicRoute component={Login} path="/login" />
						<PublicRoute component={PhoneScreen} path="/show/:uuid" />
						<PublicRoute component={TOS} path="/tos" exact />

						<PublicRoute component={AdminPanel} path="/admin" exact />

						<PublicRoute component={QRNotFound} path="/not-found" exact />
						<PublicRoute component={FollowUpPhoneScreen} path="/follow-up/:uuid" exact />
						<PublicRoute  path="/linkedin" exact component={LinkedInCallback} />

					</Switch>
				</Router>
				<ReduxToastr position="top-right" />
				<ModalConductor />
			</main>
		)
	}
}

export default App;
