import { message } from 'antd';
import { handleActions } from 'redux-actions';

import {
    login,
    loginFailed,
    loginSucceed,
    socialLogin,
    socialLoginFailed,
    socialLoginSucced,
    logout,
    register,
    registerFailed,
    registerSucceed,
    getProfile,
    getProfileSucceed,
    getProfileFailed,
    updateProfile,
    updateProfileSucceed,
    updateProfileFailed,
    changePassword,
    changePasswordSucceed,
    changePasswordFailed,
} from './authActions';

import {
    storeCurrentToken,
    storeRegisterToken,
    removeRegisterToken,
    getStoredToken,
    removeCurrentToken,
    removeCurrentUser,
    storeUserName,
    storeUserInfo,
    storeMainId
} from './services';

const defaultState = {
    user_id: null,
    error: null,
    loading: null,
    message: '',
    success: false,
    token: null,
    profile: null,
    updatedPW: false,
    updatedPF: false,
}

const reducer = handleActions(
    {
        [login](state) {
            return {
                ...state,
                error: null,
                loading: true,
                // token: getStoredToken(),

            };
        },
        [loginFailed](state, { payload: { error } }) {
            return {
                ...state,
                error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
                loading: false,
                success: false,
                token: null,
            }
        },
        [loginSucceed](state, { payload: { token } }) {
            storeCurrentToken(token);
            removeRegisterToken();
            return {
                ...state,
                token,
                error: null,
                loading: false,
                success: true,
                message: 'Successfully, Logged in'
            }
        },
        [socialLogin](state) {
            return {
                ...state,
                error: null,
                loading: true,
            };
        },
        [socialLoginFailed](state, { payload: { error } }) {
            return {
                ...state,
                error: error && error.data && error.data.Message ? error.data.Message : 'Error: Social Login Failed!',
                loading: false,
                success: false,
                token: null,
            }
        },
        [socialLoginSucced](state, { payload: { token } }) {
            storeCurrentToken(token);
            return {
                ...state,
                token,
                error: null,
                loading: false,
                success: true,
                message: 'Successfully, Logged in'
            }
        },
        [logout](state) {
            removeCurrentToken();
            removeCurrentUser();
            return {
                ...state,
            }
        },
        [register](state) {
            return {
                ...state,
                error: null,
                loading: true,
            }
        },
        [registerFailed](state, { payload: { error } }) {
            if (error.status === 500) {
                return {
                    ...state,
                    // error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
                    error: true,
                    loading: false,
                    success: false,
                    message: "You seem to  have a Hipscan account already. Login instead"
                }
            } else {
                return {
                    ...state,
                    // error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
                    error,
                    loading: false,
                    success: false,
                }
            }
        },
        [registerSucceed](state, { payload: { user_id } }) {
            const { token } = user_id
            const t = { token: token }
            storeRegisterToken(t);
            return {
                ...state,
                error: null,
                token,
                loading: false,
                user_id,
                success: true,
                message: 'We have sent verification email to your inbox. Please follow the link in order to activate the account'
            }
        },
        [getProfile](state) {
            return {
                ...state,
                error: null,
                loading: true
            }
        },
        [getProfileFailed](state, { payload: { error } }) {

          console.log('error: ', error)
            if (error?.status === 403) {
                return {
                    ...state,
                    error: true,
                    loading: false,
                    message: "The account is not activated. Please activate your account in the mail."
                }
            } else
                return {
                    ...state,
                    error: true,
                    loading: false,
                    message: error.data.Message
                }
        },
        [getProfileSucceed](state, { payload: { profile } }) {
            storeUserName(profile.username);
            storeUserInfo(JSON.stringify(profile));
            storeMainId(profile.code.id);
            return {
                ...state,
                loading: false,
                profile,
                updatedPW: false,
                updatedPF: false,
            }
        },
        [updateProfile](state, { payload: { profile } }) {
            return {
                ...state,
                error: null,
                loading: true,
                updatedPW: false,
            }
        },
        [updateProfileFailed](state, { payload: { error } }) {
            message.error("Update your account failed")
            return {
                ...state,
                error: true,
                loading: false,
                message: " Failed",
                updatedPF: false,
            }
        },
        [updateProfileSucceed](state, { payload: { profile } }) {
            storeUserName(profile.username);
            storeUserInfo(JSON.stringify(profile));
            storeMainId(profile.code.id);
            message.success("Your account was updated successfully.")
            return {
                ...state,
                loading: false,
                profile,
                updatedPF: true,
            }
        },
        [changePassword](state) {
            return {
                ...state,
                error: null,
                loading: true
            }
        },
        [changePasswordFailed](state, { payload: { error } }) {
            return {
                ...state,
                error: true,
                loading: false,
                message: error && error.data ? error.data.Message : " Failed"
            }
        },
        [changePasswordSucceed](state, { payload: { data } }) {
            if(data.status === 202){
                return {
                    ...state,
                    loading: false,
                    updatedPW: true,
                }
            } else {
                return {
                    ...state,
                    loading: false,
                    data,
                    updatedPW: false,
                }
            }

        },
    }, defaultState
);

export default reducer;
