import { wrapRequest, xapi } from '../utils';

const getHipscansAdminStats = wrapRequest(async () =>
    xapi().get('/admin/stats')
)

const getHipscansAdminUsers = wrapRequest(async () =>
    xapi().get('/admin/users')
)

const postHipscansAdminAutoLogin = wrapRequest(async (user_id) => {
    xapi().post('/admin/autologin', { user_id })
}
)

const getHipscansPlanProMonthly = wrapRequest(async () =>
    xapi().get('/payment/pro/monthly')
)

const getHipscansPlanProYearly = wrapRequest(async () =>
    xapi().get('/payment/pro/annually')
)

const getHipscansPlanPremiumMonthly = wrapRequest(async () =>
    xapi().get('/payment/premium/monthly')
)

const getHipscansPlanPremiumYearly = wrapRequest(async () =>
    xapi().get('/payment/premium/annually')
)

export {
    getHipscansAdminStats,

    getHipscansAdminUsers,

    postHipscansAdminAutoLogin,

    getHipscansPlanProMonthly,
    getHipscansPlanProYearly,
    getHipscansPlanPremiumMonthly,
    getHipscansPlanPremiumYearly,
}