import React from 'react';
import { FaQrcode, FaUser, FaRegClock, FaRegCalendar, FaSearch } from 'react-icons/fa';
import './Statistics.css';
import CanvasJSReact from '../../assets/canvas/canvasjs.react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getHipscanStats, getHipscanTotalStats, getHipscanFilter } from '../../services/profile/profileActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'moment'

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class Statistics extends React.Component {

    constructor() {
        super();
        this.state = {
            startDate: "",
            endDate: "",
            selectedDataType: 'day',
            scans: 0,
            unique_users: 0,
            scans_48_h: 0,
            filter: {}
        };

        this.onChangeDateType = this.onChangeDateType.bind(this);
    }

    formatDate(date) {
        if (date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }

        return ''
    }

    componentWillMount() {
        if (this.props.id) {
            this.props.profileActions.getHipscanStats(this.props.id);
            this.props.profileActions.getHipscanFilter(this.props.id, this.state.startDate, this.state.endDate, this.state.selectedDataType)
        } else {
            this.props.profileActions.getHipscanTotalStats();
            this.props.profileActions.getHipscanFilter('', this.state.startDate, this.state.endDate, this.state.selectedDataType)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.hipscanStats != this.props.hipscanStats) {
            if (this.props.hipscanStats.scans !== null) {
                this.setState({ scans: this.props.hipscanStats.scans });
                this.setState({ unique_users: this.props.hipscanStats.unique_users });
                this.setState({ scans_48_h: this.props.hipscanStats.scans_48_h });
            }
        }
        if (prevProps.hipscanTotalStats != this.props.hipscanTotalStats) {
            if (this.props.hipscanTotalStats.scans !== null) {
                this.setState({ scans: this.props.hipscanTotalStats.scans });
                this.setState({ unique_users: this.props.hipscanTotalStats.unique_users });
                this.setState({ scans_48_h: this.props.hipscanTotalStats.scans_48_h });
            }
        }
        if (prevProps.hipscanFilter != this.props.hipscanFilter) {
            this.setState({ filter: this.props.hipscanFilter })
        }

        if (prevState.startDate != this.state.startDate ||
            prevState.endDate != this.state.endDate ||
            prevState.selectedDataType != this.state.selectedDataType) {
            if (this.props.id) {
                this.props.profileActions.getHipscanFilter(this.props.id, this.formatDate(this.state.startDate), this.formatDate(this.state.endDate), this.state.selectedDataType)
            } else {
                this.props.profileActions.getHipscanFilter('', this.formatDate(this.state.startDate), this.formatDate(this.state.endDate), this.state.selectedDataType)
            }
        }
    }

    startDateHandleChange = date => {
        this.setState({
            startDate: date
        });
    };
    endDateHandleChange = date => {
        this.setState({
            endDate: date
        });
    };

    onChangeDateType = type => {
        this.setState({
            selectedDataType: type
        });
    };

    render() {
        let dataPoints = []
        let hasPoints = false
        for (let key in this.state.filter) {
            let val = this.state.filter[key]
            let date = key
            if (this.state.selectedDataType == 'week') {
                date = Moment().year(2020).week(26).day('Sunday').format('YYYY-MM-DD')
                date += ' ~ '
                date += Moment().year(2020).week(26).day('Saturday').format('DD')
            }
            dataPoints.push({ label: date, y: val })
            hasPoints = true
        }
        const options = {
            animationEnabled: true,
            theme: "light2",
            data: [{
                type: "area",
                color: "#f36e21",
                dataPoints: dataPoints
                // dataPoints: [
                // 	{ label: "SEPT 2019", y: 3500 },
                // 	{ label: "OCT 2019", y: 1600 },
                // 	{ label: "NOV 2019", y: 2500 },
                // 	{ label: "DEC 2019", y: 3600 },
                // 	{ label: "JAN 2020", y: 5500 },
                // 	{ label: "FEB 2020", y: 7000 },
                // 	{ label: "MAR 2020", y: 1400 },
                // 	{ label: "APR 2020", y: 5200 },
                // 	{ label: "MAY 2020", y: 5000 },
                // 	{ label: "JUN 2020", y: 2800 },
                // ]
            }]

        }
        return (
            <div>
                <div className="statistics-container d-none d-md-block">
                    <h1>Statistics</h1>
                    <div className="value-part">
                        <div className="sub">
                            <FaQrcode size="2.8em" />
                            <h1>{this.state.scans}</h1>
                            <p>SCANS</p>
                        </div>
                        <div className="sub">
                            <FaUser size="2.8em" />
                            <h1>{this.state.unique_users}</h1>
                            <p>UNIQUE USERS</p>
                        </div>
                        <div className="sub">
                            <FaRegClock size="2.8em" />
                            <h1>{this.state.scans_48_h}</h1>
                            <p>SCANS OVER LAST<br /> 48 HOURS</p>
                        </div>
                        {/* <div className="scans">
                            <div className="phones">
                                <p>iPhone</p>
                                <p>Android</p>
                                <p>Blackberry</p>
                                <p>Windows Phone</p>
                            </div>
                            <div className="diagram">
                                <div className="iphone-value">
                                    <p>50,325</p><div className="bar"></div><span>51%</span>
                                </div>
                                <div className="android-value">
                                    <p>36,098</p><div className="bar"></div><span>31%</span>
                                </div>
                                <div className="black-value">
                                    <p>10,988</p><div className="bar"></div><span>12%</span>
                                </div>
                                <div className="winphone-value">
                                    <p>5,800</p><div className="bar"></div><span>5%</span>
                                </div>
                         </div>
                        </div> */}
                    </div>
                    <div className="filter">
                        <div className="during">
                            <span className={"during-item day " + (this.state.selectedDataType === 'day' ? 'active' : '')} onClick={() => this.onChangeDateType('day')}>DAY</span>
                            <span className={"during-item week " + (this.state.selectedDataType === 'week' ? 'active' : '')} onClick={() => this.onChangeDateType('week')}>WEEK</span>
                            <span className={"during-item month " + (this.state.selectedDataType === 'month' ? 'active' : '')} onClick={() => this.onChangeDateType('month')}>MONTH</span>
                        </div>
                        <div className="firstdate">
                            <DatePicker
                                placeholderText="START DATE"
                                selected={this.state.startDate}
                                onChange={this.startDateHandleChange}
                                dateFormat="d MMMM yyyy"
                            />
                            <FaRegCalendar className="cal" />
                        </div>
                        <div className="enddate">
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={this.endDateHandleChange}
                                placeholderText="END DATE"
                                dateFormat="d MMMM yyyy"
                            />
                            <FaRegCalendar className="cal" />
                        </div>
                        <div className="search">
                            <div className="search-input-wrapper">
                                {/* <input className="search-input" type="text" ></input>
                                <FaSearch className="search-icon"/> */}
                            </div>
                        </div>
                    </div>
                    <div className="chart-part" style={{ position: 'relative' }}>
                        {/* <CanvasJSChart options={options} /> */}
                        {
                            hasPoints ? <CanvasJSChart options={options} /> :
                                <p style={{ textAlign: 'center' }}>There are no stats yet, but don’t worry as soon as you start sharing your Hipscan QR you will see the data populate here.</p>
                        }
                    </div>
                </div>
                <div className="mob-statistics-container d-block d-md-none">
                    <h1>Statistics</h1>
                    <div className="row value-part">
                        <div className="col-4">
                            <div className="sub">
                                <FaQrcode size="1.5rem" />
                                <h1>{this.state.scans}</h1>
                                <p>SCANS</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="sub">
                                <FaUser size="1.5rem" />
                                <h1>{this.state.unique_users}</h1>
                                <p>UNIQUE USERS</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="sub">
                                <FaRegClock size="1.5rem" />
                                <h1>{this.state.scans_48_h}</h1>
                                <p>SCANS OVER LAST<br /> 48 HOURS</p>
                            </div>
                        </div>
                    </div>
                    <div className="row filter">
                        <div className="col-12 during">
                            <span className={"during-item day " + (this.state.selectedDataType === 'day' ? 'active' : '')} onClick={() => this.onChangeDateType('day')}>DAY</span>
                            <span className={"during-item week " + (this.state.selectedDataType === 'week' ? 'active' : '')} onClick={() => this.onChangeDateType('week')}>WEEK</span>
                            <span className={"during-item month " + (this.state.selectedDataType === 'month' ? 'active' : '')} onClick={() => this.onChangeDateType('month')}>MONTH</span>
                        </div>
                        <div className="col-12 firstdate">
                            <DatePicker
                                placeholderText="START DATE"
                                selected={this.state.startDate}
                                onChange={this.startDateHandleChange}
                                dateFormat="d MMMM yyyy"
                            />
                            <FaRegCalendar className="cal" />
                        </div>
                        <div className="col-12 enddate">
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={this.endDateHandleChange}
                                placeholderText="END DATE"
                                dateFormat="d MMMM yyyy"
                            />
                            <FaRegCalendar className="cal" />
                        </div>
                    </div>
                    <div className="chart-part" style={{ position: 'relative' }}>
                        {/* <CanvasJSChart options={options} /> */}
                        {
                            hasPoints ? <CanvasJSChart options={options} /> :
                                <p style={{ textAlign: 'center' }}>There are no stats yet, but don’t worry as soon as you start sharing your Hipscan QR you will see the data populate here.</p>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        ...state.default.services.profile
    }),
    dispatch => ({
        profileActions: bindActionCreators({ getHipscanStats, getHipscanTotalStats, getHipscanFilter }, dispatch)
    })
)(Statistics);