import React from 'react'
import vCard from 'vcards-js'
import {
  FaDribbble,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaRegPlayCircle,
  FaComment,
  FaPhoneAlt,
} from 'react-icons/fa'
import ReactPlayer from 'react-player'
import { Modal, Box } from '@material-ui/core'

import { PDFDownloadLink } from '@react-pdf/renderer'

import './ProfilePhone.css'

import Handshake from '../../assets/images/handshake.jpg'
import Profile from '../../assets/images/profile.jpg'
import logo from '../../assets/images/logo.svg'
import { red } from 'color-name'
import PdfDownload from './PdfDownload'
import { deviceDetect, osName } from 'react-device-detect'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Button } from 'react-bootstrap'

const style = {
  position: 'absolute',
  left: '5%',
  right: '5%',
  bottom: 20,
  // transform: 'translate(-50%, -50%)',
  width: '90%',
  // bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 5,
  // p: 4,
}

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#383838',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#383838',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#383838',
      },
    },
  },
})(TextField)

class ProfilePhone extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isIncludedVideo:
        this.props.c_videoURL && this.props.c_videoURL !== 'undefined'
          ? true
          : false,
      openModel: false,
      emailSubject: '',
      emailBody: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.c_videoURL !== this.props.c_videoURL) {
      this.setState({
        isIncludedVideo:
          this.props.c_videoURL && this.props.c_videoURL !== 'undefined'
            ? true
            : false,
      })
    }

    if (prevProps.isIncludedVideo !== this.props.isIncludedVideo) {
      this.setState({
        isIncludedVideo: this.props.isIncludedVideo,
      })
    }
  }

  emailDraft = () => {
    window.open(
      `mailto:${this.props.c_email}?subject=${this.props.email_subject}&body=${this.props.email_body}`
    )
  }

  handleFacebookDraft = (fb) => {
    window.open(`fb://facewebmodal/f?href=${fb}`)
  }

  vcfFileGenerator = () => {
    const vcf = vCard()

    vcf.firstName = this.props.name
    vcf.email = this.props.c_email
    vcf.workPhone = this.props.c_number

    const link = document.createElement('a')

    link.setAttribute('href', `data:,${vcf.getFormattedString()}`)
    link.setAttribute('download', 'contact.vcf')
    link.style.display = 'none'

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
  }

  render() {
    return (
      <div className='profile-preview'>
        <Modal
          hideBackdrop={true}
          open={this.state.openModel}
          onClose={() => this.setState({ openModel: false })}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            className='d-flex justify-content-center align-items-center flex-column'
            sx={style}
          >
            <div
              style={{
                backgroundColor: '#ffffff',
                width: '100%',
                borderRadius: 7,
                padding: 4,
              }}
              className='contact-info-btn d-flex flex-row justify-content-center align-items-center'
            >
              <FaPhoneAlt
                size='16px'
                color='grey'
                style={{ marginRight: 10 }}
              />
              <p
                onClick={() => window.open(`tel:${this.props.c_number}`)}
                style={{
                  color: '#00a9ff',
                  cursor: 'pointer',
                  fontSize: 15,
                  fontWeight: '500',
                  margin: 5,
                }}
              >
                Call {this.props.c_number}
              </p>
            </div>
            <div
              style={{
                backgroundColor: '#ffffff',
                width: '100%',
                borderRadius: 7,
                padding: 4,
                marginTop: 5,
              }}
              className='contact-info-btn d-flex flex-row justify-content-center align-items-center'
            >
              <FaComment size='16px' color='grey' style={{ marginRight: 10 }} />
              <p
                onClick={() => window.open(`sms:(${this.props.c_number})`)}
                style={{
                  color: '#00a9ff',
                  cursor: 'pointer',
                  fontSize: 15,
                  fontWeight: '500',
                  margin: 5,
                }}
              >
                Message {this.props.c_number}
              </p>
            </div>
            <div
              style={{
                backgroundColor: '#ffffff',
                width: '100%',
                borderRadius: 7,
                marginTop: 5,
              }}
              className='d-flex justify-content-center align-items-center text-center'
            >
              <p
                onClick={() => this.setState({ openModel: false })}
                style={{
                  color: '#00a9ff',
                  cursor: 'pointer',
                  fontSize: 15,
                  fontWeight: '500',
                  margin: 5,
                }}
              >
                Cancel
              </p>
            </div>
          </Box>
        </Modal>
        <div className='profile'>
          {/* {
              this.props.bFile && this.props.bFile !== '' ?
              // <img src={ URL.createObjectURL(this.props.bFile)} className='background' alt='background'/> : <img src={Handshake} className='background' alt='background'/>
              <img src={this.props.bFile} className='background' alt='background' /> : <img src={Handshake} className='background' alt='background' />
          } */}
          <div className='profile-photo'>
            {this.props.file && this.props.file !== '' ? (
              // <img src={ URL.createObjectURL(this.props.file)} alt='profile'/>
              <img
                style={{ border: `2px solid ${this.props.color}` }}
                src={this.props.file}
                alt='profile'
              />
            ) : (
              <img
                style={{ border: `2px solid ${this.props.color}` }}
                src={Profile}
                alt='profile'
              />
            )}
          </div>
          <div className='display-name-pre'>
            <p className='name'>{this.props.name}</p>
            <p className='title-pre'>{this.props.title}</p>
          </div>
          <div className='display-name-pre'>
            <p style={{ cursor: 'pointer' }} onClick={this.emailDraft}>
              {this.props.c_email}
            </p>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ openModel: true })}
            >
              {this.props.c_number}
            </p>
          </div>
          <div
            className={
              this.state.isIncludedVideo
                ? 'description short'
                : 'description long'
            }
          >
            <p style={{ color: this.props.color }}>{this.props.description}</p>
          </div>
          <div className='action-buttons d-flex flex-column justify-content-center align-items-center'>
            {/* {
              <button
                style={{ backgroundColor: this.props.color }}
                className='btn action-btns'
                onClick={this.vcfFileGenerator}
              >
                Save Contact
              </button>
            } */}
            {this.props.meeting_link ? (
              <a
                href={this.props.meeting_link}
                style={{ backgroundColor: this.props.color }}
                target='_blank'
                className='btn action-btns'
              >
                Book a Meeting
              </a>
            ) : null}
            {
              <button
                style={{ backgroundColor: this.props.color }}
                target='_blank'
                className='btn action-btns'
                onClick={this.emailDraft}
              >
                Send Me A Note
              </button>
            }
          </div>
          <div className='social-icons'>
            {this.props.c_website && this.props.c_website !== 'undefined' ? (
              <a target='_blank' href={this.props.c_website}>
                <FaDribbble
                  className='website'
                  size='1.4rem'
                  style={{
                    background:
                      this.props.c_website !== ''
                        ? this.props.color
                        : '#efefef',
                  }}
                />
              </a>
            ) : null}
            {this.props.c_facebook && this.props.c_facebook !== 'undefined' ? (
              <a
                target='_blank'
                onClick={() => this.handleFacebookDraft(this.props.c_facebook)}
              >
                <FaFacebookF
                  className='facebook'
                  size='1.4rem'
                  style={{
                    background:
                      this.props.c_facebook !== ''
                        ? this.props.color
                        : '#efefef',
                  }}
                />
              </a>
            ) : null}
            {this.props.c_twitter && this.props.c_twitter !== 'undefined' ? (
              <a target='_blank' href={this.props.c_twitter}>
                <FaTwitter
                  className='twitter'
                  size='1.4rem'
                  style={{
                    background:
                      this.props.c_twitter !== ''
                        ? this.props.color
                        : '#efefef',
                  }}
                />
              </a>
            ) : null}
            {this.props.c_instagram &&
              this.props.c_instagram !== 'undefined' ? (
              <a target='_blank' href={this.props.c_instagram}>
                <FaInstagram
                  className='instagram'
                  size='1.4rem'
                  style={{
                    background:
                      this.props.c_instagram !== ''
                        ? this.props.color
                        : '#efefef',
                  }}
                />
              </a>
            ) : null}
          </div>

          {/* <div className='video-screen'>
                        {this.props.c_videoURL!=='' ?
                        <ReactPlayer url={this.props.c_videoURL} playing width='100%' height='100%'/>
                        :
                        <div className='play'>
                            <FaRegPlayCircle className='icon' size='1.2rem'/>
                        </div>
                        }
                    </div> */}
          {this.state.isIncludedVideo ? (
            <div className='video-screen'>
              {this.props.c_videoURL !== '' ? (
                <ReactPlayer
                  url={this.props.c_videoURL}
                  playing
                  width='100%'
                  height='100%'
                />
              ) : (
                <div className='play'>
                  <FaRegPlayCircle className='icon' size='1.2rem' />
                </div>
              )}
            </div>
          ) : null}
          <div className='phone-footer'>
            <p>POWERED BY</p>
            <img src={logo} alt='logo' />
          </div>
        </div>
      </div>
    )
  }
}

export default ProfilePhone
