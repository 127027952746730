import React from "react";
import { connect } from "react-redux";
import "./RequireEmailModal.css";
import { Modal, Button } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { changePassword, getProfile, updateProfile } from "../../services/auth/authActions";
import { message } from "antd";

class RequireEmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email_new: "",
      errorMessage: ""
    };
  }

  onChangeValue = (field, value) => {
    let statePW = this.state;
    statePW[field] = value;
    this.setState({ statePW, errorMessage: "" });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.updatedPF !== nextProps.updatedPF) {
      this.props.authActions.getProfile();
      this.props.modalHide();
    }
  }

  componentDidMount() {
    this.props.authActions.getProfile();
  }

  onSubmit = async () => {
    const datas = {
      email: this.state.email_new,
      firstname: this.props.profile.firstname,
      lastname: this.props.profile.lastname,
      username: this.props.profile.username
    };
    await this.props.authActions.updateProfile(datas);
  };

  render() {
    const width = window.innerWidth;
    const { email_new, email_new_confirm, errorMessage } = this.state;
    return (
      <div>
        {width > 767 ? (
          <Modal
            show={this.props.show}
            onHide={() => this.props.modalHide()}
            dialogClassName="change-email-modal"
            className="d-none d-md-block"
            aria-labelledby="example-custom-modal-styling-title"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title id="example-custom-modal-styling-title">
                Email Required
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="body-container">
                {errorMessage && errorMessage.length > 0 && (
                  <div
                    className="alert alert-danger"
                    style={{ width: "80%" }}
                    role="alert"
                  >
                    {errorMessage}
                  </div>
                )}
                <p style={{textAlign: "center"}}>
                  Your Facebook account does not provide us your email.
                  In order to subscribe to our services, we need to have your email. <br></br> <br></br>
                  Please input your email below to continue.
                </p>
                <div className="input-field">
                  <div className="common-input-email">
                    <input
                      type="email"
                      placeholder="Email address"
                      value={email_new}
                      onChange={e =>
                        this.onChangeValue("email_new", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="change-bottom-email">
                  <Button className="change-btn" onClick={this.onSubmit}>
                    Confirm Email
                  </Button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              
            </Modal.Footer>
          </Modal>
        ) : (
          <Modal
            show={this.props.show}
            onHide={() => this.props.modalHide()}
            dialogClassName="mob-change-email-modal"
            className="d-block d-md-none"
            aria-labelledby="example-custom-modal-styling-title"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title id="example-custom-modal-styling-title">
                Email Required
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="body-container">
                {errorMessage && errorMessage.length > 0 && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                <p>
                  Your Facebook account does not provide us your email.
                  In order to subscribe to our services, we need to have your email. <br></br>
                  Please input your email below to continue.
                </p>
                <div className="input-field">
                  <div className="common-input-email">
                    <input
                      type="email"
                      placeholder="Email address"
                      value={email_new}
                      onChange={e =>
                        this.onChangeValue("email_new", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="change-bottom-email">
                  <Button className="change-btn" onClick={this.onSubmit}>
                    Confirm Email
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    ...state.default.services.auth
  }),
  dispatch => ({
    authActions: bindActionCreators({ changePassword, getProfile, updateProfile }, dispatch)
  })
)(RequireEmailModal);
