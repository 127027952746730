/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useCallback } from 'react';
import { FaRegEye } from 'react-icons/fa';
import '../MyHipscans/MyHipscans.css';
import EmptyQRCodeImage from '../../assets/images/empty-qr-code.svg';
import { MdEdit, MdSave, MdCancel } from 'react-icons/md';
import { FaTrashAlt } from 'react-icons/fa';
import { getHipscans, getPartnerHipscans } from '../../services/profile/profileActions';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showModal } from '../../modals/ModalConductorActions';
import { useHistory } from 'react-router-dom';
import settings from "../../config/settings"

const PartnerHipscan = (props) => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [state, setState] = useState({
    videoURL: '',
    hipscanPreview: {},
    editHipscan: false,
    editHipscan2: false,
    mainHipscanLabel: 'Main Hipscan',
    secondHipscanLabel: 'Hacienda_Location',
    thirdHipscanLabel: 'Brooklyn_Location',

    eyeState: false,
    eyeState2: false,

    isEditingLabel: false,
    editingLabelText: '',
    editingIndex: 0,

    linkIframeViewNotWork: false,
    currentPlan: '',
  })
  const history = useHistory();

  useEffect(() => {
    if(!props.partnerHipscans?.length) {
      props.profileActions.getPartnerHipscans()
    }
  }, [props.partnerHipscans, props.updatePartnerHipscan])

  useEffect(() => {
    if (props.deletedId) {
			let index = props.partnerHipscans.findIndex(elem => elem.id === props.deletedId);
			props.partnerHipscans.splice(index, 1);
      forceUpdate();
		}
  }, [props.deletedId])

  const goDetail = id => {
		history.push({pathname: `/myhipscans/detail/${id}`});
    window.location.reload()
	};

	const setPreview = item => {
		if (item.type === 'profile') {
			item.contact.isIncludedVideo = item.contact.video_url !== '' ? true : false;
		}
		if (item.type === 'product') {
			item.isIncludedVideo = item.video !== '' ? true : false;
		}
		setState({ hipscanPreview: item });
	};

	const inputHandle = (label) => {
		this.setState({ editingLabelText: label });
	}

	const editLabel = (item, type, index) => {
		if (type === 'cancel') {
			setState({ isEditingLabel: !state.isEditingLabel });
			item.editLabel = false;
			return;
		}
		if (type === 'edit' && state.isEditingLabel) {
			// toastr.error('Failed', 'Please save first');
			return;
		}

		if (item.editLabel) {
			let params = {
				type: item.type,
				label: state.editingLabelText,
			};
			if (params.type === 'url') params.url = item.url;
			else if (params.type === 'text') params.text = item.text;
			else if (params.type === 'video') params.video = item.video;
			else if (params.type === 'flyer') {
				params['user_id'] = item.user_id;
				params['preview_url'] = item.preview_url;
				params['design_type'] = item.design_type;
				params['design_id'] = item.design_type;
			}
			props.profileActions.updateHipscan(item.id, params);
		}
		if (type === 'edit') {
			setState({ editingLabelText: item.label });
			setState({ editingIndex: index });
			setState({ isEditingLabel: !state.isEditingLabel });
			item.editLabel = true;
		}
	};

  return (
    <div className="my-hipscans">
      <div className="hipscan d-none d-md-flex">
        <div className="action-part">
          <div className="qr-s">
            {props.partnerHipscans && props.partnerHipscans.length !== 0 &&
              props.partnerHipscans.map((item, index) => (
                <div
                  key={index}
                  className={
                    index % 3 === 0 ? 'qr-first' : index % 3 === 1 ? 'qr-second' : 'qr-third'
                  }
                >
                  <div className="label">
                    <p>{item.label}</p>
                  </div>
                  <img
                    onClick={() => goDetail(item.id)}
                    className={state.hipscanPreview.id === item.id ? 'active' : ''}
                    src={`${item.code_image_url}`}
                    alt="qr"
                    onError={e => {
                      e.target.src = EmptyQRCodeImage; //replacement image imported above
                    }}
                  />
                  <div className={item.main === true ? 'eye-edit' : 'eye-edit-delete'}>
                    <FaRegEye
                      className="eye"
                      size="1.4rem"
                      // onClick={() => setPreview(item)}
                      onClick={() => goDetail(item.id)}
                    />
                    {item.main === false ? (
                      <FaTrashAlt
                        className="delete"
                        size="1.4rem"
                        onClick={() =>
                          props.modalActions.showModal('DELETE_HIPSCAN', {
                            id: item.id,
                          })
                        }
                      />
                    ) : null}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    partnerHipscans: state.default.services.profile.partnerHipscans,
    updatePartnerHipscan: state.default.services.profile.updatePartnerHipscan,
    ...state.default.services.profile,
    ...state.default.services.auth,
  }
}

const mapDispatchToProps = (dispatch) => ({
  profileActions: bindActionCreators({ getHipscans, getPartnerHipscans }, dispatch),
  modalActions: bindActionCreators({ showModal }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PartnerHipscan);
