import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './RestaurantMenuModal.css';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { GiOpenFolder } from 'react-icons/gi';
import { FaDribbble, FaFacebookF, FaTwitter, FaInstagram, FaRegPlayCircle, FaTrashAlt } from 'react-icons/fa'
import { TiSocialYoutubeCircular, TiSocialVimeoCircular } from 'react-icons/ti'
import { MdEdit, MdSave, MdCancel } from 'react-icons/md';
import { IoIosArrowBack } from 'react-icons/io';
import EditProductModal from './EditProductModal/EditProductModal';
import { array } from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
    getHipscanCategories,
    saveHipscanCategory,
    updateHipscanCategory,
    deleteHipscanCategory,
    getCategoryProduct,
    saveCategoryProduct,
    updateCategoryProduct,
    deleteCategoryProduct
} from '../../services/profile/profileActions';
// import {
//     getHipscanCategories
// } from '../../services/profile/profileApi';

class RestaurantMenuModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editShow: false,
            categories: [],
            products: [],
            categorySelectedItem: null,
            productSelectedItem: null,
            currentSelectedProduct: {}
        }
        this.editProductModalShow = this.editProductModalShow.bind(this);
        this.editProductModalHide = this.editProductModalHide.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.changeCategoryItem = this.changeCategoryItem.bind(this);
        this.changeProductItem = this.changeProductItem.bind(this);
        this.editCategory = this.editCategory.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveCategoryItem = this.saveCategoryItem.bind(this);
        this.cancelCategoryEdit = this.cancelCategoryEdit.bind(this);
        this.deleteCategoryItem = this.deleteCategoryItem.bind(this);
        this.productItemDelete = this.productItemDelete.bind(this);

        this.props.menuActions.getHipscanCategories(this.props.hipId)
    }

    static getDerivedStateFromProps(props, state) {
        // Any time the current user changes,
        // Reset any parts of state that are tied to that user.
        // In this simple example, that's just the email.
        // if (props.products !== state.products) {
        //   return {
        //     products: props.products
        //   };
        // }
        if (props.categories !== state.categories || props.products !== state.products) {
            if (!state.categorySelectedItem) {
                return {
                    categories: props.categories,
                    products: props.products,
                    categorySelectedItem: props.categories && props.categories.length > 0 ? props.categories[0].id : null
                };
            } else {
                return {
                    categories: props.categories,
                    products: props.products
                };
            }
        }

        return null;
    }

    editCategory = (e, index) => {
        e.stopPropagation();
        this.state.categories[index].isEditing = !this.state.categories[index].isEditing;
        this.state.categories[index].oriName = this.state.categories[index].name;
        this.setState({
            categories: this.state.categories
        })
    }

    editProductModalShow(item) {
        this.setState({ editShow: true });
    }
    editProductModalHide() { this.setState({ editShow: false }); }
    addCategory = () => {
        var { categories } = this.state;
        this.props.menuActions.saveHipscanCategory(this.props.hipId, 'item');
    }

    addProduct = () => {
        this.props.menuActions.saveCategoryProduct(this.props.hipId, this.state.categorySelectedItem, 'Product');
    }

    productItemDelete = (item) => {
        this.props.menuActions.deleteCategoryProduct(item.qr_id, item.category_id, item.id);
    }
    changeCategoryItem = (item, index) => {
        this.setState({
            categorySelectedItem: item,
        })
        // this.setState({product:this.state.category[index].products})
        this.props.menuActions.getCategoryProduct(this.props.hipId, item);
    }
    changeProductItem = (item, index) => {
        this.setState({ currentSelectedProduct: item });
        this.setState({
            productSelectedItem: item.id,
        })
    }

    handleChange = (e, item) => {
        let { categories } = this.state;
        let index = categories.indexOf(item);
        categories[index].name = e.target.value;

        this.setState({
            categories: categories
        });
    }

    saveCategoryItem = (e, item) => {
        e.stopPropagation();
        let { categories } = this.state;
        let index = categories.indexOf(item);
        categories[index].isEditing = false;

        this.setState({
            categories: categories
        });
        this.props.menuActions.updateHipscanCategory(this.props.hipId, item.id, item.name);
    }

    cancelCategoryEdit = (e, item) => {
        e.stopPropagation();
        let { categories } = this.state;
        let index = categories.indexOf(item);
        categories[index].name = categories[index].oriName;
        categories[index].isEditing = false;

        this.setState({
            categories: categories
        });
    }

    deleteCategoryItem = (e, item) => {
        e.stopPropagation();
        this.props.menuActions.deleteHipscanCategory(this.props.hipId, item.id);
    }

    render() {
        const { categories, products } = this.state;
        const width = window.innerWidth;
        return (
            <div>
                {width > 767 ? (
                <Modal show={this.props.show} onHide={() => this.props.modalHide()} dialogClassName="restaurant-menu-modal-container" className="d-none d-md-block" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Restaurant Menu
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="body-container">
                        <div className="categories">
                            <p>Categories</p>
                            <div className="add-categories">
                                <div className="add">
                                    <Button onClick={this.addCategory}>+Add Category</Button>
                                </div>
                                {
                                    categories && categories.map((item, index) => (
                                        <div key={index} className={this.state.categorySelectedItem == item.id ? "active" : "category"} onClick={() => this.changeCategoryItem(item.id, index)}>
                                            {
                                                categories[index].isEditing ?
                                                    (<div className="btn-group" key={'view' + index}>
                                                        <input type="text" value={item.name} onChange={(e) => { this.handleChange(e, item) }}></input>
                                                        <MdSave className="save" size="1.4rem" onClick={(e) => this.saveCategoryItem(e, item)} />
                                                        <MdCancel className="cancel" size="1.4rem" onClick={(e) => this.cancelCategoryEdit(e, item)} />
                                                    </div>) :
                                                    (<div className="btn-group" key={'edit' + index}>
                                                        <p>{item.name}</p>
                                                        <MdEdit className="edit" onClick={(e) => this.editCategory(e, index)} />
                                                        <FaTrashAlt onClick={(e) => this.deleteCategoryItem(e, item)} className="trash" />
                                                    </div>)
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="line">

                        </div>
                        <div className="products">
                            <p>Products</p>
                            <div className="add-products">
                                <div className="add">
                                    <Button onClick={this.addProduct}>+Add Product</Button>
                                </div>
                                {
                                    products && products.map((item, index) => (
                                        <div key={index} className={this.state.productSelectedItem == item.id ? "active" : "category"} onClick={() => this.changeProductItem(item, index)}>
                                            <p>{item.name}</p>
                                            <MdEdit className="edit" onClick={() => this.editProductModalShow(item)} />
                                            <FaTrashAlt onClick={() => this.productItemDelete(item)} className="trash" />
                                        </div>
                                    ))
                                }
                                <EditProductModal show={this.state.editShow} modalHide={this.editProductModalHide} hipId={this.props.hipId} product={this.state.currentSelectedProduct} />
                                {/* <EditProductModal show={this.state.editShow} modalHide={this.editProductModalHide}/> */}
                            </div>
                        </div>
                        <div className="save-btn">
                            {/* <p className="goto" onClick={this.props.modalHide}><IoIosArrowBack size="1.3rem"/>Return Without Saving</p> */}
                            <Button onClick={this.props.modalHide}>Return</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
                ) : (
                    <Modal show={this.props.show} onHide={() => this.props.modalHide()} dialogClassName="mob-restaurant-menu-modal-container" className="d-block d-md-none" aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Restaurant Menu
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="body-container">
                            <div className="categories">
                                <p>Categories</p>
                                <div className="add-categories">
                                    <div className="add">
                                        <Button onClick={this.addCategory}>+Add Category</Button>
                                    </div>
                                    {
                                        categories && categories.map((item, index) => (
                                            <div key={index} className={this.state.categorySelectedItem == item.id ? "active" : "category"} onClick={() => this.changeCategoryItem(item.id, index)}>
                                                {
                                                    categories[index].isEditing ?
                                                        (<div className="btn-group" key={'view' + index}>
                                                            <input type="text" value={item.name} onChange={(e) => { this.handleChange(e, item) }}></input>
                                                            <MdSave className="save" size="1.4rem" onClick={(e) => this.saveCategoryItem(e, item)} />
                                                            <MdCancel className="cancel" size="1.4rem" onClick={(e) => this.cancelCategoryEdit(e, item)} />
                                                        </div>) :
                                                        (<div className="btn-group" key={'edit' + index}>
                                                            <p>{item.name}</p>
                                                            <MdEdit className="edit" onClick={(e) => this.editCategory(e, index)} />
                                                            <FaTrashAlt onClick={(e) => this.deleteCategoryItem(e, item)} className="trash" />
                                                        </div>)
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="line">

                            </div>
                            <div className="products">
                                <p>Products</p>
                                <div className="add-products">
                                    <div className="add">
                                        <Button onClick={this.addProduct}>+Add Product</Button>
                                    </div>
                                    {
                                        products && products.map((item, index) => (
                                            <div key={index} className={this.state.productSelectedItem == item.id ? "active" : "category"} onClick={() => this.changeProductItem(item, index)}>
                                                <p>{item.name}</p>
                                                <MdEdit className="edit" onClick={() => this.editProductModalShow(item)} />
                                                <FaTrashAlt onClick={() => this.productItemDelete(item)} className="trash" />
                                            </div>
                                        ))
                                    }
                                    <EditProductModal show={this.state.editShow} modalHide={this.editProductModalHide} hipId={this.props.hipId} product={this.state.currentSelectedProduct} />
                                </div>
                            </div>
                            <div className="save-btn">
                                <Button onClick={this.props.modalHide}>Return</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                )}
            </div>
        )
    }
}

export default connect(
    state => ({
        ...state.default.services.profile
    }),
    dispatch => ({
        menuActions: bindActionCreators({
            getHipscanCategories,
            saveHipscanCategory,
            updateHipscanCategory,
            deleteHipscanCategory,
            getCategoryProduct,
            saveCategoryProduct,
            updateCategoryProduct,
            deleteCategoryProduct
        }, dispatch)
    })
)(RestaurantMenuModal);