import React from "react";
import "./index.scss";

import ImgNotFound from "../../assets/images/notfound.png";
import Logo from "../../assets/images/logo.svg";

class QRNotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="qr-not-found">
        <div className="img-notfound">
          <img src={ImgNotFound} alt="" />
        </div>
        <h3>We have notiﬁed the owner of this account.</h3>
        <h4>
          Want to create your own mobile experience? Check out the power of
          Hipscan, here.
        </h4>
        <div className="logo-ft">
          <p>
            POWERED BY{" "}
            <a href="/">
              <img src={Logo} alt="" />
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default QRNotFound;
