import React from "react";
import "antd/dist/antd.css";
import "./index.scss";

import moment from "moment";

import { Button, Container } from "react-bootstrap";
import { Table } from "antd";

class UsersManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.users !== this.props.users) {
      if (this.props.users.length > 0) {
        let { users } = this.props;
        let arr = users.filter(el => {
          return el.is_admin === false;
        });
        this.setState({ data: arr });
      }
    }
  };

  onChange(pagination, filters, sorter, extra) {
  }

  render() {
    const { data } = this.state;

    const columns = [
      {
        title: "USERNAME",
        dataIndex: "username",
        className: "td_username",
        key: "username",
        // sorter: {
        //   compare: (a, b) => a.username - b.username,
        //   multiple: 1
        // }
        sorter: (a, b) => a.username.length - b.username.length,
        render: username => (
          <span className="tt_username" title={username}>
            {username}
          </span>
        )
      },
      {
        title: "FIRST NAME",
        dataIndex: "firstname",
        key: "firstname",
        responsive: ["md"]
      },
      {
        title: "LAST NAME",
        dataIndex: "lastname",
        key: "lastname",
        responsive: ["lg"]
      },
      {
        title: "Plan",
        dataIndex: "plan",
        key: "plan",
        responsive: ["lg"],
        render: val => (val.external_name ? val.external_name : "")
      },
      {
        title: "SIGN UP DATE",
        dataIndex: "created_at",
        key: "created_at",
        responsive: ["lg"],
        render: created_at => moment(created_at).format("MM/DD/YYYY")
      },
      {
        title: "EMAIL ADDRESS",
        dataIndex: "email",
        key: "email",
        responsive: ["sm"]
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        render: (id, item) => (
          <div className="action-line">
            {!item.is_admin && (
              <Button
                key={id}
                className="btn-login"
                onClick={() => this.props.onAutoLoginUser(id)}
              >
                LOGIN
              </Button>
            )}
          </div>
        )
      }
    ];

    return (
      <div className="users-manager">
        <Container fluid>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            onChange={this.onChange}
          />
        </Container>
      </div>
    );
  }
}

export default UsersManager;
