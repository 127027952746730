import { wrapRequest, xapi } from '../utils';

const login = wrapRequest( async (login, password) =>
    xapi().post('/authorization',{
        login,
        password
    })
);

const socialLogin = wrapRequest( async (uuid) =>
    xapi().get(`/accept?oauth=${uuid}`)
)

const register = wrapRequest( async params => {
    xapi().post('/registration',
        params
    )
  }
);

const getProfile = wrapRequest( async () =>
    {
        return xapi().get('/profile')
    }
);

const updateProfile = wrapRequest( async (params) => {
      return xapi().put('/profile', params);
    }
);

const changePassword = wrapRequest( async (params) => {
    return xapi().put('/profile/password', params);
  }
);
export { login, register, getProfile, socialLogin, updateProfile, changePassword };
