import { createActions } from 'redux-actions';
const {
    getHipscansLimits,
    getHipscansLimitsSucceed,
    getHipscansLimitsFailed,

    getHipscansPlanFreeBasic,
    getHipscansPlanFreeBasicSucceed,
    getHipscansPlanFreeBasicFailed,

    getHipscansPlanProMonthly,
    getHipscansPlanProMonthlySucceed,
    getHipscansPlanProMonthlyFailed,

    getHipscansPlanProYearly,
    getHipscansPlanProYearlySucceed,
    getHipscansPlanProYearlyFailed,

    getHipscansPlanPremiumMonthly,
    getHipscansPlanPremiumMonthlySucceed,
    getHipscansPlanPremiumMonthlyFailed,

    getHipscansPlanPremiumYearly,
    getHipscansPlanPremiumYearlySucceed,
    getHipscansPlanPremiumYearlyFailed,

    getHipscansPlanTrial,
    getHipscansPlanTrialSucceed,
    getHipscansPlanTrialFailed,

} = createActions({
    GET_HIPSCANS_LIMITS: () => ({}),
    GET_HIPSCANS_LIMITS_SUCCEED: limits => ({ limits }),
    GET_HIPSCANS_LIMITS_FAILED: error => ({ error }),

    GET_HIPSCANS_PLAN_FREE_BASIC: () => ({}),
    GET_HIPSCANS_PLAN_FREE_BASIC_SUCCEED: plan_free_basic => ({ plan_free_basic }),
    GET_HIPSCANS_PLAN_FREE_BASIC_FAILED: error => ({ error }),

    GET_HIPSCANS_PLAN_PRO_MONTHLY: () => ({}),
    GET_HIPSCANS_PLAN_PRO_MONTHLY_SUCCEED: plan_pro_monthly => ({ plan_pro_monthly }),
    GET_HIPSCANS_PLAN_PRO_MONTHLY_FAILED: error => ({ error }),

    GET_HIPSCANS_PLAN_PRO_YEARLY: () => ({}),
    GET_HIPSCANS_PLAN_PRO_YEARLY_SUCCEED: plan_pro_yearly => ({ plan_pro_yearly }),
    GET_HIPSCANS_PLAN_PRO_YEARLY_FAILED: error => ({ error }),

    GET_HIPSCANS_PLAN_PREMIUM_MONTHLY: () => ({}),
    GET_HIPSCANS_PLAN_PREMIUM_MONTHLY_SUCCEED: plan_premium_monthly => ({ plan_premium_monthly }),
    GET_HIPSCANS_PLAN_PREMIUM_MONTHLY_FAILED: error => ({ error }),

    GET_HIPSCANS_PLAN_PREMIUM_YEARLY: () => ({}),
    GET_HIPSCANS_PLAN_PREMIUM_YEARLY_SUCCEED: plan_premium_yearly => ({ plan_premium_yearly }),
    GET_HIPSCANS_PLAN_PREMIUM_YEARLY_FAILED: error => ({ error }),

    GET_HIPSCANS_PLAN_TRIAL: (isTrial, plan) => ({isTrial, plan}),
    GET_HIPSCANS_PLAN_TRIAL_SUCCEED: plan_trial => ({ plan_trial }),
    GET_HIPSCANS_PLAN_TRIAL_FAILED: error => ({ error }),

});

export {
    getHipscansLimits,
    getHipscansLimitsSucceed,
    getHipscansLimitsFailed,

    getHipscansPlanFreeBasic,
    getHipscansPlanFreeBasicSucceed,
    getHipscansPlanFreeBasicFailed,

    getHipscansPlanProMonthly,
    getHipscansPlanProMonthlySucceed,
    getHipscansPlanProMonthlyFailed,

    getHipscansPlanProYearly,
    getHipscansPlanProYearlySucceed,
    getHipscansPlanProYearlyFailed,

    getHipscansPlanPremiumMonthly,
    getHipscansPlanPremiumMonthlySucceed,
    getHipscansPlanPremiumMonthlyFailed,

    getHipscansPlanPremiumYearly,
    getHipscansPlanPremiumYearlySucceed,
    getHipscansPlanPremiumYearlyFailed,

    getHipscansPlanTrial,
    getHipscansPlanTrialSucceed,
    getHipscansPlanTrialFailed,
};