import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4'
  },
  row: {
    flexDirection: 'row'
  },
  section: {
    margin: 10,
    fontSize: 14,
    textAlign: 'center'
  }
});

const PdfDownload = ({name, email, number, title, description}) => (
  <Document>
    <Page size="A4" style={styles.page}>
        <View style={styles.row} >
            <View style={styles.section}>
                <Text>Name</Text>
            </View>
            <View style={styles.section}>
                <Text>{name}</Text>
            </View>
        </View>
        <View style={styles.row} >
            <View style={styles.section}>
                <Text>Title</Text>
            </View>
            <View style={styles.section}>
                <Text>{title}</Text>
            </View>
        </View>
        <View style={styles.row} >
            <View style={styles.section}>
                <Text>Email</Text>
            </View>
            <View style={styles.section}>
                <Text>{email}</Text>
            </View>
        </View>
        <View style={styles.row} >
            <View style={styles.section}>
                <Text>Contact</Text>
            </View>
            <View style={styles.section}>
                <Text>{number}</Text>
            </View>
        </View>
        <View style={styles.row} >
            <View style={styles.section}>
                <Text>Description</Text>
            </View>
            <View style={styles.section}>
                <Text>{description}</Text>
            </View>
        </View>
    </Page>
  </Document>
);

export default PdfDownload