import { createActions } from 'redux-actions';

const {
    login,
    loginFailed,
    loginSucceed,
    socialLogin,
    socialLoginFailed,
    socialLoginSucced,
    logout,
    register,
    registerFailed,
    registerSucceed,
    getProfile,
    getProfileSucceed,
    getProfileFailed,
    updateProfile,
    updateProfileSucceed,
    updateProfileFailed,
    changePassword,
    changePasswordSucceed,
    changePasswordFailed,
} = createActions({
    LOGIN:( login, password ) => ({ login, password }),
    LOGIN_FAILED: error => ({ error }),
    LOGIN_SUCCEED: token => ({ token }),
    SOCIAL_LOGIN:( uuid ) => ({ uuid }),
    SOCIAL_LOGIN_FAILED: error => ({ error }),
    SOCIAL_LOGIN_SUCCEED: token => ({ token }),
    LOGOUT:() => ({}),
    REGISTER: params => ({ params}),
    REGISTER_FAILED: error => ({ error }),
    REGISTER_SUCCEED: user_id => ({ user_id }),
    GET_PROFILE: () => ({}),
    GET_PROFILE_SUCCEED: profile => ({ profile }),
    GET_PROFILE_FAILED: error => ({ error }),
    UPDATE_PROFILE: ( params ) => ({ params }),
    UPDATE_PROFILE_SUCCEED: profile => ({ profile }),
    UPDATE_PROFILE_FAILED: error => ({ error }),
    CHANGE_PASSWORD: ( params ) => ({ params }),
    CHANGE_PASSWORD_SUCCEED: data => ({ data }),
    CHANGE_PASSWORD_FAILED: error => ({ error }),
})

export {
    login,
    loginFailed,
    loginSucceed,
    socialLogin,
    socialLoginFailed,
    socialLoginSucced,
    logout,
    register,
    registerFailed,
    registerSucceed,
    getProfile,
    getProfileSucceed,
    getProfileFailed,
    updateProfile,
    updateProfileSucceed,
    updateProfileFailed,
    changePassword,
    changePasswordSucceed,
    changePasswordFailed,
};