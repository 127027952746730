import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../../services/auth/services';

const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (isLogin() ? <Component {...props} /> : <Component {...props} />)} />
    )
}

export default PublicRoute;
