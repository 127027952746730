import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { Button, Form } from 'react-bootstrap';
import { FaFacebookF, FaGoogle } from 'react-icons/fa';
//import components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
//import assets
import SignupImage from '../../assets/images/signup-background.jpg';
//import action
import { register, getProfile } from '../../services/auth/authActions';
//import css
import './Signup.css';
import { notification } from 'antd';

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            firstname: '',
            lastname: '',
            email: '',
            password: ''
        }
        this.change = this.change.bind(this);
        this.handleSignClick = this.handleSignClick.bind(this);
    }
    componentDidUpdate = async (prevProps) => {

        if (this.props.error !== prevProps.error && this.props.error != null) {
            // toastr.error('Failed', this.props.error);
        }
        if (prevProps.token !== this.props.token && this.props.token != null) {
            // toastr.success('Success', this.props.message);
            /*  await this.props.authActions.getProfile(); */
            await this.props.history.push(`/plans`);
        }

        if (this.props.message !== prevProps.message) {
            if (this.props.error) {
                // notification.error({
                //     message: "Sign up failed",
                //     description: this.props.message
                // })
            }

            if (this.props.success) {
                // notification.success({
                //     message: "Sign up successfully!",
                //     description: this.props.message
                // })
            }
        }

    }

    change = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    clearField = () => {
      this.setState({
          username: '',
          firstname: '',
          lastname: '',
          email: '',
          password: '',
      })
    }

    handleSignClick = () => {
        const data = this.state;
        if (data.username !== "" && data.firstname !== "" && data.lastname !== "" && data.email !== "" && data.password !== "") {
            let params = { username: data.username, firstname: data.firstname, lastname: data.lastname, email: data.email, password: data.password }
            this.props.authActions.register(params);
            this.clearField()
            notification.success({ message: 'Sign up successfully', description: 'Check your mail to activate account' })
        } else {
            notification.error({ message: 'Sign up failed', description: 'Something went wrong' })
        }
    }
    render() {
        window.scrollTo(0, 0);
        return (
            <div>
                <div className="signup d-none d-md-block">
                    <div className="signup-section">
                        <img src={SignupImage} className="signup-image" alt="signupimage" />
                        <Header {...this.props} />
                        <div className="container home">
                            <div className="description">
                                <h1>Go from the real world to<br />the digital<br />world in less<br />than 5 minutes.</h1>
                                <p>Hipscan makes it easy to create a dynamic QR code and point it to a digital destination so that you can capture their attention when it matters.</p>
                                <span>Create an account today and see how easy it is to start to interact with your customers.</span>
                            </div>
                            <div className="input">
                                <h1>Sign up for free, now!</h1>
                                <div className="social-signup">
                                    <a className="google-signup btn btn-primary" href="https://api.hipscan.com/auth/google/login"><FaGoogle size="1.6em" /> SIGN UP WITH GOOGLE</a>
                                    <a className="facebook-signup btn btn-primary" href="https://api.hipscan.com/auth/facebook/login"><FaFacebookF size="1.8em" /> SIGN UP WITH FACEBOOK</a>
                                </div>
                                <div className="email-signup">
                                    <p>OR SIGN UP WITH EMAIL</p>
                                    <div className="line"></div>
                                </div>
                                <div className="info-input">
                                    <Form>
                                        <Form.Group controlId='exampleForm.ControlInput1'>
                                            <Form.Control type='text' value={this.state.username} name='username' placeholder='Desired Username' onChange={this.change} />
                                        </Form.Group>
                                        <Form.Row>
                                            <Form.Group controlId='exampleForm.ControlInput2'>
                                                <Form.Control type='text' value={this.state.firstname} name='firstname' placeholder='First Name' onChange={this.change} />
                                            </Form.Group>
                                            <Form.Group controlId='exampleForm.ControlInput1'>
                                                <Form.Control type='text' value={this.state.lastname} name='lastname' placeholder='Last Name' onChange={this.change} />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Group controlId='exampleForm.ControlInput1'>
                                            <Form.Control type='email' value={this.state.email} name='email' placeholder='Email Address' onChange={this.change} />
                                        </Form.Group>
                                        <Form.Group controlId='exampleForm.ControlInput1'>
                                            <Form.Control type='password' value={this.state.password} name='password' placeholder='Password' onChange={this.change} />
                                        </Form.Group>
                                    </Form>
                                    <Button onClick={this.handleSignClick}>
                                        LET'S GO!
                                </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="mob-signup d-block d-md-none">
                    <div className="signup-section">
                        <img src={SignupImage} className="signup-image" alt="signupimage" />
                        <Header {...this.props} />
                        <div className="container home">
                            <div className="description">
                                <h1>Go from the real world to the digital world in less than 5 minutes.</h1>
                                <p>Hipscan makes it easy to create a dynamic QR code and point it to a digital destination so that you can capture their attention when it matters.</p>
                                <span>Create an account today and see how easy it is to start to interact with your customers.</span>
                            </div>
                            <div className="row input">
                                <div className="col-12">
                                    <h1>Sign up for free, now!</h1>
                                    <div className="row">
                                        <div className="col-6 social-signup">
                                            <a className="google-signup btn btn-primary" href="https://api.hipscan.com/auth/google/login"><FaGoogle size="0.7rem" /> SIGN UP WITH GOOGLE</a>
                                        </div>
                                        <div className="col-6 social-signup">
                                            <a className="facebook-signup btn btn-primary" href="https://api.hipscan.com/auth/facebook/login"><FaFacebookF size="0.7rem" /> SIGN UP WITH FACEBOOK</a>
                                        </div>
                                    </div>
                                    <div className="email-signup">
                                        <p>OR SIGN UP WITH EMAIL</p>
                                    </div>
                                    <div className="info-input">
                                        <Form>
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Control type="text" name="username" placeholder="Desired Username" onChange={this.change} />
                                            </Form.Group>
                                            <Form.Row>
                                                <Form.Group controlId="exampleForm.ControlInput2">
                                                    <Form.Control type="text" name="firstname" placeholder="First Name" onChange={this.change} />
                                                </Form.Group>
                                                <Form.Group controlId="exampleForm.ControlInput1">
                                                    <Form.Control type="text" name="lastname" placeholder="Last Name" onChange={this.change} />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Control type="email" name="email" placeholder="Email Address" onChange={this.change} />
                                            </Form.Group>
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Control type="password" name="password" placeholder="Password" onChange={this.change} />
                                            </Form.Group>
                                        </Form>
                                        <Button onClick={this.handleSignClick}>
                                            LET'S GO!
                                </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        ...state.default.services.auth
    }),
    dispatch => ({
        authActions: bindActionCreators({ register, getProfile }, dispatch)
    })
)(Signup);
