import { put, takeEvery, call, all, takeLatest } from 'redux-saga/effects';

//import actions
import {
    getHipscansSucceed,
    getHipscansFailed,

    getHipscanDetailSucceed,
    getHipscanDetailFailed,

    addHipscanSucceed,
    addHipscanFailed,

    updateHipscanSucceed,
    updateHipscanFailed,

    saveCustomDesignSucceed,
    saveCustomDesignFailed,

    previewCustomDesignSucceed,
    previewCustomDesignFailed,

    updateContactSucceed,
    updateContactFailed,

    getHipscanByUuidSucceed,
    getHipscanByUuidFailed,

    downloadQrCodeSucceed,
    downloadQrCodeFailed,

    getHipscanStatsSucceed,
    getHipscanStatsFailed,

    deleteHipscanSucceed,
    deleteHipscanFailed,

    getHipscanCategoriesSucceed,
    getHipscanCategoriesFailed,
    saveHipscanCategorySucceed,
    saveHipscanCategoryFailed,
    updateHipscanCategorySucceed,
    updateHipscanCategoryFailed,
    deleteHipscanCategorySucceed,
    deleteHipscanCategoryFailed,
    getCategoryProductSucceed,
    getCategoryProductFailed,
    saveCategoryProductSucceed,
    saveCategoryProductFailed,
    updateCategoryProductSucceed,
    updateCategoryProductFailed,
    deleteCategoryProductSucceed,
    deleteCategoryProductFailed,
    saveProductModifiersSucceed,
    saveProductModifiersFailed,
    getHipscanTotalStatsSucceed,
    getHipscanTotalStatsFailed,
    getHipscanFilterSucceed,
    getHipscanFilterFailed,
    addPartnerHipscanSucceed,
    addPartnerHipscanFailed,
    getPartnerHipscansSucceed,
    getPartnerHipscansFailed,

    addPartnerStampSucceed,
    addPartnerStampFailed,

    addFollowStampSucceed,
    addFollowStampFailed,

    getPartnerStampsSucceed,
    getPartnerStampsFailed,
} from './profileActions';

//import api
import * as profileApi from './profileApi';

export function* profileSubscriber() {
    yield all([takeEvery('GET_HIPSCANS', getHipscans)]);
    yield all([takeEvery('GET_PARTNER_HIPSCANS', getPartnerHipscans)]);
    yield all([takeEvery('GET_HIPSCAN_DETAIL', getHipscanDetail)]);
    yield all([takeEvery('ADD_HIPSCAN', addHipscan)]);
    yield all([takeEvery('UPDATE_HIPSCAN', updateHipscan)]);
    yield all([takeEvery('SAVE_CUSTOM_DESIGN', saveCustomDesign)]);
    yield all([takeEvery('PREVIEW_CUSTOM_DESIGN', previewCustomDesign)]);
    yield all([takeEvery('UPDATE_CONTACT', updateContact)]);
    yield all([takeEvery('GET_HIPSCAN_BY_UUID', getHipscanByUuid)]);
    yield all([takeEvery('DOWNLOAD_QR_CODE', downloadQrCode)]);
    yield all([takeEvery('GET_HIPSCAN_STATS', getHipscanStats)]);
    yield all([takeEvery('GET_HIPSCAN_TOTAL_STATS', getHipscanTotalStats)]);
    yield all([takeEvery('GET_HIPSCAN_FILTER', getHipscanFilter)])
    yield all([takeEvery('DELETE_HIPSCAN', deleteHipscan)]);

    yield all([takeLatest('GET_HIPSCAN_CATEGORIES', getHipscanCategories)]);
    yield all([takeEvery('SAVE_HIPSCAN_CATEGORY', saveHipscanCategory)]);
    yield all([takeEvery('UPDATE_HIPSCAN_CATEGORY', updateHipscanCategory)]);
    yield all([takeEvery('DELETE_HIPSCAN_CATEGORY', deleteHipscanCategory)]);
    yield all([takeEvery('GET_CATEGORY_PRODUCT', getCategoryProducts)]);
    yield all([takeEvery('SAVE_CATEGORY_PRODUCT', saveCategoryProduct)]);
    yield all([takeEvery('UPDATE_CATEGORY_PRODUCT', updateCategoryProduct)]);
    yield all([takeEvery('DELETE_CATEGORY_PRODUCT', deleteCategoryProduct)]);
    yield all([takeEvery('SAVE_PRODUCT_MODIFIERS', saveProductModifiers)]);
    yield all([takeEvery('ADD_PARTNER_HIPSCAN', addPartnerHipscan)]);

    yield all([takeEvery('ADD_PARTNER_STAMP', addPartnerStamp)]);
    yield all([takeEvery('ADD_FOLLOW_STAMP', addFollowStamp)]);
    yield all([takeEvery('GET_PARTNER_STAMPS', getPartnerStamps)]);
}

export function* getHipscans() {
    try {
        const myHipscans = yield call(profileApi.getHipscans);
        yield put(getHipscansSucceed(myHipscans));
    } catch (error) {
        yield put(getHipscansFailed(error));
    }
}

export function* getPartnerHipscans() {
    try {
        const myHipscans = yield call(profileApi.getPartnerHipscans);
        yield put(getPartnerHipscansSucceed(myHipscans));
    } catch (error) {
        yield put(getPartnerHipscansFailed(error));
    }
}

export function* getHipscanDetail({ payload: { id } }) {
    try {
        const myHipscanDetail = yield call(profileApi.getHipscanDetail, id);
        yield put(getHipscanDetailSucceed(myHipscanDetail));
    } catch (error) {
        yield put(getHipscanDetailFailed(error));
    }
}

export function* addHipscan({ payload: { label } }) {
    try {
        const addedHipscan = yield call(profileApi.addHipscan, label);
        yield put(addHipscanSucceed(addedHipscan));
    } catch (error) {
        yield put(addHipscanFailed(error));
    }
}

export function* updateHipscan({ payload: { id, params } }) {
    try {
        const updateHipscan = yield call(profileApi.updateHipscan, id, params);
        yield put(updateHipscanSucceed(updateHipscan));
    } catch (error) {
        yield put(updateHipscanFailed(error));
    }
}

export function* saveCustomDesign({ payload: { id, params } }) {
    try {
        const saveCustomDesign = yield call(profileApi.saveCustomDesign, id, params);
        yield put(saveCustomDesignSucceed(saveCustomDesign));
    } catch (error) {
        yield put(saveCustomDesignFailed(error));
    }
}

export function* previewCustomDesign({ payload: { id, params } }) {
    try {
        const previewCustomDesign = yield call(profileApi.previewCustomDesign, id, params);
        yield put(previewCustomDesignSucceed(previewCustomDesign));
    } catch (error) {
        yield put(previewCustomDesignFailed(error));
    }
}

export function* updateContact({ payload: { id, params } }) {
    try {
        let updatedContact = yield call(profileApi.updateContact, id, params);
        yield put(updateContactSucceed(updatedContact));
        const myHipscanDetail = yield call(profileApi.getHipscanDetail, id);
        yield put(getHipscanDetailSucceed(myHipscanDetail));
    } catch (error) {
        yield put(updateContactFailed(error));
    }
}

export function* getHipscanByUuid({ payload: { id } }) {
    try {
        const myHipscanDetail = yield call(profileApi.getHipscanByUuid, id);
        yield put(getHipscanByUuidSucceed(myHipscanDetail));
    } catch (error) {
        yield put(getHipscanByUuidFailed(error));
    }
}

export function* downloadQrCode({ payload: { id, params } }) {
    try {
        const qrCode = yield call(profileApi.downloadQrCode, id, params);
        yield put(downloadQrCodeSucceed(qrCode));
    } catch (error) {
        yield put(downloadQrCodeFailed(error));
    }
}

export function* getHipscanStats({ payload: { id } }) {
    try {
        const hipscanStats = yield call(profileApi.getHipscanStats, id);
        yield put(getHipscanStatsSucceed(hipscanStats));
    } catch (error) {
        yield put(getHipscanStatsFailed(error));
    }
}

export function* getHipscanTotalStats() {
    try {
        const hipscanTotalStats = yield call(profileApi.getHipscanTotalStats);
        yield put(getHipscanTotalStatsSucceed(hipscanTotalStats));
    } catch (error) {
        yield put(getHipscanTotalStatsFailed(error));
    }
}

export function* getHipscanFilter({ payload: {id, from, to, group} }) {
    try {
        const hipscanFilter = yield call(profileApi.getHipscanFilter, id, from, to, group)
        yield put(getHipscanFilterSucceed(hipscanFilter))
    } catch (error) {
        yield put(getHipscanFilterFailed(error))
    }
}

export function* deleteHipscan({ payload: { id } }) {
    try {
        yield call(profileApi.deleteHipscan, id);
        yield put(deleteHipscanSucceed(id));
    } catch (error) {
        yield put(deleteHipscanFailed(error));
    }
}

export function* getHipscanCategories({ payload: { id } }) {
    try {
        const categories = yield call(profileApi.getHipscanCategories, id);
        yield put(getHipscanCategoriesSucceed(categories));
    } catch (error) {
        yield put(getHipscanCategoriesFailed(error));
    }
}

export function* saveHipscanCategory({ payload: { id, name } }) {
    try {
        const category = yield call(profileApi.saveHipScanCategory, id, name);
        yield put(saveHipscanCategorySucceed(category));
    } catch (error) {
        yield put(saveHipscanCategoryFailed(error));
    }
}

export function* updateHipscanCategory({ payload: { hipId, id, name } }) {
    try {
        const updateHipscan = yield call(profileApi.updateHipScanCategory, hipId, id, name);
        yield put(updateHipscanCategorySucceed(updateHipscan));
    } catch (error) {
        yield put(updateHipscanCategoryFailed(error));
    }
}

export function* deleteHipscanCategory({ payload: { hipId, id } }) {
    try {
        yield call(profileApi.deleteHipScanCategory, hipId, id);
        yield put(deleteHipscanCategorySucceed(id));
    } catch (error) {
        yield put(deleteHipscanCategoryFailed(error));
    }
}

export function* getCategoryProducts({ payload: { hipId, catId } }) {
    try {
        const products = yield call(profileApi.getCategoryProducts, hipId, catId);
        yield put(getCategoryProductSucceed(products));
    } catch (error) {
        yield put(getCategoryProductFailed(error));
    }
}

export function* saveCategoryProduct({ payload: { hipId, catId, name } }) {
    try {
        const product = yield call(profileApi.saveProduct, hipId, catId, name);
        yield put(saveCategoryProductSucceed(product));
    } catch (error) {
        yield put(saveCategoryProductFailed(error));
    }
}

export function* updateCategoryProduct({ payload: { hipId, catId, id, params } }) {
    try {
        const product = yield call(profileApi.updateProduct, hipId, catId, id, params);
        yield put(updateCategoryProductSucceed(product));
    } catch (error) {
        yield put(updateCategoryProductFailed(error));
    }
}

export function* deleteCategoryProduct({ payload: { hipId, catId, id } }) {
    try {
        yield call(profileApi.deleteProduct, hipId, catId, id);
        yield put(deleteCategoryProductSucceed(id));
    } catch (error) {
        yield put(deleteCategoryProductFailed(error));
    }
}

export function* saveProductModifiers({ payload: { hipId, catId, prodId, params } }) {
    try {
        let modifiers = yield call(profileApi.addModifiers, hipId, catId, prodId, params);
        yield put(saveProductModifiersSucceed(prodId, modifiers));
    } catch (error) {
        yield put(saveProductModifiersFailed(error));
    }
}

export function* addPartnerHipscan({ payload }) {
  try {
      const myHipscans = yield call(profileApi.addPartnerHipscan, payload);
      yield put(addPartnerHipscanSucceed(myHipscans));
  } catch (error) {
      yield put(addPartnerHipscanFailed(error));
  }
}

export function* addPartnerStamp({ payload }) {
    try {
        const res = yield call(profileApi.addPartnerStamp, payload);
        yield put(addPartnerStampSucceed(res));
    } catch (error) {
        yield put(addPartnerStampFailed(error));
    }
}

export function* addFollowStamp({ payload }) {
    try {
        const res = yield call(profileApi.addFollowStamp, payload);
        yield put(addFollowStampSucceed(res));
    } catch (error) {
        yield put(addFollowStampFailed(error));
    }
}

export function* getPartnerStamps({ payload: { id } }) {
    try {
        const res = yield call(profileApi.getPartnerStamps, id);
        yield put(getPartnerStampsSucceed(res));
    } catch (error) {
        yield put(getPartnerStampsFailed(error));
    }
}
