/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Button } from 'react-bootstrap';
import { FaRegPlayCircle, FaRegEye, FaRegCheckCircle } from 'react-icons/fa';
import Iframe from 'react-iframe';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './MyHipscans.css';
import QRCodeImage from '../../assets/images/qr-code.png';
import EmptyQRCodeImage from '../../assets/images/empty-qr-code.svg';
import Header2 from '../../components/Header2/Header2';
import { MdEdit, MdSave, MdCancel } from 'react-icons/md';
import { FaTrashAlt, FaPhotoVideo } from 'react-icons/fa';
import { getHipscans, updateHipscan } from '../../services/profile/profileActions';
import { showModal } from '../../modals/ModalConductorActions';
import ReactPlayer from 'react-player';
import ProfilePhone from '../../components/ProfilePhone/ProfilePhone';
import ProductPhone from '../../components/ProductPhone/ProductPhone';
import MenuPhone from '../../components/MenuPhone/MenuPhone';
import StatisticsNew from '../../components/StatisticsNew';
import { getProfile } from "../../services/auth/authActions";

class MyHipscans extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			videoURL: '',
			hipscanPreview: {},
			editHipscan: false,
			editHipscan2: false,
			mainHipscanLabel: 'Main Hipscan',
			secondHipscanLabel: 'Hacienda_Location',
			thirdHipscanLabel: 'Brooklyn_Location',

			eyeState: false,
			eyeState2: false,

			isEditingLabel: false,
			editingLabelText: '',
			editingIndex: 0,

			linkIframeViewNotWork: false,
			currentPlan: ''
		};
		this.createHipscan = this.createHipscan.bind(this);
		this.eyeHipscan = this.eyeHipscan.bind(this);
		this.eyeHipscan2 = this.eyeHipscan2.bind(this);

		this.editHaciendaLocation = this.editHaciendaLocation.bind(this);
		this.inputHandle = this.inputHandle.bind(this);

		this.editHaciendaLocatio2 = this.editHaciendaLocation2.bind(this);
		this.inputHandle2 = this.inputHandle2.bind(this);

		this.goDetail = this.goDetail.bind(this);
		this.setPreview = this.setPreview.bind(this);
		this.editLabel = this.editLabel.bind(this);

		this.checkIframeLoaded = this.checkIframeLoaded.bind(this);
		this.afterLoading = this.afterLoading.bind(this);
	}

	componentWillMount() {
		this.props.profileActions.getHipscans();
	}
	componentDidMount = async () => {
		this.props.authActions.getProfile();
		if (this.props.profile && !this.props.profile.access) {
		  this.props.history.push("/plans");
		}
	};
	componentWillReceiveProps(nextProps) {
		if (this.props.profile !== nextProps.profile) {
		  this.setState({
			currentPlan:
			  nextProps.profile &&
			  nextProps.profile.access &&
			  nextProps.profile.access.plan_type,
		  });

		  if (!nextProps.profile.access) {
			this.props.history.push("/plans");
		  }
		}
  }
	componentDidUpdate(prevProps) {
		if (this.props.error !== prevProps.error && this.props.error !== null) {
			// toastr.error('Failed', this.props.error);
		}

		if (prevProps.updateHipscan !== this.props.updateHipscan) {
			// toastr.success('Success', this.props.message);
			this.props.myHipscans[this.state.editingIndex].label = this.state.editingLabelText;
			this.props.myHipscans[this.state.editingIndex].editLabel = !this.props.myHipscans[this.state.editingIndex]
				.editLabel
				? true
				: !this.props.myHipscans[this.state.editingIndex].editLabel;
			this.forceUpdate();
			this.setState({ isEditingLabel: !this.state.isEditingLabel });
		}

		if (prevProps.deletedId !== this.props.deletedId) {
			// toastr.success('Success', this.props.message);
			let index = this.props.myHipscans.findIndex(elem => elem.id === this.props.deletedId);
			this.props.myHipscans.splice(index, 1);
			this.forceUpdate();
		}

		if (prevProps.addedHipscan !== this.props.addedHipscan) {
			// toastr.success('Success', this.props.message);
			this.props.myHipscans.push(this.props.addedHipscan);
			this.forceUpdate();
		}

		if(prevProps.profile !== this.props.profile){
			if(!this.props.profile.access){
				this.props.history.push('/plans')
			}
		}
	}

	createHipscan = () => {
		this.props.history.push('/dashboard');
	};

	editHaciendaLocation = () => {
		this.setState({ editHipscan: !this.state.editHipscan });
	};

	inputHandle(label) {
		this.setState({ editingLabelText: label });
	}

	editHaciendaLocation2 = () => {
		this.setState({ editHipscan2: !this.state.editHipscan2 });
	};
	inputHandle2(e) {
		this.setState({ secondHipscanLabel: e.target.value });
	}

	eyeHipscan = () => {
		this.setState({ eyeState: !this.state.eyeState });
	};
	eyeHipscan2 = () => {
		this.setState({ eyeState2: !this.state.eyeState2 });
	};

	goDetail = id => {
		this.props.history.push(`/myhipscans/detail/${id}`);
	};

	setPreview = item => {
		if (item.type === 'profile') {
			item.contact.isIncludedVideo = item.contact.video_url !== '' ? true : false;
		}
		if (item.type === 'product') {
			item.isIncludedVideo = item.video !== '' ? true : false;
		}
		this.setState({ hipscanPreview: item });
	};

	editLabel = (item, type, index) => {
		if (type === 'cancel') {
			this.setState({ isEditingLabel: !this.state.isEditingLabel });
			item.editLabel = false;
			return;
		}
		if (type === 'edit' && this.state.isEditingLabel) {
			// toastr.error('Failed', 'Please save first');
			return;
		}

		if (item.editLabel) {
			let params = {
				type: item.type,
				label: this.state.editingLabelText,
			};
			if (params.type === 'url') params.url = item.url;
			else if (params.type === 'text') params.text = item.text;
			else if (params.type === 'video') params.video = item.video;
			else if (params.type === 'flyer') {
				params['user_id'] = item.user_id;
				params['preview_url'] = item.preview_url;
				params['design_type'] = item.design_type;
				params['design_id'] = item.design_type;
			}
			this.props.profileActions.updateHipscan(item.id, params);
		}
		if (type === 'edit') {
			this.setState({ editingLabelText: item.label });
			this.setState({ editingIndex: index });
			this.setState({ isEditingLabel: !this.state.isEditingLabel });
			item.editLabel = true;
		}
	};

	onLoadIframeView = e => {
		this.checkIframeLoaded('view');
	};

	checkIframeLoaded(type) {
		// Get a handle to the iframe element
		var iframe = document.getElementById('iframe_' + type);
		if (iframe) {
			try {
				var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

				// Check if loading is complete
				if (iframeDoc.readyState === 'complete') {
					//iframe.contentWindow.alert("Hello");
					iframe.contentWindow.onload = function () {
						// alert('I am loaded');
					};
					// The loading is complete, call the function we want executed once the iframe is loaded
					this.afterLoading(type);
					return;
				}

				// if (type === 'view') {
				// 	this.setState({ linkIframeViewNotWork: true });
				// } else {
				// 	this.setState({ linkIframeNotWork: true });
				// }
			} catch (error) {
				if (type === 'view') {
					this.setState({ linkIframeViewNotWork: true });
				} else {
					this.setState({ linkIframeNotWork: true });
				}
			}

			// If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
			window.setTimeout(this.checkIframeLoaded(), 100);
		}
	}

	afterLoading(type) {
		// alert('I am here');
		if (type === 'view') {
			this.setState({ linkIframeViewNotWork: false });
		} else {
			this.setState({ linkIframeNotWork: false });
		}
	}

	render() {
		const { linkIframeViewNotWork, currentPlan } = this.state;

		return (
			<div className="my-hipscans">
				<div className="hipscan d-none d-md-flex">
					<div className="action-part">
						<img src={QRCodeImage} className="action-image" alt="qrimage" />
						<Header2 {...this.props} />
						<div className="action xxx">
							<div className="title">
								<h1>My Hipscans</h1>
								{ currentPlan === 'premium' && <p>
									Manage all your hipscans here. Please note that each account has a limit of 25
									Hipscans.
								</p> }
							</div>
							{ currentPlan === 'premium' && <div className="new-create-btn">
								<Button onClick={() => this.props.modalActions.showModal('ADD_HIPSCAN')}>
									CREATE NEW HIPSCAN
								</Button>
							</div> }
						</div>
						<div className="qr-s">
							{this.props.myHipscans &&
								this.props.myHipscans.map((item, index) => (
									<div
										key={index}
										className={
											index % 3 === 0 ? 'qr-first' : index % 3 === 1 ? 'qr-second' : 'qr-third'
										}
									>
										{item.editLabel === true ? (
											<div className="label">
												<input
													type="text"
													value={this.state.editingLabelText}
													onChange={e => this.inputHandle(e.target.value)}
												></input>
												<div className="save-cancel">
													<MdSave
														className="icon"
														size="1.4rem"
														onClick={() => this.editLabel(item, 'save')}
													/>
													<MdCancel
														className="icon"
														size="1.4rem"
														onClick={() => this.editLabel(item, 'cancel')}
													/>
												</div>
											</div>
										) : (
											<div className="label">
												<p>{item.label}</p>
												<div className="edit">
													<MdEdit
														className="icon"
														size="1.4rem"
														onClick={() => this.editLabel(item, 'edit', index)}
													/>
												</div>
											</div>
										)}
										<img
											onClick={() => this.goDetail(item.id)}
											className={this.state.hipscanPreview.id === item.id ? 'active' : ''}
											src={item.code_image_url}
											alt="qr"
											onError={e => {
												e.target.src = EmptyQRCodeImage; //replacement image imported above
											}}
										/>
										<div className={item.main === true ? 'eye-edit' : 'eye-edit-delete'}>
											<FaRegEye
												className="eye"
												size="1.4rem"
												onClick={() => this.setPreview(item)}
											/>
											{item.main === false ? (
												<FaTrashAlt
													className="delete"
													size="1.4rem"
													onClick={() =>
														this.props.modalActions.showModal('DELETE_HIPSCAN', {
															id: item.id,
														})
													}
												/>
											) : null}
										</div>
									</div>
								))}
						</div>
					</div>
					<div className="preview-part">
						<div className="title">
							<p>SCREEN PREVIEW</p>
						</div>
						<div className="phone-frame">
							<div className="phone-screen">
								{this.state.hipscanPreview.type === 'url' ? (
									this.state.hipscanPreview.url !== '' ? (
										<>
											{linkIframeViewNotWork === true && (
												<div>
													<FaRegCheckCircle className="prev-icon" size="2.5rem" />
													<h5>
														Woohoo! Although this URL does not support preview, this URL has
														been mapped to your Hipscan QR. Go ahead and give it a scan.
													</h5>
												</div>
											)}

											<Iframe
												// onLoad={this.onLoadIframeView}
												url={this.state.hipscanPreview.url}
												width={linkIframeViewNotWork ? '1px' : '100%'}
												height={linkIframeViewNotWork ? '1px' : '100%'}
												id="iframe_view"
												display="initial"
												position="relative"
											/>
										</>
									) : (
										<div>
											<FaPhotoVideo className="prev-icon" size="2.5rem" />
											<h5>Enter URL for Preview</h5>
										</div>
									)
								) : this.state.hipscanPreview.type === 'text' ? (
									this.state.hipscanPreview.text !== '' ? (
										<div className="preview-text-container">
											{/* <IoIosInformationCircle className="prev-icon" size="2.5rem" /> */}
											<div className="preview-text">{this.state.hipscanPreview.text}</div>
										</div>
									) : (
										<div className="preview-text-container">
											{/* <IoIosInformationCircle className="prev-icon" size="2.5rem" /> */}
											<div className="preview-text">Custom Text for Preview</div>
										</div>
									)
								) : this.state.hipscanPreview.type === 'image' ? (
									this.state.hipscanPreview.image_url !== '' ? (
										<img
											className="image-preview"
											src={this.state.hipscanPreview.image_url}
											alt="upload image"
										/>
									) : (
										<div>
											<FaPhotoVideo className="prev-icon" size="2.5rem" />
											<h5>Upload Image for Preview</h5>
										</div>
									)
								) : this.state.hipscanPreview.type === 'video' ? (
									this.state.hipscanPreview.video !== '' ? (
										<ReactPlayer
											url={this.state.hipscanPreview.video}
											playing
											controls
											width="100%"
											height="100%"
											onPause
										/>
									) : (
										<div>
											<FaRegPlayCircle className="prev-icon" size="2.5rem" />
											<h5>View Video Preview</h5>
										</div>
									)
								) : this.state.hipscanPreview.type === 'profile' ? (
									<ProfilePhone
										name={this.state.hipscanPreview.name}
										description={this.state.hipscanPreview.description}
										file={this.state.hipscanPreview.profile_image_url}
										bFile={this.state.hipscanPreview.contact.background_image_url}
										c_email={this.state.hipscanPreview.contact.email}
										c_number={this.state.hipscanPreview.contact.phone}
										c_website={this.state.hipscanPreview.contact.website_url}
										c_facebook={this.state.hipscanPreview.contact.facebook_url}
										c_instagram={this.state.hipscanPreview.contact.instagram_url}
										c_twitter={this.state.hipscanPreview.contact.twitter_url}
										c_videoURL={this.state.hipscanPreview.contact.video_url}
										isIncludedVideo={this.state.hipscanPreview.contact.isIncludedVideo}
									/>
								) : this.state.hipscanPreview.type === 'product' ? (
									<ProductPhone
										productName={this.state.hipscanPreview.name}
										productPrice={this.state.hipscanPreview.price}
										videoURL={this.state.hipscanPreview.video}
										description={this.state.hipscanPreview.description}
										fileUpload={this.state.hipscanPreview.product_image_url}
									/>
								) : this.state.hipscanPreview.type === 'menu' ? (
									<MenuPhone
										restaurantName={this.state.hipscanPreview.name}
										description={this.state.hipscanPreview.description}
										uploadFile={this.state.hipscanPreview.menu_logo_url}
										themeColor={this.state.hipscanPreview.theme_color}
										bFile={this.state.hipscanPreview.contact.cover_image_url}
										c_email={this.state.hipscanPreview.contact.email}
										c_number={this.state.hipscanPreview.contact.phone}
										c_website={this.state.hipscanPreview.contact.website_url}
										c_facebook={this.state.hipscanPreview.contact.facebook_url}
										c_instagram={this.state.hipscanPreview.contact.instagram_url}
										c_twitter={this.state.hipscanPreview.contact.twitter_url}
										c_address={this.state.hipscanPreview.contact.address}
									/>
								) : this.state.hipscanPreview.type === 'flyer' ? (
									<Iframe
										url={this.state.hipscanPreview.preview_url}
										width={'100%'}
										height={'100%'}
										id="iframe_view"
										display="initial"
										position="relative"
									/>
								): null}
							</div>
						</div>
					</div>
				</div>
				<div className="mob-hipscan d-block d-md-none">
					<div className="action-part">
						<img src={QRCodeImage} className="action-image" alt="qrimage" />
						<Header2 {...this.props} />
						<div className="action">
							<div className="title">
								<h1>My Hipscans</h1>
								{ currentPlan === 'premium' && <p>
									Manage all your hipscans here. Please note that each account has a limit of 25
									Hipscans.
								</p> }
							</div>
							{ currentPlan === 'premium' && <div className="new-create-btn">
								<Button onClick={() => this.props.modalActions.showModal('ADD_HIPSCAN')}>
									CREATE NEW HIPSCAN
								</Button>
							</div> }
						</div>
						<div className="qr-s">
							{this.props.myHipscans &&
								this.props.myHipscans.length > 0 &&
								this.props.myHipscans.map((item, index) => (
									<div key={index} className="qr">
										{item.editLabel === true ? (
											<div className="label">
												<input
													type="text"
													value={this.state.editingLabelText}
													onChange={e => this.inputHandle(e.target.value)}
												></input>
												<div className="save-cancel">
													<MdSave
														className="icon"
														size="1.4rem"
														onClick={() => this.editLabel(item, 'save')}
													/>
													<MdCancel
														className="icon"
														size="1.4rem"
														onClick={() => this.editLabel(item, 'cancel')}
													/>
												</div>
											</div>
										) : (
											<div className="label">
												<p>{item.label}</p>
												<div className="edit">
													<MdEdit
														className="icon"
														size="1.4rem"
														onClick={() => this.editLabel(item, 'edit', index)}
													/>
												</div>
											</div>
										)}
										<img
											onClick={() => this.goDetail(item.id)}
											className={this.state.hipscanPreview.id === item.id ? 'active' : ''}
											src={item.code_image_url}
											alt="qr"
											onError={e => {
												e.target.src = EmptyQRCodeImage; //replacement image imported above
											}}
										/>
										<div className={item.main === true ? 'eye-edit' : 'eye-edit-delete'}>
											{item.main === false ? (
												<FaTrashAlt
													className="delete"
													size="1.4rem"
													onClick={() =>
														this.props.modalActions.showModal('DELETE_HIPSCAN', {
															id: item.id,
														})
													}
												/>
											) : null}
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
				<div className="statistics">
					{/* <Statistics /> */}
					<StatisticsNew />
				</div>
			</div>
		);
	}
}

export default connect(
	state => ({
		...state.default.services.profile,
		...state.default.services.auth,
	}),
	dispatch => ({
		profileActions: bindActionCreators({ getHipscans, updateHipscan }, dispatch),
		modalActions: bindActionCreators({ showModal }, dispatch),
		authActions: bindActionCreators({ getProfile }, dispatch),
	})
)(MyHipscans);
