import { put, takeEvery, call, all, take } from 'redux-saga/effects';

//import actions
import {
    loginFailed,
    loginSucceed,

    socialLoginFailed,
    socialLoginSucced,

    registerFailed,
    registerSucceed,

    getProfileSucceed,
    getProfileFailed,

    updateProfileSucceed,
    updateProfileFailed,

    changePasswordSucceed,
    changePasswordFailed,
} from './authActions';

//import api
import * as authApi from './authApi';

export function* authSubscriber() {
    yield all([takeEvery('LOGIN', login)]);
    yield all([takeEvery('SOCIAL_LOGIN', socialLogin)]);
    yield all([takeEvery('REGISTER', register)]);
    yield all([takeEvery('GET_PROFILE', getProfile)]);
    yield all([takeEvery('UPDATE_PROFILE', updateProfile)]);
    yield all([takeEvery('CHANGE_PASSWORD', changePassword)]);
}

export function* login({ payload: { login, password } }) {
    try {
        const token = yield call(authApi.login, login, password);
        yield put(loginSucceed(token));
    } catch (error) {
        yield put(loginFailed(error));
    }
}

export function* socialLogin({ payload: { uuid } }) {
    try {
        const token = yield call(authApi.socialLogin, uuid);
        yield put(loginSucceed(token));
    } catch (error) {
        yield put(socialLoginFailed(error));
    }
}

export function* register({ payload: { params } }) {
    try {
        const user_id = yield call(authApi.register, params);
        const { token } = user_id
        const t = { token: token }
        yield put(registerSucceed(t));
    } catch (error) {
        yield put(registerFailed(error));
    }
}

export function* getProfile() {
    try {
        const profile = yield call(authApi.getProfile);
      yield put(getProfileSucceed(profile));
    } catch (error) {
        yield put(getProfileFailed(error));
    }
}

export function* updateProfile({ payload: { params } }) {
    try {
        const profile = yield call(authApi.updateProfile, params);
        yield put(updateProfileSucceed(profile));
    } catch (error) {
        yield put(updateProfileFailed(error));
    }
}

export function* changePassword({ payload: { params } }) {
    try {
        const data = yield call(authApi.changePassword, params);
        yield put(changePasswordSucceed(data));
    } catch (error) {
        yield put(changePasswordFailed(error));
    }
}
