import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Button, Form } from 'react-bootstrap';
import './Login.css';
import SignupImage from '../../assets/images/signup-background.jpg';
import { FaFacebookF, FaGoogle } from 'react-icons/fa';

//import actions
import { login, getProfile } from '../../services/auth/authActions';
import { notification } from 'antd';


class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: "",
            password: "",
        }

        this.change = this.change.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }
    async componentDidUpdate(prevProps) {
        if (this.props.error !== prevProps.error && this.props.error !== null) {
            notification.error({ message: "Login Failed", description: "Username or Password is incorrect" });
        }
        //    if(this.props.success !== prevProps.success && this.props.success ===true) {
        //        toastr.success('Success', this.props.message);
        //    }
        if (prevProps.token !== this.props.token && this.props.token !== null) {
            // toastr.success('Success', this.props.message);
            this.props.authActions.getProfile();

        }
        if (prevProps.profile !== this.props.profile) {
            const { is_admin } = this.props.profile;

            if (is_admin) {
                this.props.history.push('/admin');
            } else {
                localStorage.removeItem('admin_token');
                localStorage.removeItem('viewedBy');
                this.props.history.push(`/myhipscans/detail/${this.props.profile.code.id}`)
            }
            // window.location.reload()
        }

        if (prevProps.message !== this.props.message) {
            // this.props.error && this.props.message && notification.error({ message: "Error", description: this.props.message });
        }
    }

    async componentWillMount() {
        if (localStorage.getItem("token") !== null) {
            await this.props.authActions.getProfile();
        }
    }

    change = (e) => { this.setState({ [e.target.name]: e.target.value }); }

    handleLoginClick = e => {
        e.preventDefault();
        const { login, password } = this.state;
        this.props.authActions.login(login, password);
        // this.props.error && notification.error({ message: "Login Failed", description: "Username or password is incorrect" });
    }
    render() {
        return (
            <div>
                <div className="login-home d-none d-md-block">
                    <div className="login-section">
                        <img src={SignupImage} className="login-image" alt="loginimage" />
                        <Header {...this.props} />
                        <div className="container" id="high">
                            <div className="home">
                                <h1>Login to Hipscan</h1>
                                <div className="info-input">
                                    <Form onSubmit={this.handleLoginClick}>
                                        <Form.Group controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" name="login" placeholder="Username/Email" onChange={this.change} />
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlInput1">
                                            <Form.Control type="password" name="password" placeholder="Password" onChange={this.change} />
                                        </Form.Group>
                                        <Button className="login-btn" type="submit">LOGIN</Button>
                                    </Form>
                                </div>
                                <div className="login-title">
                                    <p>LOGIN WITH SOCIAL</p>
                                    <div className="line"></div>
                                </div>
                                <div className="social-login">
                                    <a className="google-login btn btn-primary" href="https://api.hipscan.com/auth/google/login"><FaGoogle size="1.6em" /> LOGIN WITH GOOGLE</a>
                                    <a className="facebook-login btn btn-primary" href="https://api.hipscan.com/auth/facebook/login"><FaFacebookF size="1.8em" /> LOGIN WITH FACEBOOK</a>
                                </div>
                                {/* <div className="social-login">
                                <Button className="google-login"><FaGoogle size="1.6em"/> LOGIN WITH GOOGLE</Button>
                                <Button className="facebook-login"><FaFacebookF size="1.8em"/> LOGIN WITH FACEBOOK</Button>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="mob-login-home d-block d-md-none">
                    <div className="login-section">
                        <img src={SignupImage} className="login-image" alt="loginimage" />
                        <div className="container" id="high">
                            <div className="row home">
                                <Header {...this.props} />
                                <h1>Login to Hipscan</h1>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col">
                                            <div className="info-input">
                                                <Form>
                                                    <Form.Group controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="email" name="login" placeholder="Username/Email" onChange={this.change} />
                                                    </Form.Group>
                                                    <Form.Group controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="password" name="password" placeholder="Password" onChange={this.change} />
                                                    </Form.Group>
                                                    <Button className="login-btn" onClick={this.handleLoginClick}>LOGIN</Button>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col login-title">
                                            <p>LOGIN WITH SOCIAL</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col social-login">
                                            <a className="google-login btn btn-primary" href="https://api.hipscan.com/auth/google/login"><FaGoogle size="0.7rem" /> LOGIN WITH GOOGLE</a>
                                            <a className="facebook-login btn btn-primary" href="https://api.hipscan.com/auth/facebook/login"><FaFacebookF size="0.7rem" /> LOGIN WITH FACEBOOK</a>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        ...state.default.services.auth
    }),
    dispatch => ({
        authActions: bindActionCreators({ login, getProfile }, dispatch)
    })
)(Login);
