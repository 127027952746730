import { handleActions } from 'redux-actions';

import {
    getHipscansAdminStatsFailed,
    getHipscansAdminStatsSucceed,

    getHipscansAdminUsersSucceed,
    getHipscansAdminUsersFailed,

    postHipscansAdminAutoLoginSucceed,
    postHipscansAdminAutoLoginFailed,

    getHipscansPlanProMonthlyFailed,
    getHipscansPlanProMonthlySucceed,

    getHipscansPlanProYearlyFailed,
    getHipscansPlanProYearlySucceed,

    getHipscansPlanPremiumMonthlyFailed,
    getHipscansPlanPremiumMonthlySucceed,

    getHipscansPlanPremiumYearlyFailed,
    getHipscansPlanPremiumYearlySucceed,

} from './adminActions';
import { getHashes } from 'crypto';
import {
    getHipscansAdminStats,
    getHipscansAdminUsers,

    postHipscansAdminAutoLogin,

    getHipscansPlanProMonthly,
    getHipscansPlanProYearly,
    getHipscansPlanPremiumMonthly,
    getHipscansPlanPremiumYearly,
} from './adminApi';

const defaultState = {
    error: null,
    loading: false,
    message: '',
    success: false,
    plan_free_basic: null,
    plan_pro_monthly: null,
    plan_pro_yearly: null,
    plan_premium_monthly: null,
    plan_premium_yearly: null,
    statsAdmin: null,
    usersManager: [],
    token: null
}

const reducer = handleActions({
    [getHipscansAdminStats](state) {
        return {
            ...state,
            error: null,
            loading: true,
        }
    },
    [getHipscansAdminStatsFailed](state, { payload: { error } }) {
        return {
            ...state,
            error: true,
            loading: false,
        }
    },
    [getHipscansAdminStatsSucceed](state, { payload: { statsAdmin } }) {
        return {
            ...state,
            loading: false,
            statsAdmin
        }
    },

    [getHipscansAdminUsers](state) {
        return {
            ...state,
            error: null,
            loading: true,
        }
    },
    [getHipscansAdminUsersFailed](state, { payload: { error } }) {
        return {
            ...state,
            error: true,
            loading: false,
        }
    },
    [getHipscansAdminUsersSucceed](state, { payload: { usersManager } }) {
        return {
            ...state,
            loading: false,
            usersManager
        }
    },

    [postHipscansAdminAutoLogin](state) {
        return {
            ...state,
            error: null,
            loading: true,
        }
    },
    [postHipscansAdminAutoLoginFailed](state, { payload: { error } }) {
        return {
            ...state,
            error: true,
            loading: false,
        }
    },
    [postHipscansAdminAutoLoginSucceed](state, { payload: { token } }) {
        return {
            ...state,
            loading: false,
            token
        }
    },

    [getHipscansPlanProMonthly](state) {
        return {
            ...state,
            error: null,
            loading: true,
        }
    },
    [getHipscansPlanProMonthlyFailed](state, { payload: { error } }) {
        return {
            ...state,
            error: true,
            loading: false,
        }
    },
    [getHipscansPlanProMonthlySucceed](state, { payload: { plan_pro_monthly } }) {
        return {
            ...state,
            loading: false,
            plan_pro_monthly
        }
    },

    [getHipscansPlanProYearly](state) {
        return {
            ...state,
            error: null,
            loading: true,
        }
    },
    [getHipscansPlanProYearlyFailed](state, { payload: { error } }) {
        return {
            ...state,
            error: true,
            loading: false,
        }
    },
    [getHipscansPlanProYearlySucceed](state, { payload: { plan_pro_yearly } }) {
        return {
            ...state,
            loading: false,
            plan_pro_yearly
        }
    },

    [getHipscansPlanPremiumMonthly](state) {
        return {
            ...state,
            error: null,
            loading: true,
        }
    },
    [getHipscansPlanPremiumMonthlyFailed](state, { payload: { error } }) {
        return {
            ...state,
            error: true,
            loading: false,
        }
    },
    [getHipscansPlanPremiumMonthlySucceed](state, { payload: { plan_premium_monthly } }) {
        return {
            ...state,
            loading: false,
            plan_premium_monthly
        }
    },

    [getHipscansPlanPremiumYearly](state) {
        return {
            ...state,
            error: null,
            loading: true,
        }
    },
    [getHipscansPlanPremiumYearlyFailed](state, { payload: { error } }) {
        return {
            ...state,
            error: true,
            loading: false,
        }
    },
    [getHipscansPlanPremiumYearlySucceed](state, { payload: { plan_premium_yearly } }) {
        return {
            ...state,
            loading: false,
            plan_premium_yearly
        }
    },

}, defaultState);

export default reducer;