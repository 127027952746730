import React from 'react';
import { MdEdit, MdSave, MdCancel } from 'react-icons/md';
import { FaRegEye, FaTrashAlt } from 'react-icons/fa';
import EmptyQRCodeImage from '../../assets/images/empty-qr-code.svg';
import { Button, Modal } from "react-bootstrap";

class Hipscan extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			index: props.index,
			item: props.item,
			hipscanPreview: {},
			editHipscan: false,
			editHipscan2: false,
			mainHipscanLabel: 'Main Hipscan',
			secondHipscanLabel: 'Hacienda_Location',
			thirdHipscanLabel: 'Brooklyn_Location',

			eyeState: false,
			eyeState2: false,

			isEditingLabel: false,
			editingLabelText: '',
			editingIndex: 0,

			linkIframeViewNotWork: false,
			currentPlan: '',

			showViewModal: false
		}

		this.createHipscan = this.createHipscan.bind(this);
		this.eyeHipscan = this.eyeHipscan.bind(this);
		this.eyeHipscan2 = this.eyeHipscan2.bind(this);

		this.editHaciendaLocation = this.editHaciendaLocation.bind(this);
		this.inputHandle = this.inputHandle.bind(this);

		this.editHaciendaLocatio2 = this.editHaciendaLocation2.bind(this);
		this.inputHandle2 = this.inputHandle2.bind(this);

		this.goDetail = this.goDetail.bind(this);
		this.setPreview = this.setPreview.bind(this);
		this.editLabel = this.editLabel.bind(this);

		this.checkIframeLoaded = this.checkIframeLoaded.bind(this);
		this.afterLoading = this.afterLoading.bind(this);
	}

	createHipscan = () => {
		this.props.history.push('/dashboard');
	};

	editHaciendaLocation = () => {
		this.setState({ editHipscan: !this.state.editHipscan });
	};

	inputHandle(label) {
		this.setState({ editingLabelText: label });
	}

	editHaciendaLocation2 = () => {
		this.setState({ editHipscan2: !this.state.editHipscan2 });
	};
	inputHandle2(e) {
		this.setState({ secondHipscanLabel: e.target.value });
	}

	eyeHipscan = () => {
		this.setState({ eyeState: !this.state.eyeState });
	};
	eyeHipscan2 = () => {
		this.setState({ eyeState2: !this.state.eyeState2 });
	};

	goDetail = id => {
		this.props.history.push(`/myhipscans/detail/${id}`);
	};

	setPreview = item => {
		if (item.type === 'profile') {
			item.contact.isIncludedVideo = item.contact.video_url !== '' ? true : false;
		}
		if (item.type === 'product') {
			item.isIncludedVideo = item.video !== '' ? true : false;
		}
	};

	editLabel = (item, type, index) => {
		if (type === 'cancel') {
			this.setState({ isEditingLabel: !this.state.isEditingLabel });
			item.editLabel = false;
			return;
		}
		if (type === 'edit' && this.state.isEditingLabel) {
			// toastr.error('Failed', 'Please save first');
			return;
		}

		if (item.editLabel) {
			let params = {
				type: item.type,
				label: this.state.editingLabelText,
			};
			if (params.type === 'url') params.url = item.url;
			else if (params.type === 'text') params.text = item.text;
			else if (params.type === 'video') params.video = item.video;
			else if (params.type === 'flyer') {
				params['user_id'] = item.user_id;
				params['preview_url'] = item.preview_url;
				params['design_type'] = item.design_type;
				params['design_id'] = item.design_type;
			}
			this.props.profileActions.updateHipscan(item.id, params);
		}
		if (type === 'edit') {
			this.setState({ editingLabelText: item.label });
			this.setState({ editingIndex: index });
			this.setState({ isEditingLabel: !this.state.isEditingLabel });
			item.editLabel = true;
		}
	};

	onLoadIframeView = e => {
		this.checkIframeLoaded('view');
	};

	checkIframeLoaded(type) {
		// Get a handle to the iframe element
		var iframe = document.getElementById('iframe_' + type);
		if (iframe) {
			try {
				var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

				// Check if loading is complete
				if (iframeDoc.readyState === 'complete') {
					//iframe.contentWindow.alert("Hello");
					iframe.contentWindow.onload = function () {
						// alert('I am loaded');
					};
					// The loading is complete, call the function we want executed once the iframe is loaded
					this.afterLoading(type);
					return;
				}

				// if (type === 'view') {
				// 	this.setState({ linkIframeViewNotWork: true });
				// } else {
				// 	this.setState({ linkIframeNotWork: true });
				// }
			} catch (error) {
				if (type === 'view') {
					this.setState({ linkIframeViewNotWork: true });
				} else {
					this.setState({ linkIframeNotWork: true });
				}
			}

			// If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
			window.setTimeout(this.checkIframeLoaded(), 100);
		}
	}

	afterLoading(type) {
		// alert('I am here');
		if (type === 'view') {
			this.setState({ linkIframeViewNotWork: false });
		} else {
			this.setState({ linkIframeNotWork: false });
		}
	}

	render() {
		return (
			<div
				key={this.props.index}
				className={
					`${this.props.index % 3 === 0 ? 'qr-first' : this.props.index % 3 === 1 ? 'qr-second' : 'qr-third'} text-center`
				}
			>
				{this.props.item.editLabel === true ? (
					<div className="label">
						<input
							type="text"
							value={this.state.editingLabelText}
							onChange={e => this.inputHandle(e.target.value)}
						></input>
						<div className="save-cancel">
							<MdSave
								className="icon"
								size="1.4rem"
								onClick={() => this.editLabel(this.props.item, 'save')}
							/>
							<MdCancel
								className="icon"
								size="1.4rem"
								onClick={() => this.editLabel(this.props.item, 'cancel')}
							/>
						</div>
					</div>
				) : (
					<div className="label">
						<p>{this.props.item.label}</p>
						<div className="edit">
							<MdEdit
								className="icon"
								size="1.4rem"
								onClick={() => this.editLabel(this.props.item, 'edit', this.props.index)}
							/>
						</div>
					</div>
				)}
				<img
					onClick={() => this.goDetail(this.props.item.id)}
					className={this.state.hipscanPreview.id === this.props.item.id ? 'active' : ''}
					src={require('../../assets/images/qr-2-f4b8ec0e8f82f20281957616e37fc9b7.png')}
					alt="qr"
					width="200px"
					height="200px"
					onError={e => {
						e.target.src = EmptyQRCodeImage; //replacement image imported above
					}}
				/>
				<div className={this.props.item.main === true ? 'eye-edit' : 'eye-edit-delete'}>
					<FaRegEye
						className="eye"
						size="1.4rem"
						onClick={() => this.setPreview(this.props.item)}
					/>
					{this.props.item.main === false ? (
						<FaTrashAlt
							className="delete"
							size="1.4rem"
							onClick={() =>
								this.props.modalActions.showModal('DELETE_HIPSCAN', {
									id: this.props.item.id,
								})
							}
						/>
					) : null}
				</div>
				<Modal
					show={this.props.showViewModal}
					onHide={() => this.setState({ showViewModal: false })}
					dialogClassName="launch-event-modal"
					className="d-none d-md-block"
					aria-labelledby="example-custom-modal-styling-title"
				>
					<Modal.Header closeButton>
						<Modal.Title id="example-custom-modal-styling-title">
							qr
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="body-container">
							ahjscsja
						</div>
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}
export default Hipscan;
