import React from 'react'
import './index.scss'
import Img1 from '../../assets/icons/stats/ic1.svg'
import Img2 from '../../assets/icons/stats/ic2.svg'
import Img3 from '../../assets/icons/stats/ic3.svg'
import P1 from '../../assets/icons/stats/p1.png'
import P2 from '../../assets/icons/stats/p2.png'

import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import CanvasJSReact from '../../assets/canvas/canvasjs.react'
import DatePicker from 'react-datepicker'
import {
  FaQrcode,
  FaUser,
  FaRegClock,
  FaRegCalendar,
  FaSearch,
} from 'react-icons/fa'

import CountUp from 'react-countup'
import { ProgressBar } from 'react-bootstrap'
import Chart from 'react-google-charts'
import Moment from 'moment'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getHipscanStats,
  getHipscanTotalStats,
  getHipscanFilter,
} from '../../services/profile/profileActions'

var CanvasJSChart = CanvasJSReact.CanvasJSChart

class StatisticsNew extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: '',
      endDate: '',
      selectedDataType: 'day',
      scans: 0,
      unique_users: 0,
      scans_48_h: 0,
      filter: {},
      device: {},
      device_type: {},
      geo: [],
    }
    this.onChangeDateType = this.onChangeDateType.bind(this)
  }

  formatDate(date) {
    if (date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    }

    return ''
  }

  componentWillMount() {
    if (this.props.id) {
      this.props.profileActions.getHipscanStats(this.props.id)
      this.props.profileActions.getHipscanFilter(
        this.props.id,
        this.state.startDate,
        this.state.endDate,
        this.state.selectedDataType
      )
    } else {
      this.props.profileActions.getHipscanTotalStats()
      this.props.profileActions.getHipscanFilter(
        '',
        this.state.startDate,
        this.state.endDate,
        this.state.selectedDataType
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.hipscanStats !== this.props.hipscanStats) {
      if (this.props.hipscanStats.scans !== null) {
        this.setState({ scans: this.props.hipscanStats.scans })
        this.setState({ unique_users: this.props.hipscanStats.unique_users })
        this.setState({ scans_48_h: this.props.hipscanStats.scans_48_h })
        this.setState({ device: this.props.hipscanStats.device })
        this.setState({ device_type: this.props.hipscanStats.device_type })
        this.setState({ geo: this.props.hipscanStats.geo })
      }
    }
    if (prevProps.hipscanTotalStats !== this.props.hipscanTotalStats) {
      if (this.props.hipscanTotalStats.scans !== null) {
        this.setState({ scans: this.props.hipscanTotalStats.scans })
        this.setState({ unique_users: this.props.hipscanTotalStats.unique_users })
        this.setState({ scans_48_h: this.props.hipscanTotalStats.scans_48_h })
        this.setState({ device: this.props.hipscanTotalStats.device })
        this.setState({ device_type: this.props.hipscanTotalStats.device_type })
        this.setState({ geo: this.props.hipscanTotalStats.geo })
      }
    }
    if (prevProps.hipscanFilter !== this.props.hipscanFilter) {
      this.setState({ filter: this.props.hipscanFilter })
    }

    if (
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.selectedDataType !== this.state.selectedDataType
    ) {
      if (this.props.id) {
        this.props.profileActions.getHipscanFilter(
          this.props.id,
          this.formatDate(this.state.startDate),
          this.formatDate(this.state.endDate),
          this.state.selectedDataType
        )
      } else {
        this.props.profileActions.getHipscanFilter(
          '',
          this.formatDate(this.state.startDate),
          this.formatDate(this.state.endDate),
          this.state.selectedDataType
        )
      }
    }
  }
  startDateHandleChange = (date) => {
    this.setState({
      startDate: date,
    })
  }
  endDateHandleChange = (date) => {
    this.setState({
      endDate: date,
    })
  }

  onChangeDateType = (type) => {
    this.setState({
      selectedDataType: type,
    })
  }

  onGetValueGeoUS = (data) => {
    for (let i = 0; i < data.length; i++) {
      const el = data[i]
      if (el[0] === 'United States') {
        return el[1]
      }
    }
    // if (obj) {
    //   for (var i in obj) {
    //     if (obj.hasOwnProperty(i)) {
    //       return obj[1]
    //     }
    //   }
    // }
    return 0
  }

  render() {
    let dataPoints = []
    let hasPoints = false
    for (let key in this.state.filter) {
      let val = this.state.filter[key]
      let date = key
      // if (this.state.selectedDataType === 'week') {
      //   date = Moment().year(2020).week(26).day('Sunday').format('YYYY-MM-DD')
      //   date += ' ~ '
      //   date += Moment().year(2020).week(26).day('Saturday').format('DD')
      // }
      dataPoints.push({ label: date, y: val })
      hasPoints = true
    }

    const options = {
      animationEnabled: true,
      theme: 'light2',
      data: [
        {
          type: 'area',
          color: '#f36e21',
          dataPoints: dataPoints,
        },
      ],
    }

    const { device, geo, device_type } = this.state
    return (
      <div className='statistic-new'>
        <h1>Statistics</h1>
        <div className='stats-content'>
          <div className='stats-box stats-w20'>
            <div className='img-logo'>
              <img src={Img1} alt='' />
            </div>
            <CountUp className='count' end={this.state.scans} duration={5} />
            <p>SCANS</p>
          </div>
          <div className='stats-box stats-w20'>
            <div className='img-logo'>
              <img src={Img2} alt='' />
            </div>
            <CountUp
              className='count'
              end={this.state.unique_users}
              duration={5}
            />
            <p>UNIQUE USERS</p>
          </div>
          <div className='stats-box stats-w20'>
            <div className='img-logo'>
              <img src={Img3} alt='' />
            </div>
            <CountUp
              className='count'
              end={this.state.scans_48_h}
              duration={5}
            />
            <p>SCANS OVER LAST 48 HOURS</p>
          </div>
          <div className='stats-box stats-w40 stats-last'>
            <div className='prs-item'>
              <span className='title'>iPhone</span>
              <span className='total'>{device.iphone}</span>
              <ProgressBar
                now={device.iphone_percent ? device.iphone_percent : 0}
                label={`${device.iphone_percent ? device.iphone_percent : 0}%`}
              />
            </div>
            <div className='prs-item'>
              <span className='title'>Android</span>
              <span className='total'>{device.android}</span>
              <ProgressBar
                now={device.android_percent ? device.android_percent : 0}
                label={`${
                  device.android_percent ? device.android_percent : 0
                }%`}
              />
            </div>
            <div className='prs-item'>
              <span className='title'>Blackberry</span>
              <span className='total'>{device.blackberry}</span>
              <ProgressBar
                now={device.blackberry_percent ? device.blackberry_percent : 0}
                label={`${
                  device.blackberry_percent ? device.blackberry_percent : 0
                }%`}
              />
            </div>
            <div className='prs-item'>
              <span className='title'>Windows Phone</span>
              <span className='total'>{device.windows_phone}</span>
              <ProgressBar
                now={
                  device.windows_phone_percent
                    ? device.windows_phone_percent
                    : 0
                }
                label={`${
                  device.windows_phone_percent
                    ? device.windows_phone_percent
                    : 0
                }%`}
              />
            </div>
          </div>
        </div>
        <div className='stats-content'>
          <div className='stats-box stats-w35 stats-first'>
            <div className='process-detail'>
              <CircularProgressbar
                value={
                  device_type.mobile_percent ? device_type.mobile_percent : 0
                }
                text={`SCANS BY DEVICES`}
              />
            </div>
            <div className='info-scans'>
              <div className='info-item'>
                <img src={P1} alt='' />
                <div className='detail'>
                  <h4>
                    MOBILE ({' '}
                    {device_type.mobile_percent
                      ? device_type.mobile_percent
                      : 0}
                    %)
                  </h4>
                  <h3>{device_type.mobile ? device_type.mobile : 0}</h3>
                </div>
              </div>
              <div className='info-item'>
                <img src={P2} alt='' />
                <div className='detail detail-dark'>
                  <h4>
                    TABLET (
                    {device_type.table_percent ? device_type.table_percent : 0}
                    %)
                  </h4>
                  <h3>{device_type.tablet ? device_type.tablet : 0}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className='stats-box stats-w60 stats-first'>
            <Chart
              className='chart-geo'
              width={'400px'}
              height={'166px'}
              chartType='GeoChart'
              data={[['Country', '']].concat(geo)}
              // data={[
              //   ['Country', ''],
              //   ['Germany', 200],
              //   ['United States', 300],
              //   ['Brazil', 400],
              //   ['Canada', 500],
              //   ['France', 600],
              //   ['RU', 700]
              // ]}
              // Note: you will need to get a mapsApiKey for your project.
              // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
              mapsApiKey='AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY'
              rootProps={{ 'data-testid': '1' }}
              options={{
                colorAxis: { colors: ['#f58113', '#f58113'] },
                legend: 'none',
                pieSliceText: 'none',
              }}
            />
            <div className='geo-info'>
              <h4>UNITED STATES</h4>
              <h3>{this.onGetValueGeoUS(geo)}</h3>
            </div>
          </div>
        </div>

        <div className='user-analytics'>
          <div className='row filter'>
            <div className='col-md-12 during'>
              <span
                className={
                  'during-item day ' +
                  (this.state.selectedDataType === 'day' ? 'active' : '')
                }
                onClick={() => this.onChangeDateType('day')}
              >
                DAY
              </span>
              <span
                className={
                  'during-item week ' +
                  (this.state.selectedDataType === 'week' ? 'active' : '')
                }
                onClick={() => this.onChangeDateType('week')}
              >
                WEEK
              </span>
              <span
                className={
                  'during-item month ' +
                  (this.state.selectedDataType === 'month' ? 'active' : '')
                }
                onClick={() => this.onChangeDateType('month')}
              >
                MONTH
              </span>
            </div>
            <div className='col-md-12 firstdate'>
              <DatePicker
                placeholderText='START DATE'
                selected={this.state.startDate}
                onChange={this.startDateHandleChange}
                dateFormat='d MMMM yyyy'
              />
              <FaRegCalendar className='cal' />
            </div>
            <div className='col-md-12 enddate'>
              <DatePicker
                selected={this.state.endDate}
                onChange={this.endDateHandleChange}
                placeholderText='END DATE'
                dateFormat='d MMMM yyyy'
              />
              <FaRegCalendar className='cal' />
            </div>
          </div>
          <div className='chart-part' style={{ position: 'relative' }}>
            {/* <CanvasJSChart options={options} /> */}
            {hasPoints ? (
              <CanvasJSChart options={options} />
            ) : (
              <p style={{ textAlign: 'center' }}>
                There are no stats yet, but don’t worry as soon as you start
                sharing your Hipscan QR you will see the data populate here.
              </p>
            )}
            <div
              className='hideTrial'
              style={{
                height: '12px',
                width: '100px',
                position: 'absolute',
                bottom: '0',
                zIndex: '999',
                backgroundColor: 'white',
              }}
            ></div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    ...state.default.services.profile,
  }),
  (dispatch) => ({
    profileActions: bindActionCreators(
      { getHipscanStats, getHipscanTotalStats, getHipscanFilter },
      dispatch
    ),
  })
)(StatisticsNew)
