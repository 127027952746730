import React from "react";
import Header2 from "../../components/Header2/Header2";
import Statistics from "../../components/Statistics/Statistics";
import QRCodeImage from "../../assets/images/qr-code.png";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "./Profile.css";
import ChangePasswordModal from "../../components/ChangePasswordModal/ChangePasswordModal";
import { getProfile } from "../../services/auth/authActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateProfile } from "../../services/auth/authActions";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: { value: "", isTouched: false },
        firstname: { value: "", isTouched: false },
        lastname: { value: "", isTouched: false },
        username: { value: "", isTouched: false },
      },
      changePwShow: false,
      isFormatEmail: false,
      userInfo: {},
      currentPlan: "",
      loadingPage: false,
    };
  }

  changePwModalShow = () => {
    this.setState({ changePwShow: true });
  };
  changePwModalHide = () => {
    this.setState({ changePwShow: false });
  };

  getCurrentPlan = (plan) => {
    if (plan === "free") {
      return "Free";
    } else if (plan === "pro_monthly" || plan === "pro_annually") {
      return "Hipscan Pro";
    } else if (plan === "premium_monthly" || plan === "premium_annually") {
      return "Hipscan Premium";
    } else return plan;
  };

  componentDidMount = async () => {
    this.props.authActions.getProfile();
    if (this.props.profile && !this.props.profile.access) {
      this.props.history.push("/plans");
    }
  };

  onChangeValue = (field, value) => {
    let form = this.state.form;
    form[field].value = value;
    this.setState({ form });
  };

  onTouched = (field) => {
    let form = this.state.form;
    form[field].isTouched = true;
    this.setState({
      form,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.profile !== nextProps.profile) {
      let form = this.state.form;
      const fields = ["email", "firstname", "lastname", "username"];
      for (let i = 0; i < fields.length; i++) {
        form[fields[i]].value = nextProps.profile[fields[i]]
          ? nextProps.profile[fields[i]]
          : "";
      }

      this.setState({
        form,
        currentPlan:
          nextProps.profile &&
          nextProps.profile.access &&
          nextProps.profile.access.plan_type,
      });

      if (!nextProps.profile.access) {
        this.props.history.push("/plans");
      }
    }
    if (this.props.updatedPF !== nextProps.updatedPF) {
      this.setState({ loadingPage: false });
    }
  }

  checkEmail = () => {
    this.onTouched("email");
    const email = this.state.form.email.value;
    const formatMail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
      email
    );
    if (!formatMail && email.length > 0) {
      this.setState({
        isFormatEmail: true,
      });
      return false;
    } else {
      this.setState({
        isFormatEmail: false,
      });
    }
  };

  goDetail = (id) => {
    this.props.history.push(`/myhipscans/detail/${id}`);
  };

  onSubmit = async () => {
    this.setState({ loadingPage: true });
    let isError = false;
    let form = this.state.form;
    let datas = {};
    const fields = ["email", "firstname", "username", "lastname"];

    for (let i = 0; i < fields.length; i++) {
      form[fields[i]].isTouched = true;
      if (form[fields[i]].value === null || form[fields[i]].value.length < 1) {
        isError = true;
      }
    }

    this.setState({ form, isError: false });
    if (isError) return;

    for (let i = 0; i < fields.length; i++) {
      datas[fields[i]] = this.state.form[fields[i]].value;
    }
    await this.props.authActions.updateProfile(datas);
  };

  render() {
    let mainId = null;
    const width = window.innerWidth;
    const { form, isFormatEmail, currentPlan } = this.state;
    if (localStorage.getItem("mainid") != null) {
      mainId = localStorage.getItem("mainid");
    }

    return (
      // <Spin spinning={loadingPage}>
      <div className="detail-my-hipscan profile">
        {width > 767 ? (
          <div className="hipscan d-none d-md-flex">
            <div className="action-part">
              <img src={QRCodeImage} className="action-image" alt="qrimage" />
              <Header2 {...this.props} />
              <div className="action-edit-menu profile-form">
                <div className="username-input">
                  <input
                    type="text"
                    placeholder="userName"
                    value={form.username.value}
                    onChange={(e) =>
                      this.onChangeValue("username", e.target.value)
                    }
                    onBlur={() => this.onTouched("username")}
                  ></input>
                  <p
                    style={{
                      display:
                        form.username.isTouched &&
                        form.username.value.length < 1
                          ? "block"
                          : "none",
                    }}
                    className="error-message"
                  >
                    * Username is required
                  </p>
                </div>
                <div className="input-field">
                  <div className="common-input">
                    <input
                      type="text"
                      placeholder="First Name"
                      value={form.firstname.value}
                      onChange={(e) =>
                        this.onChangeValue("firstname", e.target.value)
                      }
                      onBlur={() => this.onTouched("firstname")}
                    ></input>
                    <p
                      style={{
                        display:
                          form.firstname.isTouched &&
                          form.firstname.value.length < 1
                            ? "block"
                            : "none",
                      }}
                      className="error-message"
                    >
                      * First Name is required
                    </p>
                  </div>
                  <div className="common-input">
                    <input
                      type="text"
                      placeholder="Last Name"
                      value={form.lastname.value}
                      onChange={(e) =>
                        this.onChangeValue("lastname", e.target.value)
                      }
                      onBlur={() => this.onTouched("lastname")}
                    ></input>
                    <p
                      style={{
                        display:
                          form.lastname.isTouched &&
                          form.lastname.value.length < 1
                            ? "block"
                            : "none",
                      }}
                      className="error-message"
                    >
                      * Last Name is required
                    </p>
                  </div>
                </div>
                <div className="email-input">
                  <input
                    type="email"
                    placeholder="Email"
                    value={form.email.value}
                    onChange={(e) =>
                      this.onChangeValue("email", e.target.value)
                    }
                    onBlur={() => this.checkEmail()}
                  />
                  <p
                    style={{
                      display:
                        form.email.value.length > 0 && isFormatEmail
                          ? "block"
                          : "none",
                    }}
                    className="error-message"
                  >
                    * Please Check Email Format
                  </p>
                  <p
                    style={{
                      display:
                        form.email.isTouched && form.email.value.length < 1
                          ? "block"
                          : "none",
                    }}
                    className="error-message"
                  >
                    * Email is required
                  </p>
                </div>
                <div className="return">
                  <p onClick={() => this.goDetail(mainId)}>
                    {" "}
                    {"< Return Without Saving"}{" "}
                  </p>
                </div>
                <div className="btns">
                  <div className="contact-info-btn">
                    <Button onClick={this.onSubmit}>UPDATE PROFILE</Button>
                  </div>
                  <div className="goto-menu-btn">
                    <Button onClick={this.changePwModalShow}>
                      CHANGE PASSWORD
                    </Button>
                    <ChangePasswordModal
                      show={this.state.changePwShow}
                      modalHide={this.changePwModalHide}
                      hipId={this.props.match.params.id}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="preview-part upgrade-plan">
              <p>YOUR CURRENT PLAN</p>
              <h1>
                {this.getCurrentPlan(currentPlan)}
                {this.props.profile &&
                  this.props.profile.access &&
                  this.props.profile.access.trial && (
                    <p style={{ fontSize: '18px' }} className="text-trial">(30-days trial)</p>
                  )}
              </h1>

              <p className="upgrade-link">
                Enjoy more powerful features on Hipscan. <br />
                <Link to="/plans" className="gotoUpgrade">
                  <span> UPGRADE PLAN NOW!</span>
                </Link>
              </p>
              <div className="change-plan-btn">
                <Link to="/plans" className="gotoUpgrade">
                  <Button> CHANGE PLAN</Button>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="mob-hipscan d-block d-md-none">
            <div className="action-part">
              <Header2 {...this.props} />
              <div className="action-edit-menu profile-form">
                <div className="username-input">
                  <input
                    type="text"
                    placeholder="userName"
                    value={form.username.value}
                    onChange={(e) =>
                      this.onChangeValue("username", e.target.value)
                    }
                    onBlur={() => this.onTouched("username")}
                  ></input>
                  <p
                    style={{
                      display:
                        form.username.isTouched &&
                        form.username.value.length < 1
                          ? "block"
                          : "none",
                    }}
                    className="error-message"
                  >
                    * Username is required
                  </p>
                </div>
                <div className="input-field">
                  <div className="common-input">
                    <input
                      type="text"
                      placeholder="First Name"
                      value={form.firstname.value}
                      onChange={(e) =>
                        this.onChangeValue("firstname", e.target.value)
                      }
                      onBlur={() => this.onTouched("firstname")}
                    ></input>
                    <p
                      style={{
                        display:
                          form.firstname.isTouched &&
                          form.firstname.value.length < 1
                            ? "block"
                            : "none",
                      }}
                      className="error-message"
                    >
                      * First Name is required
                    </p>
                  </div>
                  <div className="common-input">
                    <input
                      type="text"
                      placeholder="Last Name"
                      value={form.lastname.value}
                      onChange={(e) =>
                        this.onChangeValue("lastname", e.target.value)
                      }
                      onBlur={() => this.onTouched("lastname")}
                    ></input>
                    <p
                      style={{
                        display:
                          form.lastname.isTouched &&
                          form.lastname.value.length < 1
                            ? "block"
                            : "none",
                      }}
                      className="error-message"
                    >
                      * Last Name is required
                    </p>
                  </div>
                </div>
                <div className="email-input">
                  <input
                    type="email"
                    placeholder="Email"
                    value={form.email.value}
                    onChange={(e) =>
                      this.onChangeValue("email", e.target.value)
                    }
                    onBlur={() => this.checkEmail()}
                  />
                  <p
                    style={{
                      display:
                        form.email.value.length > 0 && isFormatEmail
                          ? "block"
                          : "none",
                    }}
                    className="error-message"
                  >
                    * Please Check Email Format
                  </p>
                  <p
                    style={{
                      display:
                        form.email.isTouched && form.email.value.length < 1
                          ? "block"
                          : "none",
                    }}
                    className="error-message"
                  >
                    * Email is required
                  </p>
                </div>

                <div className="btns">
                  <div className="contact-info-btn">
                    <Button onClick={this.onSubmit}>UPDATE PROFILE</Button>
                  </div>
                  <div className="goto-menu-btn">
                    <Button onClick={this.changePwModalShow}>
                      CHANGE PASSWORD
                    </Button>
                    <ChangePasswordModal
                      show={this.state.changePwShow}
                      modalHide={this.changePwModalHide}
                      hipId={this.props.match.params.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="statistics">
          <Statistics id={this.props.match.params.id} />
        </div>
      </div>
      // </Spin>
    );
  }
}

export default connect(
  (state) => ({
    ...state.default.services.auth,
  }),
  (dispatch) => ({
    authActions: bindActionCreators({ getProfile, updateProfile }, dispatch),
  })
)(Profile);
