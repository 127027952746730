import { handleActions } from 'redux-actions';

import {
    getHipscansFailed,
    getHipscansSucceed,
    getHipscanDetailSucceed,
    getHipscanDetailFailed,
    addHipscanSucceed,
    addHipscanFailed,
    updateHipscanSucceed,
    updateHipscanFailed,
    updateContactSucceed,
    updateContactFailed,
    getHipscanByUuidSucceed,
    getHipscanByUuidFailed,
    downloadQrCodeSucceed,
    downloadQrCodeFailed,
    getHipscanStatsSucceed,
    getHipscanStatsFailed,
    deleteHipscanSucceed,
    deleteHipscanFailed,
    getHipscanCategories,
    getHipscanCategoriesSucceed,
    getHipscanCategoriesFailed,
    saveHipscanCategory,
    saveHipscanCategorySucceed,
    saveHipscanCategoryFailed,
    updateHipscanCategory,
    updateHipscanCategorySucceed,
    updateHipscanCategoryFailed,
    deleteHipscanCategory,
    deleteHipscanCategorySucceed,
    deleteHipscanCategoryFailed,
    getCategoryProduct,
    getCategoryProductSucceed,
    getCategoryProductFailed,
    saveCategoryProduct,
    saveCategoryProductSucceed,
    saveCategoryProductFailed,
    updateCategoryProduct,
    updateCategoryProductSucceed,
    updateCategoryProductFailed,
    deleteCategoryProduct,
    deleteCategoryProductSucceed,
    deleteCategoryProductFailed,
    saveProductModifiers,
    saveProductModifiersSucceed,
    saveProductModifiersFailed,
    getHipscanTotalStatsSucceed,
    getHipscanTotalStatsFailed,
    getHipscanFilterSucceed,
    getHipscanFilterFailed,
    saveCustomDesignSucceed,
    saveCustomDesignFailed,
    previewCustomDesignFailed,
    previewCustomDesignSucceed,
    addPartnerHipscanSucceed,
    addPartnerHipscanFailed,
    getPartnerHipscansSucceed,
    getPartnerHipscansFailed,
    getPartnerHipscans,

    addPartnerStamp,
    addPartnerStampFailed,
    addPartnerStampSucceed,

    addFollowStamp,
    addFollowStampFailed,
    addFollowStampSucceed,

    getPartnerStamps,
    getPartnerStampsSucceed,
    getPartnerStampsFailed
} from './profileActions';
import { getHashes } from 'crypto';
import { getHipscans, getHipscanDetail, addHipscan, updateHipscan, updateContact, getHipscanByUuid, downloadQrCode, getHipscanStats, getHipscanTotalStats, deleteHipscan, getHipscanFilter, saveCustomDesign, previewCustomDesign } from './profileApi';

const defaultState = {
    profile: null,
    myHipscans:null,
    partnerHipscans:null,
    myHipscanDetail: null,
    categories: null,
    products: null,
    error:null,
    loading:false,
    message:'',
    success:false,
    updateHipscan: null,
    updatePartnerHipscan: null,
    deletedId: null,
    addedHipscan: null,
    hipscanTotalStats: 0,
    previewCustomDesign: null,
    stamp: null,
    partnerStamps: null,
    isStamped: false
}

const reducer = handleActions({
    [ getHipscans ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ getHipscansFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error:true,
            loading:false,
        }
    },
    [ getHipscansSucceed ] (state, { payload: { myHipscans }}){
        return {
            ...state,
            loading:false,
            myHipscans
        }
    },
    [ getPartnerHipscans ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ getPartnerHipscansFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error:true,
            loading:false,
        }
    },
    [ getPartnerHipscansSucceed ] (state, { payload: { partnerHipscans }}){
        return {
            ...state,
            loading:false,
            partnerHipscans,
            updatePartnerHipscan: null
        }
    },
    [ addPartnerStamp ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ addPartnerStampSucceed ] (state, { payload: { res }}){
        return {
            ...state,
            loading:false,
            stamp: 'added'
        }
    },
    [ addPartnerStampFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error:true,
            loading:false,
        }
    },
    [ addFollowStamp ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ addFollowStampSucceed ] (state, { payload: { res }}){
        return {
            ...state,
            loading:false,
            stamp: 'added'
        }
    },
    [ addFollowStampFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error:true,
            loading:false,
        }
    },
    [ getPartnerStamps ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ getPartnerStampsSucceed ] (state, { payload: {stamps} }){
        return {
            ...state,
            loading:false,
            isStamped: stamps.isStamped,
            partnerStamps: {
                stamped: stamps.stampedLogo,
                unstamped: stamps.unstampedLogo
            }
        }
    },
    [ getPartnerStampsFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error:true,
            loading:false,
        }
    },
    [ getHipscanDetail ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ getHipscanDetailFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error:true,
            loading:false,
        }
    },
    [ getHipscanDetailSucceed ] (state, { payload: { myHipscanDetail }}){
        return {
            ...state,
            loading:false,
            myHipscanDetail
        }
    },
    [ addHipscan ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ addHipscanSucceed ] (state, { payload: { addedHipscan }}) {
        return {
            ...state,
            error:null,
            loading:false,
            addedHipscan,
            success:true,
            message:'New hipscan have been created successfully'
        }
    },
    [ addHipscanFailed ] (state, {payload: { error }}){
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },
    [ updateHipscan ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ updateHipscanFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },
    [ updateHipscanSucceed ] (state, { payload: { updateHipscan }}){
        return {
            ...state,
            error:null,
            loading:false,
            success:true,
            updateHipscan,
            message:'The hipscan have been updated successfully'
        }
    },
    [ saveCustomDesign ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ saveCustomDesignFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },
    [ saveCustomDesignSucceed ] (state, { payload: { saveCustomDesign }}){
        return {
            ...state,
            error:null,
            loading:false,
            success:true,
            saveCustomDesign,
            message:'The custom design have been saved successfully'
        }
    },
    [ previewCustomDesign ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ previewCustomDesignFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },
    [ previewCustomDesignSucceed ] (state, { payload: { previewCustomDesign }}){
        return {
            ...state,
            error:null,
            loading:false,
            success:true,
            previewCustomDesign,
            message:'The custom design preview returned successfully'
        }
    },
    [ updateContact ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ updateContactFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },
    [ updateContactSucceed ] (state, { payload: { updatedContact } }){
        return {
            ...state,
            error:null,
            loading:false,
            success:true,
            updatedContact,
            message:'The hipscan have been updated successfully'
        }
    },
    [ getHipscanByUuid ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ getHipscanByUuidFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error:true,
            loading:false,
        }
    },
    [ getHipscanByUuidSucceed ] (state, { payload: { myHipscanDetail }}){
        return {
            ...state,
            loading:false,
            myHipscanDetail
        }
    },
    [ downloadQrCode ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ downloadQrCodeFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },
    [ downloadQrCodeSucceed ] (state, { payload: { qrCode }}){
        return {
            ...state,
            error:null,
            loading:false,
            success:true,
            message:'The QR code have been exported successfully',
            qrCode
        }
    },
    [ getHipscanStats ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ getHipscanStatsFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error:true,
            loading:false,
        }
    },
    [ getHipscanStatsSucceed ] (state, { payload: { hipscanStats }}){
        return {
            ...state,
            loading:false,
            hipscanStats
        }
    },
    [ getHipscanTotalStats ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ getHipscanTotalStatsFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error:true,
            loading:false,
        }
    },
    [ getHipscanTotalStatsSucceed ] (state, { payload: { hipscanTotalStats }}){
        return {
            ...state,
            loading:false,
            hipscanTotalStats
        }
    },
    [ getHipscanFilter ] (state) {
        return {
            ...state,
            error: null,
            loading: true,
        }
    },
    [ getHipscanFilterFailed ] (state, { payload: { error } }) {
        return {
            ...state,
            error: true,
            loading: false,
        }
    },
    [ getHipscanFilterSucceed ] (state, { payload: { hipscanFilter } }) {
        return {
            ...state,
            loading: false,
            hipscanFilter
        }
    },
    [ addPartnerHipscanSucceed ] (state, { payload: { myHipscanDetail } }) {
      return {
        state,
        updatePartnerHipscan: true
      };
    },
    [ addPartnerHipscanFailed ] (state) {
      return state;
    },
    [ deleteHipscan ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ deleteHipscanFailed ] (state, { payload: { error }}) {
        return {
            ...state,
            error:true,
            loading:false,
        }
    },
    [ deleteHipscanSucceed ] (state, { payload: { deletedId }}){
        return {
            ...state,
            loading:false,
            message:'The hipcan have been deleted successfully',
            deletedId
        }
    },

    //

    [ getHipscanCategories ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ getHipscanCategoriesSucceed ] (state, { payload: { categories }}) {
        return {
            ...state,
            error:null,
            loading:false,
            categories,
            products: categories.length > 0 ? (categories[0].products ? categories[0].products : []) : [],
            success:true,
            message:'New hipscan have been created successfully'
        }
    },
    [ getHipscanCategoriesFailed ] (state, {payload: { error }}){
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },

    [ saveHipscanCategory ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ saveHipscanCategorySucceed ] (state, { payload: { category }}) {
        let { categories } = state;
        let tempCategories = categories.slice();
        tempCategories.push(category);

        return {
            ...state,
            error:null,
            loading:false,
            categories: tempCategories,
            success:true,
            message:'New hipscan have been created successfully'
        }
    },
    [ saveHipscanCategoryFailed ] (state, {payload: { error }}){
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },

    [ updateHipscanCategory ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ updateHipscanCategorySucceed ] (state, { payload: { category }}) {
        let { categories } = state;
        let tempCategories = categories.slice();
        let item = tempCategories.find(it => it.id == category.id);
        let index = tempCategories.indexOf(item);
        tempCategories[index] = category;

        return {
            ...state,
            error:null,
            loading:false,
            categories: tempCategories,
            success:true,
            message:'New hipscan have been created successfully'
        }
    },
    [ updateHipscanCategoryFailed ] (state, {payload: { error }}){
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },

    [ deleteHipscanCategory ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ deleteHipscanCategorySucceed ] (state, { payload: { id }}) {
        let { categories } = state;
        let tempCategories = categories.slice();
        let item = tempCategories.find(it => it.id == id);
        let index = tempCategories.indexOf(item);
        tempCategories.splice(index, 1);

        return {
            ...state,
            error:null,
            loading:false,
            categories: tempCategories,
            products: [],
            success:true,
            message:'New hipscan have been created successfully'
        }
    },
    [ deleteHipscanCategoryFailed ] (state, {payload: { error }}){
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },

    [ getCategoryProduct ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ getCategoryProductSucceed ] (state, { payload: { products }}) {
        return {
            ...state,
            error:null,
            loading:false,
            products,
            success:true,
            message:'New hipscan have been created successfully'
        }
    },
    [ getCategoryProductFailed ] (state, {payload: { error }}){
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },

    [ saveCategoryProduct ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ saveCategoryProductSucceed ] (state, { payload: { product }}) {
        let { products } = state;
        let tempProducts = products.slice();
        tempProducts.push(product);

        return {
            ...state,
            error:null,
            loading:false,
            products: tempProducts,
            success:true,
            message:'New hipscan have been created successfully'
        }
    },
    [ saveCategoryProductFailed ] (state, {payload: { error }}){
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },

    [ updateCategoryProduct ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ updateCategoryProductSucceed ] (state, { payload: { addedHipscan }}) {
        return {
            ...state,
            error:null,
            loading:false,
            addedHipscan,
            success:true,
            message:'New hipscan have been created successfully'
        }
    },
    [ updateCategoryProductFailed ] (state, {payload: { error }}){
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },

    [ deleteCategoryProduct ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ deleteCategoryProductSucceed ] (state, { payload: { id }}) {
        let { products } = state;
        let tempProducts = products.slice();
        let prod = tempProducts.find(it => it.id == id);
        let index = tempProducts.indexOf(prod);
        tempProducts.splice(index, 1);

        return {
            ...state,
            error:null,
            loading:false,
            products: tempProducts,
            success:true,
            message:'New hipscan have been created successfully'
        }
    },
    [ deleteCategoryProductFailed ] (state, {payload: { error }}){
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },

    [ saveProductModifiers ] (state) {
        return {
            ...state,
            error:null,
            loading:true,
        }
    },
    [ saveProductModifiersSucceed ] (state, { payload: { prodId, modifiers }}) {
        let { products } = state;
        let tempProducts = products.slice();
        let prod = tempProducts.find(it => it.id == prodId);
        prod.modifiers = modifiers;

        return {
            ...state,
            error:null,
            loading:false,
            success:true,
            products: tempProducts,
            message:'New hipscan have been created successfully'
        }
    },
    [ saveProductModifiersFailed ] (state, {payload: { error }}){
        return {
            ...state,
            error: error && error.data && error.data.Message ? error.data.Message : 'Error: Something was wrong!',
            loading:false,
            success:false,
        }
    },
}, defaultState);

export default reducer;
