import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const PartnerEventHipscanCount = props => {

  const [state, setState] = useState({
    value: ''
  })
  const [buttonClick, setButtonClick] = useState(false)

  const handleInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleCountSet = () => {
    setButtonClick(true)
    if(state.value !== '') {
      props.handleValueSet(state.value)
    }
  }

  return <Modal
    show={props.modalStatus}
    onHide={() => props.changeModalStatusHandler(false)}
    dialogClassName='launch-event-modal'
    className='launch-event-modal d-none d-md-block'
    aria-labelledby='example-custom-modal-styling-title'
  >
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body className='launch-event-modal-body'>
      <h1 className='modal-title mb-2'>
        Enter The Number of Partners You Want Included In This Digital Scan Card
      </h1>
      <p>A corresponding number of Hipscans will be created (1 for each partner)</p>

      <div className='input-field mt-4'>
        <div className='common-input'>
          <input
            type='number'
            name='value'
            placeholder='Number of Partners'
            value={state.value}
            onChange={handleInputChange}
            className='partners-input'
            ></input>
        </div>
      </div>
      {buttonClick && state.value === "" && <p className='error-text text-center'>Please enter No. of Partner.</p>}

      <div className='save-continue-btn mt-3'>
        <Button onClick={handleCountSet}>CONFIRM AND CONFIGURE</Button>
      </div>
    </Modal.Body>
  </Modal>
};

export default PartnerEventHipscanCount;