import { wrapRequest, xapi } from '../utils';

const getHipscans =  wrapRequest( async () =>
    {
        return xapi().get('/qr')
    }
)

const getPartnerHipscans =  wrapRequest( async () =>
    {
        return xapi().get(`/qr/partners/`)
    }
)

const getHipscanDetail =  wrapRequest( async (id) =>
    xapi().get(`/qr/${id}`)
)

const addHipscan = wrapRequest( async (label) => {
        var formData = new FormData();
        formData.append("label", label);
        var result = xapi('multipart/form-data').post('/qr/init', formData)
        return result
    }
)

const updateHipscan = wrapRequest( async (id, params) => {
        var formData = new FormData();
        for (let p in params) {
            formData.append(p, params[p]);
        }
        return xapi('multipart/form-data').put(`/qr/${id}`, formData)
    }
)

const saveCustomDesign = wrapRequest( async (id, params) => {
    var formData = new FormData();
    for (let p in params) {
      formData.append(p, params[p]);
    }
    return xapi('multipart/form-data').put(`/qr/${id}/design`, formData)
  }
)

const previewCustomDesign = wrapRequest( async (id, params) => {
    var formData = new FormData();
    for (let p in params) {
      formData.append(p, params[p]);
    }
    return xapi('multipart/form-data').put(`/qr/${id}/design-preview`, formData)
  }
)

const updateContact = wrapRequest( async (id, params) => {
        var formData = new FormData();
        for (let p in params) {
            formData.append(p, params[p]);
        }
        return xapi('multipart/form-data').put(`/qr/${id}/contact`, formData)
    }
)

const getHipscanByUuid =  wrapRequest( async (uuid) =>
    xapi().get(`/public/${uuid}`)
)

const downloadQrCode = wrapRequest( async (id, params) => {
        var formData = new FormData();
        for (let p in params) {
            formData.append(p, params[p]);
        }
        return xapi('multipart/form-data').post(`/qr/${id}/code`, formData)
    }
)

const getHipscanCategories = wrapRequest( async (id) => {
    return xapi().get(`/qr/${id}/categories`)
})

const saveHipScanCategory = wrapRequest( async (id, name) => {
    var formData = new FormData();
    formData.append('name', name);

    return xapi('multipart/form-data').post(`/qr/${id}/category`, formData);
})

const updateHipScanCategory = wrapRequest( async (hipId, id, name) => {
    var formData = new FormData();
    formData.append('name', name);

    return xapi('multipart/form-data').put(`/qr/${hipId}/category/${id}`, formData);
})

const deleteHipScanCategory = wrapRequest( async (hipId, catId) => {
    return xapi().delete(`/qr/${hipId}/category/${catId}`);
})

const getCategoryProducts = wrapRequest( async (hipId, catId) => {
    return xapi().get(`/qr/${hipId}/category/${catId}/products`)
})

const getProductDetail = wrapRequest( async (hipId, catId, prodId) => {
    return xapi().get(`/qr/${hipId}/category/${catId}`)
})

const saveProduct = wrapRequest( async (hipId, catId, name) => {
    var formData = new FormData();
    formData.append('name', name);

    return xapi('multipart/form-data').post(`/qr/${hipId}/category/${catId}/product`, formData)
})

const updateProduct = wrapRequest( async (hipId, catId, id, params) => {
    var formData = new FormData();
    for (let p in params) {
        formData.append(p, params[p]);
    }
    return xapi('multipart/form-data').put(`/qr/${hipId}/category/${catId}/product/${id}`, formData)
})

const deleteProduct = wrapRequest( async (hipId, catId, prodId) => {
    return xapi().delete(`/qr/${hipId}/category/${catId}/product/${prodId}`)
})

const addModifiers = wrapRequest( async (hipId, catId, prodId, params) => {
    var formData = new FormData();
    for (let p of params) {
        formData.append('name', p.name);
        formData.append('price', p.price);
    }
    return xapi('multipart/form-data').post(`/qr/${hipId}/category/${catId}/product/${prodId}/modifiers`, formData)
})

const getHipscanStats =  wrapRequest( async (id) =>
    xapi().get(`/qr/${id}/stats`)
)

const getHipscanTotalStats =  wrapRequest( async (id) =>
    xapi().get(`/qr/stats?private=1`)
)

const getHipscanFilter = wrapRequest( async (id, from, to, group) => {
    let url = `/qr/filter`
    if (group) { url += `?group=${group}` } else { url += `?group=day` }
    if (id) { url += `&id=${id}` }
    if (from) { url += `&date-from=${from}` }
    if (to) { url += `&date-to=${to}` }

    return xapi().get(url)
})

const deleteHipscan = wrapRequest( async (id) =>
    xapi().delete(`/qr/${id}`)
)

const addPartnerHipscan = wrapRequest(async ({userId, info}) => {
  var formData = new FormData();

  formData.append('eventName', info['event'].title);
  formData.append('eventLogo', info['event'].logo);
  formData.append('eventDescription', info['event'].description);

  for (let p of info['hipscan']) {
    formData.append('name', p.name);
    formData.append('logo', p.logo);
  }
  return xapi('multipart/form-data').post(`/event`, formData)
})

const addPartnerStamp = wrapRequest(async ({name, email, qr_id}) => {
    var formData = new FormData();

    formData.append('name', name);
    formData.append('email', email);
    return xapi('multipart/form-data').post(`/partner/${qr_id}/stamp`, formData)
})

const addFollowStamp = wrapRequest(async ({firstName, lastName, email, phoneNumber, uuid}) => {
    var formData = new FormData();

    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('phoneNumber', phoneNumber);
    formData.append('uuid', uuid);
    return xapi('multipart/form-data').post(`/partner/followup`, formData)
})

const getPartnerStamps = wrapRequest(async (id) => {
    return xapi().get(`/partner/${id}/stamps`)
})

export {
    getHipscans,
    getPartnerHipscans,
    getHipscanDetail,
    addHipscan,
    updateHipscan,
    updateContact,
    getHipscanByUuid,
    downloadQrCode,
    getHipscanStats,
    deleteHipscan,
    getHipscanCategories,
    saveHipScanCategory,
    updateHipScanCategory,
    deleteHipScanCategory,
    getCategoryProducts,
    getProductDetail,
    saveProduct,
    updateProduct,
    deleteProduct,
    addModifiers,
    getHipscanTotalStats,
    getHipscanFilter,
    saveCustomDesign,
    previewCustomDesign,
    addPartnerHipscan,
    addPartnerStamp,
    addFollowStamp,
    getPartnerStamps
}
