import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {addFollowStamp} from "../../services/profile/profileActions"

import "./FollowUp.css";

const FollowUp = (props) => {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const params = useParams()
  
  const inputChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const submitHandler = () => {
    props.profileActions.addFollowStamp(state.firstName, state.lastName, state.email, state.phoneNumber, params.uuid)
  }

  return (
    <div className="profile-preview-follow container-fluid ">
      <div className="profile">
        <div className="contact-info mb-2">
          <input
            type="text"
            name="firstName"
            placeholder="Enter First Name"
            value={state.firstName}
            onChange={inputChangeHandler}
            className="user-input mb-3"
          ></input>
          <input
            type="text"
            name="lastName"
            placeholder="Enter Last Name"
            value={state.lastName}
            onChange={inputChangeHandler}
            className="user-input mb-3"
          ></input>
          <input
            type="text"
            name="phoneNumber"
            placeholder="Enter Contact Number"
            value={state.phoneNumber}
            onChange={inputChangeHandler}
            className="user-input mb-3"
          ></input>
          <input
            type="text"
            name="email"
            placeholder="Enter Email Address"
            value={state.email}
            onChange={inputChangeHandler}
            className="user-input mb-3"
          ></input>
        </div>

        <div className="submit">
          <Button onClick={submitHandler}>SUBMIT</Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.default.services.profile,
  }
}

const mapDispatchToProps = (dispatch) => ({
  profileActions: bindActionCreators(
    { addFollowStamp },
    dispatch
  )
})


export default connect(mapStateToProps, mapDispatchToProps)(FollowUp);
