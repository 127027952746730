import React, { useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { GiCancel, GiOpenFolder } from "react-icons/gi";

import './PartnerEventInfo.css';


const PartnerEventInfo = props => {
  const [state, setState] = useState({
    title: '',
    logo: null,
    description: ''
  })
  const [buttonClick, setButtonClick] = useState(false)

  const handleInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleLogoSelect = (e) => {
    setState({ ...state, [e.target.name]: e.target.files[0] })
  }

  const handleEventSet = () => {
    setButtonClick(true)
    if(state.title !== "" && state.logo !== null && state.description !== '') {
      props.setEventInfoHandler(state)
    }
  }

  return <Modal
    show={props.modalStatus}
    onHide={() => props.changeModalStatusHandler(false)}
    dialogClassName="launch-event-modal"
    className="launch-event-modal d-none d-md-block"
    aria-labelledby="example-custom-modal-styling-title"
  >
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body className="launch-event-modal-body">
      <h1 className="modal-title mb-2">
        Welcome to the event module. 
      </h1>
      <p>To begin, name your event, upload your events logo and include a description of your event.</p>

      <div className="input-field1 d-flex mb-3">
        <div className="common-input">
          <input
            type="string"
            name= 'title'
            placeholder="Name of Event"
            value={state.title}
            onChange={handleInputChange}
          ></input>
        </div>
      </div>
      {buttonClick && state.title === "" && <p className='error-text'>Please enter title.</p>}

      <div className="input-field1 d-flex mb-3">
        <div className="file-upload">
          <input
            id="selectEventLogo"
            name= 'logo'
            type="file"
            placeholder="Custom Image Type"
            onChange={handleLogoSelect}
            hidden
          ></input>
          <span>
            {state.logo?.name? state.logo.name:"Upload Logo"}
          </span>
          <div className="icon left" onClick={() => document.getElementById("selectEventLogo").click()}>
            <GiOpenFolder size="2.5rem" />
          </div>
          <div className="icon" onClick={() => setState({ ...state, logo: null })}>
            <GiCancel size="2.5rem" />
          </div>
        </div>
      </div>
      {buttonClick && state.logo === null && <p className='error-text'>Please upload logo.</p>}

      <div className="input-field1 mb-3 textarea-input">
        <textarea 
          id="#" 
          name='description'
          placeholder="Description of Event" 
          onChange={handleInputChange}
          value={state.description}
          maxLength="600"
        />
        <p className="letter-count">
          {state.description.length} / 600
        </p>
      </div>
      {buttonClick && state.description === "" && <p className='error-text'>Please enter description.</p>}

      <div className="save-continue-btn mt-3">
        <Button onClick={handleEventSet}>CONTINUE</Button>
      </div>
    </Modal.Body>
  </Modal>
};

export default PartnerEventInfo;
