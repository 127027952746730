import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'react-bootstrap';
import ModalWrapper from '../ModalWrapper';

//import actions
import { addHipscan } from '../../../services/profile/profileActions';

class HipscanAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label:'',
            type:'url',
            url:'hipscan.com'
        }

        this.onChange= this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({ label: e.target.value})
    }

    render() {
        return (
            <ModalWrapper
                title="Create new Hipscan"
                okText="Create My New Hipscan"
                onOk={ ()=> {
                    this.props.profileActions.addHipscan(this.state.label);
                }}
            >
                <Form>
                    <Form.Group controlId="label">
                        <Form.Label>What do you want to call your new Hipscan?</Form.Label>
                        <Form.Control type="text"  placeholder="London Store" onChange={this.onChange} />
                    </Form.Group>
                </Form>
            </ModalWrapper>
        )
    }
}

export default connect(
    null,
    dispatch => ({
        profileActions: bindActionCreators({ addHipscan }, dispatch)
    })
)(HipscanAdd)
