import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'react-bootstrap';
import ModalWrapper from '../ModalWrapper';

//import actions
import { deleteHipscan } from '../../../services/profile/profileActions'; 

class HipscanDelete extends React.Component {
    render() {
        return (
            <ModalWrapper
                title="Delete Hipscan"
                onOk={ ()=> {
                    this.props.profileActions.deleteHipscan(this.props.modal.params.id);
                }}
                isConfirm="yes"
            >
                <Form>
                    <Form.Group controlId="label">
                        <Form.Label>Are you sure to delete?</Form.Label>
                    </Form.Group>
                </Form>
            </ModalWrapper>
        )
    }
}

export default connect(
    null,
    dispatch => ({
        profileActions: bindActionCreators({ deleteHipscan }, dispatch)
    })
)(HipscanDelete)